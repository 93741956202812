<form
  id="formCollect"
  method="post"
  #form
>
  <div class="spacing__margin-top-xs form-container" *ngIf="this.fields">
    <voxel-container>

      <div class="spacing__margin-bottom-xs">
        <voxel-row>
          <voxel-item
            *ngFor="let field of this.fields; let i = index"
            [mc]="12"
            [tc]="12"
            [dc]="6"
          >
            <custom-input
              [id]="field.id"
              [type]="field.type"
              [name]="field.type"
              [label]="field.label"
              [description]="field.description"
              [required]="field.required"
              [placeholder]="field.placeholder"
              [options]='field.dropdownValues'
              [option-label]="field.type == 'dropdownSearch' ? 'search' : 'key'"
              [max]="field?.tamanho_maximo"
              [min]="field?.tamanho_minimo"
              [value]="field.value"
              [listname]="field.listname"
              (onBlur)="setAgeRange($event); getInput($event, i);"
              (validate)="getInput($event, i)"
              [validation]="field.validation_api"
            >
            </custom-input>
          </voxel-item>
        </voxel-row>
      </div>
    </voxel-container>

    <div
    class="spacing__margin-top-xs spacing__margin-bottom-xs button-container"
  >
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <input [value]="this.taskid" type="hidden" name="taskid" />
          <input [value]="this.route" type="hidden" name="route" />
          <voxel-button
            submit-button
            (click)="trackClick()"
            label="Próximo"
            [disabled]="this.hasFormError()"
          >
          </voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>
  </div>
</form>

<voxel-dialog
  [(showDialog)]="showModal"
  [items]="items"
  [description]="this.modalDescription"
  (dialogLinkClick)="closeModal()"
>
</voxel-dialog>

<modal-dialog
  title="Aviso"
  overlay="true"
  btn1="fechar"
  type1="close"
  class1="freeline"
>
  <div subt>
    Não foi possível validar os dados, por favor tente novamente.
  </div>
</modal-dialog>
