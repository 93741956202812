import { Component, OnInit, Input, ViewContainerRef, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ViewCompileResult } from '@angular/compiler/src/view_compiler/view_compiler';
import { BehaviorSubject, Observable } from 'rxjs';
import { removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { OcrService } from '../../shared/services/ocr.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'photo-document',
  templateUrl: './photo-document.component.html',
  styleUrls: ['./photo-document.component.css']
})
export class PhotoDocumentComponent extends GlobalProperties {

  @Input() native: any = null;
  @Input('inputs') inputs: string;

  @ViewChild('form') form: HTMLFormElement;
  @ViewChild('photoElement') photoElement: Element;
  @ViewChild('itens') actualContent: ElementRef;

  route: string;
  fields: any;
  taskid: string;
  items: Array<any>;
  idTipoConteudo: string;
  showModal: boolean = false;
  photo = { front: '', back: '' };
  capturePhoto = { front: '', back: '' };
  photoDocumentError = false;
  featureToggleOCRCNH: boolean;
  pageName;
  customer_id: string;
  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'FotoDocumento'
  };
  readonly PAGE_NAME_PEND_DOC = {
    section: 'NovoCartao',
    page: 'PendenciaDocumento'
  };
  private thePhoto: BehaviorSubject<any>;

  constructor(
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ocrService: OcrService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
    this.items = [{ id: '1', content: 'Ok', a11yLabel: 'Ok' }];
    this.thePhoto = new BehaviorSubject<any>('');
  }

  public getPhoto(): Observable<any> {
    return this.thePhoto.asObservable();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        console.log('photoDocument: ', data);
        this.setFields(data);
      }
      )).subscribe();
    this.appDynamicsService.callAppAdynamics(this.elementRef);
    // const data = JSON.parse(this.inputs);
    // console.log(data);
    // this.setFields(data);
  }

  setFields(viewModel: any) {
    this.fields = viewModel.fields;
    this.photoDocumentError = viewModel.domains.pendenciaFotoDocumento;
    this.route = viewModel.route;
    this.taskid = viewModel.taskId;
    this.featureToggleOCRCNH = viewModel.domains.featureToggleOCRCNH;
    this.customer_id = viewModel.customer_id || '';
    this.callAnalytics(this.photoDocumentError);
    this.sendDataToAndroid(viewModel);

    const tsObject = this;
    this.getPhoto().subscribe(value => {
      if (value && value.front !== '' && value.back !== '') {
        tsObject.photo.front = value.front;
        tsObject.photo.back = value.back;

        setTimeout(() => {
          this.form.nativeElement.submit();
        }, 2000);
      }
    });
  }

  closeModal() {
    this.showModal = false;
  }

  callAnalytics(photoDocumentError: boolean): void {

    if (photoDocumentError) {
      this.pageName = this.PAGE_NAME_PEND_DOC;
    } else {
      this.pageName = this.PAGE_NAME;
    }
    this.analyticsService.trackPageLoad(this.pageName, this.customer_id);
    this.ga4Service.trackPageLoad(this.pageName, this.customer_id);
  }

  sendDataToAndroid(bffParameters): void {
    const sdtoreData = {
      partner_name: bffParameters.partner_name,
      operator_id: bffParameters.operator_id,
      store_id: bffParameters.store_id,
      store_name: bffParameters.store_name,
      customer_id: bffParameters.customer_id
    };
    this.dnaService.post('', 'operatorStoreInfo', sdtoreData, false).subscribe();
  }

  captureDocuments(tipoConteudo, maskFront, maskBack) {
    this.idTipoConteudo = tipoConteudo;

    if (this.all_properties['--simulador'] == 'true') {
      if (this.featureToggleOCRCNH && tipoConteudo === '37') {
        this.ocrService.postDocumentFrontPicture('photo').subscribe(
          (value) => { console.log(value); },
          (err) => { console.error(err); }
        );
      }
      this.loadPhotoMock();
    } else {

      this.dnaService.post('', 'captureDocto', maskFront, false)
        .pipe(
          map(resultFront => {

            if (resultFront.sucess != "true" || !resultFront.escryptedMessage) {
              removeLoader();
              this.showModal = true;
            } else {
              this.capturePhoto.front = resultFront.escryptedMessage;
              if (this.featureToggleOCRCNH && tipoConteudo === '37') {
                this.ocrService.postDocumentFrontPicture(resultFront.escryptedMessage).subscribe(
                  (value) => { console.log(value); },
                  (err) => { console.error(err); }
                );
              }
              this.dnaService.post('', 'captureDocto', maskBack, false)
                .pipe(
                  map(resultBack => {

                    if (resultBack.sucess != "true" || !resultBack.escryptedMessage) {
                      removeLoader();
                      this.showModal = true;
                    } else {
                      this.capturePhoto.back = resultBack.escryptedMessage
                      this.thePhoto.next(this.capturePhoto);
                    }

                  })
                ).subscribe()
            }
          })
        ).subscribe()
    }
  }

  captureRG() {
    this.trackClick('RG');
    this.captureDocuments("35", "DOCUMENT_MASK_RG_FRONT", "DOCUMENT_MASK_RG_BACK")
  }

  captureOthers() {
    this.captureDocuments("1146", "FILTER_MASK_NOMASK", "FILTER_MASK_NOMASK")
  }

  captureCNH() {
    this.trackClick('CNH');
    this.captureDocuments("37", "DOCUMENT_MASK_CNH_FRONT", "DOCUMENT_MASK_CNH_BACK")
  }

  loadPhotoMock() {
    console.log("Mock ativado");
    this.thePhoto.next({ front: 'front', back: 'back' });
  }

  trackClick(document: string): void {
    this.analyticsService.trackClick(this.pageName,
      {
        category: 'Clique',
        action: 'FotoDocumentoBT',
        label: document
      });
    this.ga4Service.trackEvent('click', this.pageName, document);
  }
}
