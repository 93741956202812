<div class="header-general">
    <voxel-container>
        <voxel-row [ngClass]="{'adjusts': logo}">
          <voxel-item dc="12" tc="12" mc="12">
            <voxel-header
                id="header"
                dc="12" mc="12" tc="12"
                custom-header
                [title]="this.title"
                [logo]="this.logoIcon"
                [leftIcon]="this.leftIcon"
                [rightIcon]="this.rightIcon"
                [icons]="icons"
                headerAccessibility="voltar"
                (headerPrimaryClick)="sidebarComponent.toggleMenu()"
                (headerSecundaryClick)="sidebarComponent.toggleMenu()">
            </voxel-header>
          </voxel-item>
        </voxel-row>
        <voxel-row class="adjusting-background">
            <voxel-item dc="12" tc="12" mc="12" >
                    <p class="subtitle">{{this.subtitle}}</p>
                    <percentage-bar *ngIf="percentTrack" [percent]="this.percentTrack"></percentage-bar>
            </voxel-item>
            <div *ngIf="arraysearch == null" class="shadow">&nbsp;</div>
        </voxel-row>

        <voxel-row *ngIf="arraysearch != null">
            <div class="search">
                <voxel-input id="input-cpf-cnpj" placeholder="Digite CPF do cliente" mask="cpf-cnpj"
                type="tel" (inputChange)="onInputChange($event)"></voxel-input>
                <img [src]="this.path_assets + 'icone_busca.svg'">
            </div>
            <div class="shadow">&nbsp;</div>
        </voxel-row>
    </voxel-container>
    <div *ngIf="percentTrack==null" class="line"></div>
</div>

<sidebar *ngIf="this.sidebarmenu && this.parsedSidebarMenu.length != 0" [inputs]="sidebarmenu"></sidebar>
