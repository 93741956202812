import { NgModule } from '@angular/core';
import { PercentageBarComponent } from './lib/percentage-bar.component/percentage-bar.component';

@NgModule({
  declarations: [PercentageBarComponent],
  imports: [
  ],
  exports: [PercentageBarComponent]
})
export class PercentageBarModule { }
