import { Component, OnInit, Input, Renderer2, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/BG4/shared/modal/modal.component';
import { AnalyticsService } from 'src/app/BG4/shared/services/analytics.service';
import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { isString } from 'util';


interface GAPageInfo {
  section: string,
  page: string
}



@Component({
  selector: 'generic-select-service-insurance',
  templateUrl: './generic-select-service-insurance.component.html',
  styleUrls: ['./generic-select-service-insurance.component.css']
})
export class GenericSelectServiceInsuranceComponent implements OnInit {

  @Input("service-id") serviceId: string;
  @Input("service-name") serviceName: string;
  @Input("icon") icon: string;
  @Input("accordion-description") accordionDescription: string;
  @Input("accordion-invoice") accordionInvoice: any;
  @Input("accordion-advantages") accordionAdvantages: any;
  @Input("checked") checked: boolean;
  @Input("has-border-top") hasBorderTop: boolean = false;
  @Input("select-type") selectType: string;
  @Input('default-show') show: boolean = false;
  @Input('ga-page-info') gaPageInfo:GAPageInfo = {
    section: 'NovoCartao',
    page: 'TermoEResumo'
  }
  @Output("change-value") changeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild(ModalComponent) modalComponent: ModalComponent;
  modalContent = "";

  disable: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service
  ) { }

  ngOnInit() {
    this.optionsAdvantages();
   }

  private optionsAdvantages(): void {
    if (typeof (this.accordionAdvantages) !== 'undefined' &&
      this.accordionAdvantages !== null) {

      if (isString(this.accordionAdvantages)) {
        this.accordionAdvantages = JSON.parse(this.accordionAdvantages);
      }

      // variaveis locais -> nome das key
      const aecServiceId = 'adesao_avaliacao_emergencial_credito';
      const keyFaturaBlock = 'fatura_block';
      const keyDescription = 'description';
      const keyValor = 'valor';
      const textValor = 'texto_valor';

      this.accordionAdvantages.forEach(field => {

        if (field.key === keyFaturaBlock) {
          this.disable = true;
        }

        if (this.serviceId === aecServiceId) {
          this.disable = true;
        }

        if (field.key === "more") {
          this.modalContent = field.value;
        }

        if (field.key !== keyValor &&
          field.key !== keyFaturaBlock &&
          field.key !== textValor &&
          this.accordionAdvantages.filter(f => f.key === keyDescription).length === 0) {

            field.key = keyDescription;
          }
      });
    } else {
      this.accordionAdvantages = [];
    }
  }

  toogle() {
    this.show = !this.show;

    if (!this.show) return

    this.analyticsService.trackClick( this.gaPageInfo, {
      category: 'Clique',
      action: 'ContratoeServBT',
      label: 'ContratoeServ'
    });
    this.ga4Service.trackEvent('click', this.gaPageInfo, 'ContratoeServ');

  }

  checkChange($event) {
    this.changeValue.emit($event);
  }

}
