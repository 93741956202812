<form
  id="formPreviousAnalysis"
  asp-action="Index"
  asp-controller="ChooseCreditCardFamily"
  method="post"
>
  <voxel-container>
    <input [value]="this.route" type="text" name="route" hidden />
    <input [value]="this.taskId" type="text" name="taskid" hidden />
    <input
      [value]="this.id"
      type="text"
      id="fields[0].value"
      name="fields[0].value"
      hidden
    />
    <input
      [value]="this.value"
      type="text"
      id="fields[0].id"
      name="fields[0].id"
      hidden
    />

    <voxel-row class="header">
      <voxel-item dc="12" tc="12" mc="12">
        <card-header
          icon="icon-itaufonts_cartao"
          title="RESULTADO PRÉ-ANÁLISE"
          subtitle="Agora é necessário solicitar o cartão que o cliente deseja aderir."
        >
        </card-header>
      </voxel-item>
    </voxel-row>

    <modal-dialog
      title="Continuar proposta"
      display="true"
      overlay="true"
      btn1="Sim"
      type1="submit"
      class1="freeline"
      [link1]="domains?.rotaContinuarProposta"
      btn2="Não, criar novo cartão"
      type2="submit"
      class2="freeline"
      [link2]="domains?.rotaNovaProposta"
    >
      <div subt>
        Existe uma proposta em andamento para este CPF. Deseja continuar com
        esta proposta?
      </div>
      <div body class="modal">
        <span style="display: block" class="icon-itaufonts_cartao"></span>
        <div class="modal-elements">
          <small
            style="
              color: #969290;
              font-size: 12px;
              font-weight: 300;
              line-height: 20px;
            "
            >nome</small
          >
          <div
            style="
              color: #252220;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
            "
          >
            {{ domains?.produto }}
          </div>
        </div>
        <br />
        <div class="modal-elements">
          <small
            style="
              color: #969290;
              font-size: 12px;
              font-weight: 300;
              line-height: 20px;
            "
            >anuidade</small
          >
          <div
            style="
              color: #252220;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
            "
          >
            {{ domains?.anuidade }}
          </div>
        </div>
      </div>
    </modal-dialog>

    <!-- <card-analyze *ngFor="let domain of domains"
        [id]="domain.id"
        value="1"
        [selected]="this.selected?.id == domain.id"
        [cardname]="domain.nameCartao"
        [limit]="domain.limite"
        [annuity]="domain.anuidade"
        [first-installment]="domain.primeiraParcela"
        [others-installments]="domain.demaisParcelas"
        (onClick)='getCard($event)'
        [selected]='selected'></card-analyze> -->

    <card-analyze
      *ngFor="let domain of domains?.offters"
      [id]="domain.id"
      value="1"
      [selected]="this.selected?.id == domain.id"
      [cardname]="domain.nameCartao"
      [limit]="domain.limite"
      [annuity]="domain.anuidade"
      (onClick)="getCard($event)"
      [selected]="selected"
    ></card-analyze>

    <div class="button-group">
      <voxel-row class="alignment-button">
        <voxel-item dc="12" tc="12" mc="12">
          <voxel-button
            label="Criar Cartão"
            submit-button
            class="primary-button"
            [ngClass]="{ disabled: !selected }"
            [disabled]="!selected"
          ></voxel-button>
        </voxel-item>
      </voxel-row>

      <div style="padding-top: 8px"></div>

      <voxel-row class="alignment-button">
        <voxel-item dc="12" tc="12" mc="12">
          <a [link]="base_path + '/home'" loader-button>
            <voxel-button
              label="Agora não"
              class="secondary-button"
            ></voxel-button>
          </a>
        </voxel-item>
      </voxel-row>
    </div>
  </voxel-container>
</form>
