<voxel-container class="voucher-print-confirmation">
  <voxel-row>
    <voxel-item class="header" tc='12' dc='12' mc='12'>
      <div class="icon">
        <span class="icon-itaufonts_impressora"></span>
      </div>
      <div class="title">
        <p>IMPRESSÃO DE TERMO DE ADESÃO</p>
      </div>
      <div class="subtitle">
        <p>Por favor, confirme se as informações legais foram impressas,
          caso o cliente tenha optado pelo envio das informações por e-mail, sinalize abaixo:</p>
      </div>
    </voxel-item>
  </voxel-row>

  <div class="btn-content">
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">

        <form id="formConfirmation" #form asp-action="Index" asp-controller="TermPrintConfirmation"
          method="post">
          <voxel-button id="btnSubmit" label="Informações legais impressas" (click)="submitForm(1)">
          </voxel-button>

          <voxel-button id="btnPrint" label="Imprimir novamente" (click)="onClickPrintTerm()"></voxel-button>

          <voxel-button id="btnDigital" label="Cliente optou pela via digital" (click)="submitForm(2)">
          </voxel-button>

          <input [attr.value]="fields?.id" type="text" [name]="fields?.listname + '.id'" hidden>
          <input [attr.value]="fields?.id" type="text" [name]="fields?.listname + '.value'" hidden #fieldValue>
        </form>

      </voxel-item>
    </voxel-row>
  </div>

</voxel-container>
