<form method="post" #form>

  <input type="text" name="taskid" [value]="this.taskid" hidden>
  <input type="text" name="route" [value]="this.route" hidden>
  <input type="text" name="domains.PhotoFront" [value]="this.photo?.front" hidden>
  <input type="text" name="domains.PhotoBack" [value]="this.photo?.back" hidden>
  <input type="text" name="domains.IdTipoConteudo" [value]="this.idTipoConteudo" hidden>

  <div class="spacing_empty"></div>

  <card-header title="Foto Documento"
  *ngIf="!photoDocumentError"
    subtitle="Capture a foto do documento do cliente, conforme escolha abaixo, para dar continuidade no processo de contratação de cartão de crédito."
    [svg]="this.path_assets + 'icon_fotodoc.svg'"></card-header>
  <card-header title="Erro Foto Documento" *ngIf="photoDocumentError" 
  subtitle="A foto do documento não foi enviada corretamente ou ficou ilegível. Para prosseguir, tire uma nova foto do documento."
  [svg]="this.path_assets + 'icon_fotodoc.svg'"></card-header>


  
  <div class="spacing__margin-left-xs">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12" *ngIf="!photoDocumentError">
          <p>Informe o cliente que poderemos utilizar sua foto e biometria facial em produtos e/ou serviços das empresas
            do Conglomerado Itaú para processos de identificação e/ou autenticação em sistemas eletrônicos próprios ou
            de terceiros para fins de segurança e prevenção a fraudes.</p>
        </voxel-item>
        <voxel-item tc="12" dc="12" mc="12" *ngIf="photoDocumentError">
          <p>Ah, importante! Certifique-se de que o documento está legível, e não se esqueça de primeiro tirar a foto da frente, e depois, do verso do documento.</p>
        </voxel-item>
      
      </voxel-row>
    </voxel-container>
  </div>

  <div class="spacing__primary">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <voxel-button loader-button label="RG" (click)="captureRG()">
          </voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>

  <div class="spacing__secundary">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <voxel-button loader-button label="CNH" (click)="captureCNH()">
          </voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>

  <!--<div class="spacing__third">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <voxel-button loader-button label="Outros (Documentos com foto)" (click)="captureOthers()">
          </voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>-->

  <voxel-dialog [(showDialog)]="showModal" [items]="items"
    description="Para dar continuidade ao processo, capture a foto do documento do cliente."
    (dialogLinkClick)="closeModal()">
  </voxel-dialog>

</form>