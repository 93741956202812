import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.css']
})
export class PercentageBarComponent implements OnInit {


  @Input("percent")
  set percent(percent: string) {
    if (parseInt(percent) < 0 || parseInt(percent) > 100)
      throw Error("Percent not in range")

    document.documentElement.style.setProperty('--percent', percent + "%");
  }

  @Input()
  color: string;


  constructor() { }

  /**
   * Define as cores para serem exibidas no percent bar
   */
  ngOnInit() {

    let color = this.color;

    if (! this.color) {
      color = document.documentElement.style.getPropertyValue("--primary-color");
    }
    
    return this.setColors(color);
  }

  /**
   * Define as cores que serão exibidas na barra de progresso
   * 
   * @param color 
   */
  setColors(color) {
    document.documentElement.style.setProperty('--percent-color', color);
  }
}
