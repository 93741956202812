<voxel-container>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <div class="header-content">
        <span class="icon-itaufonts_fatura icon first-icon"></span>
        <h2>detalhe da proposta</h2>
      </div>
    </voxel-item>
  </voxel-row>

  <!-- Status -->
  <div class="proposal-detail__status">
    <voxel-row class=" spacing__margin-bottom-xs">
      <voxel-item dc="12" tc="12" mc="12">
        <div class="label">status</div>

        <div [ngClass]="getClassByStatus(proposal?.status_proposta)">
          {{ proposal?.status_proposta ? proposal?.status_proposta : '-' }}
        </div>

      </voxel-item>

    </voxel-row>

    <voxel-row>
      <voxel-item dc="3" tc="12" mc="12">
        <div class="label">
          número da proposta
        </div>
        <div class="value">
          {{ proposal?.id_proposta ? proposal?.id_proposta : '-' }}
        </div>
      </voxel-item>

      <voxel-item dc="4" tc="12" mc="12">
        <div class="label">
          data da proposta
        </div>
        <div class="value">
          {{ proposal?.data_criacao_proposta_venda }} - {{ proposal?.hora_criacao_proposta_venda }}
        </div>
      </voxel-item>

      <voxel-item dc="5" tc="12" mc="12">
        <div class="label">loja</div>

        <div class="value">
          {{ proposal?.loja ? proposal?.loja : '-' }}
        </div>
      </voxel-item>
    </voxel-row>
  </div>
  <!-- /Status -->

  <!-- wrapper -->

  <div class="proposal-detail__wrapper">
    <voxel-item dc="4" tc="12" mc="12">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <h3>dados pessoais</h3>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            nome
          </div>

          <div class="value">
            {{ proposal?.nome_cartao ? proposal?.nome_cartao : '-' }}
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            CPF
          </div>

          <div class="value">
            {{ proposal?.cpf ? proposal?.cpf : '-' }}
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            segmento
          </div>
          <div class="value">
            {{ proposal?.segmento ? proposal?.segmento : '-' }}
          </div>
        </voxel-item>
      </voxel-row>
    </voxel-item>

    <voxel-item dc="8" tc="12" mc="12">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <h3>CLASSIFICAÇÃO</h3>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">canal</div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="value">
            {{ proposal?.codigo_canal_venda ? proposal?.codigo_canal_venda : '-' }}
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            sub-canal
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="value">
            {{ proposal?.codigo_sub_canal_venda ? proposal?.codigo_sub_canal_venda : '-' }}
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            ação de vendas
          </div>
        </voxel-item>
      </voxel-row>

      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <div class="value">
            {{ proposal?.codigo_acao_venda ? proposal?.codigo_acao_venda : '-' }}
          </div>
        </voxel-item>
      </voxel-row>
    </voxel-item>
  </div>

  <!-- form -->
  <form #form id="formDetail" asp-action="Index" asp-controller="VoucherDetail" method="post">
    <div class="btn-content">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <a [link]="base_path + '/home'" loader-button (click)="onClickHome()">
            <voxel-button id="btnBack" label="Home"></voxel-button>
          </a>
        </voxel-item>
        <voxel-item dc="12" tc="12" mc="12">
          <voxel-button *ngIf="this.proposal.btn_imprimir && this.device === 'desktop'" id="btnSubmit" label="{{textDevice}} Voucher"
            (click)="onClickPrintTerm()"></voxel-button>

          <voxel-button *ngIf="this.proposal.btn_imprimir && this.device === 'mobile'" id="btnSubmit" label="{{textDevice}} Voucher" (click)="onSubmit(form, ipt)"></voxel-button>
        </voxel-item>
      </voxel-row>
    </div>

    <input #ipt name="keyPdf" hidden>
    <input #qtd name="qtdMax" hidden>

    <modal-dialog #voucher
      overlay="true"
      title="Escolha a quantidade de vias para impressão">
      <div body>

        <custom-input
        *ngIf="this.dropdown"
          id="qtdMax"
          type="dropdown"
          label="vias para impressão"
          required="true"
          listname="qtdMax"
          [options]='this.dropdown'
          option-label="key"
          (change)="getInput($event, 0)"
          (onBlur)="getInput($event, 0)"
          (validate)="getInput($event, 0)"
          style="display: block;margin-bottom: 2rem;">
      </custom-input>

      <button (click)="onSubmit(form, ipt)" [disabled]="this.hasFormError()">Continuar</button>
      <button class="close" type="submit" (click)="this.voucherModal.display=false;">Fechar</button>
      </div>
    </modal-dialog>

  </form>
</voxel-container>


