<form id="formAdditional" asp-action="Index" asp-controller="CaptureInformationAddress" method="post">
    <div  class="title">
        Complete as informações adicionais
    </div>

    <input type="text" [attr.name]="'fields[2].id'" [attr.value]="'descricao_cartao_emitido_ato'" hidden>
    <input type="text" [attr.name]="'fields[2].type'" [attr.value]="'dropdown'" hidden>
    <input type="text" [attr.name]="'fields[2].listname'" [attr.value]="'fields[2]'" hidden>
    <input type="text" id="descricao_cartao_emitido_ato" [attr.name]="'fields[2].value'" [attr.value]="0" hidden>

    <div class="additional-info" *ngIf="this.fields">
        <voxel-container>
            <voxel-row>
                <voxel-item
                *ngFor="let field of this.fields; let i = index;"
                [tc]="this.getColValue(field.id, 'tc')"
                [dc]="this.getColValue(field.id, 'dc')"
                [mc]="this.getColValue(field.id, 'mc')"
                [grid-col]="this.getGridPosition(field.id)">
                    <custom-input
                        [id]="field.id"
                        [type]="field.type"
                        [name]="field.type"
                        [label]="field.label"
                        [description]="field.description"
                        [required]="field.required"
                        [placeholder]="field.placeholder"
                        [value]="field.value"
                        [listname]="field.listname"
                        [options]='field.dropdownValues'
                        [max]="field?.tamanho_maximo"
					              [min]="field?.tamanho_minimo"
                        option-label="key"
                        [flag-cep]="this.flagCep"
                        [validation]="field.validation_api"
                        (onChange)="getInput($event, i); handleCardToBeIssued($event);"
                        (onBlur)="getInput($event, i)"
                        (validate)="getInput($event, i)">
                    </custom-input>

                    <br *ngIf="i == 2">
                </voxel-item>
            </voxel-row>
        </voxel-container>

        <!-- <input type="hidden" id="teste_input" name="teste_input" value="00001" /> -->

        <voxel-container>
            <voxel-row>
                <voxel-item tc="12" mc="12" dc="12">
                  <input [value]="this.taskid" type="text" name="taskid" hidden>
                    <input [value]="this.route" type="text" name="route" hidden>
                    <voxel-button #btnSubmit id="btnSubmit" label="Continuar" [disabled]="this.hasFormError()"
                      submit-button>
                    </voxel-button>
                </voxel-item>
            </voxel-row>
        </voxel-container>
    </div>
</form>
