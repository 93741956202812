import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OcrService {

  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private base_path: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.base_path = document.documentElement.style.getPropertyValue('--base-path');
  }


  public postDocumentFrontPicture(frontPicture): Observable<any> {
    return this._httpClient.post(`${this.base_path}/ocr-cnh-front`, JSON.stringify(frontPicture), this.httpOptions);
  }
}
