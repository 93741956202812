import { Component, OnInit } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'form-analyze-proposal',
  templateUrl: './form-analyze-proposal.component.html',
  styleUrls: ['./form-analyze-proposal.component.css']
})
export class FormAnalyzeProposalComponent extends GlobalProperties {

  constructor() {
    super();
   }

  ngOnInit() {
  }

}
