<div id="notifications" class="notification-wrapper">
  <voxel-container>
      <voxel-row>
        <voxel-item tc="12" mc="12" dc="12">
            <div class="notification-container" *ngFor="let notification of notifications">
              <voxel-container>
                <div class="simple-card">
                  <div class="simple-card-textbox">
                    A proposta <span class="proposal-span">{{notification.proposal}}</span> teve seu status
                    alterado de "<span>{{notification.previousstatus}}</span>" para "
                    <span [ngClass]="
                      {
                        'span-green': notification.analysisstatus == 'Finalizado',
                        'span-yellow': notification.analysisstatus == 'Analisado',
                        'span-red': notification.analysisstatus == 'Recusado'
                      }">{{notification.analysisstatus}}
                    </span>".
                  </div>

                  <voxel-link
                    content="ver mais">
                  </voxel-link>
                </div>
              </voxel-container>
            </div>
        </voxel-item>
      </voxel-row>
  </voxel-container>
</div>
