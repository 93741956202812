import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { ModalComponent } from '../../shared/modal/modal.component';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { map } from 'rxjs/operators';
import { DeviceDetectorCustomService } from 'projects/commons-util/src/crypto/device-detector-custom.service';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'voucher-confirmation',
  templateUrl: './voucher-confirmation.component.html',
  styleUrls: ['./voucher-confirmation.component.scss']
})
export class VoucherConfirmationComponent extends FormDynamicComponent implements OnInit {

  @ViewChild('form') form: ElementRef;
  @ViewChild('ipt') ipt: ElementRef;
  @ViewChild('fieldValue') fieldValue: ElementRef;
  @ViewChild(ModalComponent) modalComponent: ModalComponent;
  @ViewChild('voucher') voucherModal: ModalComponent;

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  /**
   * @description Objeto contendo dados do campo fields do input
   */
  proposal: any = {};
  device: string
  textDevice: string

  /**
   * @description Objeto contendo dados do campo domais do input
   */
  dropdown: any;
  innerWidth: any;
  keyPdf: string;

  readonly PAGE_NAME = {
    section: 'Voucher',
    page: 'ConfirmacaoDeVoucher'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private deviceDetectorCustomService: DeviceDetectorCustomService,
    private ga4Service: Ga4Service,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    //console.log("voucherinit");
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    this.device = this.deviceDetectorCustomService.deviceDetector()
    this.textDevice = this.device === "mobile" ? "exibido" : "impresso"
    
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        // console.log(data);
        this.proposal = data;
      })
    ).subscribe(
      () => {
        this.dropdown = [];
        for (let index = 1; index <= this.proposal.qtdMax; index++) {
          this.dropdown.push({ key: index, value: index });
        }
        this.printVoucher();
        // console.log(this.proposal)
      }
    );

    // const result = JSON.parse(this.inputs);
    // this.proposal = result;

    // this.dropdown = [];
    // for (let index = 1; index <= this.proposal.qtdMax; index++) {
    //   this.dropdown.push({ key: index, value: index });
    // }

    document.querySelector('voucher-confirmation').removeAttribute('inputs');
  }

  /**
   * @description Metodo vinculado ao evento click do botão de re-imprimir termo
   */
  onClickPrintTerm(): void {
    if (this.proposal.pdf) {
      this.voucherModal.display = true;
    } else {
      window.location.href = '/voucher-error';
    }
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Clique',
      action: 'BT_Voucher',
      label: 'Erro_impressao'
    });
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'erro impress. voucher');
  }

  /**
   * @description Metodo responsavel por setar o valor do input com base no botão clicado
   * e realizar o submit do formulario.
   */
  onSubmit(form: HTMLFormElement, ipt: HTMLInputElement) {
    console.log("vouchersubmit")
    initLoader(this.innerWidth);
    this.dnaService.get('/Device/VoucherKey', 'voucherKey')
      .pipe(
        map(data => {
          this.keyPdf = data.keyValue
        })
      ).subscribe(
        () => {
          if(ipt)
            ipt.setAttribute('value', this.keyPdf);
            
          form.submit();
        }
      );
  }

  onClickConfirm(){
    this.modalComponent.display = true;
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Clique',
      action: 'BT_Voucher',
      label: 'Impresso_Corretamente'
    });
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'voucher impresso corret.');
  }

  printVoucher() {
    initLoader(this.innerWidth);
    this.dnaService.post('/Device/SendVoucherToPrint', 'showVoucherPdf', { pdf: this.proposal.pdf })
      .subscribe(
        () => {
          removeLoader();
        }
      );
  }
}
