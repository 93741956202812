import { Directive, ElementRef, Input } from '@angular/core';
import { HostListener } from '@angular/core';
import { initLoader } from '../globalFunctions/loader';

@Directive({
  selector: '[loader-button]'
})
export class LoaderButtonDirective {

  @Input('link') link: string;
  @Input('noimage') noImage: boolean;

  path_assets: string;
  innerWidth: any;
  img: any;

  constructor(private el: ElementRef) {
    this.innerWidth = window.innerWidth;
    this.path_assets = document.documentElement.style.getPropertyValue('--assets-path');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    let button = this.el.nativeElement.querySelector('button') as HTMLElement;

    if (!button)
      button = this.el.nativeElement as HTMLElement;

    button.addEventListener('click', () => {
      initLoader(this.innerWidth, this.noImage);
      if (this.link) {
        setTimeout(() => {
          window.location.href = this.link;
        }, 300);
      }
    });
    button.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        initLoader(this.innerWidth, this.noImage);
        if (this.link) {
          setTimeout(() => {
            window.location.href = this.link;
          }, 300);
        }
      }
    });
  }

}
