<div class="container">
    <voxel-container>
        <voxel-row style="margin-top:20px">
            <voxel-item dc="12" tc="12" mc="12">
                <div class="container-img">
                    <img src="{{ path_assets + img}}" alt="">
                </div>

                <div class="text-container">
                    <div class="header">Cartão liberado :)</div>

                    <div class="body">Prossiga com emissão do voucher ou cartão.</div>
                </div>

                <div class="footer">
                    <a [link]="base_path + '/home'" loader-button>
                        <voxel-button class="home" id="button-principal" label="Home"></voxel-button>
                      </a>
                </div>
            </voxel-item>
        </voxel-row>
    </voxel-container>
</div>
