<voxel-container>
  <voxel-row>
    <voxel-item tc="12" dc="12" mc="12">
      <voxel-dialog
      #actualContent
      [(showDialog)]="showModalOneLink"
      [items]="items"
      dialogTitle="Continuar proposta"
      description="Existe uma proposta em andamento para este CPF.Deseja continuar com esta proposta?"></voxel-dialog>
  </voxel-item>
</voxel-row>
</voxel-container>