import { Ga4Service } from './../../shared/services/ga4.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'cancellation-confirmed',
  templateUrl: './cancellation-confirmed.component.html',
  styleUrls: ['./cancellation-confirmed.component.css']
})
export class CancellationConfirmedComponent extends GlobalProperties {

  @Input('inputs') inputs: string;
  obj: any;
  backToCardList: boolean;
  fullDate: string;
  innerWidth: any;
  img: string;

  readonly PAGE_NAME = {
    section: 'SolicitarCancelamentoCartao',
    page: 'CancelamentoEfetuado'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt')
      .pipe(
        map(data => {
          this.setFields(data);
        })
      ).subscribe(() => this.callAnalytics());

    // this.setFields(JSON.parse(this.inputs));

    document.querySelector('cancellation-confirmed').setAttribute('inputs', '');

    this.img = 'Group 16.svg';
  }

  setFields(aux: any) {
    this.obj = aux;

    let rawDate = this.obj.solicitation.date;
    let rawHour = this.obj.solicitation.hour;

    rawDate = rawDate.replace(/\./g, '/');
    rawHour = rawHour.slice(0, 5);
    rawHour = rawHour.replace(/\./g, ':');
    this.fullDate = (rawDate + ' - ' + rawHour);

    if (this.obj.solicitation.qtdcards > 0) {
      this.backToCardList = true;
    } else {
      this.backToCardList = false;
    }

    this.innerWidth = window.innerWidth;
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
