import { Component, OnInit } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'form-warning-exit-flow',
  templateUrl: './form-warning-exit-flow.component.html',
  styleUrls: ['./form-warning-exit-flow.component.css']
})
export class FormWarningExitFlowComponent extends GlobalProperties {

  constructor() {
    super();
   }

  ngOnInit() {
  }

}
