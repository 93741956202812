import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { initLoader } from '../globalFunctions/loader';

@Directive({
	selector: '[custom-header]'
})
export class CustomHeaderDirective {

	@Input('logo') logo: any;
	@Input('noIcons') noIcons: any;
	@Input('leftIcon') leftIcon: any;
	@Input('rightIcon') rightIcon: any;

	constructor(private el: ElementRef) { }

	elementsLeft() {
		if (this.el.nativeElement.querySelector('.header__back').children.length > 0) {
			const elementLeft = this.el.nativeElement.querySelector('.header__back').children[0].querySelector('span') as HTMLElement;
			const button = this.el.nativeElement.querySelector('.header__back').children[0] as HTMLElement;


			if (this.logo) {
				elementLeft.innerHTML = '<object type="image/svg+xml" data="' + this.logo + '"</object>';
			} else {
				if (this.leftIcon) {
					elementLeft.setAttribute('class', this.leftIcon);
					elementLeft.innerText = '_';
					button.addEventListener('click', (e) => {
						button.style.pointerEvents = 'none';
						initLoader(window.innerWidth);
					});
				} else {
					this.el.nativeElement.querySelector('.header__btn').remove();
				}
			}
		}
	}

	elementsRight() {
		if (this.el.nativeElement.querySelector(".header__actions").children.length > 0) {
			const elementsRight = this.el.nativeElement.querySelector(".header__actions").children[0].querySelector("span") as HTMLElement;
			elementsRight.setAttribute('class', this.rightIcon);
			elementsRight.innerText = '_';
		}
	}

	elementsDel() {
		if (this.noIcons == undefined)
			return;

		if (this.el.nativeElement.querySelector(".header__back").children.length > 0)
			this.el.nativeElement.querySelector(".header__back").children[0].querySelector("span").remove()

		if (this.el.nativeElement.querySelector(".header__actions").children.length > 0)
			this.el.nativeElement.querySelector(".header__actions").children[0].querySelector("span").remove()
	}

	setElements() {
		this.elementsLeft();
		this.elementsRight();
		this.elementsDel();
	}

	changeColor(element, attribute) {
		let obj = element.querySelector("object").getSVGDocument();

		if (obj == null) {
			return;
		}

		obj.querySelectorAll("g[fill]").forEach(item => {
			item.setAttribute("fill", attribute)
		});
	}

}
