import { GlobalProperties } from 'src/app/BG4/shared/global-properties';
import { Component, OnInit, Input } from '@angular/core';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map, skipWhile } from 'rxjs/operators';
import { iif } from 'rxjs';

@Component({
  selector: 'device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent extends GlobalProperties implements OnInit {

  @Input("inputs") inputs: string;

  fields: any;

  constructor(private cryptoPipe: CryptoPipe) {
    super();
   }

  ngOnInit() {
    this.parseJSON(this.inputs);
  }

  parseJSON(string: string) {
    this.cryptoPipe.transform(string, 'decrypt').pipe(
      map(data => {
        this.setFields(data);
      }
     )).subscribe();
    
    // if(string) {
    //   this.setFields(JSON.parse(string));
    // }  
  }

  setFields(obj: any) {  
    this.fields = obj;

    document.querySelector('device-detail').setAttribute('inputs', '');
  }
  
}
