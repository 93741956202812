<voxel-container>
  <form id="formCardCancellationData" method="post">

    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <card-header title="SOLICITAR CANCELAMENTO DE CARTÃO" icon="icon-itaufonts_cartao" subtitle="Por favor preencha os dados abaixo para solicitar o cancelamento do cartão."></card-header>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item *ngFor="let field of fields; let i = index;"
      [tc]="this.getColValue(field.id, 'tc')"
      [dc]="this.getColValue(field.id, 'dc')"
      [mc]="this.getColValue(field.id, 'mc')" 
      style="padding-bottom: 10px;">
        <custom-input 
            [id]="field.id"
            [type]="field.type"
            [name]="field.id"
            [label]="field.label"
            [description]="field.description"
            [required]="field.required"
            [listname]="field.listname"
            [options]="field.dropdownValues"
            [max]="field?.tamanho_maximo"
					  [min]="field?.tamanho_minimo"
            option-value="key"
            option-label="value"
            (change)="getInput($event, i)"
            (validate)="getInput($event, i)"
            [validation]="field.validation_api">
          </custom-input>
      </voxel-item>
    </voxel-row>

    <input type="hidden" id="route" name="route" [value]="route">
    
    <voxel-row class="btnGroup">
      <voxel-item dc="12" tc="12" mc="12">
            <voxel-button 
              label="Continuar" 
              class="primary-button" 
              submit-button 
              [disabled]="hasFormError()"></voxel-button>
      </voxel-item>
    </voxel-row>

  </form>
</voxel-container>