<div class="main">
  <label style='padding-left: 12px;'>{{this.label}}</label>
  <div class="date-picker">
    <div class="selected-date">
      <input class='input-datepicker' type="text"  maxlength="10" placeholder="dd/mm/aaaa" mask="00/00/0000" matInput [matDatepicker]="picker" (dateChange)='setDate($event.value)' (blur)='blurEmit($event)' >
      <mat-datepicker #picker></mat-datepicker>
      
      <!--<voxel-input class="v-datepicker" id="inputdate" mask="custom-mask" customMask="00/00/0000" hideEditIcon="true"
        [value]="this.currentDate" (inputBlur)='onOutFocus($event.item.value)'></voxel-input>-->

      <span class="icon-itaufonts_calendario icon" mat-raised-button (click)="picker.open()"></span>
    </div>
  </div>
</div>
<!-- <span *ngIf='error === true'>*Data em formato inválido</span> -->