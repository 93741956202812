import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';import { Component, OnInit, Input, HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'user-information-generic',
  templateUrl: './user-information-generic.component.html',
  styleUrls: ['./user-information-generic.component.css']
})
export class UserInformationGenericComponent extends GlobalProperties implements OnInit {
  web: boolean;
  text: string;
  msg: string;
  @Input('inputs') inputs: string;


  constructor(private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() {
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }

    if (this.inputs == 'warning') {
      this.text = 'Analise da biometria em andamento, por favor tente novamente em alguns minutos.'
    } else {
      this.text = 'Identificamos uma pendência em sua biometria facial. Entre em contato com o suporte para regularização.';
      this.msg = 'Importante: o acesso é pessoal e intransferível. Se atente ao uso, e não compartilhe seu acesso com outros usuários.';
    }
  }
}
