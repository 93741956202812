import { environment } from 'src/environments/environment';
import { SectionComponent } from './section.component';
import { FormAdditionalCardComponent } from '../shared/form-additional-card/form-additional-card.component';
import { Component, OnInit, Input, ComponentFactory, ComponentFactoryResolver, ViewContainerRef, ViewChildren, QueryList, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { timer } from 'rxjs';

@Component({
  selector: 'additional-card',
  templateUrl: './additional-card.component.html',
  styleUrls: ['./additional-card.component.css']
})
export class AdditionalCardComponent implements OnInit {

  /**
   * JSON com os campos que devem ser renderizados na tela
   */
  @Input()
  inputs: string;

  /**
   * Lista de todas as divs que poderão receber itens dinâmicos
   */
  @ViewChildren(SectionComponent)
  sections: QueryList<SectionComponent>;

  @ViewChild('form') form: ElementRef;

  /**
   * Divs ativas onde ficarão os formulários de cartão criados dinamicamente
   */
  activeSections: SectionComponent[];

  /**
   * Factory para criação de formulário de cartão adicional
   */
  formFactory: ComponentFactory<FormAdditionalCardComponent>;

  /**
   * Lista de opções que serem renderizadas no form de cartão adicional
   */
  optionsFormAdditional: any = {};

  /**
   * Lista de todos cards adicionados dinamicamente
   */
  cards: Array<FormAdditionalCardComponent> = [];

  /**
   *
   */
  listForm: Array<boolean> = [];

  /**
   * Todos os campos deserializados do JSON
   */
  objs: any;

  /**
   * Somente os inputs deserializados do JSON, enviado pelo back-end
   */
  fields: any;

  /**
   * Contador de formulários criados dinâmicamente
   */
  count: number = 0;

  /**
   * Habilita/Desabilita botão de adicionar cartão
   */
  enabledAdd: boolean = true;

  /**
   * Habilita/Desabilita botão de confirmar
   */
  enabledSubmit: boolean = true;

  additionalCardsNumber: number;
  customer_id: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'CartaoAdicional'
  };

  /**
   * Construct...
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private factoryResolver: ComponentFactoryResolver,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) { }

  /**
   *  Ao iniciar o componente...
   */
  ngOnInit() {
    this.parseJson(this.inputs);
    this.startFactory();
    document.querySelector('additional-card').setAttribute('inputs', '');
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  /**
   * @description
   *
   */
  ngAfterViewInit() {
    this.pushFormToSecction();
  }


  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * @description
   * Converte o JSON em objeto para renderizar os inputs na tela
   */
  parseJson(json: string) {
    if (environment.type == "local") {
      timer(1000).subscribe(()=>{
        var data = JSON.parse(json)
        this.objs = data;
        this.fields = data.fields[0];
      })
      return;
    }

    this.cryptoPipe.transform(json, 'decrypt').pipe(
      map(data => {
        this.objs = data;
        this.fields = data.fields[0];
        this.customer_id = data.customer_id || '';
      }
      )).subscribe(() => { this.callInitAnalytics(); });
  }

  callInitAnalytics(): void {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
  }

  /**
   * @description
   * Inicia o componente de factory para criação de form dinamicamente
   */
  startFactory() {
    let component = FormAdditionalCardComponent;
    this.formFactory = this.factoryResolver.resolveComponentFactory(component);
  }

  /**
   * @description
   * Verifica a sessão que deve ser renderizados os forms dinamicos
   */
  pushFormToSecction() {
    this.activeSections = this.sections.reduce((result, section, index) => {
      if (section.active) {
        result.push(section);
      }
      return result;
    }, []).reverse();
  }

  /**
   * Retorna um index válido para ser criado um novo form
   * @return number
   */
  getIndex(): number {
    return this.cards.length;
  }

  /**
   * @description
   * Função para criar formulário de cartão adicional dinamicamente
   */
  addCard() {
    this.activeSections.forEach((section) => {
      var self = this;
      let index = this.getIndex();

      var obj = section.viewContainerRef.createComponent(this.formFactory, index);
      let form = obj.instance;


      form.index = index;
      form.fieldId = this.fields.id;
      form.fieldType = this.fields.type;
      form.listname = this.fields.listname;
      form.inputs = this.fields.properties;

      this.cards[index] = form;

      form.onChangeIndex.subscribe(({ data }) => this.validCPF(form, data));

      this.checkEnableNewCard();

      form.onRemove.subscribe(form => {
        obj.destroy();
        self.rmCard(form);
        self.checkSubmitButton();
      });

      form.onValid.subscribe(card => {
        self.checkSubmitButton();
      });
    });
  }

  validCPF(form, data) {
    if (this.cards.length < 1) return;

    this.cards.forEach((fm) => {
      if (fm.formInputs.first.id === data.id) return;

      if (fm.formInputs.first.value !== data.value) {
        return form.formInputs.first.validateInput();
      };

      form.formInputs.first.invalidateInput("CPF já foi utilizado.");
      form.checkForm();
    });
  }

  callAnalytics(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'AddCartaoBT',
        label: this.cards.length.toString()
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, this.cards.length.toString());
  }

  /**
   * Verifica se todos os formulário de cartão adicional
   * estão preenchido corretamente para habilitar o botão de submit
   * @param index
   * @param valid
   */
  checkSubmitButton() {

    var enabled = true;

    for (let card of this.cards) {
      if (card.valid == false) {
        enabled = false;
      }
    }

    return this.enabledSubmit = enabled;
  }

  /**
   * Habilita/Desabilita o botão para adicionar novo form
   * para criar cartão
   */
  checkEnableNewCard() {
    if (this.cards.length >= this.fields.tamanho_maximo) {
      return this.enabledAdd = false;
    }
    return this.enabledAdd = true;
  }

  /**
   * Remove as referências do formulário ao ser destruído
   * Podendo assim reutilizar seu index em um novo form
   * @param integer
   * @return void
   */
  rmCard(form) {
    this.rmListForm(form);
    this.checkEnableNewCard();
    this.renameIndexForm(form.index);
  }

  /**
   * Ao remover um formulário, verifica a sua posição (cartão adicional 1,2,3..)
   * Se houver outros formulários de cartão adicional com um valor maior,
   * deve ser renomado
   */
  renameIndexForm(removedIndex) {
    this.cards.forEach(card => {
      if (card.index < removedIndex) {
        return;
      }
      card.index = card.index - 1;
    })
  }

  /**
   * Remove o formulário da lista de formulários para validação
   * e reavalia se todos os formulários já foram removidos.
   * Se foram, pode habilitar o botão de submit novamente
   * @param index
   */
  rmListForm(form) {
    this.cards.splice(form.index, 1);
    if (this.cards.length == 0) {
      this.enabledSubmit = true;
    }
  }
}
