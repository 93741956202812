import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalProperties } from 'src/app/BG4/shared/global-properties';
import { AnalyticsService } from 'src/app/BG4/shared/services/analytics.service';
import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';

@Component({
  selector: 'card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.css']
})
export class CardMenuComponent extends GlobalProperties {

  @Input("route") route: string;
  @Input("icon") icon: string;
  @Input("name") name: string;
  @Input("notification") notification: number;

  constructor(
      private cdr: ChangeDetectorRef,
      private analyticsService: AnalyticsService,
      private ga4Service: Ga4Service
    ) {
    super();
  }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  click(label: string): void {
    this.analyticsService.trackClick({section: 'Home'}, {
      category: 'Clique',
      action: 'HomeBT',
      label
    });

    this.ga4Service.trackEvent('click', {section: 'Home'}, label);
  }

}
