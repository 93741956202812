import { Component, OnInit, Input } from '@angular/core';
import { GlobalProperties } from 'src/app/BG4/shared/global-properties';

@Component({
  selector: 'card-header-photo-document',
  templateUrl: './card-header-photo-document.component.html',
  styleUrls: ['./card-header-photo-document.component.css']
})
export class CardHeaderPhotoDocumentComponent extends GlobalProperties {

  @Input("title") title:string;
  @Input("subtitle") subtitle:string;


  constructor() {
    super();
   }

  ngOnInit() {
  }

}
