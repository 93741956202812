<div class="header-general">
  <voxel-container>
      <!-- header -->
      <voxel-row [ngClass]="{'adjusts': logo}">
        <voxel-item dc="12" tc="12" mc="12">
          <voxel-header
              id="header"
              dc="12" mc="12" tc="12"
              custom-header
              [title]="this.title"
              [logo]="this.logoIcon"
              [icons]="icons"
              headerAccessibility="voltar"
              (headerPrimaryClick)="sidebarComponent.toggleMenu()"
              (headerSecundaryClick)="sidebarComponent.toggleMenu()">
          </voxel-header>
        </voxel-item>
      </voxel-row>
      <!-- /header -->

      <!-- sub-title -->
      <voxel-row class="adjusting-background">
          <voxel-item dc="12" tc="12" mc="12" >
              <p class="subtitle">{{this.subtitle}}</p>
              <percentage-bar *ngIf="percentTrack" [percent]="this.percentTrack"></percentage-bar>
          </voxel-item>
          <div *ngIf="!arraysearch" class="shadow">&nbsp;</div>
      </voxel-row>
      <!-- /sub-title -->

      <!-- search -->
      <voxel-row class="arraysearch" *ngIf="arraysearch">
          <div class="search" [ngClass]="{filter: filter, active: active}">
              <voxel-input
              id="input-cpf-cnpj"
              mask="cpf"
              hideEditIcon="true"
              [value]="this.searchInput"
              placeholder="Digite CPF do cliente"
              (inputChange)="onInputChange($event)">
              </voxel-input>

              <ngx-select-dropdown *ngIf="filter && this.innerWidth > 991" [(ngModel)]="selectedFilters" [multiple]="true" [options]="filter" [config]="config"></ngx-select-dropdown>

              <span *ngIf="filter && this.innerWidth <= 991" (click)="active=!active" class="icon-itaufonts_filtro"></span>

              <svg-icon *ngIf='enabledBtnSearch'
              (click)="searchIconClick($event)"
              [svgStyle]="{'fill':'var(--primary-color)'}"
              src="{{ path_assets + 'icone_busca.svg' }}">
              </svg-icon>

              <span *ngIf='!enabledBtnSearch'
                  class="close icon-itaufonts_fechar"  (click)='clearInput($event)'>
              </span>

              <div *ngIf="active && this.innerWidth <= 991" class="options">
                <ngx-select-dropdown [(ngModel)]="selectedFilters" [multiple]="true" [options]="filter" [config]="config"></ngx-select-dropdown>
              </div>

          </div>
          <div class="shadow">&nbsp;</div>
      </voxel-row>
      <!-- search -->
  </voxel-container>
  <div *ngIf="!percentTrack" class="line"></div>
</div>

<sidebar [hidden]="!this.sidebarmenu || !this.parsedSidebarMenu || this.parsedSidebarMenu.length <= 0"></sidebar>
