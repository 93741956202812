import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input('title') title: string;
  @Input('overlay') overlay: boolean;

  @Input('btn1') btn1: string; // Label
  @Input('type1') type1: string; // Submit or close
  @Input('input1') input1: string; // Input hidden
  @Input('class1') class1: string; // CSS button (outline, freeline, solid)
  @Input('link1') link1: string;

  @Input('btn2') btn2: string; // Label
  @Input('type2') type2: string; // Submit or close
  @Input('input2') input2: string; // Input hidden
  @Input('class2') class2: string; // CSS button (outline, freeline, solid)
  @Input('link2') link2: string;

  @Input('btn3') btn3: string; // Label
  @Input('type3') type3: string; // Submit or close
  @Input('input3') input3: string; // Input hidden
  @Input('class3') class3: string; // CSS button (outline, freeline)
  @Input('link3') link3: string;

  @Output('event-btn') eventBtn = new EventEmitter<any>();
  @Output('call-btn')  callBtn = new EventEmitter<any>();

  display: boolean;
  value: string;
  height: string;

  constructor() { }

  ngOnInit() {
    this.overlay = Boolean(JSON.parse(this.overlay.toString()));
    // this.display = Boolean(JSON.parse(this.display.toString()));
  }

  onClick(display, input) {
    this.display = display;
    this.value = input;
    this.eventBtn.emit(input);
  }

  callButton(){
    this.callBtn.emit()
  }

}
