import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class DateService {
  private readonly ageGroups = ["18-24", "25-34", "35-44", "45-54", "55-64"];

  constructor() { }

  age(dateOfBirth: string) {
    const [dayOB, monthOB, yearOB] = dateOfBirth.split("/");
    const today = new Date();

    var age = today.getFullYear() - Number(yearOB);
    const month = (today.getMonth() + 1) - Number(monthOB);

    if (month < 0 || (month === 0 && today.getDate() > Number(dayOB)))
      age--;

    return age;
  }

  ageRange(dateOfBirth: string) {
    const age = this.age(dateOfBirth);

    if (!age) return "";

    return this.ageGroups.find(ageGroup => {
      const [minAge, maxAge] = ageGroup.split("-");
      return Number(minAge) <= age && age <= Number(maxAge);
    }) || "65+";
  }
}
