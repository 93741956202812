import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { FormDynamicComponent } from '../../../shared/form-dynamic/form-dynamic.component';

@Component({
  selector: 'form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.css']
})
export class FormAddressComponent extends FormDynamicComponent {

  @Input("flag-cep") flagCep: string;

  @Output() cepSearch = new EventEmitter();

  fields: Array<any> = [];
  grid: Array<any> = [];
  validateFields: Array<any> = [];
  type: string = "";

  adicional: any;
  correspondencia: any;

  isValid: boolean;

  blockList1 = [
    "endereco_residencial_bairro",
    "endereco_residencial_logradouro",
    "endereco_residencial_cidade",
    "endereco_residencial_estado"
  ];

  blockList2 = [
    "endereco_comercial_bairro",
    "endereco_comercial_logradouro",
    "endereco_comercial_cidade",
    "endereco_comercial_estado"
  ];

  blockList3 = [
    "endereco_correspondencia_bairro",
    "endereco_correspondencia_logradouro",
    "endereco_correspondencia_cidade",
    "endereco_correspondencia_estado"
  ];

  constructor() {
    super();
    this.populateGrid();
  }

  ngOnInit() {        
  }

  searchCEP(event) {
    this.cepSearch.emit(event);
  }

  validateField(event, type, i) {
    if (this.fields[i].required)
      this.validateFields[i] = event;
    // console.log(event, type, i);
    // console.log(this.validateFields);
  }

  populateGrid() {
    this.grid["endereco_residencial_cep"]             = {mc: 12, tc: 6, dc: 12, grid: null};
    this.grid["endereco_residencial_bairro"]          = {mc: 12,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_residencial_logradouro"]      = {mc: 12, tc: 6, dc: 6, grid: null};
    this.grid["endereco_residencial_numero"]          = {mc: 4,  tc: 2, dc: 6, grid: null};
    this.grid["endereco_residencial_cidade"]          = {mc: 7,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_residencial_estado"]          = {mc: 5,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_residencial_complemento"]     = {mc: 8,  tc: 4, dc: 6, grid: null};

    this.grid["endereco_comercial_confirmacao"]       = {mc: 12, tc: 12, dc: 12, grid: null};
    this.grid["endereco_comercial_cep"]               = {mc: 12, tc: 6, dc: 12, grid: null};
    this.grid["endereco_comercial_bairro"]            = {mc: 12,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_comercial_logradouro"]        = {mc: 12, tc: 6, dc: 6, grid: null};
    this.grid["endereco_comercial_numero"]            = {mc: 4,  tc: 2, dc: 6, grid: null};
    this.grid["endereco_comercial_cidade"]            = {mc: 7,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_comercial_estado"]            = {mc: 5,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_comercial_complemento"]       = {mc: 8,  tc: 4, dc: 6, grid: null};

    this.grid["endereco_correspondencia_confirmacao"] = {mc: 12, tc: 12, dc: 12, grid: null};
    this.grid["endereco_correspondencia_cep"]         = {mc: 12, tc: 6, dc: 12, grid: null};
    this.grid["endereco_correspondencia_bairro"]      = {mc: 12,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_correspondencia_logradouro"]  = {mc: 12, tc: 6, dc: 6, grid: null};
    this.grid["endereco_correspondencia_numero"]      = {mc: 4,  tc: 2, dc: 6, grid: null};
    this.grid["endereco_correspondencia_cidade"]      = {mc: 7,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_correspondencia_estado"]      = {mc: 5,  tc: 6, dc: 6, grid: null};
    this.grid["endereco_correspondencia_complemento"] = {mc: 8,  tc: 4, dc: 6, grid: null};
  }

  readOnly(index) {
    if (this.fields[index].hasOwnProperty('isBlock')) {
      if (this.fields[index].isBlock) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
