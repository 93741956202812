<form asp-action="Index" asp-controller="AnalyzingProposal" method="post">
  <div class="container-img">
    <!-- <img class="image-desktop" src="{{ path_assets + 'ilustra_analisandoproposta.svg'}}" width="300px" height="300px">
    <img class="image-mobile" src="{{ path_assets + 'icon_an_prop.svg'}}" width="166px" height="166px"> -->
    <img src="{{ path_assets + img}}">
  </div>

  <div class="header">{{title}}</div>

  <div class="body">{{subtitle}}</div>

  <voxel-container>
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <a [link]="base_path + '/home'" loader-button>
          <voxel-button class="home" id="button-principal" label="voltar ao início"></voxel-button>
        </a>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>

