<form id="tokenDna" asp-action="Index" asp-controller="TokenDnaController" method="post" #form>
  <div class="main-header"  *ngIf='this.web === false'>
    <p>Ativar dispositivo</p>
  </div>

  <div class="container-img">
    <img src="{{ path_assets + 'ico_aguar_autor.svg' }}">
  </div>

  <div class="header">Código de Ativação</div>

  <div class="body">
    <p>{{this.textInformation}}</p> 
    <p>Inventário: {{ this.inventory }}</p>
  </div>

  <div class="field">
    <custom-input
      [id]="field.id"
      [type]="field.type"
      [required]="field.required"
      [mask]="mask.value"
      [pattern]="mask.pattern"
      [focus]="true"
      [value]="field.value"
      [listname]="field.listname"
      (onBlur)="getInput($event, 0)"
      (validate)="getInput($event, 0)"
      [validation]="field.validation_api">
    </custom-input>
  </div>
  <input [attr.value]="false" type="text" name="SendDeviceActivationEmail" hidden>
  <voxel-container>
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <voxel-button id="button-principal" label="Confirmar" [disabled]="this.hasFormError()" (click)="onClickSubmit(false)"></voxel-button>
      </voxel-item>


      <voxel-item dc="12" tc="12" mc="12">
        <div class="btn-row">
          <a loader-button style="width: 50%;">
          <voxel-button id="btnRequestCode" label="Reenviar Código" (click)="onClickSubmit(true)"></voxel-button>
          </a>
          <a [link]="base_path + '/logoff'" loader-button style="width: 50%;">
            <voxel-button class="home" id="button-principal" label="Sair"></voxel-button>
          </a>
        </div>
      </voxel-item>

    </voxel-row>
  </voxel-container>

  <voxel-dialog [(showDialog)]="failActivation" [items]="items"
    description="Não foi possível ativar o dispositivo, verifique a chave de ativação."
    (dialogLinkClick)="closeModal()">
  </voxel-dialog>

</form>
