<voxel-container>
  
  <!-- <voxel-modal
            id="modalid"
            modalTitle="Sim ,Quero Sair"
            [(showModal)]="modalShow.simple"
            [buttonLabels]=" { primary: 'primário' } ">
            Desejas sair do fluxo? Pode resultar na perda das informações.
  </voxel-modal> -->

  <!-- <voxel-row>
    <img src="{{path_assets + 'assets/ilustra_avisoerro.svg'}}">
  </voxel-row> -->

   <voxel-row>
    <p class="title-text">Desejas sair do fluxo? Pode resultar na perda das informações.</p>

  </voxel-row>

  
  <voxel-row>
    <p class="link"><a href="#">Não </a><br>
  <a href="#">Sim, Quero Sair </a></p>
  </voxel-row> 


</voxel-container>
