import { OnInit, Injectable, isDevMode } from '@angular/core';
import { StylesControlComponent } from './styles-control/styles-control.component';

@Injectable()
export abstract class GlobalProperties implements OnInit {

    startDevMode() {
        var styles = new StylesControlComponent();
        styles.initAllParameters();
    }

    constructor() {
        if (isDevMode() && document.documentElement.style.length == 0)
            this.startDevMode();
            
        this.simulador = (document.documentElement.style.getPropertyValue("--simulador") == "true")
        this.path_assets = document.documentElement.style.getPropertyValue('--assets-path');
        this.base_path = document.documentElement.style.getPropertyValue("--base-path");
        this.url_dna = document.documentElement.style.getPropertyValue("--urldna");
        for(var i = 0; i < document.documentElement.style.length; i++) 
        {
            var nameAttribute = document.documentElement.style[i];
            this.all_properties[nameAttribute] = document.documentElement.style.getPropertyValue(nameAttribute);
        }
    }

    path_assets: string;
    base_path: string;
    simulador: Boolean
    url_dna: String
    all_properties: any = [];

    ngOnInit() { }
}
