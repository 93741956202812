import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'approved-request',
  templateUrl: './approved-request.component.html',
  styleUrls: ['./approved-request.component.css']
})
export class ApprovedRequestComponent implements OnInit {

  @Input()
  inputs: string;

  web: boolean;

  offters: any;
  route: string;
  taskid: string;
  customer_id: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'SolicitacaoAprovada'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) { }


  ngOnInit() {
    this.parseJson(this.inputs);
    this.setDevice();
    document.querySelector('approved-request').setAttribute('inputs', '');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDevice();
  }

  /**
   * @description
   * Faz a conversão de JSON para object
   * @return void
   */
  parseJson(json: string) {
    this.cryptoPipe.transform(json, 'decrypt').pipe(
      map(data => {
        this.route = data.route;
        this.taskid = data.taskId;
        this.customer_id = data.customer_id;
        this.offters = data.domains.offters;
        this.getLimit(this.offters);
      }
      )).subscribe();


    // const data = JSON.parse(json);
    // this.route = data.route;
    // this.taskid = data.taskId;
    // this.offters = data.domains.offters;
    // this.getLimit(this.offters);
  }

  getLimit(offers): void {
    const limiteString = offers[0].limite.replace('R$', '').replace('.', '').replace(',', '.');
    const limite = Number(limiteString);
    this.callAnalytics(limite);
  }

  callAnalytics(limite): void {
    this.analyticsService.trackPageLoadCustomParams(
      this.PAGE_NAME,
      [{ param: 'limof', value: limite }],
      this.customer_id
    );
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id, [{ param: 'limite_ofertado', value: limite }]);

    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }


  setDevice() {
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }
  }


}
