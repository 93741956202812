<form id="formDetail" asp-action="Index" asp-controller="ConsultProposalDetail" method="post" #form>
  <voxel-container>
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <div class="header-content">
          <span class="icon-itaufonts_fatura icon first-icon"></span>
          <h2>detalhe da proposta</h2>
        </div>
      </voxel-item>
    </voxel-row>

    <!-- Status -->
    <div class="proposal-detail__status">
      <voxel-row class=" spacing__margin-bottom-xs">
        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">status</div>

          <div [ngClass]="getClassByStatus(proposal?.status_proposta)">
            {{ proposal?.status_proposta ? proposal?.status_proposta : '-' }}
          </div>

        </voxel-item>

      </voxel-row>

      <voxel-row>
        <voxel-item dc="3" tc="12" mc="12">
          <div class="label">
            número da proposta
          </div>
          <div class="value">
            {{ proposal?.id_proposta ? proposal?.id_proposta : '-' }}
          </div>
        </voxel-item>

        <voxel-item dc="3" tc="12" mc="12">
          <div class="label">
            data da proposta
          </div>
          <div class="value">
            {{ proposal?.data_criacao_proposta_venda }} - {{ proposal?.hora_criacao_proposta_venda }}
          </div>
        </voxel-item>

        <voxel-item dc="6" tc="12" mc="12">
          <div class="label">loja</div>

          <div class="value">
            {{ proposal?.loja ? proposal?.loja : '-' }}
          </div>
        </voxel-item>
      </voxel-row>
    </div>
    <!-- /Status -->

    <!-- wrapper -->

    <div class="proposal-detail__wrapper">
      <voxel-item dc="4" tc="12" mc="12">
        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <h3>dados pessoais</h3>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">
              nome
            </div>

            <div class="value">
              {{ proposal?.nome_cartao ? proposal?.nome_cartao : '-' }}
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">
              CPF
            </div>

            <div class="value">
              {{ proposal?.cpf ? proposal?.cpf : '-' }}
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">
              segmento
            </div>
            <div class="value">
              {{ proposal?.segmento ? proposal?.segmento : '-' }}
            </div>
          </voxel-item>
        </voxel-row>
      </voxel-item>

      <voxel-item dc="8" tc="12" mc="12">
        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <h3>CLASSIFICAÇÃO</h3>
          </voxel-item>
        </voxel-row>


        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">canal</div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="value">
              {{ proposal?.codigo_canal_venda ? proposal?.codigo_canal_venda : '-' }}
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">
              sub-canal
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="value">
              {{ proposal?.codigo_sub_canal_venda ? proposal?.codigo_sub_canal_venda : '-' }}
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="label">
              ação de vendas
            </div>
          </voxel-item>
        </voxel-row>

        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">
            <div class="value">
              {{ proposal?.codigo_acao_venda ? proposal?.codigo_acao_venda : '-' }}
            </div>
          </voxel-item>
        </voxel-row>
      </voxel-item>
    </div>

    <!-- wrapper -->

    <div class="btn-content">
      <voxel-row>
        <input [attr.value]="false" type="text" name="confirm_cancelation" hidden>
        <input [attr.value]="false" type="text" name="confirm_modal" hidden>

        <voxel-item *ngIf="this.proposal.btn_continuar" dc="12" tc="12" mc="12">
          <voxel-button id="btnSubmit" label="Continuar Proposta" (click)="onClickContinuar()"></voxel-button>
        </voxel-item>

        <voxel-item *ngIf="this.proposal.link_embossing" dc="12" tc="12" mc="12">
          <a [href]="this.proposal.link_embossing" class="link-home" style="width: 100%;">
            <voxel-button loader-button label="Vincular cartão" id="btnEmbossing"></voxel-button>
          </a>
        </voxel-item>



        <voxel-item  dc="12" tc="12" mc="12">
          <div class="btn-row">
          <voxel-button id="btnCancel" label="Cancelar Proposta" (click)="onClickCancelar()" style="width: 100%;" *ngIf="this.proposal.btn_cancelar"></voxel-button>

          <a [href]="base_path + '/home'" class="link-home" style="width: 100%;">
            <voxel-button loader-button label="Home" id="button-home"></voxel-button>
          </a>
        </div>
        </voxel-item>
      </voxel-row>
    </div>
  </voxel-container>

</form>

<modal-dialog [title]="modalConfig.title" display="true" overlay="true" (event-btn)="modalConfig.buttonAction($event)"
  input1="primary" [btn1]="modalConfig.primaryButtonLabel" type1="close" class1="solid"
  input2="secondary" [btn2]="modalConfig.secondaryButtonLabel" type2="close" class2="outline">
  <div body class="modal">
    <div class='modal-elements' [innerHTML]="modalConfig.content"></div>
  </div>
</modal-dialog>
