import { Component, OnInit, Renderer2, ElementRef, HostListener, Input, ChangeDetectorRef } from '@angular/core';
import { ValidInput } from 'src/app/BG4/shared/valid-input';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'form-reset-password',
  templateUrl: './form-reset-password.component.html',
  styleUrls: ['./form-reset-password.component.css']
})
export class FormResetPasswordComponent  extends FormDynamicComponent {
  @Input("input") input:string;

  route: string;
  taskId: string;

  passwordForceColor: string = 'red';
  passwordForcePercent: number = 0;
  passwordForceText: string = ''
  numbers: string = '0123456789';
  letters: string = 'abcdefghyjklmnñopqrstuvwxyz';
  capsLetters: string = 'ABCDEFGHYJKLMNÑOPQRSTUVWXYZ';

  percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0, g: 130, b: 0 } }
  ];

  constructor(private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() {
    this.parseJSON(this.input);
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  parseJSON(string: string) {
    this.cryptoPipe.transform(string, 'decrypt').pipe(
      map(data => {
        this.setFields(data);
      }
     )).subscribe();

    // this.setFields(JSON.parse(string));
  }

  setFields(obj: any) {
    this.route = obj.route;
    this.taskId = obj.taskId;
    this.fields = obj.fields;

    document.querySelector('form-reset-password').setAttribute('input', '');
  }

  checkForce(event) {
    var result = 1;
    var value = event.srcElement.value;
    if (value.length != 0) {
      if (this.containNumber(value) && this.containLeters(value)) {
        result += 30;
      }
      if (this.containLowerCase(value) && this.containCapsLetters(value)) {
        result += 30;
      }
      if (value.length >= 4 && value.length <= 5) {
        result += 10;
      } else {
        if (value.length >= 6 && value.length <= 8) {
          result += 30;
        } else {
          if (value.length > 8) {
            result += 39;
          }
        }
      }
    }

    this.passwordForceText = result <= 30 ? 'Fraca' : (result <= 40 ? 'Média' : (result <= 90 ? 'Forte' : 'Muito Forte'));
    this.passwordForcePercent = result;
    this.passwordForceColor = this.getColorForPercentage(result / 100);
  }

  private containNumber(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.numbers.indexOf(text.charAt(i), 0) != -1) {
        return 1
      }
    }

    return 0;
  }

  private containLeters(text) {
    text = text.toLowerCase();
    for (var i = 0; i < text.length; i++) {
      if (this.letters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  private containLowerCase(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.letters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  private containCapsLetters(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.capsLetters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  getColorForPercentage = function (pct) {
    for (var i = 1; i < this.percentColors.length - 1; i++) {
      if (pct < this.percentColors[i].pct) {
        break;
      }
    }
    var lower = this.percentColors[i - 1];
    var upper = this.percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

  hasPasswordError(): boolean {
    if (this.inputsForm.length == 0 || this.inputsForm[0] == undefined) {
      return true;
    }

    if(this.hasFormError()) {
      return this.hasFormError();
    }

    if(!this.checkPasswordConfirmation()) {
      return !this.checkPasswordConfirmation();
    }

    return false;
  }

  checkPasswordConfirmation(index = 0): boolean {
    if (this.inputsForm.length == 0 || this.inputsForm[index] == undefined) {
      return true;
    }

    return this.inputsForm[1].value === this.inputsForm[2].value;
  }
}

