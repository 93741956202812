import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormDynamicComponent } from 'src/app/BG4/shared/form-dynamic/form-dynamic.component';

@Component({
  selector: 'form-additional-card',
  templateUrl: './form-additional-card.component.html',
  styleUrls: ['./form-additional-card.component.css']
})

export class FormAdditionalCardComponent extends FormDynamicComponent  {

  /**
   * Lista de imputs que serão rendereizados na tela
   */
  @Input()
  inputs:any;

  /**
   * Inidice do formulário utilizado para indicar a quantidade
   * de cartão adicional
   */
  @Input()
  index:number;

  /**
   * Nome da lista para ser enviado para o back-end
   */
  @Input()
  listname:string = '';

  /**
   * Id que precisa ser enviado para o back-end
   */
  @Input()
  fieldId:string = '';

  /**
   * Tipo que precisa ser enviado para o back-end
   */
  @Input()
  fieldType:string = ''

  @ViewChildren('input') formInputs;

  /**
   * Evento disparado quando o form é removido
   */
  @Output()
  onRemove:EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento disparado quando o form está válido
   */
  @Output('onValid')
  onValid:EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento disparado quando o indice do form foi alterado
   */
  @Output()
  onChangeIndex:EventEmitter<any> = new EventEmitter<any>();

  /**
   * Array para armazenar o indicador se cada campo está valido
   */
  validFields:Array<any> = [];

  /**
   * Variavel para indicar o nome da lista do back-end com o indice dinâmico
   */
  listIndex:string;

  /**
   * Indicador para exibir/ocultar os campos no accordion
   */
  show:boolean = false;

  /**
   * Indicar para não renderizar mais o form
   */
  exists:boolean = true;

  /**
   * Indicador para saber quando o form está válido
   */
  valid:boolean = false;

  /**
   * Dependências do componente
   * @param cdr
   */
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Ao iniciar o componente...
   */
  ngOnInit() {
    this.cdr.detectChanges();
    this.setFields();
    this.setPrefixListname();
  }

  /**
   * Detecta todas as modificações feitas no form
   * depois do renderizado
   */
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * Deixa de exibir o formulário ao clicar no ícone de remover
   * Ele será destruido quando enviar o evento ao componente pai
   */
  remove() {
    this.exists = false;
    return this.onRemove.emit(this);
  }

  /**
   * Prepara os campos do formulário para o acompanhamento
   * de validação.
   */
  setFields() {
    for (let i:number=0; i < this.inputs.length; i++) {
      this.validFields[i] = false;
    }
  }

  /**
   * Define o nome de prefixo do list name
   */
  setPrefixListname() {
    this.listIndex = this.listname;
  }

  /**
   * Exibe/Oculta os campos do formulário
   */
  toogle() {
    this.show = ! this.show;
  }

  /**
   * Valida as informações do form
   * @param value
   * @param index
   */
  validateField(value, index) {
    this.validFields[index] = value;
    this.checkForm();
  }

  validCPF(value, index) {
    if (value.label !== 'CPF') return;
    this.onChangeIndex.emit({data: value, index});
  }

  /**
   * Verifica se todos os campos do formulário estão válidos
   * Ao verificar emite um evento para o evento pai
   */
  checkForm() {
    var check = true;
    for (let index = 0; index < this.validFields.length; index++) {
      if (this.validFields[index].valid !== true) {
        check = false;
      }
    }

    this.valid = check
    this.onValid.emit(this);
  }

  /**
   * Retorna o nome válido para cada campo renderizado
   * pra ser capturado pelo back-end
   * @param property
   */
  generateName(property) {
    return this.listIndex + '.' + 'properties[' + (property.match(/\d+/g).map(Number)[0] + (4 * Number(this.index))) + ']';
  }
}
