<div class="custom-header-mobile">
  <div style="width: 100%; display: flex;" *ngIf='!searchOn'>
    <div class="logo">
      <img [src]="this.path_assets + this.all_properties['--partner-logo']">
    </div>
    <div class="text">
      <p>Selecionar loja</p>
    </div>
    <div class="search-button">
      <span class="icon-itaufonts_busca_consulta" (click)='allowSearch()'></span>
    </div>
  </div>
  <div style='width: 100%; display: flex;' *ngIf='searchOn'>
    <div class='search-input'>
      <input id='id_searchBox' [(ngModel)]='term' (keyup)='searchStores()' type="text">
    </div>
    <div class="search-button-input">
      <span class="icon-itaufonts_busca_consulta" (click)='allowSearch()'></span>
    </div>
  </div>
</div>

<form class="form-choose-store" id="formChooseStore" asp-action="Index" asp-controller="Home" method="post">
  <voxel-container>
    <voxel-row>
      <voxel-item dc='12' mc='12' tc='12'>
        <div>
          <p class="title">Selecione a loja que você irá trabalhar…</p>
        </div>
      </voxel-item>
    </voxel-row>

    <input [value]="this.inputs.route" type="text" name="route" hidden>
    <input [value]="this.inputs.taskId" type="text" name="taskid" hidden>
    <input [value]="this.selectedStore?.id" type="text" id="fields[0].value" name="fields[0].value" hidden>

    <div class="scroll">
      <selectable-card *ngFor='let store of renderedStores' [id]='store.id_loja' subtitle='nome da loja'
        [title]='store.name_loja' (getCard)='getSelectedCard($event)'
        [selected]='selectedStore?.id == this.store.id_loja' svg=' '></selectable-card>
    </div>

    <voxel-row>
      <voxel-item dc='12' mc='12' tc='12'>
        <div class="btnGroup">
          <voxel-button label='Próxima' class="primary" [disabled]='disabled' [ngClass]="{'disabled': disabled}"
            submit-button [payload]="false"></voxel-button>
          <a [link]="base_path + '/logoff'" loader-button>
            <voxel-button label='Sair' class="secondary" submit-button [payload]="false"></voxel-button>
          </a>
        </div>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>
