import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.css']
})
export class SystemNotificationsComponent implements OnInit {

  @Input() inputs: string;

  public notifications: any[];

  constructor() {
    this.notifications = [];
  }

  ngOnInit() {
    let data = JSON.parse(this.inputs);
    this.notifications = data.inputs;
    document.querySelector('system-notifications').setAttribute('inputs', '');
  }

}
