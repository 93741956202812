<form id="formProfessional" asp-action="Index" asp-controller="CaptureInformationAddress" method="post" #form>
  <voxel-container>
      <voxel-row>
          <voxel-item>
              <div class="spacing__margin-top-xs spacing__margin-bottom-xs bold main-title-professional">
                  Falta pouco :)<br>  Agora são dados profissionais
              </div>
          </voxel-item>
      </voxel-row>

      <voxel-row>
          <voxel-item>
              <div class="spacing__margin-top-xs spacing__margin-bottom-xs bold sub-title-professional">
                  dados profissionais
              </div>
          </voxel-item>
      </voxel-row>
      <div class="profissional-data__container spacing__margin-top-xs" *ngIf="this.fields">
        <voxel-row>

            <voxel-item *ngFor="let field of this.fields; let i = index;" [tc]="12" [dc]="6" [mc]="12"
              [ngSwitch]="field.id">

              <custom-input
                [id]="field.id"
                [type]="field.type"
                [name]="field.type"
                [label]="field.label"
                [description]="field.description"
                [required]="field.required"
                [placeholder]="field.placeholder"
                [value]="field.value"
                [max]="field?.tamanho_maximo"
					      [min]="field?.tamanho_minimo"
                [listname]="field.listname"
                [options]='field.dropdownValues'
                (onBlur)="getInput($event, i)"
                (validate)="getInput($event, i)"
                option-value="value"
                [option-label]="field.type == 'dropdownSearch' ? 'search' : 'key'"
                [search]="field?.search"
                [validation]="field.validation_api">
              </custom-input>
            </voxel-item>
          </voxel-row>
      </div>

      <div class="spacing__margin-top-xs spacing__margin-bottom-xs btn-bottom btn-professional-bottom">
        <voxel-row>
          <voxel-item tc="12" dc="12" mc="12">
            <input [value]="this.taskid" type="hidden" name="taskid">
            <input [value]="this.route" type="hidden" name="route">
            <voxel-button 
              submit-button
              label="Continuar" 
              [disabled]="this.hasFormError()" 
              (click)="trackClick()"
            >
            </voxel-button>
          </voxel-item>
        </voxel-row>
      </div>
  </voxel-container>
</form>
