/**
 * Retorna o tipo do elemento.
 * @param needle - elemento que deseja obter o tipo.
 *
 * ### Exemplos:.
 *
 * ```ts
 * const case1 = "caso 01";
 * typeOf(case1); // "string"
 *
 * const case2 = [''];
 * typeOf(case2); // "array"
 *
 * const case3 = {a: 'test'};
 * typeOf(case3); // "object"
 *
 * class MyClass {}
 * const case4 = new MyClass();
 * typeOf(case4); // "object"
 * ```
 */
export const typeOf = (needle: unknown): string => {
  const objectPrototypeString = Object.prototype.toString.call(needle);
  const startSlice = objectPrototypeString.lastIndexOf(' ') + 1;

  return objectPrototypeString.slice(startSlice, -1).toLowerCase();
};
