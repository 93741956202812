<div class="card-analyze" [ngClass]="{'selected': selected===true}" (click)='clickCard()'>
    <voxel-row >
        <voxel-item tc="12" dc="12" mc="12">
            <span class="icon-itaufonts_full_cartao icon"></span>
        </voxel-item>

        <voxel-item tc="12" dc="12" mc="12">
            <label>nome do cartão</label>
            <p class="title">{{ this.cardname}}</p>
        </voxel-item>
        <voxel-item tc="12" dc="12" mc="12">
            <label>limite</label>
            <p class="title">{{ this.limit }}</p>
        </voxel-item>
        <voxel-item tc="12" dc="12" mc="12">
            <label>anuidade</label>
            <p class="title">{{ this.annuity }} </p>
        </voxel-item>      
    </voxel-row>
</div>
