import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormDynamicComponent } from "../../shared/form-dynamic/form-dynamic.component";
import { CryptoPipe } from "projects/commons-util/src/crypto/crypto.pipe";
import { map } from "rxjs/operators";
import { removeLoader } from "projects/commons-util/src/globalFunctions/loader";
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { ModalComponent } from "../../shared/modal/modal.component";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { AppDynamicsService } from "../../shared/services/app-dynamics.service";
import { Ga4Service } from "../../shared/services/ga4.service";
import { DayViewComponent } from "angular-mydatepicker";
import { DateService } from "src/app/core/utils/date";

@Component({
  selector: "form-pre-analysis",
  templateUrl: "./pre-analysis.component.html",
  styleUrls: ["./pre-analysis.component.css"]
})
export class PreAnalysisComponent extends FormDynamicComponent {
  @Input("input") input: string;
  @ViewChild("form") form: ElementRef;

  ageRange: string = "";
  route: string;
  taskid: string;
  domains: any[];
  showModal: boolean = false;
  items: Array<any>;
  modalDescription: string =
    "Não foi possível validar os dados, por favor tente novamente.";
  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'PreAnalise'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service,
    private dateService: DateService
  ) {
    super();
    this.items = [
      {
        id: "1",
        content: "Ok",
        a11yLabel: "Ok"
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe
      .transform(this.input, "decrypt")
      .pipe(
        map(data => {
          this.route = data.route;
          this.taskid = data.taskId;
          this.fields = data.fields;
          this.domains = data.domains;
          this.saveAnalyticsDataId(data);
        })
      )
      .subscribe(() => this.callAnalytics());
    this.appDynamicsService.callAppAdynamics(this.elementRef);

    // const data = JSON.parse(this.input);
    // console.log('data', data);

    // this.route = data.route;
    // this.taskid = data.taskId;
    // this.fields = data.fields;
    // this.domains = data.domains;
    // this.saveAnalyticsDataId(data);
    // this.callAnalytics();

    document
      .querySelector("form-pre-analysis")
      .setAttribute("input", "");
  }

  setAgeRange(data) {
    const { id, value } = data;
    if (id !== "data_nascimento") return;

    const ageRange = this.dateService.ageRange(value);
    this.analyticsService.setParameters({ customer_age_range: ageRange });
  }

  saveAnalyticsDataId(data: any) {
    console.log('> backend response: ', data);
    this.analyticsService.setParameters({ id_customer: 'no_customerid', store_name: data.store_name || '', customer_age_range: null });
    if (data.partner_name) {
      this.analyticsService.setParameters({ id_parceiro: data.partner_name});
    }
    if (data.store_id) {
      this.analyticsService.setParameters({ id_store_operator: data.store_id});
    }
    if (data.operator_id) {
      this.analyticsService.setParameters({ id_operator: data.operator_id});
    }
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  closeModal() {
    this.showModal = false;
  }

  trackClick(): void {
    const detail = this.sendResultToClient ? 'sim' : 'não';
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'DesejaReceberResultado',
        label: detail
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, `DesejaReceberResultado: ${detail}`);
  }

}
