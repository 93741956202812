import { Component, ChangeDetectorRef } from '@angular/core';
import { InputDynamicComponent } from '../input-dynamic/input-dynamic.component';
import { ValidateService } from 'projects/commons-util/src/validade-field/validate.service';

@Component({
  selector: 'custom-input',
  templateUrl: '../input-dynamic/input-dynamic.component.html',
  styleUrls: ['../input-dynamic/input-dynamic.component.css']
})
export class CustomInputComponent extends InputDynamicComponent {

  constructor(
    cdr: ChangeDetectorRef,
    _validateField: ValidateService
  ) {
    super(cdr, _validateField)
  }

}
