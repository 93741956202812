import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.css']
})
export class CardTemplateComponent implements OnInit {

  @Input("id") id:string;
  @Input("owner") owner:string;
  @Input("cardname") cardname:string;
  @Input("value") value:any;
  @Input("limit") limit:string;
  @Input("annuity") annuity:string;
  @Input("annuityTwoYears") annuityTwoYears:string;
  @Input("isAdditional") isAdditional:boolean = false;
  @Input("isBlocked") isBlocked:boolean = false;

  constructor() { }

  ngOnInit() {
  }


  formatCurrency(value) {
    let format = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };
    return parseFloat(value).toLocaleString('pt-BR', format)
  }  

}
