import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';

@Component({
  selector: 'additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.css']
})
export class AdditionalInfoComponent extends FormDynamicComponent {

  @Input() inputs: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'InformacoesAdicionais'
  };

  taskid: string;
  route: string;
  grid: any[] = [];
  fields: any[] = [];
  validateFields: any[] = [];
  customer_id: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef
  ) {
    super();
    this.populateGrid();
  }

  ngOnInit() {
    this.parseJSON(this.inputs);
    document.querySelector('additional-info').setAttribute('inputs', '');

    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  parseJSON(string: string) {
    if (environment.type == "local") {
      timer(1000).subscribe(()=> {
        const parsed = JSON.parse(this.inputs);
        this.fields = this.handleFields(parsed.fields);
        this.taskid = parsed.taskId;
        this.route = parsed.route;
      });
      return;
    }

    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.fields = this.handleFields(data.fields);
        this.taskid = data.taskId;
        this.route = data.route;
        this.customer_id = data.customer_id;
      }
      )).subscribe(() => { this.callAnalytics(); });
  }

  callAnalytics() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
  }

  /**
   * Remove o campo referente a descrição do cartão emitido.
   * @param fields - campos que serão exibidos no frontend.
   * @returns
   */
  handleFields(fields) {
    return fields.filter(field => field.id !== 'descricao_cartao_emitido_ato');
  }

  /**
   * Manipular a forma como o cartão vai ser impresso, quando o tipo de emissão do cartão for selecionado.
   *
   * Em todos os casos a forma como o cartão vai ser impresso é sempre "0" - Online todos, caso o operador selecione voucher(03), o valor será 1 - Envio correios.
   * @param event
   * @returns
   */
  handleCardToBeIssued(event) {
    const { id, selected } = event;
    const canBeSendByPost = ['03'];

    if (id !== 'descricao_emissao_cartao_credito_proposta') return;

    if (canBeSendByPost.includes(selected)) {
      document.getElementById("descricao_cartao_emitido_ato").setAttribute("value", "1");
    } else {
      document.getElementById("descricao_cartao_emitido_ato").setAttribute("value", "0");
    }
  }

  // validateField(event, type, i) {
  //   if (this.fields[i].required)
  //     this.validateFields[i] = event;

  //   console.log(this.validateFields)
  // }

  // validateForm() {
  //   for (let i = 0; i < this.validateFields.length; i++) {
  //     const element = this.validateFields[i];
  //     if (element === false){ return true;}
  //   }

  //   return false;
  // }

  //   submitForm(taskid: any, route: any) {
  //   const obj = <HTMLElement> taskid;
  //   obj.setAttribute('value', this.taskid);
  //   const obj2 = <HTMLElement> route;
  //   obj2.setAttribute('value', this.route);
  //   const elementForm: HTMLFormElement = document.getElementById('formAdditional') as HTMLFormElement;
  //   elementForm.submit();
  //   }

  populateGrid() {
    this.grid["dia_vencimento_cartao"] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid["descricao_emissao_cartao_credito_proposta"] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid["descricao_cartao_emitido_ato"] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid["adesao_recebimento_oferta_produto"] = { mc: 12, tc: 12, dc: 12, grid: null };
  }

}
