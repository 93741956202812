import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorCustomService {

  constructor(private deviceService: DeviceDetectorService) {
  }

  deviceDetector(): string {
    if (this.deviceService.isDesktop()) {
      return 'desktop';
    } else {
      return window['handlerJs'] ? 'mobile' : 'desktop';
    }
  }
}
