<voxel-container>
  <div class="center-div">

    <!-- Page Title -->
    <voxel-row>
      <voxel-item>
        <div class="header-content">
          <span class="icon-itaufonts_fatura icon first-icon"></span>
          <h2>IMPRESSÃO DE COMPROVANTE</h2>
        </div>
        <div class="header-content">
          <h2 class="h3">Por favor, selecione o comprovante que deseja imprimir:</h2>
          <br>
        </div>
      </voxel-item>
    </voxel-row>
    <!-- End Page Title -->

    <!-- Checkboxes com titulo dinamico -->
    <!-- <div *ngFor="let tipo of this.proposal ">
      <voxel-row>
        <voxel-item>
          <h3 class="title">{{tipo.tipo}}</h3>
          <hr>
        </voxel-item>
      </voxel-row>
      <voxel-row>
        <hr>
        <voxel-item *ngFor="let item of tipo.comprovantes; let indexOfelement=index">
          <voxel-checkbox name="checkbox" [id]="tipo.tipo+indexOfelement" label={{item.texto}} (change)="selected_chart($event.srcElement)">
          </voxel-checkbox>
        </voxel-item>
      </voxel-row>
    </div> -->
    <!-- End Checkboxes com titulo dinamico -->

    <!-- Checkbox Fixo -->
    <voxel-row>
      <voxel-item>
        <h3 class="title">Cartões</h3>
        <hr>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <hr>
      <voxel-item *ngFor="let item of this.proposal?.data?.comprovantes; let indexOfelement=index">
        <voxel-checkbox
          className="checkbox"
          [name]='indexOfelement.toString()'
          [id]='indexOfelement.toString()'
          [label]='item.texto'
          (change)="selected_chart($event.srcElement)"
          [checked]='item.check'>
        </voxel-checkbox>
      </voxel-item>
    </voxel-row>
    <!-- End Checkbox Fixo -->

    <!-- Form -->
    <div class="div-button">
      <form id="formDetail" asp-action="Index" asp-controller="TermProposalDetail" method="post">
        <div class="btn-content">
          <voxel-row>
            <voxel-item>
              <voxel-button 
                name="btnSubmit" 
                id="btnSubmit" 
                label="Imprimir comprovante" 
                (click)="onClickPrintTerm()">
              </voxel-button>
            </voxel-item>
          </voxel-row>
        </div>
      </form>
    </div>
    <!-- End Form -->
  </div>
</voxel-container>
