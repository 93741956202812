import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { map, catchError } from 'rxjs/operators';

import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { Observable, throwError } from 'rxjs';
import { CacheRedisService } from '../cache/cacheRedis.service';

@Pipe({
  name: 'crypto'
})
export class CryptoPipe implements PipeTransform {

  constructor(
    private dnaService: DnaService,
    private cacheService: CacheRedisService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  transform(value: string, action: string, loader: boolean = true): Observable<any> {
    loader ? initLoader(innerWidth) : '';
    // console.log("Iniciar observable")
    let observable$ = new Observable(obs => {
      this.cacheService.get()
        .pipe(
          map(dataRedis => {

            if (dataRedis.tokenencrypt) {
              this.dnaService.post('/Cryptography/TokenEncrypt', 'TokenEncrypt', { message: dataRedis.tokenencrypt })
                .pipe(
                  map(dataToken => {
                    if (dataToken) {
                      this.cacheService.post({ tokenencrypt: dataToken.message })
                        .pipe(
                          map(dataRedisPost => {

                            if (action === 'decrypt') {
                              // console.log("Iniciando decrypt")
                              this.dnaService.post('/Cryptography/Decrypt', 'Decrypt', { message: value })
                                .pipe(
                                  map(data => {
                                    // console.log("Fim decript")
                                    obs.next(data)
                                  })
                                ).subscribe()
                            } else if (action === 'encrypt') {
                              // console.log("Iniciando encrypt")
                              this.dnaService.post('/Cryptography/Encrypt', 'Encrypt', { message: value })
                                .pipe(
                                  map(data => {
                                    // console.log("Fim encrypt")
                                    obs.next(data)
                                  })
                                ).subscribe()
                            } else {
                              obs.next(null)
                            };

                          })
                        )
                        .subscribe()
                    } else {
                      throwError("Token nao retornado a aplicacao")
                    }
                  })
                ).subscribe()
            } else {
              // console.log("Token inexistente")
              this.dnaService.postCaptureLogs('token-id is missing', true, true,'token-id is missing');

              obs.next(null)
            };

          })
        )
        .subscribe()

    }).pipe(
      map((result: any) => { removeLoader(); return result })
    );

    return observable$.pipe(
      catchError(err => this.handleError(err))
    );
  }


  private handleError(error: any): Observable<never> {
    let errorMessage = '';
    if (error.error && error.error.message) {
      errorMessage = error.error.message; // Get client-side error
    } else if (error.status && error.error.message) {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`; // Get server-side error
    } else {
      errorMessage = error;
    }

    this.dnaService.postCaptureLogs(errorMessage, true, true,errorMessage);
    return throwError(errorMessage);
  }
}
