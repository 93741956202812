import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import {
  Component,
  Input,
  ChangeDetectorRef
} from "@angular/core";
import { FormDynamicComponent } from "../../shared/form-dynamic/form-dynamic.component";
import { CryptoPipe } from "projects/commons-util/src/crypto/crypto.pipe";
import { map } from "rxjs/operators";
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from "../../shared/services/analytics.service";

@Component({
  selector: 'temporary-password',
  templateUrl: './temporary-password.component.html',
  styleUrls: ['./temporary-password.component.css']
})
export class TemporaryPasswordComponent extends FormDynamicComponent {
  @Input("inputs") inputs: string;

  route: string;
  taskid: string;
  domains: any[];
  items: Array<any>;
  temporaryPassword: string;
  cpf: string;
  readonly PAGE_NAME = {
    section: 'RedefSenhaProvisoria'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.items = [
      {
        id: "1",
        content: "Ok",
        a11yLabel: "Ok"
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe
      .transform(this.inputs, "decrypt")
      .pipe(
        map(data => {
          this.route = data.route;
          this.taskid = data.taskId;
          this.fields = data.fields;
          this.domains = data.domains;
          this.cpf = data.domains.cpf;
          this.temporaryPassword = data.domains.temporaryPassword;
        })
      )
      .subscribe(() => this.callAnalytics());

    // const data = JSON.parse(this.inputs);
    // this.fields = JSON.parse(this.inputs).fields;
    // this.cpf = data.domains.cpf;
    // this.temporaryPassword = data.domains.temporaryPassword;


    document
      .querySelector("temporary-password")
      .setAttribute("input", "");
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  onClickHome(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'BTSenha',
        label: 'Home'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'BTSenha_Home');
  }

  onClickNewPassword(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'BTSenha',
        label: 'SolicitarNova'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'BTSenha_SolicitarNova');
  }

}
