<div class="container-img">
  <!-- <img *ngIf="this.innerWidth < 768" src="{{ path_assets + '03_dispositivo_nao_autorizado.svg' }}">
  <img *ngIf="this.innerWidth > 767" src="{{ path_assets + 'ilustra_sempermissao.svg' }}"> -->
  <img src="{{ path_assets + img }}">
</div>

<div *ngIf="unauthorizedType == 'device'">
  <div class="header">Dispositivo não autorizado :(</div>

  <div class="body">Você não tem permissão para autorizar este dispositivo, solicite ao master ou responsável para
    autorizar o dispositivo.</div>
</div>
<div *ngIf="unauthorizedType == 'app-version'">
  <div class="header">Versão do aplicativo inválida! :(</div>
  <div class="body">Solicite ao master ou responsável a atualização de seu aplicativo.</div>
</div>
<voxel-container>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <a [link]="base_path + '/logoff'" loader-button>
        <voxel-button class="home" id="button-principal" label="Sair"></voxel-button>
      </a>
    </voxel-item>
  </voxel-row>
</voxel-container>