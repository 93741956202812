<form asp-action="Index" asp-controller="DeniedProduct" method="post">
  <voxel-container>
    <voxel-row class="row">
      <voxel-item style="height: 126px;">
        <div class="title">
          <card-header title="RESULTADO PRÉ-ANÁLISE" >
          </card-header>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item>
        <div class="text">
          <p>
            Agradecemos o interesse do cliente para este produto, mas neste momento não foi possível atender esta solicitação.
          </p>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item style="width: 100%;">
        <div class="btnGroup">
          <a [link]="base_path + '/home'" loader-button>
            <voxel-button class="secondary" label='Home'></voxel-button>
          </a>
        </div>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>
