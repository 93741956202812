import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { isNull } from 'util';
import { ChangeDetectorRef } from '@angular/core';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { element } from '@angular/core/src/render3';
import { ModalComponent } from '../../shared/modal/modal.component';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { environment } from 'src/environments/environment';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'form-customer',
  templateUrl: './form-customer.component.html',
  styleUrls: ['./form-customer.component.css']
})
export class FormCustomerComponent extends FormDynamicComponent {

  // error = 'invalid'
  // label= 'CPF'

  @ViewChild(ModalComponent) modalComponent: ModalComponent;


  @Input()
  mainTitle: string;

  /**
   * String em JSON com todos os inputs para serem renderizados
   */
  @Input()
  inputs: string;

  /**
   * Lista dos campos deserializados prontos para renderizar
   */
  fields: any;

  /**
   * Variavel de controle para habilitar/desabilitar o botão de submit
   */
  agree: boolean = false;

  /**
   * Grupos de campos devem ser ocultados/exibidos de acordo
   * com os eventos do formulário
   */
  visible: Array<any> = [];

  /**
   * Armazena o estado civil do Usuário,
   * Status inicial casado = false
   */
  married: boolean;

  /**
   * Valor do route para o back-end
   */
  route: string;

  /**
   * Valor do task para o back-end
   */
  taskId: string;

  // validateFields: Array<any> = [];

  additionalFields: Array<any> = [];

  model: IMyDateModel = null;

  masks: Array<any> = [];
  mask: string = '';
  pattern: string = '';
  showModal: boolean = false;
  items: Array<any>;
  customer_id: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'DadosDoCliente'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
    this.populateGrid();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * @description
   * Popula a tabela de configurações de front-end de cada campo
   * Essa tabela é utilizada para configurar a dimensão de cada coluna;
   * Definir a orientação que cada campo deve ter(direita, esquerda);
   * Definir que tipo de agrupamento cada campo pertence e qual indice de grupo
   * @todo Verificar se é possivel colocar essa configuração no back-end
   */
  populateGrid() {
    this.grid['cpf'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['numero_celular'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['email'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['data_nascimento'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['nome_completo'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['tipo_documento'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['numero_documento'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'docs', group: '2' };
    this.grid['orgao_expedidor'] = { mc: 6, tc: 3, dc: 6, grid: null, type: 'docs', group: '2' };
    this.grid['uf_expedicao'] = { mc: 6, tc: 3, dc: 6, grid: null, type: 'docs', group: '2' };
    this.grid['data_expedicao'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'docs', group: '2' };
    this.grid['cidade_nascimento'] = { mc: 6, tc: 3, dc: 6, grid: null, type: null, group: null };
    this.grid['estado_nascimento'] = { mc: 6, tc: 3, dc: 6, grid: null, type: null, group: null };
    this.grid['nacionalidade'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['genero'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['estado_civil'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['nome_da_mae'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['numero_celular'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['email'] = { mc: 12, tc: 6, dc: 6, grid: null, type: null, group: null };
    this.grid['dados_conjuge'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'conj', group: '001' };
    this.grid['nome_conjuge'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'conj', group: '001' };
    this.grid['numero_cpf_conjuge'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'conj', group: '001' };
    this.grid['data_nascimento_conjuge'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'conj', group: '001' };
    this.grid['telefone_conjuge'] = { mc: 12, tc: 6, dc: 6, grid: null, type: 'conj', group: '001' };
  }

  ngOnInit() {
    this.parseJson();
    this.initGroups();
    this.renderScreenAtTop();
  }

  parseJson() {
    if (environment.type == "local") {
      const data = JSON.parse(this.inputs);
      this.setFields(data);
    } else {
      this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
        map(data => {
          this.setFields(data);
        }
        )).subscribe(() => { this.getMetrics(); });
    }

    document.querySelector('form-customer').setAttribute('inputs', '');
  }

  getMetrics() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  setFields(obj: any) {
    this.route = obj.route ? obj.route : '';
    this.taskId = obj.taskId ? obj.taskId : '';
    this.fields = obj.fields ? obj.fields : {};
    this.additionalFields = obj.domains.inputs ? obj.domains.inputs : {};
    this.masks = obj.domains.masks ? obj.domains.masks.masks : [];
    this.customer_id = obj.customer_id || '';

    this.openModal(obj.fields);

    this.fields.forEach(field => {
      if (field.type !== 'object') {
        if (field.id === 'estado_civil') {
          if (field.value === '001') {
            this.married = true;
          }
        }
      }
    });
  }

  openModal(fields) {
    fields.map(field => {
      if (field.value) {
        this.modalComponent.display = true
      }
    });
  }

  /**
 * @description
 * Quando o formulário já recebe inputs com valores, eles entram em foco para autovalidação
 * Esse método serve apenas para scrollar a tela ao topo para o usuário começar a preenher
 * desde o primeiro campo
 * @return void
 */
  renderScreenAtTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 400);
  }


  /**
   * @description
   * Inicializa a propriedade visible com valores padrão.
   * É essencial para bom funcionamento da funcionalidade de
   * ocultação/exibição dos campos que alternam visibilidade
   * conforme opções selecionadas no combo tipo_documento e estado_civil
   * @return void
   */
  initGroups(): void {
    /*
      // Para utilização deste método de configuração inicial
      // é necessário que o objeto recebido pela propriedade fields
      // contenha nas properties tipo_documento e estado_civil a propriedade
      // group setada com o valor do grupo em questão, exemplo:
      {
        id: 'tipo_documento',
        group: 'docs'
      }, {
        id: 'estado_civil',
        group: 'conj
      }
      for (let field of this.fields) {
        if ('group' in field) {
          this.visible[field.group] = [];
          let count:number = 0;
          for (let val of field.dropdownValues) {
            if (count == 0) {
              this.visible[field.group][val.code] = true;
            } else {
              this.visible[field.group][val.code] = false;
            }
            count++;
          }
        }
      }
      // console.log(this.visible);
    */
    this.visible['docs'] = [];
    this.visible['docs']['2'] = true;
    this.visible['docs']['3'] = false;
    this.visible['conj'] = [];
    this.visible['conj']['001'] = true;
    this.visible['conj']['002'] = false;
    this.visible['conj']['003'] = false;
    this.visible['conj']['004'] = false;
    this.visible['conj']['005'] = false;
    this.visible['conj']['006'] = false;
  }


  /**
   * @description
   * Exibe um determinado grupo de inputs de acordo com seu tipo
   *
   * @param type   Tipo do grupo
   * @param group  Indice de grupo
   * @return void
   */
  showGroup(type, group) {
    let index = group.value;

    if (this.visible[type] == null) {
      this.visible[type] = []
      this.visible[type][index] = true;
      return;
    }

    for (let i in this.visible[type]) {
      this.visible[type][i] = false;
    }

    this.visible[type][index] = true;
  }

  /**
   * @description
   * Verifica se o input pode ser exibido na tela,
   * de acordo com o tipo e indice do seu grupo
   *
   * @param id
   * @return boolean
   */
  isVisible(id): boolean {

    if (this.grid[id] == null) return true;

    let type = this.grid[id].type;

    if (type == null) return true;

    if (this.visible[type] == null) return false;

    let group = this.grid[id].group;
    return this.visible[type][group];
  }

  /**
   * @description
   * Assinala/Desassinala os termos de contrato
   */
  checkAgree() {
    this.agree = !this.agree;
  }

  /**
   * @description
   * Alterna o status da propriedade married
   */
  toggleCivilStatus(event, index): void {
    if (this.fields[index].id === 'estado_civil') {
      if (event.selected === '001') {
        this.married = true;
      } else {
        this.married = false;
        this.inputsForm = this.inputsForm.slice(0, 11);
      }
    }

    if (this.fields[index].id === 'tipo_documento') {
      this.mask = '';
      this.masks.forEach(mask => {
        if (event && event.selectedLabel && event.selectedLabel.toString().includes(mask.key)) {
          this.mask = mask.value;
          this.pattern = mask.pattern;
          return;
        }
      });
    }
  }

  verifyPartner(i, j) {

    const ids = [
      'nome_conjuge',
      'numero_cpf_conjuge',
      'data_nascimento_conjuge',
      'telefone_conjuge'
    ];

    const id = this.fields[i].properties[j].id;

    if (ids.indexOf(id) === -1) { return false; }

    if (this.married) {
      this.fields[i].properties[j].required = true;
      return false;
    }

    this.fields[i].properties[j].required = false;
    return true;
  }

  closeModal() {
    this.showModal = false;
  }

  modalBtnEvent($event: any): void {
    this.renderScreenAtTop();
  }

}
