import { Directive, HostListener, ElementRef, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Directive({
  selector: '[cpfFuncional]'
})
export class CpfFuncionalDirective {

  @Input('maxlength') length: string;
  @Output('inputChange') inputChange = new EventEmitter();

  constructor(private el: ElementRef) { }


  convertCpfFuncional(num) {
    if (num) {
      num = num.toString();

      num = num.replace(/\D/g, '');

      switch (true) {
        case num.length < 10:
          num = num.replace(/(\d+)/, '$1');
          break;
        case num.length == 10:
          num = num.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case num.length == 11:
          num = num.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case num.length > 11:
          num = num.replace(/(\d{3})(\d{3})(\d{3})(\d{2})(\d+)/, '$1.$2.$3-$4');
          break;
      }
    }
    this.inputChange.emit(num);
    // console.log(num)
    return num;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    var value = this.el.nativeElement.querySelector('input').value;
    this.el.nativeElement.querySelector('input').value = this.convertCpfFuncional(value + event.key)

    event.preventDefault()
  }

  @HostListener('keyup', ['$event']) onKeyUp() {
    var value = this.el.nativeElement.querySelector('input').value;
    this.el.nativeElement.querySelector('input').value = this.convertCpfFuncional(value)
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    // console.log(event)
  }

}
