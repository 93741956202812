<div class="container-img">
  <!-- <img *ngIf="this.innerWidth < 768" src="{{ path_assets + '03_dispositivo_nao_autorizado.svg' }}">
  <img *ngIf="this.innerWidth > 767" src="{{ path_assets + 'ilustra_sempermissao.svg' }}"> -->
  <img src="{{ path_assets + img }}">
</div>

<div class="header">Voucher<br>indisponível :(</div>

<div class="body"></div>

<voxel-container>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <a [link]="base_path + '/Home'" loader-button>
        <voxel-button class="home" id="button-principal" label="Home"></voxel-button>
      </a>
    </voxel-item>
  </voxel-row>
</voxel-container>
