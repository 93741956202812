<div class="card-header spacing__margin-xs">
  <voxel-container>
    <voxel-row>
      <voxel-item tc="12" dc="12" mc="12">
        <span *ngIf="! this.svg" class="{{ this.icon +' icon' }}"></span>
        <svg-icon *ngIf="this.svg" [applyCss]="true" class="css" src="{{ this.svg }}"></svg-icon>
      </voxel-item>

      <voxel-item class="title" tc="12" dc="12" mc="12">
        <p>{{ this.title }}</p>
        <br>
      </voxel-item>

      <voxel-item tc="12" dc="12" mc="12">
        <label class="subtitle">{{ this.subtitle }}</label>
      </voxel-item>

      <voxel-item tc="12" dc="12" mc="12" style="margin-top: 20px;">
        <label class="subtitle">{{ this.message }}</label>
      </voxel-item>
      
    </voxel-row>
  </voxel-container>
</div>
