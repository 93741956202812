<div *ngIf="type === 'DEFAULT'" [class]="'default-container clearfix'">
  <div class="title">
    {{ name }}
  </div>
  <div *ngFor="let array of columnArray" class="column" [style.width.%]="100/columnArray.length">
    <div *ngFor="let item of array; let i = index;">
      <div [class]="'square weight' + item.type_item.weight">
        <card-menu
          [route]="this.base_path + item.route"
          [icon]="item.icon"
          [name]="item.name"
          [notification]="item.type_item.type === 'NOTIFICATION' ? 10 : 0">
        </card-menu>
      </div>
    </div>
  </div>
</div>
<div *ngIf="type === 'HORIZONTAL'" [class]="'horizon-container clearfix'">
  <div class="title">
    {{ name }}
  </div>
  <div class="carousel">
    <div class="clearfix" [style.width.px]="widthCarousel">
      <div *ngFor="let item of array" [class]="'min-square min-weight' + item.type_item.weight">
        <card-menu
          [route]="this.base_path + item.route"
          [icon]="item.icon"
          [name]="item.name">
        </card-menu>
      </div>
    </div>
  </div>
</div>
<ng-content></ng-content>
