<form
  id="formCollect"
  asp-action="Index"
  asp-controller="CaptureInformationAddress"
  method="post"
  #form
>
  <div class="spacing__margin-top-xs form-container" *ngIf="this.fields">
    <voxel-container>
      <voxel-row>
        <voxel-item
          *ngFor="let field of this.fields; let i = index"
          [mc]="12"
          [tc]="12"
          [dc]="6"
        >
          <div
            class="spacing__margin-top-x spacing__margin-bottom-x"
            *ngIf="field.id == 'indicador_resultado_pre_analise'"
          >
            &nbsp;
          </div>

          <custom-input
            [id]="field.id"
            [type]="field.type"
            [name]="field.type"
            [label]="field.label"
            [description]="field.description"
            [required]="field.required"
            [placeholder]="field.placeholder"
            [value]="field.value"
            [max]="field?.tamanho_maximo"
            [min]="field?.tamanho_minimo"
            [listname]="field.listname"
            (onBlur)="getInput($event, i)"
            (validate)="getInput($event, i)"
            [validation]="field.validation_api"
            *ngIf="field.type !== 'boolean'"
          >
          </custom-input>
        </voxel-item>
      </voxel-row>

      <div class="spacing__margin-top-xs spacing__margin-bottom-xs">
        <voxel-row>
          <voxel-item
            *ngFor="let field of this.fields; let i = index"
            [mc]="12"
            [tc]="12"
            [dc]="12"
          >
            <custom-input
              [id]="field.id"
              [type]="field.type"
              [name]="field.type"
              [label]="field.label"
              [description]="field.description"
              [required]="field.required"
              [placeholder]="field.placeholder"
              [max]="field?.tamanho_maximo"
              [min]="field?.tamanho_minimo"
              [value]="field.value"
              [listname]="field.listname"
              (onBlur)="getInput($event, i)"
              (validate)="getInput($event, i)"
              [validation]="field.validation_api"
              *ngIf="field.type === 'boolean'"
            >
            </custom-input>
          </voxel-item>
        </voxel-row>
      </div>
    </voxel-container>
  </div>

  <div
    class="spacing__margin-top-xs spacing__margin-bottom-xs button-container"
  >
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <input [value]="this.taskid" type="hidden" name="taskid" />
          <input [value]="this.route" type="hidden" name="route" />
          <voxel-button
            (submitedFunction)="submitButton($event)"
            submit-button
            label="Próximo"
            [disabled]="this.hasFormError()"
          >
          </voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>
</form>

<voxel-dialog
  [(showDialog)]="showModal"
  [items]="items"
  [description]="this.modalDescription"
  (dialogLinkClick)="closeModal()"
>
</voxel-dialog>

<modal-dialog
  title="Aviso"
  overlay="true"
  btn1="fechar"
  type1="close"
  class1="freeline"
>
  <div subt>
    Não foi possível validar os dados, por favor tente novamente.
  </div>
</modal-dialog>
