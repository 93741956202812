import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../../shared/modal/modal.component';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'form-choose-product',
  templateUrl: './form-choose-product.component.html',
  styleUrls: ['./form-choose-product.component.css']
})
export class FormChooseProductComponent extends GlobalProperties {

  @Input("inputs") inputs: any;
  @ViewChild(ModalComponent) modalComponent: ModalComponent;

  inputsParsed: any;
  productFamily: any;
  selectedProduct: any;
  enabled: boolean = false;

  img: string;

  constructor(private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() { 
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {          

        this.inputsParsed = data;
        this.productFamily = data.domains.familiaProdutos;
        this.modalComponent.display = data.domains.propostaEmAberto;
        this.inputsParsed.domains.rotaContinuarProposta = this.base_path + data.domains.rotaContinuarProposta;
        this.inputsParsed.domains.rotaNovaProposta = this.base_path + data.domains.rotaNovaProposta;

        if(this.productFamily.length == 0) {
          document.body.style.backgroundColor = 'white';
        }
      }
    )).subscribe();
    
    document.querySelector('form-choose-product').setAttribute('inputs', '');
    
    this.img = '08_proposta_recusada copy.svg';    
  }

  setFields(data: any) {
    this.inputsParsed = data;
    this.productFamily = data.domains.familiaProdutos;
    this.modalComponent.display = data.domains.propostaEmAberto;
    this.inputsParsed.domains.rotaContinuarProposta = this.base_path + data.domains.rotaContinuarProposta;
    this.inputsParsed.domains.rotaNovaProposta = this.base_path + data.domains.rotaNovaProposta;
  }

  getSelectedCard(e) {
    this.selectedProduct = e;
    this.enabled = true;
  }

  openModal() {
    this.modalComponent.display = true;
    window.scrollTo(0, 0);
  }
}
