import { Component, OnInit, Input } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends GlobalProperties {

  @Input("link-sair") linkSair : string;
  @Input("folder-image") folderImage: string;

  innerWidth: any;
  img: string;

  constructor() {
    super();
   }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_esqueceusenha.svg';
    // } else {
    //   this.img = '01_esqueceu_sua_senha.svg';
    // }

    this.img = '01_esqueceu_sua_senha.svg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_esqueceusenha.svg';
    // } else {
    //   this.img = '01_esqueceu_sua_senha.svg';
    // }
  }

  buttonSair(){
    window.location.href = this.linkSair;
  }

}
