import { Component, OnInit, Input } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'term-proposal-detail',
  templateUrl: './term-proposal-detail.component.html',
  styleUrls: ['./term-proposal-detail.component.scss']
})
export class TermProposalDetailComponent extends GlobalProperties implements OnInit {

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  /**
   * @description Objeto contendo dados da proposta
   */
  proposal: any = {};

  /**
   * @description Array contendo os status validos
   */
  private validStatus = ['em análise', 'recusado', 'finalizado'];

  constructor(private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => this.proposal = data
    )).subscribe();

    // console.log(this.proposal)
    // this.proposal = JSON.parse(this.inputs)
    document.querySelector('term-proposal-detail').removeAttribute('inputs');
  }

  /**
   * Retorna a classe CSS de acordo com o valor
   * do status da proposta
   *
   * @param value status da proposta
   * @return Objeto contendo nome da classe e um valor boleano true se a classe for valida
   */
  getClassByStatus(value: string): object {
    value = value ? value.toLowerCase() : '';

    const obj = {
      analisado: this.validStatus.includes(value),
      recusado: this.validStatus.includes(value),
      'em-andamento': this.validStatus.includes(value)
    };

    return obj;
  }


  /**
   * @description Metodo vinculado ao evento click do botão de re-imprimir termo
   */
  onClickPrintTerm(): void {

    if (this.proposal && this.proposal.pdf) {

      const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
      'resizable,screenX=50,screenY=50,width=850,height=1050';

      const htmlPop = '<embed width=100% height=100%'
                    + ' type="application/pdf"'
                    + ' src="data:application/pdf;base64,'
                    + encodeURI(this.proposal.pdf)
                    + '"></embed>';

      const pdfWindow = window.open('', 'PDF', winparams);
      pdfWindow.document.write(htmlPop);

    } else {
      alert('pdf nao existe');
    }
  }

}
