import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[read-only]'
})
export class ReadOnlyDirective {

  constructor(private el:ElementRef) { }

  @Input("read-only") readOnly:string;

  apply:boolean = true;

  /**
   * Necessária inicialização neste Hook visto que
   * deve agir mesmo em componentes gerados dinamicamente
   * como o FormDynamicComponent
   */
  ngAfterViewInit() {
    if (this.readOnly) {
      this.apply = this.readOnly == "false" ? false : true;
    }

    if (this.apply) {
      this.modifyAttributes();
    }
  }

  /**
   * Varre os elementos que compõem o componente que
   * recebeu a presente diretiva a procura de elementos input
   * e em seguida torna estes modo apenas leitura
   * @param elem
   */
  private modifyAttributes():void {

    this.readOnlyCustomDatepicker();

    let voxelInputs = <HTMLCollection> this.el.nativeElement.querySelectorAll("voxel-input");

    for (let i = 0; i < voxelInputs.length; i++) {
        let div = <HTMLElement> voxelInputs[i].querySelector("div");
        div.setAttribute("style", "border-bottom: 1px solid #D8D2CE !important");

        let label = <HTMLElement> voxelInputs[i].querySelector("label");
        label.setAttribute("style", "color: #D8D2CE !important");
        label.setAttribute("style", "font-weight: 400 !important");

        let input = <HTMLElement> voxelInputs[i].querySelector("input");
        input.setAttribute("readonly", "true");
        input.setAttribute("style", "color: #D8D2CE !important");
    }

    let voxelSelects = <HTMLCollection> this.el.nativeElement.querySelectorAll("voxel-dropdown-list");

    for(let i = 0; i < voxelSelects.length; i++) {
      let label = <HTMLElement> voxelSelects[i].querySelector("label");
      label.setAttribute("style", "color: #D8D2CE !important");
      label.setAttribute("style", "font-weight: 400 !important");

      let select = <HTMLElement> voxelSelects[i].querySelector("select");
      select.setAttribute("style", "pointer-events: none !important; touch-action: none !important; color: #D8D2CE !importan");

      let overlay = <HTMLElement> voxelSelects[i].querySelector(".overlay");
      overlay.classList.add('disabledbutton');

      let div = <HTMLElement> voxelSelects[i].querySelector("div");
        div.setAttribute("style", "border-bottom: 1px solid #D8D2CE !important");
    }

  }

  /**
   * Deixa o datepicker como read only
   */
  readOnlyCustomDatepicker() {
    var color = document.documentElement.style.getPropertyValue('--primary-color');
    var customDatepicker = <HTMLCollection> this.el.nativeElement.querySelectorAll("custom-datepicker");

    for (let i = 0; i < customDatepicker.length; i++) {
      
      let input = <HTMLElement> customDatepicker[i].querySelector("input");
      input.setAttribute("readonly", "true");
      input.setAttribute("style", "color: #D8D2CE !important"); 

      let datepicker = <HTMLElement> customDatepicker[i].querySelector("mat-datepicker"); 
      let oldSpan = <HTMLElement> customDatepicker[i].querySelector("span");
      
      let newSpan = document.createElement('span');
      newSpan.setAttribute("class", oldSpan.getAttribute("class"))
      newSpan.style.color = color

      datepicker.append(newSpan);
      oldSpan.remove();
    }
  }

}
