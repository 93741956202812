import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';


@Component({
  selector: 'form-new-card',
  templateUrl: './form-new-card.component.html',
  styleUrls: ['./form-new-card.component.css']
})
export class FormNewCardComponent extends GlobalProperties {

  /**
   * Propriedades do formulário
   */
  @ViewChild('frm') frm;

  validate: boolean;

  /**
   * Entrada de valor de CPF
   */
  @Input("cpf") cpfValue: string

  /**
   *
   * @param cdr
   */
  constructor(public cdr: ChangeDetectorRef,
    public cryptoPipe: CryptoPipe) {
    super();
  }

  /**
   *
   */
  ngOnInit() {
    this.cryptoPipe.transform('', 'token').subscribe();
  }

  /**
   *
   */
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * Verifica se o formulário está valido
   * @param event
   */
  validateField(event) {
    this.validate = event.valid;
  }

  /**
   * Verifica a tecla digitada e se o formulário está valido
   * Se a tecla for enter, submete o formulário.
   * @param $event
   */
  onCheckToSubmit(event) {
    if (event.key === 'Enter' && this.validate) {
      // this.frm.nativeElement.submit();
      const teste = document.querySelector('#button-proxima') as HTMLElement;
      teste.querySelector('button').click();
    }
  }

}
