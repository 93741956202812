<!-- WEB -->
<div class="container-web" id='login-web'>
  <div class="backgroundFilter">
    <img [src]="this.folderImage + '/background_login.png'" class="back-image">
  </div>
  <div class="login-form-background">
    <voxel-row>
      <voxel-item style='margin-left: 7vw; margin-top: 28vh;'>
        <voxel-item>
          <div class="movaLogo">
            <div>
              <img [src]="this.folderImage + 'Logo_Mova.svg'">
            </div>
            <div class="text">
              <span class="webTextCanal">mobilidade <br> de vendas <br> e atendimento</span>
            </div>
          </div>
        </voxel-item>
        <div class="form-div">
          <label class="form-label">Modulo de Segurança</label>
          <button 
          loader-button
          (click)="baixarDocumento()"
          id="button2"
          label="Download Manual"
          >Download Manual</button>
          <button 
          loader-button
          (click)="baixarInstalador()"
          id="button1"
          label="Download Instalador"
          > Download Instalador </button>
          <br>
        </div>
      </voxel-item>
    </voxel-row>
  </div>
  <div class="login-slogan">
    <p align='right' class="slogan-text">Módulo de segurança - MOVA - faça o seu download aqui!</p>
  </div>
</div>
