import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'styles-control',
  templateUrl: './styles-control.component.html',
  styleUrls: ['./styles-control.component.css']
})
export class StylesControlComponent implements OnInit {

  constructor() { }

  initAllParameters() {
    document.documentElement.style.setProperty('--background-color', "#ffffff");
    document.documentElement.style.setProperty('--background-inverse', "#EFE9E5");
    document.documentElement.style.setProperty('--primary-color', "#EB1D29");
    document.documentElement.style.setProperty('--secondary-color', "#ffffff");
    document.documentElement.style.setProperty('--partner-logo', "logo-par-magalu.svg");
    document.documentElement.style.setProperty('--basic-color', '#252220');
    document.documentElement.style.setProperty('--assets-path', '../../../assets/');
    document.documentElement.style.setProperty('--base-path', '');
    document.documentElement.style.setProperty('--blank-color', '#ffffff');
    document.documentElement.style.setProperty('--urldna', 'http://localhost:3717');
    document.documentElement.style.setProperty('--simulador', 'true');
  }

  ngOnInit() {
    this.initAllParameters();
  }

}
