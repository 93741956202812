<ng-container *ngIf="parsedInputs != null">
  <form method="POST" *ngIf='newHtml == false'>

    <input type="text" name="taskid" [value]="this.taskid" hidden>
    <input type="text" name="route" [value]="this.route" hidden>
    <input type="text" name="domains.termSignature.base64" [value]="this.signature" hidden>
    <input type="text" name="fields[0].id" [value]="this.parsedInputs?.fields[0].id" hidden>
    <input type="text" name="fields[0].value" [value]="this.disabled" hidden>
    <!-- <input type="text" name="domains.IdTipoConteudo" [value]="this.idTipoConteudo" hidden> -->

    <voxel-container>
      <voxel-row>
        <voxel-item>
          <p style='font-size: 24px;padding: 25px 24px;'>Confira os dados</p>
        </voxel-item>
      </voxel-row>
    </voxel-container>


    <voxel-row class="header" tc='12' dc='12' mc='12'>
      <voxel-item>
        <label class="title">CARTÕES</label>
      </voxel-item>
    </voxel-row>

    <!-- Card Template -->
    <card-template *ngFor="let card of domainTermCard" [owner]=card.namePersona [cardname]=card.nameCartao
      [limit]=card.limite [annuity]=card.anuidade [isAdditional]=card.isAdditional
      [annuityTwoYears]=card.anuidade_demais_anos>
    </card-template>

    <!-- FIM Card Template -->

    <!-- Valor mensal -->
    <voxel-row class="header" tc='12' dc='12' mc='12' *ngIf="domainTermService && domainTermService.length > 0">
      <voxel-item style="margin-top: 0.9rem;">
        <label class="title">SERVIÇOS</label>
      </voxel-item>
    </voxel-row>

    <small-card-template *ngFor='let service of domainTermService' [icon]=service.icon [name]=service.texto [label]="service.texto_valor"
      [value]=service.valorMensal>
    </small-card-template>


    <voxel-row dc='12' mc='12' tc='12' class="header" *ngIf="domainTermService && domainTermInsurance.length > 0">
      <voxel-item style="margin-top: 1.2rem;">
        <label class="title">SEGUROS</label>
        <p class="subtitle">Apenas para o titular.</p>
      </voxel-item>
    </voxel-row>

    <small-card-template *ngFor='let insurance of domainTermInsurance' [icon]=insurance.icon [name]=insurance.texto [label]="insurance.texto_valor"
      [value]=insurance.valorMensal>
    </small-card-template>
    <!--FIM Valor mensal -->

    <!-- DROPDOWN COM ICONE -->
    <voxel-row dc='12' mc='12' tc='12' class="header">
      <voxel-item style="margin-top: 1.2rem;">
        <label class="title">INFORMAÇÕES LEGAIS</label>
      </voxel-item>
    </voxel-row>

    <div class="legal-info-box" *ngIf="domainTermDebitoMinimoContas.length">
      <div class="legal-info">
        <generic-select-service-insurance
          service-name="débito em conta"
          accordion-description="regras e cláusulas"
          [default-show]="true"
          [ga-page-info]="pageInfo"
          icon="icon-itaufonts_cartoes"
          >
          <div>
            <p>
              Em caso de atraso no pagamento da fatura, o débito em conta previsto no seu contrato de cartão de crédito
  poderá ser realizado na(s) conta(s) corrente, poupança, salário e/ou pagamento:
            </p>

            <table>
              <tr>
                <td>
                  agência
                </td>
                <td>
                  conta
                </td>
              </tr>

              <tr *ngFor="let conta of domainTermDebitoMinimoContas">
                <td>
                  {{conta.agencia}}
                </td>
                <td>
                  {{conta.conta}}-{{conta.dac}}
                </td>
              </tr>
            </table>

              <p>
                A consulta da conta para realização do débito estará disponível através do Itaú na Internet e aplicativo Itaú, nos comprovantes. Você pode cancelar a autorização para o débito entrando em contato com a central de atendimento ou na agência.
              </p>
          </div>
        </generic-select-service-insurance>
      </div>
    </div>
    <div class="legal-info-box">
      <div class="legal-info" *ngFor="let information of domainTermInformation">
        <generic-select-service-insurance
          [ga-page-info]="pageInfo"
          [service-id]=' '
          [service-name]='information.titulo'
          [icon]="information.icon"
          accordion-description=" "
          [accordion-invoice]="teste"
          [accordion-advantages]='teste2'
          [has-border-top]='false'
        >
          <div [innerHTML]="information.resumo"></div>
          <div align="center" class="link" (click)="openModal(information.titulo, information.texto)">
            <voxel-link content="ver mais">
            </voxel-link>
          </div>
        </generic-select-service-insurance>
      </div>
    </div>
    <!--FIM DROPDOWN COM ICONE -->

    <voxel-button style="padding: 16px;" label="Clique para assinar" (click)="callSignature()"></voxel-button>
    <span *ngIf="signature" class="icon-itaufonts_chat_enviado icon"></span>
    <br>
    <br>

    <!-- CHEBOX CUSTOM -->
    <div class="c-checkbox">
      <custom-checkbox (change)="checkBox()">Li e concordo com os termos oferecidos.</custom-checkbox>
    </div>
    <!-- FIM CHEBOX CUSTOM -->


    <voxel-button submit-button style='padding: 16px; ' label='Continuar' [disabled]="validFields()" (click)="onClickTag()"></voxel-button>
  </form>

  <!-- -------------------------------------------------------------------------------------------------------- -->
  <!-- WEB -->

  <form method="POST" *ngIf='newHtml == true'>
    <input type="text" name="taskid" [value]="this.taskid" hidden>
    <input type="text" name="route" [value]="this.route" hidden>
    <input type="text" name="domains.termSignature.base64" [value]="this.signature" hidden>
    <input type="text" name="fields[0].id" [value]="this.parsedInputs?.fields[0].id" hidden>
    <input type="text" name="fields[0].value" [value]="this.disabled" hidden>
    <!-- <input type="text" name="domains.IdTipoConteudo" [value]="this.idTipoConteudo" hidden> -->

    <voxel-row>
      <voxel-item>
        <div class="title">
          <p>Confira os dados.</p>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item>
        <div class="item-title">
          <p>CARTÕES</p>
        </div>
        <div class="cards">
          <div *ngFor="let card of domainTermCard" class="card-spacing">
            <card-template [owner]=card.namePersona [cardname]=card.nameCartao [limit]=card.limite
              [annuity]=card.anuidade [annuityTwoYears]=card.anuidade_demais_anos [isAdditional]=card.isAdditional>
            </card-template>
          </div>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item>
        <div class="services-insurances">
          <div class="services">
            <div class="item-title">
              <p>SERVIÇOS</p>
            </div>
            <div class="cards-alignment">
              <div *ngFor='let service of domainTermService'>
                <!-- {{domainTermService | json}} -->
                <div style="width: 100%;">

                  <small-card-template [icon]=service.icon [name]=service.texto [value]=service.valorMensal [label]="service.texto_valor">
                  </small-card-template>
                </div>
              </div>
            </div>
          </div>
          <div class="insurances item-title">
            <p>SEGUROS</p>
          </div>
          <div class="cards-alignment">
            <div *ngFor='let insurance of domainTermInsurance'>
              <div style="width: 100%;">
                <small-card-template [icon]=insurance.icon [name]=insurance.texto [value]=insurance.valorMensal [label]="insurance.texto_valor">
                </small-card-template>
              </div>
            </div>
          </div>
        </div>
      </voxel-item>
    </voxel-row>


    <voxel-row>
      <voxel-item style="width: 100%;">
        <div class="legal-information">
          <p class="item-title">INFORMAÇÕES LEGAIS</p>
          <div>
            <div class="legal-info" *ngIf="domainTermDebitoMinimoContas.length">
              <generic-select-service-insurance
                service-name="débito em conta"
                accordion-description="regras e cláusulas"
                [default-show]="true"
                [ga-page-info]="pageInfo"
                icon="icon-itaufonts_cartoes"
                >
                <div>
                  <span>
                    Em caso de atraso no pagamento da fatura, o débito em conta previsto no seu contrato de cartão de crédito
        poderá ser realizado na(s) conta(s) corrente, poupança, salário e/ou pagamento:
                  </span>

                  <table>
                    <tr>
                      <td>
                        agência
                      </td>
                      <td>
                        conta
                      </td>
                    </tr>

                    <tr *ngFor="let conta of domainTermDebitoMinimoContas">
                      <td>
                        {{conta.agencia}}
                      </td>
                      <td>
                        {{conta.conta}}-{{conta.dac}}
                      </td>
                    </tr>
                  </table>

                    <span>
                      A consulta da conta para realização do débito estará disponível através do Itaú na Internet e aplicativo Itaú, nos comprovantes. Você pode cancelar a autorização para o débito entrando em contato com a central de atendimento ou na agência.
                    </span>
                </div>
              </generic-select-service-insurance>
            </div>
            <div class="legal-info" *ngFor="let information of domainTermInformation">
              <generic-select-service-insurance
                [service-id]=' '
                [service-name]='information.titulo'
                [icon]="information.icon"
                accordion-description=" "
                [accordion-invoice]="teste"
                [accordion-advantages]='teste2'
                [has-border-top]='false'
                [ga-page-info]="pageInfo"
              >
                <div [innerHTML]="information.resumo"></div>
                <div align="center" class="link" (click)="openModal(information.titulo, information.texto)">
                  <voxel-link content="ver mais">
                  </voxel-link>
                </div>
              </generic-select-service-insurance>
            </div>
          </div>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item style='width: 50%; margin: auto;'>
        <div class="signature-div">
          <voxel-button style="padding-left: 32px;" label="Clique para assinar" (click)="callSignature()"></voxel-button>
          <span *ngIf="signature" class="icon-itaufonts_chat_enviado icon"></span>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item>
        <div class="checkbox-div">
          <custom-checkbox (change)="checkBox()">Li e concordo com os termos oferecidos.</custom-checkbox>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item style='width: 50%; margin: auto;'>
        <div class="button-div">
          <voxel-button submit-button style='padding: 16px; ' label='Continuar' [disabled]="validFields()"></voxel-button>
        </div>
      </voxel-item>
    </voxel-row>
  </form>

  <voxel-dialog [(showDialog)]="showModal" [items]="items"
    description="Para dar continuidade ao processo, colete a assinatura do cliente." (dialogLinkClick)="closeModal()">
  </voxel-dialog>

  <modal-dialog #insurance overlay="true" [title]="this.modalTitle" btn1="Fechar" type1="close" input1="1"
    class1="outline">
    <div body [innerHTML]="textModal"></div>
  </modal-dialog>

  <modal-dialog #sign title="Aviso" overlay="true" btn1="Fechar" type1="close" class1="freeline">
    <div subt>
      Erro ao abrir a assinatura digital.
    </div>
  </modal-dialog>

</ng-container>
