import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, take, finalize, map } from 'rxjs/operators';
import { DeviceDetectorCustomService } from './device-detector-custom.service';


@Injectable({
  providedIn: 'root'
})
export class DnaService {
  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  url_dna: string
  redirectError: boolean
  url: string

  constructor(
    private http: HttpClient,
    private deviceDetectorCustomService: DeviceDetectorCustomService
  ) {
    this.url_dna = "http://127.0.0.1:3717"
  }

  get(routeDesktop: string, metodoMobile: string, redirectError: boolean = true): Observable<any> {
    this.redirectError = redirectError;

    const device = this.deviceDetectorCustomService.deviceDetector();
    let observable$ = null;

    if (device === 'desktop') {
      observable$ = new Observable(obs => {
        this.http.get(`${this.url_dna}${routeDesktop}`)
          .pipe(
            catchError(err => this.handleError(err)),
            map(data => {
              // console.log(data)
              this.postCaptureLogs({ response: data, type: device, method: routeDesktop }, false)
              obs.next(data)
            }
            )).subscribe();
      })
    } else {
      observable$ = new Observable(obs => {
        const data = this.metodoSDK(metodoMobile);

        if (!data) {
          obs.error('error getting sdk method: ' + metodoMobile + '; type: ' + device);
        }

        this.postCaptureLogs({ response: data, type: device, method: metodoMobile }, false);

        obs.next(data);
      }).pipe(
        map((result: any) => this.isJson(result) ? JSON.parse(result) : result)
      );
    }

    return observable$.pipe(
      retry(1),
      catchError(err => this.handleError(err))
    )
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  isObj(teste) {
    return typeof teste === 'object'
  }

  post(routeDesktop: string, metodoMobile: string, body: any, redirectError: boolean = true): Observable<any> {
    this.redirectError = redirectError;

    const device = this.deviceDetectorCustomService.deviceDetector();
    let observable$ = null;

    // console.log("Device: " + device + " metodoMobile: " + metodoMobile)
    if (device === 'desktop') {
      observable$ = new Observable(obs => {
        this.http.post(`${this.url_dna}${routeDesktop}`, this.isObj(body) ? JSON.stringify(body) : body, this.httpOptions)
          .pipe(
            catchError(err => this.handleError(err)),
            map(data => {
              this.postCaptureLogs({ body: body, response: data, type: device, method: routeDesktop }, false)
              obs.next(data)
            }
            )).subscribe();
      })
    } else {
      observable$ = new Observable(obs => {
        try {
          const data = this.metodoSDK(metodoMobile, this.isObj(body) ? JSON.stringify(body) : body);
          // console.log("retorno: " + data)
          if (!data) {
            this.postCaptureLogs('error getting sdk method: ' + metodoMobile + '; type: ' + device, false);
          }

          this.postCaptureLogs({ body: body, response: data, type: device, method: metodoMobile }, false);
          obs.next(data);
        } catch (error) {
          this.postCaptureLogs(error, true, false);
        }
      }).pipe(
        map((result: any) => this.isJson(result) ? JSON.parse(result) : result)
      );
    }

    return observable$.pipe(
      retry(1),
      catchError(err => this.handleError(err))
    )
  }

  private  metodoSDK(nameMetodo: string, body: any = null): any {
    try {
      var handler = window['handlerJs']

      if (body != null) {
        return handler[nameMetodo](body)
      } else {
        return handler[nameMetodo]()
      }
    } catch (exp) {
      return exp
    }
  }

  postCaptureLogs(errorMessage: any, redirect: boolean, error: boolean = false, genericError: any = ""): void {
    // console.log("logs: " + errorMessage + " redirect: " + redirect)

    errorMessage["result"] = error ? "ERROR" : "SUCCESS"

    if(errorMessage["response"])
      errorMessage["response"] = this.isJson(errorMessage["response"]) ? JSON.parse(errorMessage["response"]) : errorMessage["response"]

    this.http.post('/capture-logs', JSON.stringify({ data: errorMessage }), this.httpOptions)
      .pipe(
        take(1),
        finalize(() => redirect && this.redirectError ? window.location.href = '/warning-error/dna' : '')
      )
      .subscribe();
  }


  download(route: string): Observable<Blob> {
    this.url = document.documentElement.style.getPropertyValue("--base-path") + route;
    var authorization = 'Bearer ' + sessionStorage.getItem("access_token");

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

    return this.http.get<Blob>(this.url, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        retry(1),
        catchError(err => this.handleError(err))
      );
  }


  private handleError(error: any): Observable<never> {
    let errorMessage = '';
    if (error.error && error.error.message) {
      errorMessage = error.error.message; // Get client-side error
    } else if (error.status && error.message) {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`; // Get server-side error
    } else {
      errorMessage = error;
    }

    this.postCaptureLogs(errorMessage, true, true, errorMessage);

    return throwError(errorMessage);
  }
}
