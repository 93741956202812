<form method="post" class="proposal-aproval">

    <input type="text" name="taskid" [value]="this.taskid" hidden>
    <input type="text" name="route"  [value]="this.route"  hidden>

  <voxel-container>

    <voxel-row *ngIf='web'>
      <voxel-item dc="12" tc="12" mc="12">
          <span class="icon-itaufonts_cartoes" ></span>
        </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <p class="title">Sua solicitação foi aprovada!</p>
        <p class="subtitle">Agora é só confirmar as informações abaixo.</p>
      </voxel-item>
    </voxel-row>

    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12" *ngFor='let offter of this.offters'>
        <card-template
          [isAdditional]="offter.isAdditional"
          [isBlocked]="offter.isBlock"
          [owner]="offter.namePersona"
          [cardname]="offter.nameCartao"
          [limit]="offter.limite"
          [annuity]="offter.anuidade"
          [annuityTwoYears]="offter.anuidade_demais_anos">
        </card-template>
      </voxel-item>
    </voxel-row>

    <div class="btnGroup">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <voxel-button label='continuar' submit-button [payload]="false"></voxel-button>
        </voxel-item>
      </voxel-row>
    </div>


  </voxel-container>
</form>
