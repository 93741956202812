import { constants } from '../constants';

export function initLoader(width, noimage = false) {
    var img = "";
    var isDesktop = true//width > 1024;

    if(noimage){
        img = constants.svg_black
    }
    else if (width < 768) {
        img = constants.foto_login
    } else {
        img = constants.background_login
    }

    const overlay = document.createElement('DIV');
    overlay.className = 'lds-over';

    const loader = document.createElement('DIV');
    loader.className = 'lds-roller';

    for (var i = 0; i < 8; i++)
        loader.append(document.createElement('DIV'))

    overlay.style.cssText = "top: " + window.scrollY + "px;left: 0;right: 0;bottom: " + (-window.scrollY) + "px;position: absolute;background-image: url('data:image/png;base64," + img + "');background-size: cover;opacity: 0.9;filter: grayscale(1);z-index:99999;";
    document.body.insertAdjacentElement('beforebegin', overlay);

    if(isDesktop)
        document.body.insertAdjacentElement('beforebegin', loader);

    document.body.setAttribute('class', 'noscroll');
}

export function removeLoader() {
  const overlay = document.querySelector('.lds-over');
  const loader = document.querySelector('.lds-roller');
  if (overlay) { overlay.remove(); }
  if (loader) { loader.remove(); }
  document.body.classList.remove('noscroll');
}
