<form
  id="formCollect"
  method="post"
  #form
>
  <div class="spacing__margin-top-xs form-container">
    <voxel-container>    

      <voxel-row>
        <voxel-item
          [mc]="12"
          [tc]="12"
          [dc]="12"
        >
        <card-header title="SENHA PROVISÓRIA"
        subtitle="A nova senha provisória gerada para o CPF de número {{cpf | mask: '000.000.000-00'}} é:"
        [svg]="this.path_assets + 'icone_cpf.svg'"></card-header>

        <div class="temporaryPassword">{{temporaryPassword}}</div>
        </voxel-item>
      </voxel-row>
   
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <span class="bold">
            A senha deve ser digitada exatamente dessa forma
          </span>
          <span>- respeitando os caracteres maiúsculos e minúsculos.</span>
        </voxel-item>
      </voxel-row>
    </voxel-container>

    <div
    class="spacing__margin-top-xs spacing__margin-bottom-xs button-container"
  >
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <input [value]="this.taskid" type="hidden" name="taskid" />
          <input [value]="this.route" type="hidden" name="route" />
          <a [href]="base_path + '/master-password-reset'" class="link-home" style="width: 100%;" (click)="onClickNewPassword()">
            <voxel-button loader-button label="Solicitar nova senha" id="button-new-pwd"></voxel-button>
          </a>
          <a [href]="base_path + '/home'" class="link-home" style="width: 100%;" (click)="onClickHome()">
            <voxel-button loader-button label="Home" id="button-home"></voxel-button>
          </a>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>
  </div>
</form>