import { Component, OnInit, Input, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';


@Component({
  selector: 'card-analyze',
  templateUrl: './card-analyze.component.html',
  styleUrls: ['./card-analyze.component.css']
})
export class CardAnalyzeComponent implements OnInit {

  @Output() onClick = new EventEmitter();

  @Input("id") id:string;
  @Input("value") value:any;
  @Input("cardname") cardname:string;
  @Input("brand") brand:string;
  @Input("limit") limit:string;
  @Input("annuity") annuity:string;
  @Input("selected") selected:boolean; 

  constructor() { }  
  
  ngOnInit() {
  }
  
  clickCard(){
      this.onClick.emit(this);
  } 

}
