<div class="form-additional-card" *ngIf="this.exists == true">

    <input type="text" [name]="this.listIndex + '.id'"   [value]="this.fieldId" hidden>
    <input type="text" [name]="this.listIndex + '.type'" [value]="this.fieldType" hidden>

    <voxel-container class="flex-container">
        <voxel-row class="header" [ngClass]="{'selected': show}">
            <voxel-item tc="9" dc="10" mc="9" class="form-item">
                <button class="btn-description" (click)="this.toogle()">
                    <span class="icon icon-itaufonts_cartao"></span>
                    <label>cartão adicional {{ this.index + 1 }}</label>
                    <span *ngIf="this.valid" class="icon icon-itaufonts_check"></span>
                    <span *ngIf="! this.valid" class="icon icon-itaufonts_fechar"></span>
                </button>
            </voxel-item>
            <voxel-item tc="3" dc="2" mc="3">
                <button class="btn-delete" (click)="this.remove()">
                    <span class="icon icon-itaufonts_excluir"></span>
                </button>

                <button class="btn-toogle" (click)="this.toogle()">
                    <span [ngClass]="{'icon icon-itaufonts_seta_down': !show, 'icon arrow icon-itaufonts_seta_up': show}"></span>
                </button>
            </voxel-item>
        </voxel-row>
    </voxel-container>
    <voxel-container [hidden]="!this.show">
        <voxel-row>
            <voxel-item *ngFor="let field of this.inputs; let i = index" dc="6" tc="12" mc="12" >
                <custom-input *ngIf="field.type != 'dropdown'"
                    #input
                    isObject="true"
                    [attr.data-id]="field.id"
                    [listname]="this.generateName(field.listname)"
                    [type]="field.type"
                    [label]="field.label"
                    [id]="this.generateName(field.listname) + '.value'"
                    [idProperty]="field.id"
                    [required]="field.required"
                    [value]="field.value"
                    [max]="field?.tamanho_maximo"
                    (onBlur)="getInput($event, i); validCPF($event, i)"
                    [min]="field?.tamanho_minimo"
                    (validate)="validateField($event, i);"
                    [validation]="field.validation_api"
                    [additionalCpfs]="additionalCpfs">
                </custom-input>

                <custom-input *ngIf="field.type == 'dropdown'"
                    #input
                    [attr.data-id]="field.id"
                    [listname]="this.generateName(field.listname)"
                    [type]="field.type"
                    [label]="field.label"
                    [id]="this.generateName(field.listname) + '.value'"
                    [options]="field.dropdownValues"
                    option-value="key"
                    option-label="value"
                    (onBlur)="getInput($event, i); validateField($event, i)"
                    [max]="field?.tamanho_maximo"
                    [min]="field?.tamanho_minimo"
                    [required]="field.required"
                    (validate)="validateField($event, i)"
                    [idProperty]="field.id"
                    [validation]="field.validation_api"
                    [additionalCpfs]="additionalCpfs">
                </custom-input>
            </voxel-item>
        </voxel-row>
    </voxel-container>
</div>
