import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'card-cancellation',
  templateUrl: './card-cancellation.component.html',
  styleUrls: ['./card-cancellation.component.css']
})
export class CardCancellationComponent implements OnInit, OnChanges {

  @Output() onClick = new EventEmitter();

  @Input("id") 
  id:string;
  
  @Input("customer-name") 
  customerName: string;
  
  @Input("card-alias") 
  cardAlias: string;
  
  @Input("card-number") 
  cardNumber: string;
  
  @Input("cpf") 
  cpf: string;
  
  @Input("flag") 
  flag: string;
  
  @Input("cancelled") 
  cancelled: boolean;
  
  @Input("ownership") 
  ownership: string;
  
  @Input("selected") 
  selected: boolean;
  
  @Input("value") 
  value: string;

  /**
   * @description Propriedade armazena a classe de estilos 
   * da etiqueta de cartão adicional
   */
  additionalclass:string;

  /**
   * @description Objeto será utilizado por ngClass no template
   */
  cardClasses = {
    "card": true,
    "card-selected": false,
    "card-cancelled": false
  };

  ngOnChanges(changes) {
    if (changes["value"] && this.value) {
      if (changes['value'].currentValue == this.id) {
        return this.cardClasses["card-selected"] = true;
      }
      return this.cardClasses["card-selected"] = false;
    }
  }
  
  ngOnInit() {
    if (this.cancelled) {
      this.cardClasses["card-cancelled"] = true;
    }
    this.cardNumber = this.cardNumber.replace(/\./g, " ");
    this.additionalclass = this.ownership
    .toLowerCase() != 'titular' ? 'additional' : 'titular';
  }
  
  
  clickCard(event: MouseEvent) {
    if (this.cancelled) {
      event.stopPropagation();
    }
    this.selected = true;
    this.onClick.emit(this);
    this.cardClasses["card-selected"] = true;
  }

}