import { element } from 'protractor';
import { Component, OnInit, ElementRef, Renderer2, Output, EventEmitter, Input, ViewChild, TemplateRef } from '@angular/core';
import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';




@Component({
  selector: 'custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.css']
})
export class CustomDatepickerComponent implements OnInit {

    @Input("label") label: string;
    

    /**
     * 
     */
    @Output() 
    getDate = new EventEmitter();
    
    @Output() 
    inputBlur = new EventEmitter();

    error: boolean;
    
    constructor(private _adapter: DateAdapter<any>, private el:ElementRef) {}

    ngOnInit() {
    }


    /**
     * Verifica se a data é valida e emite o valor em string
     */
    setDate(e){
       if(e == null){
        this.getDate.emit('');
        this.error = true;
       }else{
        var day   = this.verifyNumber(e.getDate());
        var month = this.verifyNumber( e.getMonth()+1);
        var year  = this.verifyNumber(e.getFullYear());

        var dateString  = day +"/"+ month +"/"+ year ;
        // console.log(dateString);
        this.getDate.emit(dateString);
        this.error = false;
       }
    }

    /**
     * Verifica se o número é menor que dez
     */

    verifyNumber(number: number){
        if(number < 10){
            return ("0"+ number)
        }else{
            return number;
        }
    
    }

    blurEmit(e){
        this.inputBlur.emit(e);
    }

}
