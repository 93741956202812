import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormDynamicComponent } from "../../shared/form-dynamic/form-dynamic.component";
import { CryptoPipe } from "projects/commons-util/src/crypto/crypto.pipe";
import { map } from "rxjs/operators";
import { removeLoader } from "projects/commons-util/src/globalFunctions/loader";
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';

@Component({
  selector: "get-data-for-credit-analysis",
  templateUrl: "./get-data-for-credit-analysis.component.html",
  styleUrls: ["./get-data-for-credit-analysis.component.css"]
})
export class GetDataForCreditAnalysisComponent extends FormDynamicComponent {
  @Input("input") input: string;
  @ViewChild("form") form: ElementRef;

  route: string;
  taskid: string;
  domains: any[];
  showModal: boolean = false;
  items: Array<any>;
  modalDescription: string =
    "Não foi possível validar os dados, por favor tente novamente.";

  constructor(private cdr: ChangeDetectorRef,
     private cryptoPipe: CryptoPipe,
     private dnaService: DnaService) {
    super();
    this.items = [
      {
        id: "1",
        content: "Ok",
        a11yLabel: "Ok"
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe
      .transform(this.input, "decrypt")
      .pipe(
        map(data => {
          this.route = data.route;
          this.taskid = data.taskId;
          this.fields = data.fields;
          this.domains = data.domains;
        })
      )
      .subscribe();

    document
      .querySelector("get-data-for-credit-analysis")
      .setAttribute("input", "");
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  closeModal() {
    this.showModal = false;
  }

  submitButton(event) {
    var objectLog = {
      message: "Coleta dados Front",
      taskId: this.taskid,
      fields: this.fields,
      route: this.route,
      domains: this.domains
    }

    this.dnaService.postCaptureLogs(objectLog, false, false, objectLog);

    if (!this.taskid) {
      removeLoader();
      this.showModal = true;
    } else {
      this.form.nativeElement.submit();
    }
  }
}
