import { Component, OnInit, Input, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';

import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'card-cancellation-data-bkp',
  templateUrl: './card-cancellation-data-bkp.component.html',
  styleUrls: ['./card-cancellation-data-bkp.component.css']
})
export class CardCancellationDataBkpComponent extends FormDynamicComponent {

  @Input('inputs') inputs: any;

  /**
   * @description Armazena os objetos de campos que serão renderizados
   */
  fields: any;

  /**
   * @description Armazena a rota que será utilizada pelo backend
   */
  route: string;

  /**
   * @description Armazena os grid de campos para cada dispositivo
   */
  grid: Array<any> = [];

  /**
   * @param el
   * @param renderer
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe
  ) {
    super();
    this.populateGrid();
    this.validateFields = [];
  }


  populateGrid() {
    this.grid['contact_time'] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid['number_contact'] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid['other_number_contact'] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid['email'] = { mc: 12, tc: 12, dc: 6, grid: null };
    this.grid['description'] = { mc: 12, tc: 12, dc: 12, grid: null };
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt')
      .pipe(
        map(data => {
          this.fields = data.inputs;
          this.route = data.route;
          this.populateValidateFields();
        })
      ).subscribe()

      // var data = JSON.parse(this.inputs);
      // this.fields = data.inputs;
      // this.route = data.route;
      // this.populateValidateFields();

    document.querySelector('card-cancellation-data').setAttribute('inputs', '');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * @description
   * @param event
   * @param i
   */
  validateField(event, i: number) {
    if (this.fields[i].required) {
      this.validateFields[i] = event;
    }
  }

  /**
   * @description
   * Popula array validateFields com booleans
   * equivalentes aos campos que devem ser preenchidos
   */
  populateValidateFields(): void {
    this.fields.forEach(field => {
      this.validateFields.push(!field.required);
    });
  }

  verifyRequireds(event, i) {
    if (event && event.target) {
      this.fields[i].value = event.target.value;
      this.validateFields[i] = event.target.value.length > 0;
    }
  }

  /**
   * @description
   * Habilita ou desabilita o botão de enviar
   */
  validateForm() {
    for (let i = 0; i < this.validateFields.length; i++) {
      const element = this.validateFields[i];
      if (element === false) { return true; }
    }

    return false;
  }

}
