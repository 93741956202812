<div class="dropdown-list">
  <label class="lblText">{{label}}</label>
  <br/>
  <div class="select-box" (blur)='onBlur($event)' tabindex="1" [ngStyle]="{'pointer-events': disableDropdown}">
    <select #selectEl
            [id]="this.id"
            (focusout)="onFocusOut($event)"
            (change)="onChange($event.target.value)"
            [required]="required"
            [disabled]="disabled"
            [attr.selected]="selected"
            class="disabledbutton"
            >
      <option *ngIf="placeholder" [selected]="placeholder !== ''" [disabled]="placeholder !== ''" hidden>
        {{placeholder}}
      </option>
      <option *ngFor="let opt of options" [value]="opt.value"
              [selected]="opt.value == selected ? true : false">
        {{opt.label}}
      </option>
    </select>

    <voxel-icon
      dropDownDebounceClick
      class="arrow-icon">
        seta_baixo
    </voxel-icon>
    <div class="overlay"></div>
  </div>

</div>
