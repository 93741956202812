import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { SidebarComponent } from '../../../core/sidebar/sidebar.component';
import { GlobalProperties } from 'src/app/BG4/shared/global-properties';
import { CustomHeaderDirective } from 'projects/commons-util/src/custom-header/svg-header.directive';
import { FormDynamicComponent } from 'src/app/BG4/shared/form-dynamic/form-dynamic.component';
import { trigger } from '@angular/animations';

@Component({
  selector: 'header-demo',
  templateUrl: 'header-demo.component.html',
  styleUrls: ['header-demo.component.css'],
  providers: [CustomHeaderDirective]
})
export class HeaderDemoComponent extends FormDynamicComponent {

  @ViewChild(SidebarComponent)
  sidebarComponent: SidebarComponent;

  @ViewChild('content', { read: TemplateRef })
  actualContent: TemplateRef<any>;

  @ViewChild(CustomHeaderDirective)
  customHeader: CustomHeaderDirective;

  @Input('logo') logo: boolean;
  @Input('title') title: string = '';
  @Input('subtitle') subtitle: string;
  @Input('percent') percentTrack: number = 0;
  @Input('search') search: boolean;
  @Input('removeicon') removeIcon: boolean;
  @Input('arraysearch') arraysearch: any[] = [];
  @Input('backbutton') backButton: boolean;

  /**
   * Evento quando clicar na lupa de pesquisa
   */
  @Output("onIconSearchClick")
  onIconSearchClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Valor do campo de pesquisa
   */
  @Input("value")
  searchInput:string = '';

  /**
   * Controle para habilitar btn de pesquisa
   */
  enabledBtnSearch:boolean = true;

  leftIcon: any;
  rightIcon: any;
  logoIcon: any;
  innerWidth: any;
  parsedSidebarMenu: any;
  result: any[];
  sidebarmenu: string;
  filter: any[] = [];
  status_selected: any[] = [];
  active: boolean = false;

  icons = [
    { icon: 'icon-itaufonts_filtro', a11yLabel: ' ' }
  ];

  config = {
    search:true,
    placeholder:'Selecione o status',
    moreText: ' ',
    noResultsFound: 'Nenhum resultado encontrado',
    searchPlaceholder:'Pesquisar',
  }

  selectedFilters = [];

  constructor(private el: ElementRef) {
    super();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 991) {
      this.active = false;
    }
  }

  onInit () {
    this.result = Object.assign([], this.arraysearch);
    this.adjustTitleSize(this.title);

    if (this.removeIcon) {
      this.rightIcon = '';
    } else {
      this.setLeftIcon();
      this.setRightIcon();
      this.customHeader.rightIcon = this.rightIcon;
      this.customHeader.leftIcon = this.leftIcon;
      this.customHeader.setElements();
    }

    this.selectedFilters =  this.status_selected;

    if (this.innerWidth <= 991 && this.status_selected.length > 0 && this.filter.length > 0) {
      this.active = true;
    }
  }

  /**
   * Recebe o valor digitado no input de pesquisa
   *
   * @param input
   */
  onInputChange(input: any) {
    this.enableSearch(input)
    this.searchInput = input.item.clearValue;
    this.formatIput();
  }

  /**
   * Habilita/desabilita o botão de pesquisar
   * de acordo com a validação do input de pesquisa
   *
   * @param input
   */
  enableSearch(obj) {
    let input = obj.item;
    if (input.valid == true || input.clearValue.length == 0) {
      return this.enabledBtnSearch = true;
    }

    return this.enabledBtnSearch = false;
  }

  /**
   * Define o ícone do logo no cabeçalho
   *
   * @return void
   */
  setLeftIcon() {
    if (this.logo) {
      this.logoIcon = this.path_assets + this.all_properties['--partner-logo'];
    } else if(this.backButton) {
      this.leftIcon = 'icon-itaufonts_seta';
    } else {
      this.leftIcon = null;
    }
  }

  setRightIcon() {
    if (this.sidebarmenu) {
      this.parsedSidebarMenu = JSON.parse(this.sidebarmenu);
      if(this.sidebarmenu && this.parsedSidebarMenu.length != 0) {
        this.sidebarComponent.inputs = this.sidebarmenu;
        this.sidebarComponent.parseJSON();
        this.rightIcon = 'icon-itaufonts_menu_hamburguer';
      }
    }
  }

  /**
   * Diminui as divs de action e do botão do voltar do Voxel Header
   * para permitir títulos com tamanhos maiores
   *
   * @param title
   * @return string
   */
  adjustTitleSize(title: string) {

    if (title && title.length < 20) return;

    let divRight = document.getElementsByClassName('header__actions')[0];
    let divLeft  = document.getElementsByClassName('header__back')[0];

    divRight.setAttribute('style', 'width: 3.5rem;');
    divLeft.setAttribute('style', 'width: 3.5rem;');
  }

  /**
   * Envia os dados preenchidos no input do search
   *
   * @return Event
   */
  searchIconClick($e) {
    if (! this.enabledBtnSearch) {
      return;
    }

    const search = {
      cpf: this.searchInput,
      filters: this.selectedFilters
    };

    this.onIconSearchClick.emit(search);
  }

  /**
   * Função para filtrar o array de resultado da busca
   * no search input
   *
   * @param search
   */
  filterArray(search:string) {
    const pattern = /[^A-Z0-9]+/ig;

    this.result = this.arraysearch
                      .filter(a => a.cpf.toLowerCase().replace(pattern, '')
                      .indexOf(search.toLowerCase().replace(/[^A-Z0-9]+/ig, '')) !== -1);

    if (search == null || search === '') {
      this.result = Object.assign([], this.arraysearch);
    }
  }

  clearInput($e){
    this.searchInput = "";
    this.enabledBtnSearch = true;

    // this.onIconSearchClick.emit(null);
  }

  formatIput() {
      if(this.searchInput){
          this.searchInput =
          this.searchInput
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
  }

}
