import { Component, OnInit, Input } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'print-comprovant-detail',
  templateUrl: './print-comprovant-detail.component.html',
  styleUrls: ['./print-comprovant-detail.component.scss']
})
export class PrintComprovantDetailComponent extends GlobalProperties implements OnInit {

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  /**
   * @description Objeto contendo dados da proposta
   */
  proposal: any = {};

  constructor(private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
     map(data => {
        this.proposal = data
        console.log(this.proposal)
    }
    )).subscribe();

      // console.log(this.proposal)
      // this.proposal = JSON.parse(this.inputs)
      // document.querySelector('print-comprovant-detail').removeAttribute('inputs');
  }

  /**
   * Retorna a classe CSS de acordo com o valor
   * do status da proposta
   *
   * @param value status da proposta
   * @return Objeto contendo nome da classe e um valor boleano true se a classe for valida
   */


  /**
   * @description Metodo vinculado ao evento click do botão de re-imprimir termo
   */

  selected_chart(input: HTMLInputElement) {
    for (var item in this.proposal.data.comprovantes) {
      if (input.checked === true) {
        this.proposal.data.comprovantes[input.name].check = true
        console.log(this.proposal.data.comprovantes[input.name])
      } else {
        this.proposal.data.comprovantes[input.name].check = false
      }
    }
  }

  onClickPrintTerm(): void {
    var isChecked = document.getElementsByClassName("checkbox")
    for (let i = 0; i < isChecked.length; i++) {
      if (this.proposal.data.comprovantes[i].check == true) {
        const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
          'resizable,screenX=50,screenY=50,width=850,height=1050';

        const htmlPop = '<embed width=100% height=100%'
          + ' type="application/pdf"'
          + ' src="data:application/pdf;base64,'
          + encodeURI(this.proposal.data.comprovantes[i].pdf)
          + '"></embed>'

        const pdfWindow = window.open('', 'PDF', winparams);
        pdfWindow.document.write(htmlPop);
      }
    }
  }

}
