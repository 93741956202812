import { Component, Input, HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'form-warning-error-device',
  templateUrl: './form-warning-error-device.component.html',
  styleUrls: ['./form-warning-error-device.component.css']
})

export class FormWarningErrorDeviceComponent  extends GlobalProperties  {

  @Input("inputs") inputs: string;
  innerWidth: any;
  web: boolean;
  textButton: string = 'Voltar para home';
  parse: any;
  errorMessage: any;
  img: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.parseJson();
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_avisoerro.svg';
    // } else {
    //   this.img = 'icon_algo_errado.svg';
    // }

    this.img = 'icon_algo_errado.svg';
  }

  parseJson() {
    this.parse = JSON.parse(this.inputs);
    this.errorMessage = decodeURI(this.parse.error_message);
    this.textButton = (! this.parse.textButton) ? this.textButton : this.parse.textButton
    document.querySelector('form-warning-error-device').setAttribute('inputs', '');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_avisoerro.svg';
    // } else {
    //   this.img = 'icon_algo_errado.svg';
    // }
  }

  callSdk() {
    if (! window['handlerJs']) return null;

    return window['handlerJs'].tentarNovamenteButton();
  }

}
