import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { GlobalProperties } from 'src/app/BG4/shared/global-properties';

@Component({
  selector: 'selectable-card',
  templateUrl: './selectable-card.component.html',
  styleUrls: ['./selectable-card.component.css']
})
export class SelectableCardComponent extends GlobalProperties {

  @Output() getCard = new EventEmitter();
  @Input("id") id:any;
  @Input("title") title:any;
  @Input("icon") icon:any;
  @Input("subtitle") subtitle:any;
  @Input("selected") selected:boolean;
  @Input("svg") svg:any;

  innerWidth: any;
  size: number;

  constructor() {
    super();
    this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.size = this.innerWidth * 0.07
  }

  ngOnInit() {
  }

  sendObj(){
    this.getCard.emit(this);
  }

}
