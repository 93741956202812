import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {

  constructor() { }

  @Input("inputs") inputs: string;

  items : any
  parsedInputs: any;

  ngOnInit() {
    this.parsedInputs = JSON.parse(this.inputs);
    this.items = this.parsedInputs.items;

    setTimeout(() => {
      location.reload()
    }, 10000);
  }

}
