<div class="input-dynamic">
  <div [ngSwitch]="this.type">
    <voxel-input #input
      *ngSwitchCase="'cellphone'"
      class="cellphone"
      hideEditIcon="true"
      mask="custom-mask"
      customMask="(00) 00000-0000"
      [id]="this.id"
      [required]="this.required"
      data-input-id="this.id"
      [(label)]='this.label'
      [value]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-input #input
      *ngSwitchCase="'phone'"
      class="phone"
      hideEditIcon="true"
      mask="custom-mask"
      customMask="(00) 0000-0000"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      [value]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-input #input
      *ngSwitchCase="'text'"
      class="example-class"
      hideEditIcon="true"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [id]="this.id"
      [maxlength]="this.max ? this.max : maxInt"
      [(placeholder)]="this.placeholder"
      [required]="this.required"
      [(label)]='this.label'
      [value]="this.value"
      [attr.init]="onValidate(input)"
      >
    </voxel-input>

    <div #input *ngSwitchCase="'document'" style="padding-top: .5rem;">

      <div *ngIf="this.mask">
        <label style="margin-left: 12px;display: block;">{{this.label}}</label>
          <p-inputMask class="ui-state-filled"
            #imask
            [mask]="this.mask"
            [unmask]="true"
            [autoClear]="false"
            [autoFocus]="this.focus"
            (onBlur)="onBlur($event)"
            (onFocus)="onGetFocus()"
            [attr.init]="onValidate(imask)"
            placeholder="xxxxxxxx">
          </p-inputMask>
      </div>

      <voxel-input *ngIf="!this.mask" #input
        class="teste"
        hideEditIcon="true"
        (keypress)="validateCharacteres($event)"
        (inputBlur)="onBlur($event)"
        (inputFocus)="onGetFocus()"
        (inputChange)="onInput($event)"
        [id]="this.id"
        [maxlength]="this.max ? this.max : maxInt"
        [(placeholder)]="this.placeholder"
        [required]="this.required"
        [(label)]='this.label'
        [value]="this.value"
        [attr.init]="onValidate(input)">
      </voxel-input>
    </div>

    <!-- <voxel-input #input *ngSwitchCase="'document'"
      class="teste"
      hideEditIcon="true"
      [id]="this.id"
      (inputBlur)="onBlur($event)"
      [(placeholder)]="this.placeholder"
      [required]="this.required"
      [(label)]='this.label'
      [value]="this.value"
      [attr.init]="onValidate(input)"
      customMask="00000000000"
      mask="custom-mask">
    </voxel-input> -->

    <voxel-input #input
      *ngSwitchCase="'money'"
      class="money"
      mask="currency"
      hideEditIcon="true"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      [value]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-dropdown-list #input
      *ngSwitchCase="'dropdown'"
      class="dropdown"
      type="dropdown"
      [label]="this.label"
      [options]="this.options"
      [selected]="this.value"
      [mutavel]="this.mutavel"
      [search]="this.search"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputFocusOut)="setTouched()"
      (optionChange)="change($event); onBlurChange($event);"
      [id]="this.id"
      >
    </voxel-dropdown-list>

    <div #input *ngSwitchCase="'dropdownSearch'" style="padding-top: .5rem;">
      <label style="margin-left:12px;">{{this.label}}</label>
      <ng-select2
        width="87vw"
        [data]="this.options"
        [value]="this.value"
        options="{}"
        (valueChanged)="change($event); onValidate($event);">
      </ng-select2>
    </div>

    <div *ngSwitchCase="'boolean'">
      <voxel-checkbox #input
        [label]="this.label"
        [name]="this.id"
        [id]="this.id"
        [value]="this.value"
        (checkboxChange)="checkboxValue($event)"
        (checkboxBlur)="checkboxValue($event)"
        (checkboxChange)="onValidate($event)"
        (checkboxFocus)="onGetFocus()"
        [attr.init]="onValidate(input)">
      </voxel-checkbox>
    </div>

    <voxel-input #input
      *ngSwitchCase="'email'"
      hideEditIcon="true"
      type='email'
      hintTheme='critical'
      [id]="this.id"
      [required]="this.required"
      [maxlength]="this.max ? this.max : maxInt"
      [(label)]='this.label'
      [(placeholder)]="this.placeholder"
      [customErrorMessage]="this.mensagem"
      [value]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-accordion *ngSwitchCase="'dropdown-card-info'">
      <voxel-accordion-item id="idAccordion1" title="Title" icon='icon-itaufonts_cartao'>
      </voxel-accordion-item>
    </voxel-accordion>

    <!-- <div *ngSwitchCase="'date'" >
      <table class="adjust-table-date">
        <tr>
          <td>
            <voxel-input #input
              class="remove-bottom-border-date teste"
              hideEditIcon="true"
              mask="date"
              type="date"
              [id]="this.id"
              [(label)]='this.label'
              [value]='this.value'
              [required]="this.required"
              [attr.init]="onValidate(input)">
            </voxel-input>
          </td>
          <td style='text-align:right;'>
              <span class="icon-itaufonts_calendario icon-color-red"></span>
          </td>
        </tr>
      </table>
      <hr style='height: 1px;'>
    </div> -->

    <voxel-input #input
      *ngSwitchCase="'cpf'"
      hideEditIcon="true"
      mask="cpf"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      [(value)]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-input #input
      *ngSwitchCase="'rg'"
      hideEditIcon="true"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      mask="custom-mask"
      customMask="00.000.000-00"
      [value]="this.value"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <voxel-input #input
      *ngSwitchCase="'cep'"
      hideEditIcon="true"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      mask="custom-mask"
      customMask="00000-000"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [value]="this.value"
      [attr.init]="onValidate(input)"
      (inputChange)="resetFlag()">
    </voxel-input>

    <voxel-input #input
      *ngSwitchCase="'number'"
      hideEditIcon="true"
      [id]="this.id"
      [required]="this.required"
      [(label)]='this.label'
      mask="custom-mask"
      [maxlength]="this.max ? this.max : maxInt"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      customMask="000000" [value]="this.value"
      [attr.init]="onValidate(input)">
    </voxel-input>

    <!-- <custom-datepicker #input
      *ngSwitchCase="'date'"
      [label]="this.label"
      (getDate)='getNewDate($event);'
      (inputBlur)="onBlur($event)"
      [attr.init]="onValidate(this.value)"
      >
    </custom-datepicker> -->
    <!-- <div *ngSwitchCase="'date'" class="datepicker">
      <label>{{this.label}}</label>
      <p-calendar #input
        [locale]="ptBr"
        [touchUI]="true"
        [(ngModel)]="this.value"
        dateFormat="dd/mm/yy"
        [monthNavigator]="true"
        [yearNavigator]="true"
        yearRange="1900:2030"
        showButtonBar="true"
        (onBlur)="onBlur($event)"
        (onClose)="this.block = true;"
        (click)="toggleCalendar(input)"
        [attr.init]="onValidate(this.value)">
      </p-calendar>
      <button (click)="this.block = false; input.toggle();">
        <i class="icon-itaufonts_calendario"></i>
      </button>
    </div> -->

    <div *ngSwitchCase="'date'" class="datepicker">
      <label>{{this.label}}</label>
      <div class="input-calendar">
        <input
        name="mydate"
        class="input-box"
        angular-mydatepicker
        [(ngModel)]="this.value"
        [options]="myDpOptions"
        [locale]="'pt-br'"
        #dp="angular-mydatepicker"
        (blur)="onBlur($event)"
        (focus)="onGetFocus()"
        (input)="onValidate($event.target.value)"
        [attr.init]="onValidate(this.value)"
        [ngClass]="{active: this.value ? true : false}"
        (keydown.backspace)="this.status = true"/>
        <div class="input-calendar-append">
          <button type="button" class="btn btn-secondary" *ngIf="this.value" (click)="dp.clearDate()">
            <i class="icon-itaufonts_fechar"></i>
          </button>
        </div>
        <div class="input-calendar-append">
          <button type="button" class="btn btn-secondary" (click)="dp.clearDate();dp.toggleCalendar()">
            <i class="icon-itaufonts_calendario"></i>
          </button>
        </div>
      </div>
    </div>

    <voxel-input #input
      *ngSwitchCase="'password'"
      class="teste"
      type='password'
      hideEditIcon="true"
      (inputBlur)="onBlur($event)"
      (inputFocus)="onGetFocus()"
      (inputChange)="onInput($event)"
      (keypress)="this.overlayLoader ? $event.preventDefault() : ''"
      [id]="this.id"
      [maxlength]="this.max ? this.max : maxInt"
      [(placeholder)]="this.placeholder"
      [required]="this.required"
      [(label)]='this.label'
      [attr.init]="onValidate(input)">
    </voxel-input>

    <span *ngSwitchCase="'label'" class="msg-label">
      {{this.label}}
    </span>

    <div class="scroll-overlay" *ngIf="overlayLoader">
      <!-- scroll -->
      <div class="loader" id="loader-4">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <span *ngSwitchDefault>Erro ao encontrar componente</span>
  </div>
</div>

<span [hidden]="this.valid || !this.touched" class="msg-error">*{{this.msgErrorValidation}}</span>
<!--<ng-content select="[subt]"></ng-content>!-->

<input hidden [attr.value]="this.idProperty ? this.idProperty : this.id" [name]="listname ? listname + '.id' : 'id'">
<input hidden [attr.value]="this.type" [name]="listname ? listname + '.type' : 'type'">
<input hidden [attr.value]="this.name" [name]="listname ? listname + '.name' : 'name'">
<input hidden [attr.value]="this.label" [name]="listname ? listname + '.label' : 'label'">
<input hidden [attr.value]="this.description" [name]="listname ? listname + '.description' : 'description'">
<input hidden [attr.value]="this.required" [name]="listname ? listname + '.required' : 'required'">
<input hidden [attr.value]="this.placeholder" [name]="listname ? listname + '.placeholder' : 'placeholder'">
<input hidden [attr.value]="this.listname" [name]="listname ? listname + '.listname' : 'listname'">
<input hidden [attr.value]="this.dropdownValues" [name]="listname ? listname + '.dropdownValues' : 'dropdownValues'">
<input hidden [attr.value]="this.value" [name]="listname ? listname + '.value' : 'value'">
<input hidden [attr.value]="this.min" [name]="listname ? listname + '.tamanho_minimo' : 'tamanho_minimo'">
<input hidden [attr.value]="this.max" [name]="listname ? listname + '.tamanho_maximo' : 'tamanho_maximo'">
<input hidden [attr.value]="this.flagCep" [name]="listname ? listname + '.flagCep' : 'flagCep'" *ngIf="this.type == 'cep'">
<input hidden [attr.value]="this.isBlock" [name]="listname ? listname + '.isBlock' : 'isBlock'">
