import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'denied-proposal',
  templateUrl: './denied-proposal.component.html',
  styleUrls: ['./denied-proposal.component.css']
})
export class DeniedProposalComponent extends GlobalProperties {

  innerWidth: any;
  img: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'SolicitacaoNegada'
  };


  constructor(
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) {
    super();
   }

  ngOnInit() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    this.img = '08_proposta_recusada copy.svg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}
