<form id="formCollect" method="post" #form>
  <div class="spacing__margin-top-xs form-container">
    <voxel-container>
      <voxel-row>
        <voxel-item>
          <div class="spacing__margin-top-xs spacing__margin-bottom-xs bold main-title">
            Insira o CPF do operador que deseja recuperar a senha
          </div>
        </voxel-item>
      </voxel-row>

      <div class="spacing__margin-bottom-xs" style="margin-bottom: 80px;">
        <voxel-row>
          <voxel-item *ngFor="let field of this.fields; let i = index" [mc]="12" [tc]="12" [dc]="12">
            <custom-input [id]="field.id" [type]="field.type" [name]="field.type" [label]="field.label"
              [description]="field.description" [required]="field.required" [placeholder]="field.placeholder"
              [options]='field.dropdownValues' [option-label]="field.type == 'dropdownSearch' ? 'search' : 'key'"
              [max]="field?.tamanho_maximo" [min]="field?.tamanho_minimo" [value]="field.value"
              [listname]="field.listname" (onBlur)="getInput($event, i)" (validate)="getInput($event, i)"
              [validation]="field.validation_api">
            </custom-input>
          </voxel-item>
        </voxel-row>
      </div>

    </voxel-container>

    <div class="spacing__margin-top-xs spacing__margin-bottom-xs button-container">
      <voxel-container>
        <voxel-row>
          <voxel-item tc="12" dc="12" mc="12">
            <input [value]="this.taskid" type="hidden" name="taskid" />
            <input [value]="this.route" type="hidden" name="route" />
            <voxel-button submit-button label="Próximo" [disabled]="this.hasFormError()" (click)="onClickNext()">
            </voxel-button>
            <a [href]="base_path + '/home'" class="link-home" style="width: 100%;" (click)="onClickHome()">
              <voxel-button loader-button label="Home" id="button-home"></voxel-button>
            </a>
          </voxel-item>
        </voxel-row>
      </voxel-container>
    </div>

    <voxel-dialog [(showDialog)]="showModal" [items]="items" [description]="this.modalDescription"
      (dialogLinkClick)="closeModal()">
    </voxel-dialog>

  </div>
</form>