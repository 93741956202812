<div>
  <!-- (window:scroll)="onScroll($event)" -->
  <header-demo
    [arraysearch]="this.arraysearch"
    [value]="this.value"
    [backbutton]="false"
    [title]="title"
    [subtitle]="subtitle"
    (onIconSearchClick)="search($event)">
  </header-demo>

  <div class="content">

    <div *ngIf="this.arraysearch.length > 0" class="title">
      Selecione uma proposta e veja os detalhes
    </div>
    <div *ngIf="this.arraysearch.length == 0 && this.value != null" class="title">
      Ops, não consta nenhuma proposta com o CPF digitado. :(
    </div>
    <div *ngIf="this.arraysearch.length == 0 && this.value == null" class="title">
      Ops, não consta nenhuma proposta para esta loja. :(
    </div>


    <form id="formProposal" name="formProposal" method="post" asp-action="Index" asp-controller="ConsultProposal">

      <input name="cpf_search" id="cpfSearchId" hidden>
      <input name="filter_search" id="filterSearchId" hidden>
      <input name="id_proposta" id="proposalId" hidden>
      <input name="page" id="pageId" hidden>
      <input name="position" id="positionId" hidden>

      <voxel-container>
        <voxel-row>
          <voxel-item dc="12" tc="12" mc="12">

            <div *ngFor="let item of this.arraysearch" class="card-proposal" loader-button (click)="goToProposal(item)">

              <div class="icon-left">
                <svg-icon [svgStyle]="{'fill':'var(--primary-color)'}"
                  src="{{ path_assets + 'icon_acompanhamento_proposta.svg' }}">
                </svg-icon>
              </div>

              <div class="content-right">

                <voxel-row>
                  <voxel-item *ngIf="item.nome_completo" dc="4" tc="12" mc="12">
                    <span class="card-title">
                      Nome
                    </span>
                    <small>{{item.nome_completo}}</small>
                  </voxel-item>
                <!-- </voxel-row>
                <voxel-row> -->
                  <voxel-item data-class="id-prop" dc="4" tc="7" mc="7">
                    <span class="card-title">
                      Proposta
                    </span>
                    <small>{{item.id_proposta}}</small>
                  </voxel-item>
                  <voxel-item dc="4" tc="7" mc="7">
                    <span class="card-title">
                      CPF
                    </span>
                    <small>{{item.cpf}}</small>
                  </voxel-item>
                  <voxel-item dc="4" tc="5" mc="5">
                    <span class="card-title">
                      Status
                    </span>
                    <small [ngClass]="item.status_proposta" style="font-weight: 100;">{{item.status_proposta}}</small>
                  </voxel-item>
                  <voxel-item dc="4" tc="6" mc="12">
                    <span class="card-title">
                      Data
                    </span>
                    <small>{{item.data_criacao_proposta_venda}} - {{item.hora_criacao_proposta_venda}}</small>
                  </voxel-item>
                </voxel-row>
              </div>
            </div>
          </voxel-item>
          <voxel-item dc="12" tc="12" mc="12" *ngIf="!this.block">
            <!-- <div *ngIf="!this.block" class="scroll-loader">
              <div class="scroll-title">
                Deslize para carregar mais propostas...
              </div>
            </div>
            <div class="scroll-container"></div> -->
            <div class="card-button">
              <voxel-button *ngIf="this.arraysearch.length > 0" id="btnSubmit" label="Carregar mais" (click)="loadProposals()">
              </voxel-button>
            </div>
          </voxel-item>
        </voxel-row>
      </voxel-container>

      <voxel-dialog 
        [(showDialog)]="cancelationSuccess" [items]="items"
        [description]="dialogMessage"
        (dialogLinkClick)="closeModal()"
      >
      </voxel-dialog>

    </form>
  </div>
</div>
