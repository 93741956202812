<form asp-action="Index" asp-controller="DeniedProposal" method="post">
  <div class="container-img">
    <!-- <img *ngIf="this.innerWidth < 768" src="{{ path_assets + '08_proposta_recusada copy.svg' }}">
    <img *ngIf="this.innerWidth > 767" src="{{ path_assets + 'ilustra_nao_aprovado.svg' }}"> -->
    <img src="{{ path_assets + img}}">
  </div>

  <div class="header">Proposta não aprovada</div>

  <div class="body">Não foi possível dar continuidade na sua proposta.</div>

  <voxel-container>
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <a [link]="base_path + '/home'" loader-button>
          <voxel-button class="home" id="button-principal" label="voltar ao início"></voxel-button>
        </a>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>
