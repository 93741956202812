<form id="formNewCard" #frm asp-action="Index" asp-controller="NewCard" method="post" name="frm">
  <voxel-container>
    <voxel-row class="rowText">
      <voxel-item dc="12" tc="12" mc="12">
        <div class="rowInput">
          <svg-icon [applyCss]="true" class="iconImg" src="{{this.path_assets + 'icone_cpf.svg'}}"></svg-icon>
          <p class="pText">
            Insira o CPF do cliente que
            deseja o cartão.
          </p>
        </div>
      </voxel-item>
      <voxel-item dc="12" tc="12" mc="12">
        <div>
          <custom-input id="cpf" type="cpf" name="cpf" label="CPF do cliente" description="" [required]="true"
            placeholder="" [value]="this.cpfValue" listname="" options='' option-label="" tabindex="1"
            (keyup)="onCheckToSubmit($event)" (validate)="validateField($event)">
          </custom-input>
        </div>
        <div>
          <div class="custom-error" *ngIf="!this.validate">CPF Inválido!</div>
        </div>
      </voxel-item>
    </voxel-row>

    <voxel-row class="rowBtn">
      <voxel-item dc="12" tc="12" mc="12">
        <voxel-button tabindex="2" [disabled]="!this.validate" submit-button id="button-proxima" label="Próxima">
        </voxel-button>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>
