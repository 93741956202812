import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormDynamicComponent } from "../../shared/form-dynamic/form-dynamic.component";
import { CryptoPipe } from "projects/commons-util/src/crypto/crypto.pipe";
import { map } from "rxjs/operators";
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from "../../shared/services/analytics.service";

@Component({
  selector: 'master-password-reset',
  templateUrl: './master-password-reset.component.html',
  styleUrls: ['./master-password-reset.component.css']
})
export class MasterPasswordResetComponent extends FormDynamicComponent {
  @Input("inputs") inputs: string;
  @ViewChild("form") form: ElementRef;

  route: string;
  taskid: string;
  domains: any;
  showModal: boolean = false;
  items: Array<any>;
  modalDescription: string = "Usuário não existente. contate o master para avaliação/solicitação de acesso.";
  name: string;
  unauthorized: boolean;
  readonly PAGE_NAME = {
    section: 'RedefSenhaCPF'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.items = [
      {
        id: "1",
        content: "Ok",
        a11yLabel: "Ok"
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe
      .transform(this.inputs, "decrypt")
      .pipe(
        map(data => {
          this.route = data.route;
          this.taskid = data.taskId;
          this.fields = data.fields;
          this.domains = data.domains;
          this.saveAnalyticsDataId(data);
        })
      ).subscribe(() => {

        console.log("route", this.route)
        console.log("taskId", this.taskid)
        console.log("fields", this,this.fields)
        console.log("domains", this.domains)

        if(this.domains && this.domains.errorReset)
          this.showModal = true;

        this.callAnalytics()
      });

    // const data = JSON.parse(this.inputs);
    // this.fields = data.fields;
    // this.checkDomains(data.domains);

    document
      .querySelector("master-password-reset")
      .setAttribute("input", "");
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  closeModal() {
    this.showModal = false;
  }

  saveAnalyticsDataId(data: any) {
    console.log('> backend response: ', data);
    this.analyticsService.setParameters({ id_customer: 'no_customerid', store_name: data.store_name || '', customer_age_range: null });
    if (data.partner_name) {
      this.analyticsService.setParameters({ id_parceiro: data.partner_name});
    }
    if (data.store_id) {
      this.analyticsService.setParameters({ id_store_operator: data.store_id});
    }
    if (data.operator_id) {
      this.analyticsService.setParameters({ id_operator: data.operator_id});
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onClickNext(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'BTSenha',
        label: 'Proximo'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'BTSenha_Proximo');
  }

  onClickHome(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'BTSenha',
        label: 'Home'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'BTSenha_Home');
  }

}

