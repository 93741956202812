import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModalComponent } from '../../shared/modal/modal.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'select-services-insurance',
  templateUrl: './select-services-insurance.component.html',
  styleUrls: ['./select-services-insurance.component.css']
})
export class SelectServicesInsuranceComponent implements OnInit {

  /**
   * @description Armazena a string contendo o JSON que será feito parse
   */
  @Input() inputs: string;

  /**
   * @description Armazena o objeto da Modal
   */
  @ViewChild(ModalComponent) modalComponent: ModalComponent;
  @ViewChild('form') form: ElementRef;



  /**
   * @description Armazena Objeto que relaciona ids com seus respectivos icons
   */
  private idAndIcons: any;

  /**
   * @description Armazena o objeto principal que será utilizado na página
   */
  public parsedInputs: any;
  private readonly AEC_SERVICE_ID = 'adesao_avaliacao_emergencial_credito';

  public fields = [];
  public fields1 = [];
  public fields2 = [];
  public hiddens = [];

  public taskId: string;
  public route: string;
  public sms: string;
  public hasOfertaCartaoProtegido: boolean;
  private customer_id: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'SelecionarServicos'
  };

  /**
   * @description Armazena o status de desabilitado do botão continuar
   */
  public buttonDisabled: boolean = true;

  /**
   * @description Armazena o objeto selecionado
   */
  public fieldSelected: any;

  /**
   * @description Armazena em um array os campos obrigatórios
   */
  public validateFields: Array<boolean>;

  /**
   * @description Armazena objeto que será utilizado para
   * renderizar inputs do tipo hidden contendo valores dos serviços
   */
  public valuesInsuranceInputHiddens;

  /**
   * @description  Informações do modal
   */
  public bodytext: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) {

    this.validateFields = [];

    this.idAndIcons = [
      {
        id: 'adesao_sms',
        label: 'Aviso SMS',
        icon: 'icon-itaufonts_feedback'
      },
      {
        id: 'adesao_avaliacao_emergencial_credito',
        label: 'Avaliação Emergencial de Crédito (AEC)',
        icon: 'icon-itaufonts_exclamacao'
      },
      {
        id: 'adesao_fatura_digital',
        label: 'Fatura Digital',
        icon: 'icon-itaufonts_fatura'
      },
      {
        id: 'id_oferta_cartao_protegido',
        label: 'Cartão Protegido',
        icon: 'icon-itaufonts_seguro_cartao'
      },
      {
        id: 'id_oferta_valor_conta_paga',
        label: 'Conta Paga',
        icon: 'icon-itaufonts_seguro_renda'
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.parsedInputs = data;
        this.taskId = this.parsedInputs.taskId;
        this.route = this.parsedInputs.route;
        this.customer_id = data.customer_id || '';
        this.populateValidateFields();
        this.populateIcons();
      }
      )).subscribe(() => { this.getMetrics(); });

    // console.log(JSON.parse(this.inputs));
    // this.parsedInputs = JSON.parse(this.inputs);
    // this.taskId = this.parsedInputs.taskId;
    // this.route = this.parsedInputs.route;
    // this.populateValidateFields();
    // this.populateIcons();

    document.querySelector('select-services-insurance').setAttribute('inputs', '');
  }

  getMetrics() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * @return void
   * @description Percorre o JSON recebido via inputs e
   * formata para o padrão esperado a ser renderizado no HTML
   */
  populateIcons() {
    this.parsedInputs.fields.forEach(field => {
      this.idAndIcons.forEach(icon => {
        if (field.id === icon.id) {
          field['icon'] = icon.icon;
        }
      });
    });
  }

  /**
   * @description Popula array validateFields com booleans
   * equivalentes aos campos que devem ser preenchidos
   */
  populateValidateFields(): void {
    this.mapInsuranceService();

    this.fields = this.parsedInputs.fields;
    this.fields1 = this.parsedInputs.fields.filter(f => f.description === 'SERVIÇOS');
    this.fields2 = this.parsedInputs.fields.filter(f => f.description === 'SEGUROS');
    // this.hiddens = this.parsedInputs.fields.filter(f => f.id.indexOf('valor') != -1);

    this.fields1.forEach(field => {

      if ((field.dropdownValues && field.dropdownValues.filter(f => f.key === 'fatura_block').length > 0) ||
        field.id === this.AEC_SERVICE_ID
      ) {
        field.required = false;
        field.checked = true;
        field.value = true;
      }

      this.validateFields.push(!field.required);
    });

    this.fields2.forEach(field => {
      this.validateFields.push(!field.required);
    });
  }

  mapInsuranceService() {
    const insurance = this.parsedInputs.domains.find(domain => domain.id === 'cartao_protegido') || {};
    const value = [
      {
        "key": "valor",
        "value": insurance.valor
      },
      {
        "key": "resumo",
        "value": insurance.resumo
      },
      {
        "key": "more",
        "value": insurance.texto
      }
    ];

    this.parsedInputs.fields = this.parsedInputs.fields.map(field => {
      if (field.id === "id_oferta_cartao_protegido") {
        return { ...field, dropdownValues: [...(field.dropdownValues || []), ...value], listname: "fields[3]" };
      }
      return field;
    });
  }

  /**
   * @description Habilita ou desabilita o botão de enviar
   */
  validateForm() {
    for (let i = 0; i < this.validateFields.length; i++) {
      const element = this.validateFields[i];
      if (element === false) { return true; }
    }
  }

  trackClick(): void {
    const hasSmsService = this.sms ? 'sim' : 'não';
    const hasInsuranceService = this.hasOfertaCartaoProtegido ? 'sim' : 'não';

    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'SMS',
        label: hasSmsService
      }
    );
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'Seguro Cartão Protegido',
        label: hasInsuranceService
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, `SMS ${hasSmsService}`);
    this.ga4Service.trackEvent('click', this.PAGE_NAME, `Seguro Cartão Protegido ${hasInsuranceService}`);
  }


  /**
   * @description Abre modal
   */
  openModal(field) {
    this.fieldSelected = field;
    this.modalComponent.input1 = 'confirmar';
    this.modalComponent.input2 = 'voltar';
    this.modalComponent.title = field.label;
    this.modalComponent.display = true;
    window.scrollTo(0, 0);

    //Preenche valor no body
    if (field.id == 'id_oferta_cartao_protegido') {
      const modal = this.parsedInputs.domains.find(domain => domain.id === 'cartao_protegido');
      this.bodytext = modal.texto;
    }
    else if (field.id == 'id_oferta_valor_conta_paga') {
      const modal = this.parsedInputs.domains.find(domain => domain.id === 'conta_paga');
      this.bodytext = modal.texto;
    }
  }

  setResumo(field) {
    return this.parsedInputs.domains.filter(d => field.id.includes(d.id))[0];
  }

  /**
   * @description Escuta evento do botão clicado na modal
   * @param $e
   */
  btnClicked($e) {
    if ($e == 'confirmar') {
      this.fields.filter(f => f.id == this.fieldSelected.id)[0].checked = !this.fields.filter(f => f.id == this.fieldSelected.id)[0].checked;
      this.setServiceChecked(
        this.fields.filter(f => f.id == this.fieldSelected.id)[0].checked,
        this.fieldSelected.id,
        this.fieldSelected.value,
        this.fields.findIndex(f => f.id == this.fieldSelected.id)
      );
    }
  }

  /**
   * @description Retorna true para o primeiro id de cada grupo
   * @param id
   * @return boolean
   */
  verifyBorder(id): boolean {
    return (id == 'adesao_sms' ||
      id == 'numero_adesao_cartao_protegido');
  }

  /**
   * @description Atualiza booleans no input hidden e no array
   * de controle validateFields
   */
  setServiceChecked(ev, id, value, i): void {

    if (id === 'adesao_sms') {
      this.sms = ev ? 'sim' : 'nao';
    }
    else if (id === 'id_oferta_cartao_protegido') {
      this.hasOfertaCartaoProtegido = ev;
    }

    let hiddenA = document.getElementById(id);
    hiddenA.setAttribute('value', ev);

    if (this.fields[i].required) {
      this.validateFields[i] = ev;
    }

  }

}
