import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal/modal.component';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'previous-analysis',
  templateUrl: './previous-analysis.component.html',
  styleUrls: ['./previous-analysis.component.css']
})
export class PreviousAnalysisComponent extends GlobalProperties {

  @Input("inputs") inputs: any;
  @Input("redirect-route") redirectRoute: string;
  @ViewChild(ModalComponent) modalComponent: ModalComponent;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'ResultadoPreAnalise'
  };

  domains: any;
  selectedValue: any;
  selected: any;
  route: any;
  taskId: any;
  value: any;
  id: any;
  customer_id: string;

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        console.log('accept offer: ', data);
        this.setGaParameters(data);
        this.domains = data.domains;
        this.route = data.route;
        this.taskId = data.taskId;
        this.customer_id = data.customer_id;
        this.getLimit();
        this.domains.rotaContinuarProposta = this.base_path + (data.domains.rotaContinuarProposta ? data.domains.rotaContinuarProposta : "");
        this.domains.rotaNovaProposta = this.base_path + data.domains.rotaNovaProposta;
        this.modalComponent.display = data.domains.propostaEmAberto;
      }
      )).subscribe();
    this.appDynamicsService.callAppAdynamics(this.elementRef);


    // const data = JSON.parse(this.inputs);
    // this.setGaParameters(data);
    // this.domains = data.domains;
    // this.route = data.route;
    // this.taskId = data.taskId;
    // this.getLimit();
    // this.domains.rotaContinuarProposta = this.base_path + (data.domains.rotaContinuarProposta ? data.domains.rotaContinuarProposta : "");
    // this.domains.rotaNovaProposta = this.base_path + data.domains.rotaNovaProposta;
    // this.modalComponent.display = data.domains.propostaEmAberto;

    document.querySelector('previous-analysis').setAttribute('inputs', '');
  }

  setGaParameters(bffParameters): void {
    console.log('accept offer: ', bffParameters);
    this.analyticsService.setParameters(
      {
        id_operator: bffParameters.operator_id || '',
        id_parceiro: bffParameters.partner_name || '',
        id_store_operator: bffParameters.store_id || '',
        id_customer: bffParameters.customer_id || 'no_customerid',
        store_name: bffParameters.store_name || ''
      }
    );
  }

  getCard(card: any) {
    this.selected = card;
    this.value = this.selected.value;
    this.id = this.selected.id;
  }

  getLimit(): void {
    const limiteString = this.domains.offters[0].limite;
    const limite = limiteString.replace('R$', '').replace('.', '').replace(',', '.');
    this.callAnalytics(Number(limite));
  }

  callAnalytics(limite: number): void {
    this.analyticsService.trackPageLoadCustomParams(
      this.PAGE_NAME,
      [{ param: 'limof', value: limite }],
      this.customer_id
    );
    this.ga4Service.trackPageLoad(this.PAGE_NAME,
      this.customer_id,
      [{ param: 'limite_ofertado', value: limite }]
    );
  }
}
