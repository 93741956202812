import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Subscription, throwError } from 'rxjs'
import { map } from 'rxjs/operators'
import { DnaService } from '../../../../../projects/commons-util/src/crypto/dna.service'
import { GlobalProperties } from '../../shared/global-properties'
import { CacheRedisService } from 'projects/commons-util/src/cache/cacheRedis.service'
import { AnalyticsService } from '../../shared/services/analytics.service'

@Component({
  selector: 'inject-user',
  templateUrl: './inject-user.component.html',
  styleUrls: ['./inject-user.component.css'],
})
export class InjectUserComponent extends GlobalProperties implements OnInit, OnDestroy {

  @Input() url: string;

  constructor(private dnaService: DnaService) {
    super()
  }

  ngOnInit() {
    this.initDna()
  }

  ngOnDestroy() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {}

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';')
    let caLen: number = ca.length
    let cookieName = `${name}=`
    let c: string

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '')
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length)
      }
    }
    return ''
  }

  initDna() {
    var user = this.getCookie('usr')

    this.dnaService.post('/Device/SetUsr', 'setUser', { usr: user })
      .pipe(
        map(userInjected => {
          if(userInjected.message == "Usuario recebido com sucesso"){
            location.href = this.url
          }else{
            window.location.href = '/warning-error'
          }
        })
      )
      .subscribe()
  }
}
