import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe'; import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { take, map } from 'rxjs/operators';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';

@Component({
  selector: 'user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.css']
})
export class UserInformationComponent extends GlobalProperties implements OnInit {
  @Input("inputs") inputs: string;
  @ViewChild('form') form: HTMLFormElement;
  text: string;
  web: boolean;

  biometriaModel: any = "";
  idUsuario: any;
  androidAppVersion: number;
  showModal: boolean = false;
  items: Array<any>;
  modalDescription: string = "Não foi possível capturar a biometria, por favor tente novamente.";

  readonly PAGE_NAME = { section: 'BiometriaFacial' };


  constructor(
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  ngOnInit() {
    this.text = 'Warning'
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    };

    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.idUsuario = data.domains;
        this.saveAnalyticsDataId();
        this.androidAppVersion = Number(data.domains.androidAppVersion);
      }
      )).subscribe(() => this.callAnalytics());
    this.appDynamicsService.callAppAdynamics(this.elementRef);

    // this.idUsuario = this.inputs;
  }

  saveAnalyticsDataId(): void {
    this.analyticsService.setParameters(
      {
        id_operator: this.idUsuario.id_operator || '',
        id_parceiro: this.idUsuario.name_parceiro || '',
        id_store_operator: this.idUsuario.id_store_operator || '',
        id_customer: 'no_customerid',
        customer_age_range: null
      }
    );
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  closeModal() {
    this.showModal = false;
  }

  captureBiometria() {
    if (this.all_properties['--simulador'] == 'true') {
      this.biometriaModel = "simulador"
      this.form.nativeElement.submit();
    } else {

      const sdkParameter = this.androidAppVersion >= 33110 ? this.idUsuario : this.idUsuario.cpf;

      this.dnaService.post('', 'duploFatorAutenticacao', sdkParameter, false)
        .pipe(
          map(result => {
            if (result.sucess != "SUCCESS" && result.errorMessage.toLowerCase().includes("no_more_attemps")) {
              this.modalDescription = "O usuário excedeu o número de tentativas para captura da biometria facial nessa sessão. Siga as orientações e tente novamente.";
              removeLoader();
              this.showModal = true;
            } else if (result.sucess != "SUCCESS" && result.errorMessage.toLowerCase().includes("client_blocked")) {
                this.modalDescription = "O usuário excedeu o número de tentativas para captura da biometria facial. Tente novamente em 4h";
                removeLoader();
                this.showModal = true;
            } else if (result.sucess != "SUCCESS") {
                this.modalDescription = "Não foi possível capturar a biometria, por favor tente novamente.";
                removeLoader();
                this.showModal = true;
            } else {
                this.biometriaModel = result.escryptedMessage
                setTimeout(() => {
                this.form.nativeElement.submit();
                }, 1500);
            }
          })
        ).subscribe();

    }
  }
}
