<div class="card-template">
  <voxel-container>
    <div class="card" [ngClass]="{'disabled': this.isBlocked}">
      <label class="header-label" style="margin-bottom: 16px;">
        <span class="icon-itaufonts_full_cartao header-icon "> </span>
        <span class="header-card-owner ">{{this.owner}}</span>
        <span class="header-aditional-flag " align='center' *ngIf='this.isAdditional' [ngClass]="{'disabled-flag': this.isBlocked}">
          adicional
        </span>
      </label>
      <table class="table-alignment" style="margin-bottom: 8px;">
        <tr>
          <td style="padding-bottom: 8px;">
            nome do cartão
            <p>{{this.cardname}}</p>
          </td>
        </tr>
        <tr>
          <td style="padding-bottom: 8px;">
            limite
            <p>{{ this.limit }}</p>
          </td>
        </tr>
        <tr>
          <td *ngIf="!isAdditional" style="padding-bottom: 8px;" colspan="2">
            Anuidade
            <p>{{this.annuity}}</p>
          </td>

          <td *ngIf="isAdditional" style="padding-bottom: 8px;" colspan="2">
            Anuidade 1º ano
            <p>{{this.annuity}}</p>
          </td>
        </tr>
        <tr *ngIf="isAdditional && this.annuityTwoYears">
          <td style="padding-bottom: 8px;" colspan="2">
            Anuidade demais anos
            <p>{{this.annuityTwoYears}}</p>
          </td>
        </tr>
      </table>
    </div>
  </voxel-container>
  <div class="spacing-bottom"></div>
</div>
