import { Component, OnInit, Renderer2, ElementRef, HostListener, Input } from '@angular/core';
import { ValidInput } from 'src/app/BG4/shared/valid-input';

@Component({
  selector: 'form-change-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.css']
})
export class FormChangePasswordComponent extends ValidInput {

  @Input('name-login') nameLogin: string;
  @Input('name-current-password') nameCurrentPassword: string;
  @Input('name-new-password') nameNewPassword: string;
  @Input('name-confirmation-password') nameConfirmationPassword: string;

  @Input('id-error-login') idErrorLogin: string;
  @Input('id-error-current-password') idErrorCurrentPassword: string;
  errorCurrentPassword: string;

  @Input('id-error-new-password') idErrorNewPassword: string;
  errorNewPassword: string;

  @Input('id-error-confirmation-password') idErrorConfirmationPassword: string;

  @Input('folder-image') folderImage: string;
  @Input('password-text-tip') passwordTextTip: string;

  @Input('message-sucess') messageSuccess: string;
  @Input('link-redirect') linkRedirect: string;

  web: boolean = false;
  passwordForceColor: string = 'red';
  passwordForcePercent: number = 0;
  passwordForceText: string = ''
  numbers: string = '0123456789';
  letters: string = 'abcdefghyjklmnñopqrstuvwxyz';
  capsLetters: string = 'ABCDEFGHYJKLMNÑOPQRSTUVWXYZ';

  showModal: boolean = false;
  items: Array<any>;

  psw1: string;
  psw2: string;

  percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0, g: 130, b: 0 } }
  ];

  /**
   * Coleçao de campos no formulário para validação
   */
  fields: Array<any> = [];

  /**
   * Define os objetos para validação
   *
   * @return void
   */
  initValidation() {
    this.fields.push({ name: this.nameLogin, valid: false, touched: false });
    this.fields.push({ name: this.nameCurrentPassword, valid: false, touched: false });
    this.fields.push({ name: this.nameNewPassword, valid: false, touched: false });
    this.fields.push({ name: this.nameConfirmationPassword, valid: false, touched: false });
  }

  /**
   *
   */
  validateField(event, id: string) {
    for (let field of this.fields) {
      if (field.name !== id) {
        continue;
      } else {
        field.touched = true;
        switch (field.name) {
          case this.nameLogin:
            let valor = '';
            if (event) {
              if (event.item) {
                valor = event.item.value.replace(/[^A-Z0-9]+/ig, '');
              } else {
                valor = event.replace(/[^A-Z0-9]+/ig, '');
              }
            }
            if (valor.length === 9) {
              field.valid = true;
            } else if (valor.length > 9) {
              field.valid = this.validaCPF(valor);
            } else {
              field.valid = false;
            }
            break;
          case this.nameCurrentPassword:
            field.valid = event.$event.target.value ? true : false;
            break;
          case this.nameNewPassword:
            this.psw1 = event.$event.target.value;
            // console.log(this.psw1, this.psw2);
            // console.log(this.psw1 === this.psw2 ? true : false);
            this.fields[2].valid = this.psw1 === this.psw2 ? true : false;
            this.fields[3].valid = this.psw1 === this.psw2 ? true : false;
            break;
          case this.nameConfirmationPassword:
            this.psw2 = event.$event.target.value;
            // console.log(this.psw1, this.psw2);
            // console.log(this.psw1 === this.psw2 ? true : false);
            this.fields[2].valid = this.psw1 === this.psw2 ? true : false;
            this.fields[3].valid = this.psw1 === this.psw2 ? true : false;
            break;

          default:
            break;
        }
      }
    }
  }


  isValidForm(): boolean {

    for (let field of this.fields) {
      if (!field.valid) {
        return false;
      }
    }

    return true;
  }


  getColorForPercentage = function (pct) {
    for (var i = 1; i < this.percentColors.length - 1; i++) {
      if (pct < this.percentColors[i].pct) {
        break;
      }
    }
    var lower = this.percentColors[i - 1];
    var upper = this.percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDevice();
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    super();

    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  ngOnInit() {
    this.setDevice();
    this.initValidation();

    if(this.messageSuccess)
      this.showModal = true
    else 
      this.messageSuccess = "-1"

    var urlParams = new URLSearchParams(document.location.search);
    if (urlParams.has('changeerrorcode')){
      var errorCode = urlParams.get("changeerrorcode")

      this.showModal = true;
      if(errorCode == "-30" || errorCode == "1")
      this.messageSuccess = "Erro na troca de senha. Favor observar os critérios de criação da mesma e redigitá-la para conferência."
      else
        this.messageSuccess = "Login e/ ou senha inválida. Por favor, tente novamente."
    }
  }

  private containNumber(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.numbers.indexOf(text.charAt(i), 0) != -1) {
        return 1
      }
    }

    return 0;
  }

  private containLeters(text) {
    text = text.toLowerCase();
    for (var i = 0; i < text.length; i++) {
      if (this.letters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  private containLowerCase(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.letters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  private containCapsLetters(text) {
    for (var i = 0; i < text.length; i++) {
      if (this.capsLetters.indexOf(text.charAt(i), 0) != -1) {
        return 1;
      }
    }

    return 0;
  }

  checkForce(event) {
    var result = 1;
    var value = event.srcElement.value;
    if (value.length != 0) {
      if (this.containNumber(value) && this.containLeters(value)) {
        result += 30;
      }
      if (this.containLowerCase(value) && this.containCapsLetters(value)) {
        result += 30;
      }
      if (value.length >= 4 && value.length <= 5) {
        result += 10;
      } else {
        if (value.length >= 6 && value.length <= 8) {
          result += 30;
        } else {
          if (value.length > 8) {
            result += 39;
          }
        }
      }
    }

    this.passwordForceText = result <= 30 ? 'Fraca' : (result <= 40 ? 'Média' : (result <= 90 ? 'Forte' : 'Muito Forte'));
    this.passwordForcePercent = result;
    this.passwordForceColor = this.getColorForPercentage(result / 100);
  }

  setDevice() {
    if (window.innerWidth > 1023) {
      this.web = true;
    } else {
      this.web = false;
    }
  }

  closeModal(){ this.showModal = false }

  ngAfterViewInit() {
    this.addTootip()
  }

  addTootip() {
    let tips = [];
    let text = '';
    if (this.passwordTextTip) {
      tips = JSON.parse(this.passwordTextTip);
      tips.forEach(element => {
        text = text + '• ' + element + '\n';
      });
    }

    const span = document.createElement('SPAN');
    span.setAttribute('data-tooltip', text);

    const icon = document.createElement('ICON');
    icon.setAttribute('class', 'icon-itaufonts_informacao');

    span.appendChild(icon);

    const element = this.el.nativeElement.querySelector('voxel-input.newpass').querySelector('label') as HTMLElement;
    element.insertAdjacentElement('afterend', span);

  }


}

