import { Component, Input, Output, ElementRef, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ValidInput } from '../../../BG4/shared/valid-input';
import { error } from '@angular/compiler/src/util';
import { DatePipe } from '@angular/common';
import { ValidateService } from 'projects/commons-util/src/validade-field/validate.service';
import { DropdownListComponent } from 'projects/dropdown-list/src/projects';

@Component({
  selector: 'input-dynamic',
  templateUrl: './input-dynamic.component.html',
  styleUrls: ['./input-dynamic.component.css']
})
export class InputDynamicComponent extends ValidInput {

  @Input()
  id: any;

  @Input()
  idProperty: any;

  @Input()
  name: any;

  @Input()
  label: string;

  @Input()
  type: string;

  @Input()
  mask: string;

  @Input()
  pattern: string;

  @Input()
  focus: string;

  @Input()
  mensagem: string;

  @Input()
  description: string;

  @Input()
  required: boolean;

  @Input()
  enabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  value: any;

  @Input()
  model: IMyDateModel = null;

  @Input()
  listname: string;

  @Input()
  options: any = [];

  @Input()
  min: string;

  @Input()
  max: string;

  @Input()
  mutavel: boolean;

  @Input()
  search: boolean;

  @Input()
  validation: boolean;

  @Input()
  additionalCpfs: Record<string, string>[];

  @Input("option-label")
  optionLabel: string;

  @Input("option-value")
  optionValue: string = 'value';

  @Input("flag-cep")
  flagCep: string;

  @Input("new-password")
  newPassword: string;

  @Input("is-block")
  isBlock: Boolean;

  @Input()
  isObject: any;

  @Input()
  timeOutValidation: boolean = false;

  @Output()
  onChange: EventEmitter<any> = new EventEmitter<any>();

  @Output("onBlur")
  blurEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output("teste")
  testeEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  validate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  cepSearch: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputDynamicAccordionRef') inputDynamicAccordionRef: ElementRef;
  @ViewChild('input') dropdownListRef: DropdownListComponent;
  @ViewChild('dp') dp: any;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    selectorHeight: '260px',
    selectorWidth: '290px'
    // other options are here...
  };

  myDateInit: boolean = true;

  requiredFields = [
    'label',
    'id'
  ];

  dropdownValues: string;

  cep: string;

  /**
   * Converte datas
   */
  pipe = new DatePipe('pt-BR');

  /**
   * Controla se o input passou por todas as verificaÃ§Ãµes necessÃ¡rias
   * de acordo com seu tipo e requerimento
   */
  valid: boolean = false;

  status: boolean = true;

  /**
   * Controla se o input jÃ¡ tenha sido clicado ou recebido foco
   * pelo usuÃ¡rio
   */
  touched: boolean = false;

  /**
   * Controle para definir tamanho maximo de uma string
   */
  maxInt: number = Number.MAX_SAFE_INTEGER

  /**
  * Controla se o input passou pela validaÃ§Ã£o via API
  * definido pelo input validation_api
  */
  validationValid: boolean = false;

  validationApi: boolean = true;

  msgErrorValidation: string = "Campo Inválido";

  overlayLoader: boolean = false;

  firstBlur: boolean = true;

  inputTimeout: any;

  // Force Invalidate field.
  isValidField = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private _validateField: ValidateService
  ) {
    super();
  }

  checkRequired() {
    var scope = this
    this.requiredFields.forEach(element => {
      if (!scope[element])
        throw error("Attribute " + element + " required")
    });
  }

  /**
   * @description
   * Ao inicializar o componente, verifica
   */
  ngOnInit() {
    // debugger
    this.parseAlias();

    if (this.type === 'boolean') {
      this.value = false;
    }

    if (this.type === 'dropdown' || this.type === 'dropdownSearch') {
      this.handleOptions();
    }

    if (this.type === 'date' && this.value) {
        const parts = this.value.split("/");
        const date = new Date(parts[2], parts[1] -1, parts[0]);
        this.model = {isRange: false, singleDate: {date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }}};
    }

    if(!this.required){
      this.valid = true
      this.validationValid = true
    }

    if (!this.validation || this.type == 'boolean') {
      this.validationValid = true;
    }

    this.consoleInputs();
  }

  ngAfterViewInit() {
    const elements: HTMLCollection = document.getElementsByClassName('select2-selection__arrow') as HTMLCollection;
    if (elements.length > 0) {
      elements.item(0).setAttribute('class', 'icon-itaufonts_seta_down');
    }
  }

  consoleInputs() {

    const debug = {
      id: this.id,
      type: this.type,
      name: this.name,
      label: this.label,
      description: this.description,
      required: this.required,
      placeholder: this.placeholder,
      listname: this.listname,
      options: this.options,
      optionLabel: this.optionLabel,
      value: this.value,
      max: this.max
    };
  }

  /**
   * @description
   * Identifica qual Ã© o input que deve ser utilizado
   * atravÃ©s de um "apelido" passado do back-end.
   * Auxilia para mudanÃ§as de type, consultando um catalogo par-valor
   * com o nome do apelido/nome do objeto
   */
  parseAlias() {
    const i = this.type;
    const catalog = {
      data: 'date',
      string: 'text',
      domain: 'dropdown',
      celular: 'cellphone'
    };
    this.type = (!catalog[i]) ? this.type : catalog[i];
  }

  /**
   * @description
   * Envia o valor selecionado do dropdown para o componente pai
   *
   * @param event
   */
  change(event) {
    this.onChange.emit(event);
  }

  /**
   * @description
   * Emite o status do input para o componente pai
   * ao perder o foco
   *
   * @param event
   */
  onBlur($e) {
    clearTimeout(this.inputTimeout);

    const input =
      $e.selected ? $e.selected :
        $e.item === undefined && $e.target.value ? $e.target.value :
          $e.item === undefined ? $e.target.firstChild.value : $e.item;

    this.touched = true;

    this.validateField(input, true);

    this.blurEmitter.emit(this);
  }

  onBlurChange($e) {
    const input = $e.selected ? $e.selected : $e.item === undefined && $e.target ? $e.target.value : $e.item;

    this.firstBlur ? this.firstBlur = false : this.touched = true;

    this.validateField(input, true);

    this.blurEmitter.emit(this);
  }

  setTouched() {
    this.touched = true;
  }

  validateCharacteres($e) {
    let regex = new RegExp("^[a-zA-Z0-9]+$");
    let key = String.fromCharCode(!$e.charCode ? $e.which : $e.charCode);
    if (!regex.test(key)) {
       $e.preventDefault();
       return false;
    }
  }

  /**
   * @description
   * Identifica qual o formato de itens passado para o dropdown
   * Se o tipo for string, faz um manipulaÃ§Ã£o para JSON
   * Reorganiza as opÃ§Ãµes do options antes de passar para o componente
   */
  handleOptions() {
    if (typeof this.options === 'string') {
      this.options = JSON.parse(this.options);
    }
    if (this.optionLabel != null) {
      this.dropdownValues = JSON.stringify(this.options);
      this.options = this.getReorganizedOptions(this.options);
    }
  }

  /**
   * @description
   * Reorganiza as opÃ§Ãµes do options antes de passar para o componente.
   * O modelo padrÃ£o do componente de dropdown usa:
   * value = para o valor do option
   * label = para o texto do option
   * Caso haja alguma mudanÃ§a dos nomes, deve ser informado via @Input()
   *
   * @param options Lista de opÃ§Ãµes
   */
  getReorganizedOptions(options) {
    const list = [];

    const first = {
      value: '',
      label: ''
    };

    list.push(first);

    for (let i in options) {
      const arr = {};
      if (this.optionLabel === "search") {
        arr['id'] = options[i]['key']
        arr['text'] = options[i]['value']
      } else {
        arr['value'] = options[i][this.optionValue]
        arr['label'] = options[i][this.optionLabel]
      }

      list.push(arr);
    }
    return list;
  }

  checkboxValue(event: any) {
    this.value = event.checked;
    this.cdr.detectChanges();
  }

  /**
   * Dispara o evento quando Ã© validado
   *
   * @param $e
   */
  onValidate($e) {
    this.valid = this.validateField($e, false) && this.isValidField;
    return this.validate.emit(this);
  }

  /**
   * Dispara o evento quando o controle ganha foco
   *
   */
  onGetFocus() {
    if(this.validation && this.type !== 'boolean') {
      this.validationValid = false;
    }
  }

  /**
  * @description
  * Inicia/reinicia o timeout para validaÃ§Ã£o do campo.
  */
  onInput($e) {
    if (this.timeOutValidation) {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(()=>{
        this.onBlur($e)
      },5000)
    }
  }

  /**
   * Remove a invalidação forçada do método `invalidateInput`.
   */
  validateInput() {
    this.msgErrorValidation = "Campo Inválido";
    this.valid = true;
    this.isValidField = true;
    this.validate.emit(this);
  }

  /**
   * Força a invalidação do campo até que o método `validateInput` seja executado.
   * @param msg mensagem de error que deve ser exibida.
   */
  invalidateInput(msg: string) {
    this.msgErrorValidation = msg;
    this.valid = false;
    this.isValidField = false;
    this.validate.emit(this);
  }

  /**
   * Retorna o status de validaÃ§Ã£o do input
   *
   * @param event
   * @return boolean
   */
  validateField(event: any, blur: boolean): boolean {

    const value = this.getValue(event);
    this.value = value;

    // ForÃ§a validaÃ§Ã£o em todos campos
    // this.validation = true;

    if (!this.validateRequired()) {
      this.msgErrorValidation = "Campo Obrigatório"
      return false;
    }

    if (!this.validMinLength()) {
      return false;
    }

    if (this.validation && blur) {
      this.overlayLoader = true;

      var context = this
      this.validateFieldApi(value)
        .then(data => {
          this.status = false;
          this.validationApi = (data.status === 200);
          this.validationValid = (data.status === 200);

          if (!this.validationApi) this.msgErrorValidation = data.message;

          this.valid = this.validaBff(event);
          this.overlayLoader = false;
          return this.validationValid;
        })
        .catch(() => window.location.href = '/warning-error');
    } else {
      let ret = this.validaBff(event);
      this.valid = ret;

      if (!this.valid && this.validationApi) this.msgErrorValidation = "Campo Inválido";

      return ret;
    }
  }

  validaBff(event: any): boolean {
    const value = this.getValue(event);

    switch (this.type) {

      case 'cellphone':
        return (!value || value.replace(/[^A-Z0-9]+/ig, '').length >= 11 ? true : false) && this.validationApi;

      case 'phone':
        return (!value || value.replace(/[^A-Z0-9]+/ig, '').length == 10 ? true : false) && this.validationApi;

      case 'boolean':
        return (this.validateBoolean()) && this.validationApi;

      case 'email':
        return (!value || this.validaEmail(event.value)) && this.validationApi;

      case 'cpf':
        return (!value || this.validaCPF(event.value.replace(/[^A-Z0-9]+/ig, ''))) && this.validationApi;

      case 'date':
        if (this.dp && value)
          this.dp.elem.nativeElement.value = value;

        return (this.validateDate()) && this.validationApi;

      case 'cep':
        if (this.flagCep == 'true') {
          this.flagCep = 'false';
          this.cepSearch.emit(!value || value.replace(/[^A-Z0-9]+/ig, '').length == 8 ? value.replace(/[^A-Z0-9]+/ig, '') : '');
        }
        return (!value || value.replace(/[^A-Z0-9]+/ig, '').length == 8 ? true : false) && this.validationApi;

      case 'password':
        if (this.id == 'confirmation_password') {
          return (this.newPassword === value ? true : false) && this.validationApi;
        } else {
          return (true) && this.validationApi;
        }

      case 'document':
        if (this.mask) {
          return (RegExp(this.pattern).test(value)) && this.validationApi;
        } else {
          return (true) && this.validationApi;
        }

      case 'dropdown':
        return event !== undefined ? (true) && this.validationApi : (false) && !this.validationApi

      default:
        return (true) && this.validationApi;
    }
  }

 /**
   * @description
   * Realiza a verificaÃ§Ã£o do campo via API
   * com base no input validation_api
   *
   * @param idField id do campo que sera verificado
   * @param valueField valor do campo
   */
  private async validateFieldApi(value: any) {
    if (this.isObject) {
      return this._validateField.validateField(this.idProperty, value, this.type).toPromise();
    }
    return this._validateField.validateField(this.id, value, this.type).toPromise();
  }

  /**
   * Retorna se o campo preenchido respeita os tamanhos mÃ­nimos
   * definido
   *
   * @param boolean
   */
  validMinLength(): boolean {

    if (this.min === undefined || this.min == '') {
      return true;
    }

    let min = parseInt(this.min);

    return (this.value < min) ? false : true;
  }

  /**
   * Retorna se Ã© um campo requirido e Ã© um campo valido
   *
   * @return boolean
   */
  validateRequired(): boolean {
    if (!this.required)
      return true

    var value = null

    if (this.value && typeof (this.value) != 'boolean' && this.type != 'date')
      value = this.value.trim()
    else
      value = this.value

    return (value && value != null && value != undefined && value != "");
  }

  /**
   * Verifica se Ã© um data vÃ¡lida no formato dd/mm/YYYY
   *
   * @param string
   * @return boolean
   */
  validateDate(): boolean {
    let pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
    let result = pattern.test(this.value);

    return (this.value && result) ? true : false;
  }

  /**
   * Verifica se o input de boolean Ã© vÃ¡lido ou nÃ£o
   *
   * @param $e
   * @return boolean
   */
  validateBoolean(): boolean {
    return (this.required && this.value || !this.required) ? true : false;
  }

  /*
   * Formata data utilizando regex
   */

  formateDate(date: any) {
    // console.log(date);

    if (!date) {
      return this.value;
    } else if (date.singleDate) {
      return date.singleDate.formatted;
    }

    let dateFormatted = date.replace(/[^0-9]+/g, '');

    if (dateFormatted.length > 2 && dateFormatted.length < 5) {
      dateFormatted = dateFormatted.replace(/(\d{1,2})(.*)/, '$1/$2');
    } else if (dateFormatted.length >= 5 && dateFormatted.length < 8) {
      dateFormatted = dateFormatted.replace(/(\d{1,2})(\d{1,2})(.*)/, '$1/$2/$3');
    } else if (dateFormatted.length >= 8) {
      dateFormatted = dateFormatted.replace(/(\d{1,2})(\d{1,2})(\d{4})(.*)/, '$1/$2/$3');
    }

    return dateFormatted;
  }

  /**
   * Retorna o valor de um evento  de acordo com o tipo de input
   * FunÃ§Ã£o auxiliar de validaÃ§Ã£o
   *
   * @param event
   * @return string
   */
  getValue($e): string {
    if ($e == undefined || $e == null) return this.value;

    if (this.type === 'boolean') return $e.checked

    if (this.type === 'document' && typeof $e === 'string') return $e;

    if (this.type === 'document') return $e.value;

    if (this.type === 'dropdown') return $e;

    if (this.type === 'date') return this.formateDate($e);

    if (this.type === 'dropdownSearch') return $e;

    return $e.value;
  }

  resetFlag() {
    this.flagCep = 'true';
  }

  getNewDate(e) {
    this.value = e;
  }

  /**
   * EspecÃ­fico para additional-info para tratar
   * o dropdown CartÃµes a emitir baseado no Tipo de EmissÃ£o selecionado
   *
   * @param tipoDeEmissao
   */
  handleCartoesAEmitir(tipoDeEmissao) {
    if (tipoDeEmissao.value === "02" || tipoDeEmissao.value === "06" || tipoDeEmissao.value === "07" || tipoDeEmissao.value === "") {
       this.dropdownListRef.reloadOptions("CARTAO");
     } else {
       this.dropdownListRef.reloadOptions("OUTROS");
       this.value="1";
       this.valid=true;
     }
   }


}
