import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.css']
})
export class CardHeaderComponent  {
  
  /**
   * @description
   * Titulo que será exibido em destaque no card
   */
  @Input() 
  title:string;

  /**
   * @description
   * Mensagem secundária que será exibido abaixo do título
   */
  @Input() 
  subtitle:string;

  @Input() 
  message:string;

  /**
   * @description
   * Ícone do voxel que é exibida acima do card.
   * Em caso de ser passado vazio, é exibido um icone default 
   */
  @Input() 
  icon:string = 'icon-itaufonts_cartoes';

  /**
   * @description
   * Icone em svg para ser exibido no lugar do icone do voxel.
   * É necessário passar o caminho completo do arquivo SVG
   */
  @Input() 
  svg:string;

}
