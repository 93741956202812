<form method="post" #form>
  <input #taskid type="text" name="taskid" [value]="this.captureFacialBiometryViewModel?.taskId" hidden>
  <input #route type="text" name="route" [value]="this.captureFacialBiometryViewModel?.route" hidden>
  <input type="text" name="domains" [value]="this.biometriaModel" hidden>

  <card-header title="BIOMETRIA FACIAL"
    subtitle="É necessário tirar uma foto do rosto do cliente para dar andamento do processo."
    [svg]="this.path_assets + 'icone_biometria.svg'"></card-header>

  <div class="spacing__margin-left-xs">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <p>Informe o cliente que poderemos utilizar sua foto e biometria facial em produtos e/ou serviços das empresas
            do Conglomerado Itaú para processos de identificação e/ou autenticação em sistemas eletrônicos próprios ou
            de terceiros para fins de segurança e prevenção a fraudes.</p>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>

  <div class="spacing__primary">
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <voxel-button loader-button (click)="captureBiometria()" label="Tirar foto"></voxel-button>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>

  <voxel-dialog [(showDialog)]="showModal" [items]="items"
  [description]="this.modalDescription"
    (dialogLinkClick)="closeModal()">
  </voxel-dialog>

</form>

<modal-dialog title="Aviso" overlay="true" btn1="fechar" type1="close" class1="freeline">
  <div subt>
    {{ captureFacialBiometryViewModel?.domains?.message_error }}
  </div>
</modal-dialog>