<form id="formCustomer" asp-action="Index" asp-controller="CaptureInformationDocuments" method="post">
	<div class="spacing__margin-top-xs" *ngIf="this.fields">
		<voxel-container>
			<voxel-row>
				<voxel-item tc="12" mc="12" dc="12">
					<div class="label-title">Preencha todos os dados do cliente.</div>
					<br>
				</voxel-item>
			</voxel-row>
			<voxel-row>
				<voxel-item *ngFor="let field of this.additionalFields; let i = index;"
					[attr.data-id]="field.id+'-item'"
					[tc]="this.getColValue(field.id, 'tc')"
					[dc]="this.getColValue(field.id, 'dc')"
					[mc]="this.getColValue(field.id, 'mc')"
					[grid-col]="this.getGridPosition(field.id)">
					<custom-input
						[id]="field.id"
						[type]="field.type"
						[label]='field.label'
						[value]="field.value"
						[validation]="field.validation_api"
						read-only>
					</custom-input>
				</voxel-item>

        <voxel-item *ngFor="let field of this.fields; let i = index;"
          [attr.data-id]="field.id+'-item'"
          [tc]="this.getColValue(field.id, 'tc')"
          [dc]="this.getColValue(field.id, 'dc')"
          [mc]="this.getColValue(field.id, 'mc')"
          [grid-col]="this.getGridPosition(field.id)"
          [ngSwitch]="field.id">
          <ng-container *ngIf="field.type != 'object'; else elseTemplate">
			<ng-container *ngIf="field.autovalidate; else templateNoValidation">

			  <custom-input
				auto-validate
				[id]="field.id"
				[type]="field.type"
				[name]="field.type"
				[label]="field.label"
				[description]="field.description"
				[required]="field.required"
				[placeholder]="field.placeholder"
				[value]="field.value"
				[max]="field?.tamanho_maximo"
				[min]="field?.tamanho_minimo"
				[listname]="field.listname"
				[options]='field.dropdownValues'
				(onBlur)="getInput($event, i)"
				option-label="key"
				(onChange)="toggleCivilStatus($event, i)"
				(validate)="getInput($event, i)"
				[validation]="field.validation_api"
				[mask]="this.mask"
				[pattern]="this.pattern"
				[className]='field.teste === true ? "example-class" : "other-class"'
				[search]="field?.search">
			  </custom-input>

			</ng-container>
			<ng-template #templateNoValidation>
				<custom-input
				[id]="field.id"
				[type]="field.type"
				[name]="field.type"
				[label]="field.label"
				[description]="field.description"
				[required]="field.required"
				[placeholder]="field.placeholder"
				[value]="field.value"
				[max]="field?.tamanho_maximo"
				[min]="field?.tamanho_minimo"
				[listname]="field.listname"
				[options]='field.dropdownValues'
				(onBlur)="getInput($event, i)"
				option-label="key"
				(onChange)="toggleCivilStatus($event, i)"
				(validate)="getInput($event, i)"
				[validation]="field.validation_api"
				[mask]="this.mask"
				[pattern]="this.pattern"
				[className]='field.teste === true ? "example-class" : "other-class"'
				[search]="field?.search">
			  </custom-input>
			</ng-template>
		  </ng-container>

					<ng-template #elseTemplate>
            <voxel-item class="conjuge" *ngIf="married" tc="12" dc="12" mc="12">
              <p id='lable-data-conjugue'>dados cônjuge</p>
            </voxel-item>

						<voxel-row>
							<voxel-item
								*ngFor="let prop of field.properties; let j = index;" tc="6" dc="6" mc="12">

								<input [attr.value]="field.id" type="text" [name]="field.listname + '.id'" hidden>
								<input [attr.value]="field.type" type="text" [name]="field.listname + '.type'" hidden>
								<input [attr.value]="field.listname" type="text" [name]="field.listname + '.listname'" hidden>

								<custom-input
									[id]="prop.id"
									[type]="prop.type"
									[name]="prop.type"
									[label]="prop.label"
									[description]="prop.description"
									[required]="prop.required"
									[placeholder]="prop.placeholder"
									[max]="field?.tamanho_maximo"
									[min]="field?.tamanho_minimo"
									[value]="prop.value"
									[listname]="field.listname + '.' + prop.listname"
									[options]='prop.dropdownValues'
									option-label="key"
									(onBlur)="getInput($event, i)"
									(teste)="getTeste($event, i)"
									[hidden]="verifyPartner(i, j)"
									(validate)="getInputConjuje($event, i + j, this.married)"
									[validation]="prop.validation_api">
								</custom-input>
							</voxel-item>
						</voxel-row>
					</ng-template>
				</voxel-item>
			</voxel-row>
		</voxel-container>
	</div>

	<div class="spacing__margin-top-xs spacing__margin-bottom-xs">
		<voxel-container>
			<voxel-row>
				<voxel-item tc="12" dc="12" mc="12">
					<input [value]="this.taskId" type="text" name="taskId" hidden>
					<input [value]="this.route" type="text" name="route" hidden>
					<voxel-button submit-button label="Continuar" [disabled]="this.hasFormError()">
					</voxel-button>
				</voxel-item>
			</voxel-row>
		</voxel-container>
	</div>
</form>

<modal-dialog class="modal" title="Atenção!" display="true" overlay="true" (event-btn)="modalBtnEvent($event)"
	input1="btnHome" btn1="Fechar" type1="close" class1="outline">
  <div body class="modal-body">
    <div class='modal-elements'>
      <p>Por favor, verifique os campos já preenchidos e os corrija caso necessário.</p>
    </div>
  </div>
</modal-dialog>