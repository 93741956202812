import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'awaiting-authorization',
  templateUrl: './awaiting-authorization.component.html',
  styleUrls: ['./awaiting-authorization.component.css']
})
export class AwaitingAuthorizationComponent extends GlobalProperties {

  innerWidth: any;
  web: boolean;
  img: string;

  constructor() {
    super();
   }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000){
      this.web = true;
    } else {
      this.web = false;
    }

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_aguardandoautorizacao.svg';
    // } else {
    //   this.img = 'ico_aguar_autor.svg';
    // }

    this.img = 'ico_aguar_autor.svg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if(window.innerWidth > 1000){
      this.web = true;
    } else {
      this.web = false;
    }

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_aguardandoautorizacao.svg';
    // } else {
    //   this.img = 'ico_aguar_autor.svg';
    // }
  }

}
