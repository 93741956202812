import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { CustomInputComponent } from '../custom-input/custom-input.component';
import { GlobalProperties } from '../global-properties';


export abstract class FormDynamicComponent extends GlobalProperties implements OnInit {

  ngOnInit() { }

  ngAfterViewInit() {
    this.setTimeoutValidation();
  }

  grid: Array<any> = [];
  inputsForm: any[] = [];
  fields: Array<any> = [];
  validateFields: Array<any> = [];
  lastField: any;
  sendResultToClient: boolean;
  ocupacao: string;
  profissao: string;
  renda: string;


  /**
   * Retorna o valor da coluna do voxel
   * de acordo com o nome do input
   *
   * @param id string   Id/nome do input
   * @param type string Tipo da coluna(desktop, mobile, desktop)
   * @returns string
   */
  getColValue(id: string, type: string) {
    let item = (!this.grid[id]) ? { mc: 12, tc: 6, dc: 6 } :
      this.grid[id];
    return item[type];
  }

  /**
   * Retorna o valor da coluna do voxel de acordo com o nome do
   * input
   *
   * @param id string   Id/nome do input
   * @returns string
   */
  getGridPosition(id) {
    let val = (!this.grid[id]) ? null : this.grid[id]['grid'];
    return val;
  }

  /**
   * Marca se o campo foi tocado ou não
   * @returns void
   */
  updateStatus(obj) {
    obj.touched = true;
  }

  /**
   * Verifica se será mostrada a mensagem de erro
   * @param index
   * @returns boolean
   */
  showError(index) {
    if (this.inputsForm.length == 0 || this.inputsForm[index] == undefined || this.inputsForm[index].overlayLoader) {
      return false;
    }

    let obj = this.inputsForm[index];
    if (obj.validation) {
      return ((!obj.valid || !obj.validationValid) && obj.touched) ? true : false;
    } else {
      return (!obj.valid && obj.touched) ? true : false;
    }
  }

  /**
   * Recebe todos os status vindo do input dynamic(se é valido, se foi clicado, etc..)
   * e armazena o objeto para validação de formulário
   *
   * @param input
   * @param index
   */
  getInput(input, index) {
    this.inputsForm[index] = input;

    //especifico para pre-analysis
    if (input.id === 'indicador_receber_email_recusa') {
      this.sendResultToClient = input.value;
    }

    //especifico para professional-data
    switch (input.id) {
      case 'ocupacao_profissional':
        input.options.map((item) => {
          if (item.value === input.value) {
            this.ocupacao = item.label;
          }
        });
        break;
      case 'profissao':
        input.options.map((item) => {
          if (item.value === input.value) {
            this.profissao = item.label;
          }
        });
        break;
      case 'renda_principal':
         this.renda = input.value;
    }
  }

  getTeste(input, index) {
    console.log("passa no getteste")
  }

  // Especifico para form-customer para tratar a validação de conjuje
  getInputConjuje(input, index, conjuje) {
    if (conjuje) {
      if (this.lastField) {
        this.lastField.timeOutValidation = false;
      }
      this.inputsForm[index] = input;
      this.setTimeoutValidation();
    } else {
      this.setTimeoutValidation();
    }
  }

  /**
   * Retorna se há erros no formulário, de acordo com as RNs de
   * validação de cada input
   *
   * @return boolean
   */
  hasFormError(): boolean {
    // DEBUG VALIDATE INPUTS
    // let valids = [];
    // this.inputsForm.forEach(input => {
    //   valids.push(input.id + ', ' + input.valid + ', ' + input.validationValid);
    // });
    // console.clear();
    // console.log(valids);
    if (this.inputsForm.length == 0) return false;
    this.setTimeoutValidation();

    for (let input of this.inputsForm) {

      if (input == undefined) continue;

      if (input.validation) {
        if (!input.valid || !input.validationValid) {
          return true;
        }
      } else {
        if (!input.valid) {
          return true;
        }
      }
    }

    return false;
  }

  setTimeoutValidation() {
    this.lastField = this.inputsForm[this.inputsForm.length-1];
    if (this.lastField) {
      if (this.inputsForm[this.inputsForm.length-2] && this.lastField.type=='dropdown' || this.lastField.isBlock == true) {
        for (let i=2; this.lastField.type=='dropdown' || this.lastField.isBlock == true; i++) {
          this.lastField = this.inputsForm[this.inputsForm.length-i];
        }
      }
      this.lastField.timeOutValidation = true;
    }
  }
}
