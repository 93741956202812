import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'analyzing-proposal',
  templateUrl: './analyzing-proposal.component.html',
  styleUrls: ['./analyzing-proposal.component.css']
})
export class AnalyzingProposalComponent extends GlobalProperties {

  @Input()
  title: string;

  @Input()
  subtitle: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'SolicitacaoEmAnalise'
  };


  img: string;

  constructor(
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_analisandoproposta.svg';
    // } else {
    // 
    //  this.img = 'icon_an_prop.svg';
    // }
  }

  ngOnInit() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_analisandoproposta.svg';
    // } else {
    //   this.img = 'icon_an_prop.svg';
    // }

    this.appDynamicsService.callAppAdynamics(this.elementRef);

    this.img = 'icon_an_prop.svg';

    if (!this.title) {
      this.title = 'Analisando proposta …';
    }
    if (!this.subtitle) {
      this.subtitle = 'O pedido do cliente está sendo finalizado. Em breve, ele poderá emitir o cartão ou voucher. Acompanhe o pedido em Consultar propostas.';
    }
  }

}
