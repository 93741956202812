import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { Component, HostListener, Input, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheRedisService } from 'projects/commons-util/src/cache/cacheRedis.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';

@Component({
  selector: 'authorize-device',
  templateUrl: './authorize-device.component.html',
  styleUrls: ['./authorize-device.component.css']
})
export class AuthorizeDeviceComponent extends GlobalProperties implements OnInit {

  @Input() domain: string;
  deviceKey: string;
  @ViewChild("form") form: ElementRef;

  subscriprions: Subscription[] = [];
  innerWidth: any;
  web: boolean;
  img: string;
  readonly PAGE_NAME = { section: 'AutorizarDispositivo' };

  constructor(
    private dnaService: DnaService,
    private deviceService: DeviceDetectorService,
    private redisService: CacheRedisService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }
  }

  getDeviceDataEncrypted(redirect: boolean) {
    this.subscriprions.push(
      this.dnaService.get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted')
        .pipe(
          map(dataEncrypt => {
            this.redisService.post({ id: dataEncrypt.id, message: dataEncrypt.message })
              .pipe(
                map(data => {
                  if (redirect) {
                    window.location.href = this.base_path + '/activate-device';
                  } else {
                    this.callAnalytics();
                  }
                })
              )
              .subscribe();
          })
        )
        .subscribe()
    );
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  getCheckDeviceSecurity() {
    this.subscriprions.push(
      this.dnaService.get('/KeyVault/CheckDeviceSecurity', 'CheckDeviceSecurity')
        .pipe(
          map(dataAuthorization => {

            this.redisService.post({ authorization: dataAuthorization.toString() })
              .pipe(
                map(data => {
                  this.getDeviceDataEncrypted(true);
                })
              )
              .subscribe();
          })
        )
        .subscribe()
    );
  }

  importDeviceKey() {
    initLoader(innerWidth);
    if (this.deviceKey) {

      this.redisService.get()
        .pipe(
          map(dataGet => {

            const body = { deviceKeyToBeImported: this.deviceKey, token: dataGet.tokenencrypt };
            this.subscriprions.push(
              this.dnaService.post('/KeyVault/ImportDeviceKey', 'ImportDeviceKey', body)
                .pipe(
                  map(dataImport => {
                    if (dataImport && dataImport.import == 'true') {

                      this.redisService.post({ tokenencrypt: dataImport.token, id: dataImport.id })
                        .pipe(
                          map(data => {
                            this.getCheckDeviceSecurity();
                          })
                        )
                        .subscribe();
                    } else {
                      if (!this.deviceService.isDesktop()) {
                        window['handlerJs'] ? window['handlerJs'].resetDNA() : '';
                        window.location.href = this.base_path + '/activate-device';
                      }
                    }
                  })
                )
                .subscribe()
            );

          })
        )
        .subscribe();
    } else {
      removeLoader();
      this.getDeviceDataEncrypted(false);
    }
  }

  ngOnInit() {
    const bffParameters = JSON.parse(this.domain);
    this.deviceKey = bffParameters.data;
    this.analyticsService.setParameters(
      {
        id_operator: bffParameters.id_operator || '',
        id_parceiro: bffParameters.name_parceiro || '',
        id_store_operator: bffParameters.id_store_operator || '',
        id_customer: 'no_customerid',
        customer_age_range: null
      }
    );
    this.importDeviceKey();

    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }

    this.img = '03_dispositivo_nao_autorizado.svg';
  }

  ngOnDestroy() {
    this.subscriprions.forEach(s => {
      s.unsubscribe();
    });
  }

  submitButton(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'AutorizarDisptBT',
        label: 'SolicitarAutorizacao'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'AutorizarDisptBT_SolicitarAut');
  }
}
