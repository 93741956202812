import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ModalComponent } from '../../shared/modal/modal.component';
import { removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'form-capture-facial-biometry',
  templateUrl: './form-capture-facial-biometry.component.html',
  styleUrls: ['./form-capture-facial-biometry.component.css']
})

export class FormCaptureFacialBiometry extends GlobalProperties {

  @Input('inputs') inputs: string;

  @ViewChild(ModalComponent) modalComponent: ModalComponent;
  @ViewChild('form') form: HTMLFormElement;
  @ViewChild('itens') actualContent: ElementRef;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'BiometriaFacial'
  };

  frames: any;
  items: Array<any>;
  biometriaModel: any = "";
  showModal: boolean = false;
  biometriaSDKParametroModel: any;
  captureFacialBiometryViewModel: any;
  modalDescription: string = "Não foi possível capturar a biometria, por favor tente novamente.";
  customer_id: string;

  constructor(
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  getNameObject = function (obj, familia) {
    if (!isNaN(obj)) {
      return familia + '[' + obj + ']';
    }

    return familia + '.' + obj
  };

  checkObject(obj) {
    return typeof (obj) === 'object';
  }

  getKeys = function (object) {
    return Object.keys(object);
  };

  closeModal() {
    this.showModal = false;
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        console.log('biometria: ', data);
        this.customer_id = data.customer_id;
        this.setGaParameters(data);
        this.captureFacialBiometryViewModel = data;
      }
      )).subscribe(() => { this.setMetrics(); });


    // this.captureFacialBiometryViewModel = this.inputs;

    document.querySelector('form-capture-facial-biometry').setAttribute('inputs', '');
  }

  setMetrics(): void {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
    this.sendDataToAndroid();
  }

  sendDataToAndroid(): void {
    const sdtoreData = {
      partner_name: this.captureFacialBiometryViewModel.partner_name,
      operator_id: this.captureFacialBiometryViewModel.operator_id,
      store_id: this.captureFacialBiometryViewModel.store_id,
      store_name: this.captureFacialBiometryViewModel.store_name,
      customer_id: this.customer_id
    };
    this.dnaService.post('', 'operatorStoreInfo', sdtoreData, false).subscribe();
  }

  setGaParameters(bffParameters): void {
    this.analyticsService.setParameters(
      {
        id_operator: bffParameters.operator_id || '',
        id_parceiro: bffParameters.partner_name || '',
        id_store_operator: bffParameters.store_id || '',
        id_customer: bffParameters.customer_id || 'no_customerid',
        store_name: bffParameters.store_name || ''
      }
    );
  }

  /* Plug and play */
  captureBiometria() {
    console.log("bypass biometria", this.captureFacialBiometryViewModel.domains.bypass_biometry)

    if (this.all_properties['--simulador'] == 'true') {
      this.loadBiometria();
      setTimeout(() => {
        this.form.nativeElement.submit();
      }, 1500);
    } else {

      const sdkParameter = this.captureFacialBiometryViewModel.domains.androidAppVersion >= 33420 ? this.captureFacialBiometryViewModel.domains : this.captureFacialBiometryViewModel.domains.id_pessoa;
      this.dnaService.post('', 'captureBiometria', sdkParameter, false)
        .pipe(
          map(result => {
            if (this.captureFacialBiometryViewModel.domains.bypass_biometry) {
              this.biometriaModel = result.escryptedMessage;
              setTimeout(() => {
                this.form.nativeElement.submit();
              }, 1500);
            } else {
              if (result.sucess != 'SUCCESS') {
                this.modalDescription = 'Não foi possível capturar a biometria, por favor tente novamente.';
                removeLoader();
                this.showModal = true;
              } else {
                this.biometriaModel = result.escryptedMessage;
                setTimeout(() => {
                  this.form.nativeElement.submit();
                }, 1500);
              }
            }
          })
        ).subscribe();

    }
  }

  loadBiometria() {
    this.biometriaModel = '';
  }
}