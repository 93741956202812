<div class="form-body">
  <voxel-container>
      <voxel-row class="header-search" *ngIf="cards != null">
          <div class="search">
            <custom-input
              #ipt
              id="cpf_seach"
              type="cpf"
              name="cpf_seach"
              label="CPF do cliente"
              description=""
              [required]="true"
              placeholder=""
              [value]="this.cpf"
              listname=""
              options=''
              option-label=""
              tabindex="1"
              [validation]="false"
              (keyup.enter)="sendSearch(ipt)"
              (validate)="validateField($event)">
            </custom-input>
            <button class="btn-search" [disabled]="!this.validate" (click)="sendSearch(ipt)">
              <img src="{{ path_assets + 'icone_busca.svg' }}">
            </button>
            <div *ngIf="cpfError" class="cpf-error">*CPF inválido</div>
          </div>
          <div class="shadow">&nbsp;</div>
      </voxel-row>
  </voxel-container>


  <form id="formCardCancellation" asp-action="Index" asp-controller="ChooseCreditCardFamily" method="post">
      <voxel-container>

          <voxel-row>
              <voxel-item dc="12" tc="12" mc="12">
                  <h3 *ngIf="cards.length == 0 && route == 'comprovant-cards'">
                      Não foram encontrados cartões com comprovantes para consulta.
                  </h3>
                  <h3 *ngIf="cards.length == 0 && route != 'comprovant-cards'">
                      Ops, não encontramos nenhum cartão. :(
                  </h3>
                  <h1 *ngIf="cards.length >= 1">
                      Selecione o Cartão
                  </h1>
              </voxel-item>
          </voxel-row>

          <input [value]="this.route" type="hidden" name="route" id="route">
          <input [value]="this.hiddenCpf" type="hidden" name="cpf" id="cpf">
          <input [value]="this.hiddenCardSelected" type="hidden" name="idcard_selected">

          <div class="cards">
              <card-cancellation *ngFor="let card of cards"
                  [id]="card.id_card"
                  [value]="this.selected"
                  [selected]="(this.selected == card.id)"
                  [customer-name]="card['name_carrier']"
                  [card-alias]="card['name_card']"
                  [card-number]="card['number_card']"
                  [cpf]="card.cpf"
                  [flag]="card.flag_card"
                  [ownership]="card.ownership"
                  [cancelled]="card.cancelled"
                  (onClick)='getCard($event)'>
              </card-cancellation>
          </div>

          <div *ngIf="cards.length >= 1" class="button-group">
              <voxel-row>
                  <voxel-item dc="12" tc="12" mc="12">
                      <voxel-button
                          label="Continuar"
                          submit-button
                          [payload]="false"
                          class="primary-button"
                          [disabled]="!selected">
                      </voxel-button>
                  </voxel-item>
              </voxel-row>
          </div>

      </voxel-container>
    </form>
</div>
