import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'term-print-confirmation',
  templateUrl: './term-print-confirmation.component.html',
  styleUrls: ['./term-print-confirmation.component.scss']
})
export class TermPrintConfirmationComponent extends GlobalProperties implements OnInit {

  @ViewChild('form') form: ElementRef;
  @ViewChild('fieldValue') fieldValue: ElementRef;

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  /**
   * @description Objeto contendo dados do campo fields do input
   */
  fields: any = {};

  /**
   * @description Objeto contendo dados do campo domais do input
   */
  domains: any = {};

  constructor(private cryptoPipe: CryptoPipe) {
    super();
  }

  ngOnInit() {
    // this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
    //   map(data => {
    //     this.fields = data.fields[0];
    //     this.domains = data.domains;
    //     this.onClickPrintTerm();
    //   }
    // )).subscribe();

    const result = JSON.parse(this.inputs);
    this.fields = result.fields[0];
    this.domains = result.domains;

    document.querySelector('term-print-confirmation').removeAttribute('inputs');
  }

  /**
   * @description Metodo vinculado ao evento click do botão de re-imprimir termo
   */
  onClickPrintTerm(): void {

    if (this.domains && this.domains.pdf) {

      const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
      'resizable,screenX=50,screenY=50,width=850,height=1050';

      const htmlPop = '<embed width=100% height=100%'
                    + ' type="application/pdf"'
                    + ' src="data:application/pdf;base64,'
                    + encodeURI(this.domains.pdf)
                    + '"></embed>';

      const pdfWindow = window.open('', 'PDF', winparams);
      pdfWindow.document.write(htmlPop);

    } else {
      alert('pdf nao existe');
    }
  }

  /**
   * @description Metodo responsavel por setar o valor do input com base no botão clicado
   * e realizar o submit do formulario.
   */
  submitForm(value: number) {
    this.fieldValue.nativeElement.setAttribute('value', value);
    this.form.nativeElement.submit();
  }
}
