import { Ga4Service } from './../../shared/services/ga4.service';
import { DnaService } from './../../../../../projects/commons-util/src/crypto/dna.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CoreAnalyticsView } from '@app-analytics/analytics';
import { catchError, map, tap, delay } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { WebViewHandlerService } from '../../shared/services';

type StatusCopiedDetails = Record<
  'default' | 'success' | 'failed' | 'pending',
  number
>;

@Component({
  selector: 'form-warning-error',
  templateUrl: './form-warning-error.component.html',
  styleUrls: ['./form-warning-error.component.css']
})
export class FormWarningErrorComponent extends GlobalProperties {

  @Input("inputs") inputs: string;
  innerWidth: any;
  web: boolean;
  link: string;
  textButton: string;
  parse: any;
  errorMessage: any;
  img: string;

  idDispositivo: string;
  cod_error: string;
  tracer_log: string;

  statusCopiedDetails: StatusCopiedDetails = {
    default: 0,
    success: 1,
    failed: 3,
    pending: 4,
  };

  copiedDetails =
    this.statusCopiedDetails.default;

  readonly PAGE_NAME = { section: 'Erro' };

  constructor(
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private router: Router,
    private webViewHandlerService: WebViewHandlerService
  ) {
    super();
  }

  ngOnInit() {
    this.parseJson();
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }

    this.dnaService.get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted', false)
      .pipe(
        map(value => value ? this.idDispositivo = value.id : 'Não identificado.')
      )
      .subscribe();

    this.img = 'icon_algo_errado.svg';
  }

  parseJson() {
    this.parse = JSON.parse(this.inputs);
    this.link = this.base_path + decodeURI(this.parse.link_button);
    this.errorMessage = decodeURI(this.parse.error_message);
    this.textButton = decodeURI(this.parse.text_button);
    this.cod_error = decodeURI(this.parse.cod_error);
    this.tracer_log = decodeURI(this.parse.tracer_log);
    document.querySelector('form-warning-error').setAttribute('inputs', '');
    const errorDetail = this.parse.cod_error ? this.cod_error : this.errorMessage;

    this.analyticsService.trackPageLoad(
      this.PAGE_NAME,
      '',
      this.parse.cod_error ? this.cod_error : this.errorMessage,
    );
    this.ga4Service.trackPageLoad(this.PAGE_NAME, null, [{ param: 'error_detail', value: errorDetail }]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }
    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_avisoerro.svg';
    // } else {
    //   this.img = 'icon_algo_errado.svg';
    // }
  }

  onCopyDetailsToClipboard() {
    // Versão do typescript não reconhece encadeiamento opcional.
    // Apenas apartir de versões 3.7+
    // see more https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html.
    const currentNavigation = this.router.getCurrentNavigation() || ({} as any);
    const previousNavigation = currentNavigation.previousNavigation || {};
    const finalUrl = previousNavigation.finalUrl;

    const navigator: any = window.navigator;

    const parseToCopy = [
      `código do erro: ${this.cod_error}`,
      `id do dispositivo: ${this.idDispositivo}`,
      `tracer de log: ${this.tracer_log}`,
      `rota: ${finalUrl ? finalUrl.toString() : 'desconhecida'}`,
      `ocorrido em: ${new Date().toUTCString()}`,
      `agente: ${navigator.userAgent}`
    ].join('\n');

    this.webViewHandlerService.handleObservable({
      method: "copyToClipboard",
      args: [parseToCopy],
      fallback: () => {
        return from(
          navigator.clipboard.writeText(parseToCopy)
        ).pipe(
          map(() => true),
          catchError(() => of(false))
        );
      },
    })
    .pipe(
      tap(() => {
        this.copiedDetails = this.statusCopiedDetails.success;
      }),
      catchError(() => {
        this.copiedDetails = this.statusCopiedDetails.failed;
        return of(false);
      }),
      delay(4000),
      tap(() => {
        this.copiedDetails = this.statusCopiedDetails.default;
      })
    )
    .subscribe();
  }
}

