import { Ga4Service } from './../../shared/services/ga4.service';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'form-card-cancellation',
  templateUrl: './form-card-cancellation.component.html',
  styleUrls: ['./form-card-cancellation.component.css']
})
export class FormCardCancellationComponent extends FormDynamicComponent {

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  /**
   * @description Armazena a rota que será utilizada
   * pelo backend, será enviada via input hidden
   */
  route: string;

  /**
   * @description Armazena o id do
   * último cartão selecionado
   */
  selected: string;

  /**
   * @description Armazena o cpf consultado na busca
   */
  cpf: string;

  /**
   * @description Armazenna no input hidden o id do
   * card selecionado ou null, caso um cpf seja pesquisado.
   */
  hiddenCardSelected: string;

  /**
   * @description Armazena no input hidden o cpf pesquisado
   * ou null, caso selecionado um card para exclusão
   */
  hiddenCpf: string;

  /**
   * @default Controla a exibição do erro do campo de
   * busca de CPF
   */
  cpfError: boolean;

  /**
   * @description Armazena o array de objetos do tipo card
   * que serão renderizados no template e selecionados para
   * posterior exclusão
   */
  cards: any[] = [];

  validate: boolean;

  readonly PAGE_NAME = {
    section: 'SolicitarCancelamentoCartao',
    page: 'ListaDeCartoesAtivos'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.hiddenCardSelected = 'null';
    this.hiddenCpf = 'null';
    this.cpf = '';
    this.selected = '';
    this.cpfError = false;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt')
      .pipe(
        map(data => {
          this.route = data.route;
          this.cards = data.cards;
          this.cpf = data.cpf;
          this.hiddenCpf = data.cpf;
          this.saveAnalyticsDataId(data);
        })
      ).subscribe(() => this.callAnalytics());

    // var data = JSON.parse(this.inputs)
    // this.route = data.route;
    // this.cards = data.cards;
    // this.cpf = data.cpf;
    // this.hiddenCpf = data.cpf;

    document.querySelector('form-card-cancellation').setAttribute('inputs', '');
  }

  saveAnalyticsDataId(data: any) {
    console.log('> backend response: ', data);
    this.analyticsService.setParameters({ id_customer: 'no_customerid', store_name: data.store_name || '', customer_age_range: null });
    if (data.partner_name) {
      this.analyticsService.setParameters({ id_parceiro: data.partner_name});
    }
    if (data.store_id) {
      this.analyticsService.setParameters({ id_store_operator: data.store_id});
    }
    if (data.operator_id) {
      this.analyticsService.setParameters({ id_operator: data.operator_id});
    }
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  /**
   * @description Método executado para receber do
   * componente filho (cardCancellation) o cartão
   * selecionado via Output
   * @param ev
   */
  getCard(ev) {
    if (ev.selected) {
      this.selected = ev.id;
      this.hiddenCpf = 'null';
      this.hiddenCardSelected = this.selected;
    } else {
      this.selected = '';
      this.hiddenCardSelected = 'null';
    }
  }

  /**
   * Verifica se o formulário está valido
   * @param event
   */
  validateField(event) {
    this.validate = event.valid;
    if (!this.validate && event.value && event.value.length == 14) {
      this.cpfError = true;
    } else {
      this.cpfError = false;
    }
  }

  /**
   * Método utilizado para o botão de lupa no template,
   * apenas faz um post no formCardCancellation
   */
  sendSearch(input: any): void {

    if (this.validate) {
      initLoader(innerWidth);
      this.cpf = input.value;
      this.hiddenCpf = input.value;
      this.hiddenCardSelected = 'null';

      var cpfField = document.getElementById('cpf');
      cpfField.setAttribute('value', input.value);

      let form = <HTMLFormElement>document
        .getElementById('formCardCancellation');
      form.submit();
    }
  }

}
