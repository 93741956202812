import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { DnaService } from '../../../../../projects/commons-util/src/crypto/dna.service';
import { GlobalProperties } from '../../shared/global-properties';
import { CacheRedisService } from 'projects/commons-util/src/cache/cacheRedis.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'app-capture-device-infos',
  templateUrl: './capture-device-infos.component.html',
  styleUrls: ['./capture-device-infos.component.css'],
})
export class CaptureDeviceInfosComponent extends GlobalProperties
  implements OnInit, OnDestroy {
  @ViewChild('submitButton') submitButton: ElementRef;

  subscriprions: Subscription[] = [];
  innerWidth: number;
  showError: boolean = false;
  img: string;


  constructor(
    private dnaService: DnaService,
    private redisService: CacheRedisService,
    private deviceService: DeviceDetectorService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service
  ) {
    super();
  }

  readonly PAGE_NAME = {
    section: 'PreLogin',
  };

  checkUrlBase() {
    this.dnaService
      .get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted')
      .pipe(
        map((dataEncrypt) => {
          this.redisService
            .getBaseUrl(dataEncrypt.id)
            .pipe(
              map((responseurl) => {
                if (location.href != responseurl.url) {
                  this.setCookie("sessionId", dataEncrypt.id, 1)
                  
                  location.href = responseurl.url
                } else {
                  this.initDna();
                }
              })
            )
            .subscribe()
        })
      )
      .subscribe();
  }


  ngOnInit() {
    const { version: appVersion } = require('package.json');
    console.log(appVersion);

    this.checkUrlBase();

    this.analyticsService.trackPreLoginPage();
    this.innerWidth = window.innerWidth;
    this.img = 'icon_algo_errado.svg';
  }

  ngOnDestroy() {
    this.subscriprions.forEach((s) => {
      s.unsubscribe();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_avisoerro.svg';
    // } else {
    //   this.img = 'icon_algo_errado.svg';
    // }
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  initDna() {
    this.dnaService
      .post('/Device/SetEnvironment', 'SetEnvironment', {
        message: this.all_properties['--environment'],
      })
      .pipe(
        map((data) => {
          if (!data) {
            throwError('Nao foi possivel definir o ambiente');
            this.callAnalyticsEvent('Nao foi possivel definir o ambiente');
          } else {
            this.dnaService
              .get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted')
              .pipe(
                map((dataEncrypt) => {
                  if (dataEncrypt) {
                    this.redisService
                      .setAws(dataEncrypt.id)
                      .pipe(
                        map((setAwsData) => {
                          console.log(setAwsData);

                          if (setAwsData.urlAws != "")
                            location.href = setAwsData.urlAws;
                          else {
                            this.redisService
                              .get()
                              .pipe(
                                map((dataRedis) => {
                                  const deleteDNA = dataRedis.deleteDna;
                                  // console.log("Iniciando captura dispositivo")
                                  if (deleteDNA === 'true') {
                                    this.dnaService
                                      .get('/Device/resetDNA', 'resetDNA')
                                      .pipe(
                                        map((dataReset) => {
                                          this.redisService
                                            .delete('deleteDna')
                                            .pipe(
                                              map((dataDelete) => {
                                                if (!dataDelete) {
                                                  throwError(
                                                    'Nao foi possivel resetar o DNA',
                                                  );
                                                  this.callAnalyticsEvent(
                                                    'Nao foi possivel resetar o DNA',
                                                  );
                                                } else {
                                                  this.getCheckDeviceSecurity();
                                                }
                                              }),
                                            )
                                            .subscribe();
                                        }),
                                      )
                                      .subscribe();
                                  } else this.getCheckDeviceSecurity()
                                }),
                              )
                              .subscribe();
                          }
                        }),
                      )
                      .subscribe();
                  } else {
                    console.log(3, Date.now());
                    this.showError = true;
                    this.callAnalyticsEvent(
                      'Não foi possível carregar os dados do device',
                    );
                  }
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }

  getCheckDeviceSecurity() {
    // console.log("Inicio getCheckDeviceSecurity");
    this.subscriprions.push(
      this.dnaService
        .get('/KeyVault/CheckDeviceSecurity', 'CheckDeviceSecurity')
        .pipe(
          map((data) => {
            this.redisService
              .post({ authorization: data.toString() })
              .pipe()
              .subscribe(() => this.getDeviceDataEncrypted());
          }),
        )
        .subscribe(),
    );
    // console.log("Fim getCheckDeviceSecurity");
  }

  getDeviceDataEncrypted() {
    // console.log("Inicio getDeviceDataEncrypted");

    this.subscriprions.push(
      this.dnaService
        .get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted')
        .pipe(
          map((dataEncrypt) => {
            if (dataEncrypt) {
              this.redisService
                .post({ id: dataEncrypt.id, message: dataEncrypt.message })
                .pipe()
                .subscribe(() => this.submitButton.nativeElement.click())
            } else {
              this.showError = true;
              this.callAnalyticsEvent(
                'Não foi possível carregar os dados do device',
              );
            }
          }),
        )
        .subscribe(),
    );
    // console.log("Fim getDeviceDataEncrypted");
  }

  private callAnalyticsEvent(error: string): void {
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Erro',
      action: 'ErroTelaPreLogin',
      label: error,
    });
  }
}
