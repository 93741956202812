import {
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { HostListener } from "@angular/core";
import { initLoader } from "../globalFunctions/loader";
import { DnaService } from "../crypto/dna.service";
import { map, retry, catchError } from "rxjs/operators";
import { CacheRedisService } from "../cache/cacheRedis.service";
import { throwError } from "rxjs";
import { HEADER_OFFSET } from "@angular/core/src/render3/interfaces/view";

@Directive({
  selector: "[submit-button]"
})
export class SubmitButtonDirective {
  @Input("noimage") noImage: boolean;
  @Input("payload") payload: boolean;
  @Output()
  submitedFunction: EventEmitter<any> = new EventEmitter<any>();

  form: HTMLFormElement;
  innerWidth: any;
  path_assets: string;
  formSubmited: boolean;

  constructor(
    private el: ElementRef,
    private dnaService: DnaService,
    private redisService: CacheRedisService
  ) {
    this.innerWidth = window.innerWidth;
    this.path_assets = document.documentElement.style.getPropertyValue(
      "--assets-path"
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    // console.log("TESTEEEEEEEE");
    if (this.payload === undefined) {
      this.payload = true;
    }
  }

  ngAfterViewInit() {
    const button = this.el.nativeElement.querySelector("button")
      ? this.el.nativeElement.querySelector("button")
      : (this.el.nativeElement as HTMLElement);
    this.form = button.closest("form") as HTMLFormElement;
    this.formSubmited = false;
    if (!this.form) {
      throw new Error("Submit directive need a form parent");
    }

    this.addEventClick(button);
    this.addEventEnter(button);
  }

  addEventClick(button: HTMLElement) {
    button.addEventListener("click", () => {
      initLoader(this.innerWidth, this.noImage);

      // console.log(this.payload);

      if (this.payload) {
        this.validPayload(this.form);
      } else if (this.submitedFunction.observers.length != 0) {
        this.submitedFunction.emit(true);
      } else {
        this.formSubmited = true
        this.form.submit();
      }
    });
  }

  addEventEnter(button: HTMLElement) {
    button.addEventListener("keypress", e => {
      if (e.key === "Enter") {
        initLoader(this.innerWidth, this.noImage);

        if (this.payload) {
          this.validPayload(this.form);
        } else if (this.submitedFunction.observers.length != 0) {
          this.submitedFunction.emit(true);
        } else {
          this.formSubmited = true
          this.form.submit();
        }
      }
    });
  }

  async validPayload(form: HTMLFormElement) {
    let voxelInputs = form.querySelectorAll("input");
    let validInputs = [];
    let name, value;

    voxelInputs.forEach(input => {
      if (input.name.includes(".id") || input.name === "id") {
        name = input.value;
      }
      if (input.name.includes(".value") || input.name === "value") {
        value = input.value;
      }
      if (name && value) {
        validInputs.push({ key: name, value: value });
        name = "";
        value = "";
      }
    });

    var objectLog = {
      type: "submit-button",
      location: window.location.href,
      inputs: validInputs
    }
    
    this.dnaService.postCaptureLogs(objectLog, false, false, objectLog);

    this.redisService
      .get()
      .pipe(
        map(data => {
          let token: any = data.tokenencrypt;
          let body = {};
          if (validInputs.length > 2) {
            let index1, index2;
            while (index1 === index2) {
              index1 = Math.floor(Math.random() * validInputs.length);
              index2 = Math.floor(Math.random() * validInputs.length);
            }
            body = {
              message: token,
              inputs: validInputs.filter(
                (input, i) => i === index1 || i === index2
              )
            };
          } else {
            body = { message: token, inputs: validInputs };
          }

          // console.log(body);

          if (token) {
            this.dnaService
              .post("/Cryptography/TokenEncrypt", "TokenEncrypt", body)
              .pipe(
                retry(1),
                catchError(error => this.processError(error)),
                map(dataEncrypt => {
                  this.redisService
                    .post({ tokenCompleteCrypt: dataEncrypt.message })
                    .pipe(
                      map(data => {

                        if (this.submitedFunction.observers.length != 0) {
                          this.submitedFunction.emit(true);
                        } else if(!this.formSubmited) this.form.submit();

                        this.formSubmited = true;
                      })
                    )
                    .subscribe();
                })
              )
              .subscribe();
          } else {
            this.dnaService.postCaptureLogs(
              "token-id is missing",
              true,
              true,
              "token-id is missing"
            );
          }
        })
      )
      .subscribe();
  }

  processError(err) {
    let message = "";

    if (err.error && err.error.content) {
      message = err.error.message;
    } else if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    this.dnaService.postCaptureLogs(message, true, true, message);
    return throwError(message);
  }
}
