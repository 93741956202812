import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, ElementRef, Renderer2, Input, HostListener, ChangeDetectorRef, } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ModalComponent } from '../../shared/modal/modal.component';
import { removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';



interface TermDebitoMinimoContasProp{
  agencia: string,
  conta: string ,
  dac: string
}
interface TermDebitoMinimo{
  id: null|number|string ,
  contas: TermDebitoMinimoContasProp[]
}

type PageInfoParams = Record<'section'|'page',string>

@Component({
  selector: 'term-summary',
  templateUrl: './term-summary.component.html',
  styleUrls: ['./term-summary.component.css']
})
export class TermSummaryComponent extends GlobalProperties {

  @Input() native: any = null;
  @Input('inputs') inputs: string;

  @ViewChild('sign') signModal: ModalComponent;
  @ViewChild('insurance') insuranceModal: ModalComponent;

  route: string;
  taskid: string;
  cards: any;

  signature: any;
  idTipoConteudo: any;
  parsedInputs: null | Record<string,any> = null;

  teste = null;
  teste2 = [];
  textModal: any;
  modalTitle: any;
  newHtml = false;
  disabled = false;
  items: Array<any>;
  showModal: boolean = false;

  /* Domains values */
  domainTermService: Array<any>
  domainTermInsurance: Array<any>
  domainTermInformation: Array<any>
  domainTermCard: Array<any>
  domainTermDebitoMinimoContas: TermDebitoMinimoContasProp[]

  servicesIcons = [
    { icon: 'icon-itaufonts_sms_mensagem_chat', id: 'adesao_sms' },
    { icon: 'icon-itaufonts_exclamacao', id: 'adesao_avaliacao_emergencial_credito' },
    { icon: 'icon-itaufonts_fatura', id: 'adesao_fatura_digital' }
  ];

  insuranceIcons = [
    { icon: 'icon-itaufonts_seguro_cartao', id: 'adesao_cartao_protegido' },
    { icon: 'icon-itaufonts_seguro_renda', id: 'adesao_conta_paga' }
  ];

  customer_id: string;

  pageInfo:PageInfoParams = {
    section: 'NovoCartao',
    page: 'TermoEResumo'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) {
    super();
    this.items = [{ id: '1', content: 'Ok', a11yLabel: 'Ok' }];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 1000) {
      this.newHtml = true;
    } else {
      this.newHtml = false;
    }
  }

  ngOnInit() {
    if (window.innerWidth > 1000) {
      this.newHtml = true;
    } else {
      this.newHtml = false;
    }
    this.idTipoConteudo = '35';

    if(environment.type=='local'){

      /**
       * deixa ASYNC para manter a
       *  mesma proposta quando estiver em ambiente
       *  HOM e PROD
       */
      timer(1000).pipe(take(1)).subscribe(()=>{
        this.parsedInputs = JSON.parse(this.inputs);
        this.setFields();
      })
    } else {
      this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
        map(data => {
          this.parsedInputs = data;
          this.setFields();
        }
        )).subscribe();
    }

  }

  /**
   * atualiza valores da var `pageInfo`
   */
  private updatePageInfo(params: Partial<PageInfoParams>){
    this.pageInfo = { ...this.pageInfo, ...params }
  }

  private firePageLoadEvent(paramsPage: PageInfoParams) {
    this.analyticsService.trackPageLoad(paramsPage, this.customer_id);
    this.ga4Service.trackPageLoad(paramsPage, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  setFields() {
    this.taskid = this.parsedInputs.taskId;
    this.route = this.parsedInputs.route;
    this.customer_id = this.parsedInputs.customer_id;


    if(!this.parsedInputs.domains) {
      console.error('Not found param `domains`', this.parsedInputs)
    }

    this.parsedInputs.domains.termServices.forEach(obj => {
      const workingObj = obj;
      this.servicesIcons.filter(obj => {
        if (obj.id == workingObj.id) {
          workingObj['icon'] = obj.icon;
        }
      });
    });
    this.domainTermService = this.parsedInputs.domains.termServices || []



    this.parsedInputs.domains.termInsurance.forEach(obj => {
      const workingObj = obj;
      this.insuranceIcons.filter(obj => {
        if (obj.id == workingObj.id) {
          workingObj['icon'] = obj.icon;
        }
      });
    });
    this.domainTermInsurance = this.parsedInputs.domains.termInsurance || []

    this.parsedInputs.domains.termInformation.forEach(element => {
      if (element.id == 'id_cartao_servicos') {
        element['icon'] = 'icon-itaufonts_cartoes';
        element['margintop'] = true;
      } else {
        element['icon'] = 'icon-itaufonts_seguros';
        element['margintop'] = false;
      }

      if (this.parsedInputs.domains.termInformation && this.parsedInputs.domains.termInformation[this.parsedInputs.domains.termInformation.length - 1] == element) {
        element['radiusbottom'] = true;
      } else {
        element['radiusbottom'] = false;
      };

    });
    const termDebitoMinimo = this.parsedInputs.domains && this.parsedInputs.domains.termDebitoMinimo || []
    const accounts = termDebitoMinimo.reduce((acc, data) => {
      const { contas = [] } = data
      return [...acc, ...contas]
    }, [] as TermDebitoMinimoContasProp[])

    this.domainTermDebitoMinimoContas = accounts

    this.domainTermInformation = this.parsedInputs.domains.termInformation || []
    this.domainTermCard = this.parsedInputs.domains.termCard || []



    const page = accounts.length ? this.pageInfo.page + 'ComDebitoMinimo' : this.pageInfo.page
    this.updatePageInfo({ page })
    this.firePageLoadEvent(this.pageInfo);
    this.cdr.detectChanges();

  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  closeModal() {
    this.showModal = false;
  }

  checkBox() {
    this.disabled = !this.disabled;
  }

  validFields() {
    return !(this.disabled && this.signature ? true : false);
  }

  openModal(title, text) {
    this.insuranceModal.display = true;
    this.textModal = text;
    this.modalTitle = title;
    window.scrollTo(0, 0);
  }

  callSignature() {
    if (this.signature) {
      console.log('Assinatura ja capturada');
      return;
    }

    if (this.all_properties['--simulador'] == 'true') {
      this.signature = 'assinatura';
    } else {

      let json = this.parsedInputs.domains.termSignature.infos;
      this.dnaService.post('', 'captureSignature', JSON.stringify(json), false)
        .pipe(
          map(resultSignature => {

            if (!resultSignature) {
              removeLoader();
              this.showModal = true;
            } else {
              this.signature = resultSignature;
            }

          })
        ).subscribe();
    }
  }

  onClickTag(): void {
    this.analyticsService.trackClick(this.pageInfo,
      {
        category: 'Clique',
        action: 'ContinuarLiberarCartaoBT',
        label: 'CartaoLiberado'
      });
    this.ga4Service.trackEvent('card_released', this.pageInfo);
  }
}
