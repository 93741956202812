import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { Select2OptionData } from 'ng-select2';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'professional-data',
  templateUrl: './professional-data.component.html',
  styleUrls: ['./professional-data.component.css']
})
export class ProfessionalDataComponent extends FormDynamicComponent {

  @Input("inputs") inputs: string;
  @ViewChild('form') form: ElementRef;

  route: string;
  taskid: string;
  fields: any;

  validateFields: Array<any> = [];

  list: Array<Select2OptionData> = [
    { id: '1', text: '1' },
    { id: '2', text: '2' },
    { id: '3', text: '3' },
    { id: '4', text: '4' },
    { id: '5', text: '5' },
  ];

  customer_id: string;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'DadosProfissionais'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
  }

  ngOnInit() {
    if (environment.type == "local") {
      timer(1000).subscribe(()=> {
        this.setFields(JSON.parse(this.inputs));
        console.log(JSON.parse(this.inputs));
      });

      return;
    }

    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.setFields(data);
      }
      )).subscribe(() => { this.getMetrics(); });

    document.querySelector('professional-data').setAttribute('inputs', '');
  }

  getMetrics() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  setFields(obj: any) {
    this.fields = obj.fields;
    this.route = obj.route;
    this.taskid = obj.taskId;
    this.customer_id = obj.customer_id || '';

    this.fields.forEach(field => {
      this.validateFields.push(!field.required);
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  trackClick(): void {
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'DadosProfissionaisBT',
        label: `${this.ocupacao}_${this.profissao}_${this.renda}`
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME, `${this.ocupacao}_${this.profissao}_${this.renda}`);
  }

}
