import { deepCloneFromObject } from '../deep-clone-from-object';
import { typeOf } from '../type-of';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Obj = Record<string, any>;

const merge = (target: Obj, source: Obj) => {
  Object.keys(source).forEach(key => {
    if (typeOf(source[key]) === 'object') {
      target[key] = target[key] || {};
      merge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  });
};

/**
 * Mesclar 2 objetos
 * @param target Objeto alvo
 * @param source Objeto que será mesclado ao objeto alvo
 * @returns Retorna novo objeto
 */
export const mergeObjects = <Target = Obj, Source = Obj>(
  target: Target,
  source: Source
) => {
  const output = deepCloneFromObject(target);

  merge(output, deepCloneFromObject(source));

  return output as Target & Source;
};
