import { Ga4Service } from './../../shared/services/ga4.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { ModalComponent } from '../../shared/modal/modal.component';

import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'card-cancellation-data',
  templateUrl: './card-cancellation-data.component.html',
  styleUrls: ['./card-cancellation-data.component.scss']
})
export class CardCancellationDataComponent extends GlobalProperties implements OnInit {

  @Input("inputs") inputs: string;

  @ViewChild('form') form: HTMLFormElement;
  @ViewChild(ModalComponent) modalComponent: ModalComponent;

  signature: string;

  fields: any;
  items: Array<any>;
  showModal = false;

  innerWidth: any;

  readonly PAGE_NAME = {
    section: 'SolicitarCancelamentoCartao',
    page: 'DadosDaSolicitacao'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.items = [{ id: '1', content: 'Ok', a11yLabel: 'Ok' }];
  }

  ngOnInit(): void {
    this.parseJSON(this.inputs);
  }

  parseJSON(string: string): void {
    this.cryptoPipe.transform(string, 'decrypt').pipe(
      map(data => {
        this.setFields(data);
      }
      )).subscribe(() => this.callAnalytics());

    // if (string) {
    //   this.setFields(JSON.parse(string));
    // }
  }

  setFields(obj: any): void {
    this.fields = obj;

    document.querySelector('card-cancellation-data').setAttribute('inputs', '');
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad( this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  onClickContinuar(): void {
    this.modalComponent.display = true;
    window.scrollTo(0, 0);
  }

  modalBtnEvent($event: any): void {
    if ($event === 'btnContinuarCancelamento') {
      this.analyticsService.trackClick(this.PAGE_NAME, {
        category: 'Clique',
        action: 'CancelamentoCartaoBT',
        label: 'ContinuarCancelamento'
      });
      this.ga4Service.trackEvent('click', this.PAGE_NAME, 'ContinuarCancelamento');
      this.callSignature();
    }
  }

  callSignature() {
    if (this.signature) {
      //console.log('Assinatura ja capturada')
      return;
    }

    if (this.all_properties['--simulador'] == 'true') {
      this.signature = 'assinatura';
      initLoader(innerWidth)
      document.querySelector('#inputSignature').setAttribute('value', this.signature);

      this.form.nativeElement.submit();
    } else {

      if (!window['handlerJs']) {
        removeLoader();
        this.showModal = true
      }
      else {
        let json = [];
        json.push(`Cancelamento do cartão: ${this.fields.name_card} | ${this.fields.number_card}`);
        this.signature = window['handlerJs'].captureSignature(JSON.stringify(json));
        if (!this.signature) {
          removeLoader();
          this.showModal = true;
        } else {
          initLoader(innerWidth)
          document.querySelector('#inputSignature').setAttribute('value', this.signature);
          this.form.nativeElement.submit();
        }
      }
    }
  }

  closeModal() {
    this.showModal = false;
  }
}
