import { NgModule } from '@angular/core';
import { CpfFuncionalDirective } from './cpf-funcional/cpf-funcional.directive';
import { CustomHeaderDirective } from './custom-header/svg-header.directive';
import { ReadOnlyDirective } from './read-only/read-only.directive';
import { SubmitButtonDirective } from './submit-button/submit-button.directive';
import { LoaderButtonDirective } from './loader-button/loader-button.directive';
import { CryptoPipe } from './crypto/crypto.pipe';
import { HttpClientModule } from '@angular/common/http';
import { AutoValidateFieldDirective } from './auto-validate-field/auto-validate-field.directive';

@NgModule({
  declarations: [
    CpfFuncionalDirective,
    CustomHeaderDirective,
    SubmitButtonDirective,
    LoaderButtonDirective,
    AutoValidateFieldDirective,
    ReadOnlyDirective,
    CryptoPipe
  ],
  imports: [
    HttpClientModule
  ],
  exports: [
    CpfFuncionalDirective,
    CustomHeaderDirective,
    SubmitButtonDirective,
    LoaderButtonDirective,
    AutoValidateFieldDirective,
    ReadOnlyDirective,
    CryptoPipe
  ],
  providers: [ CryptoPipe ]
})
export class CommonsUtilModule { }
