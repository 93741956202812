import { Component, OnInit, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'form-choose-store',
  templateUrl: './form-choose-store.component.html',
  styleUrls: ['./form-choose-store.component.css']
})
export class FormChooseStoreComponent extends GlobalProperties {

  @Input() inputs: any;
  stores: any;
  selectedStore: any;
  disabled: boolean = true;
  searchOn: boolean = false;
  match: any;
  renderedStores = [];
  term: string;
  innerWidth: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    super();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.inputs = JSON.parse(this.inputs);
    this.stores = this.inputs.content.lojas;
    this.renderedStores = this.stores;
    document.querySelector('form-choose-store').setAttribute('inputs', '');
  }

  getSelectedCard(e){
    this.selectedStore = e;
    this.disabled = false;
  }

  allowSearch(){
    this.searchOn = !this.searchOn;
    if(this.searchOn == true){
      setTimeout(function(){
          document.getElementById('id_searchBox').focus();
      }, 150);
    }

  }

  searchStores(){
    this.renderedStores = [];

    this.renderedStores = this.stores.filter(element =>
      element.name_loja
      .toUpperCase()
      .includes(this.term.toUpperCase()));
  }


}
