<form class="form-choose-product" id="formChooseProduct" asp-action="Index" asp-controller="ChooseCreditCardFamily"
  method="post">

  <voxel-container>

    <input [value]="this.inputsParsed?.route" type="text" name="route" hidden>
    <input [value]="this.inputsParsed?.taskId" type="text" name="taskid" hidden>
    <input [value]="this.selectedProduct?.id" type="text" id="fields[0].value" name="fields[0].value" hidden>
    <input [value]="this.selectedProduct?.id" type="text" id="fields[0].id" name="fields[0].id" hidden>

    <modal-dialog title="Continuar proposta" display="true" overlay="true" btn1="Sim" type1="submit" class1="freeline"
      [link1]="inputsParsed?.domains.rotaContinuarProposta" btn2="Não, criar novo cartão" type2="submit"
      class2="freeline" [link2]="inputsParsed?.domains.rotaNovaProposta">
      <div subt>
        Existe uma proposta em andamento para este CPF. Deseja continuar com esta proposta?
      </div>
      <div body class="modal">
        <span style="display: block;" class="icon-itaufonts_cartao"></span>
        <div class='modal-elements'>
          <small style="color: #969290;font-size: 12px;font-weight: 300;line-height: 20px;">nome</small>
          <div style="color: #252220;font-size: 14px;line-height: 20px;white-space: nowrap;">
            {{this.inputsParsed?.domains.produto}} {{this.inputsParsed?.domains.bandeira}}
            {{this.inputsParsed?.domains.variante}}</div>
        </div>
        <br>
        <div class="modal-elements">
          <small style="color: #969290;font-size: 12px;font-weight: 300;line-height: 20px;">anuidade</small>
          <div style="color: #252220;font-size: 14px;line-height: 20px;white-space: nowrap;">
            {{this.inputsParsed?.domains.anuidade}}</div>
        </div>
      </div>
    </modal-dialog>

    <div [ngSwitch]="productFamily?.length" class="form-choose-product">
      <div *ngSwitchCase="0">

        <div class="container-img">
          <img src="{{ path_assets + img }}">
        </div>

        <div class="header">Sem ofertas no momento :(</div>

        <div class="body">Não existe ofertas de produtos para o CPF informado.</div>

        <a [link]="base_path + '/home'" loader-button>
          <voxel-button class="home" id="button-principal" label="Home"></voxel-button>
        </a>

      </div>
      <div *ngSwitchDefault>

        <voxel-row>
          <voxel-item dc='12' mc='12' tc='12'>
            <p class="title">Selecione a opção que o cliente deseja:</p>
          </voxel-item>
        </voxel-row>

        <div class="scroll">
          <voxel-row>
            <voxel-item dc='12' mc='12' tc='12' *ngFor='let product of productFamily'>
              <div class='teste'>
                <selectable-card [id]='this.product.id_familia_produto' (getCard)='getSelectedCard($event)'
                  [title]='this.product.nome_familia_produto'
                  [selected]='this.selectedProduct?.id == this.product.id_familia_produto'>
                </selectable-card>
              </div>
            </voxel-item>
          </voxel-row>
        </div>

        <voxel-row>
          <voxel-item dc='12' mc='12' tc='12'>
            <voxel-button [disabled]='!enabled' class="primary" label='Próxima' submit-button></voxel-button>
          </voxel-item>
        </voxel-row>

      </div>

    </div>

  </voxel-container>
</form>
