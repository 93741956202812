import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { map } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal/modal.component';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { DeviceDetectorCustomService } from 'projects/commons-util/src/crypto/device-detector-custom.service';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss']
})
export class VoucherDetailComponent extends FormDynamicComponent implements OnInit {

  /**
   * @description Armazena a string recebida via input
   * que conterá o JSON a ser parseado
   */
  @Input() inputs: string;

  @ViewChild('voucher') voucherModal: ModalComponent;

  /**
   * @description Objeto contendo dados da proposta
   */
  proposal: any = {};

  /**
   * @description Array contendo os status validos
   */
  private validStatus = ['em análise', 'recusado', 'finalizado'];

  dropdown: Array<any>;
  innerWidth: any;
  keyPdf: string;
  device: string;
  textDevice: string;
  customer_id: string;

  readonly PAGE_NAME = {
    section: 'Voucher',
    page: 'DetalheDaProposta'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private deviceDetectorCustomService: DeviceDetectorCustomService,
    private ga4Service: Ga4Service,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.device = this.deviceDetectorCustomService.deviceDetector();
    this.textDevice = this.device === "mobile" ? "Utilizar" : "Imprimir"
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map((data) => {
        console.log('voucher: ', data);
        this.setGaParameters(data);
        this.sendDataToAndroid(data);
        this.proposal = data;
        this.customer_id = data.customer_id || '';
      }
      )).subscribe(
        () => {
          this.callAnalytics();
          this.dropdown = [];
          for (let index = 1; index <= this.proposal.qtdMax; index++) {
            this.dropdown.push({ key: index, value: index });
          }
          // console.log(this.proposal)
        }
      );

    // this.proposal = JSON.parse(this.inputs);
    // this.dropdown = [];
    // for (let index = 1; index <= this.proposal.qtdMax; index++) {
    //   this.dropdown.push({ key: index, value: index });
    // }

    document.querySelector('voucher-detail').removeAttribute('inputs');
  }

  setGaParameters(bffParameters): void {
    this.analyticsService.setParameters(
      {
        id_operator: bffParameters.operator_id || '',
        id_parceiro: bffParameters.partner_name || '',
        id_store_operator: bffParameters.store_id || '',
        id_customer: bffParameters.customer_id || 'no_customerid',
        store_name: bffParameters.store_name || ''
      }
    );
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Objeto Visto',
        action: 'StatusPropostaVoucher',
        label: this.proposal.status_proposta
      }
    );
  }

  sendDataToAndroid(bffParameters): void {
    const sdtoreData = {
      partner_name: bffParameters.partner_name,
      operator_id: bffParameters.operator_id,
      store_id: bffParameters.store_id,
      store_name: bffParameters.store_name,
      customer_id: this.customer_id
    };
    this.dnaService.post('', 'operatorStoreInfo', sdtoreData, false).subscribe();
  }

  /**
   * Retorna a classe CSS de acordo com o valor
   * do status da proposta
   *
   * @param value status da proposta
   * @return Objeto contendo nome da classe e um valor boleano true se a classe for valida
   */
  getClassByStatus(value: string): object {
    value = value ? value.toLowerCase() : '';

    const obj = {
      analisado: this.validStatus.includes(value),
      recusado: this.validStatus.includes(value),
      'em-andamento': this.validStatus.includes(value)
    };

    return obj;
  }


  /**
   * @description Metodo vinculado ao evento click do botão de re-imprimir termo
   */
  onClickPrintTerm(): void {
    if (this.proposal.pdf) {
      this.voucherModal.display = true;
    } else {
      window.location.href = '/voucher-error';
    }
  }

  onSubmit(form: HTMLFormElement, ipt: HTMLInputElement) {
    initLoader(this.innerWidth);
    console.clear();
    this.dnaService.get('/Device/VoucherKey', 'voucherKey')
      .pipe(
        map(data => {
          this.keyPdf = data.keyValue;
        })
      ).subscribe(
        () => {
          ipt.setAttribute('value', this.keyPdf);
          form.submit();
        }
      );
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Clique',
        action: 'BT_Voucher',
        label: 'Imprimir_Voucher'
      });
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'emitir_voucher');
  }

  public onClickHome(): void {
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Clique',
      action: 'BT_Voucher',
      label: 'Home'
    });
  }

}
