<div class="header-home">
  <img [src]="partnerLogo">
  <div class="title">
    <div class="circle">
      <small class="name">{{ initials }}</small>
    </div>
    <span>
      <div class="operator">{{ operdador.name_operator }}</div>
      <div class="store">{{ operdador.name_store_operator }} ({{operdador.id_store_operator}})</div>
    </span>
  </div>
  <!-- <div class="menu_t">
    <span class="icon-itaufonts_menu_hamburguer" (click)="sidebarComponent.toggleMenu()"></span>
  </div> -->
  <div class="menu_d">
    <!-- <span class="rotate90 icon-itaufonts_outros" (click)="sidebarComponent.toggleMenu()"></span> -->
    <span style="padding: 0 16px;"><small style="border: 0.5px solid #dbdbdb;"></small></span>
    <a [link]="base_path + '/logoff'" loader-button style="cursor: pointer;">
      <span class="rotate90 icon-itaufonts_resgate"></span>
    </a>
  </div>
</div>
<div class="home-container">
  <div class="containers">
    <div class="header-container">
      <div class="title">
        Seja bem vindo :)
      </div>
      <small class="subtitle">Todas as funcionalidades pensadas para você.</small>
    </div>
    <group-menu
      *ngFor="let menu of listMenu; let i = index;"
      [array]="menu.itemsGroup"
      [name]="menu.name"
      [type]="menu.type">
      <div [ngClass]="{'margin-bottom': i + 1 < listMenu.length}"></div>
    </group-menu>
  </div>
</div>

<!-- <sidebar [inputs]="menu"></sidebar> -->
