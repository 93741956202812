<voxel-container>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <div class="header-content">
        <span class="icon-itaufonts_fatura icon first-icon"></span>
        <h2>detalhe do dispositivo</h2>
      </div>
    </voxel-item>
  </voxel-row>

  <div class="device-detail__status">
    <voxel-row class=" spacing__margin-bottom-xs">
      <voxel-item dc="12" tc="12" mc="12">
        <div class="label">
          Número de Inventário
        </div>

        <div class="value">
          {{ fields?.inventoryNumber }}
        </div>

      </voxel-item>

      <voxel-item dc="6" tc="12" mc="12">
        <div class="label">
          Processador
        </div>
        <div class="value">
          {{ fields?.processorModelName }}
        </div>
      </voxel-item>

      <voxel-item dc="6" tc="12" mc="12">
        <div class="label">
          Mac Andress
        </div>
        <div class="value">
          {{ fields?.macAdress }}
        </div>
      </voxel-item>


      <voxel-item dc="6" tc="12" mc="12">
        <div class="label">
          Memoria RAM
        </div>

        <div class="value">
          {{ fields?.ramMemoryAmount }}
        </div>
      </voxel-item>

      <voxel-item dc="6" tc="12" mc="12">
        <div class="label">
          Sistema Operacional
        </div>

        <div class="value">
          {{ fields?.operationalSystemVersionName }}
        </div>
      </voxel-item>


      <voxel-item dc="12" tc="12" mc="12">
        <div class="label">
          Espaço em disco
        </div>

        <div class="value">
          {{ fields?.diskSize }}
        </div>
      </voxel-item>
      
      <voxel-item *ngIf="fields?.versionApp != null" dc="12" tc="12" mc="12">
        <div class="label">
          Versão do aplicativo:
        </div>

        <div class="value">
          {{ fields?.versionApp }}
        </div>
      </voxel-item>

    </voxel-row>
  </div>

  <div class="btn-content">
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <a [link]="base_path + '/home'" loader-button>
          <voxel-button id="btnBack" label="Home"></voxel-button>
        </a>
      </voxel-item>
    </voxel-row>
  </div>

</voxel-container>
