<form
  id="formCollect"
  method="post"
  #form
>
  <div class="spacing__margin-top-xs form-container">
    <voxel-container>    

      <voxel-row *ngIf="hasPermission">
        <voxel-item
          [mc]="12"
          [tc]="12"
          [dc]="12"
        >

        <div class="header">Por favor, confirmar os dados abaixo para a recuperação de senha</div>

        <div class="body">Nome: {{name}}</div>
        <div class="body" style="margin-bottom: 1rem;">CPF: {{cpf | mask: '000.000.000-00'}}</div>


        </voxel-item>
      </voxel-row>

      <voxel-row *ngIf="!hasPermission">
        <voxel-item
          [mc]="12"
          [tc]="12"
          [dc]="12"
        >
        <div class="header">CPF inválido ou sem autorização para redefinição de senha.</div>
        </voxel-item>
      </voxel-row>
    </voxel-container>

    <div
    class="spacing__margin-top-xs spacing__margin-bottom-xs button-container"
  >
    <voxel-container>
      <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          <a [href]="base_path + '/temporary-password'" class="link-home" style="width: 100%;">
            <voxel-button *ngIf="hasPermission" (click)="trackClick()" loader-button label="Gerar nova senha" id="button-new-pwd"></voxel-button>
          </a>
          <a *ngIf="!hasPermission" [href]="base_path + '/master-password-reset'" class="link-home" style="width: 100%;">
            <voxel-button loader-button label="Solicitar nova senha" id="button-new-pwd"></voxel-button>
          </a>
          <a [href]="base_path + '/home'" class="link-home" style="width: 100%;">
            <voxel-button loader-button label="Home" id="button-home"></voxel-button>
          </a>
        </voxel-item>
      </voxel-row>
    </voxel-container>
  </div>
  </div>
</form>