import { Ga4Service } from './../../shared/services/ga4.service';
import { Component, ChangeDetectorRef, Input } from '@angular/core';

import { FormNewCardComponent } from '../../new-card/form-new-card/form-new-card.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'client-id',
  templateUrl: './client-id.component.html',
  styleUrls: ['./client-id.component.css']
})
export class ClientIdComponent extends FormNewCardComponent {

  /**
 * Entrada de valor de CPF
 */
  @Input("description_text") descriptionText: string

  readonly PAGE_NAME = {
    section: 'SolicitarCancelamentoCartao',
    page: 'IdentificacaoDoCliente'
  };

  constructor(
    public cdr: ChangeDetectorRef,
    public cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super(cdr, cryptoPipe);
  }

  ngOnInit() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  onChangedValue(): void {
    const divError: HTMLElement = document.querySelector('.custom-error');
    if (divError != null) {
      divError.style.display = 'block';
    }
  }

}
