import { Injectable } from '@angular/core';
import { Observable, of, timer, from } from 'rxjs';
import { finalize, tap, map, take, catchError, switchMap, delay } from 'rxjs/operators';

import { initLoader, removeLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CryptoPipe } from '../crypto/crypto.pipe';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private base_path: string
  constructor(private _httpClient: HttpClient,
    private _cryptoPipe: CryptoPipe) {
    this.base_path = document.documentElement.style.getPropertyValue("--base-path")
  }


  /**
   * Faz uma requisição POST para o bff validar o input
   *
   * @return boolean - true se o campo for valido ou false se nao for valido
   */
  validateField(id_campo: string, valor_campo: any, tipo_campo: any): Observable<any> {
    // initLoader(innerWidth);

    const inputValidate = {
      'id_campo': id_campo,
      'valor_campo': valor_campo,
      'tipo_campo': tipo_campo
    };

    return from(this._cryptoPipe.transform(valor_campo, 'encrypt', false))
      .pipe(
        take(1),
        tap(value => inputValidate.valor_campo = value.message),
        switchMap(() => this.postValidateField(JSON.stringify(inputValidate)))
      );

      // return of(true)
      //   .pipe(
      //     switchMap(() => this.postValidateField(JSON.stringify(inputValidate)))
      //   );
  }

  private postValidateField(inputValidate): Observable<boolean> {
    // console.log(inputValidate);
    return this._httpClient.post(`${this.base_path}/validate-field`, inputValidate, this.httpOptions)
      .pipe(
        take(1),
        //tap(value => console.log(value)),
        map((value: any) => value),
        catchError(() => window.location.href = '/warning-error')
      );

    // return of(true)
    //   .pipe(
    //     delay(2000),
    //     take(1),
    //     finalize(() => removeLoader())
    //   )
  }
}
