import { Component, ElementRef, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { SidebarComponent } from '../../core/sidebar/sidebar.component';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends GlobalProperties {

  @ViewChild(SidebarComponent)
  sidebarComponent: SidebarComponent;

  @Input('inputs') inputs: string;
  @Input('menu') menu: string;

  listMenu: any[] = [];
  partnerLogo: string;
  initials: string;
  popupInfos: any;

  width: string;

  operdador = {
    name_operator: '',
    name_store_operator: '',
    id_store_operator: ''
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
    this.partnerLogo = this.path_assets + this.all_properties['--partner-logo'];
  }

  ngOnInit() {
    const { version: appVersion } = require('package.json');
    console.log(appVersion);
    this.parseJSON();
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  parseJSON() {
    this.cryptoPipe.transform(this.inputs, 'decrypt')
      .pipe(
        map(data => {
          this.listMenu = data.listMenu;
          this.operdador.name_operator = data.name_operator;
          this.operdador.name_store_operator = data.name_store_operator;
          this.operdador.id_store_operator = data.id_store_operator;
          this.popupInfos = data.popup_infos;
          this.initials = this.getInitials(this.operdador.name_operator);
          this.callOperatorForm();
          this.analyticsService.setParameters(
            {
              id_operator: data.id_operator || '',
              id_parceiro: data.name_parceiro || '',
              id_store_operator: data.id_store_operator || '',
              id_customer: 'no_customerid',
              store_name: data.name_store_operator || '',
              customer_age_range: null
            }
          );
        })
      ).subscribe(() => this.callAnalytics());

    // const input = JSON.parse(this.inputs);
    // this.listMenu = input.listMenu;
    // this.operdador.name_operator = input.name_operator;
    // this.operdador.name_store_operator = input.name_store_operator;
    // this.operdador.id_store_operator = input.id_store_operator;
    // this.popupInfos = input.popup_infos;
    // this.initials = this.getInitials(this.operdador.name_operator);
    // this.callOperatorForm();
    // this.callAnalytics();

    document.querySelector('home-page').setAttribute('inputs', '');
  }

  callAnalytics(): void {
    this.ga4Service.trackPageLoad({ section: 'Home' }, 'no_customerid');
    this.analyticsService.trackPageLoad({ section: 'Home' }, 'no_customerid');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private callOperatorForm(): void {
    if (this.all_properties['--simulador'] != 'true') {
      this.dnaService.post('', 'userResearchForm', this.popupInfos, false).subscribe();
    }
  }

  private getInitials(name: string): string {
    let initials: any = name.split(' ').filter(item => item !== '');
    if (initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
  }

}
