import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppDynamicsService {

  constructor() { }

  callAppAdynamics(elementRef: ElementRef): void {
    setTimeout(() => {
      this.callAppAdynamics2(elementRef);
    }, 2000);
  }

  callAppAdynamics2(elementRef: ElementRef): void {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '//cdn.appdynamics.com/adrum/adrum-22.2.0.3616.js';
    elementRef.nativeElement.appendChild(s);

    const s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.charset = 'UTF-8';
    s2.innerHTML =  `window["adrum-start-time"] = new Date().getTime();
    (function(config){
      config.appKey = "GR-AAB-BHW";
      config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
      config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
      config.beaconUrlHttp = "http://gru-col.eum-appdynamics.com";
      config.beaconUrlHttps = "https://gru-col.eum-appdynamics.com";
      config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
      config.maxUrlLength = 512;
    })(window["adrum-config"] || (window["adrum-config"] = {}));
    `;
    elementRef.nativeElement.appendChild(s2);
  }

}
