<div class="warning-error">
  <div class="main-header" *ngIf='this.web === false'>
    <p>Validação Biometria</p>
  </div>

  <div class="body">
    <card-header title="BIOMETRIA FACIAL" [subtitle]=this.text [message]=this.msg [svg]="this.path_assets + 'icone_biometria.svg'">
    </card-header>
  </div>

  <div class="btnGroup">
    <a [link]="base_path + '/logoff'" loader-button>
      <voxel-button id="button-exit" label="Sair"></voxel-button>
    </a>
  </div>

</div>