<div class="main-header"  *ngIf='this.web === false'>
  <p>Autorizar dispositivo</p>
</div>

<form action="registry-device" method="post" #form>
  <div class="container-img">
    <!-- <img *ngIf="this.innerWidth < 768" src="{{ path_assets + 'icon_disp_aut.svg' }}">
    <img *ngIf="this.innerWidth > 767" src="{{ path_assets + 'ilustra_naoautorizado.svg' }}"> -->
    <img src="{{ path_assets + img }}">
  </div>

  <div class="header">Dispositivo não autorizado :(</div>

  <div class="body">Solicite a autorização do mesmo para usar o aplicativo e seus serviços.</div>

  <voxel-container>
    <voxel-row class="btnGroup">
      <voxel-item dc="12" tc="12" mc="12">
        <voxel-button submit-button [payload]="false" id="button-solicitacao" class="btnAutorizacao" label="Solicitar autorização"
          (click)="submitButton()"
        ></voxel-button>
        <a [link]="base_path + '/logoff'" loader-button>
          <voxel-button class="home" id="button-principal" label="Sair"></voxel-button>
        </a>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</form>

