import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'small-card-template',
  templateUrl: './small-card-template.component.html',
  styleUrls: ['./small-card-template.component.css']
})
export class SmallCardTemplateComponent implements OnInit {

  @Input("name") name: string;
  @Input("icon") icon: string;
  @Input("value") value: string;
  @Input("label") label: string;

  constructor() { }

  ngOnInit() {
  }

}
