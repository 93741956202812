import { NgModule } from '@angular/core';
import { CardHeaderComponent } from './card-header.component';
import { VoxelGridModule } from '@voxel/internet';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [CardHeaderComponent],
  imports: [
    VoxelGridModule,
    AngularSvgIconModule,
    CommonModule
  ],
  exports: [CardHeaderComponent]
})
export class CardHeaderModule { }
