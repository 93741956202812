import { Component, Input, ViewContainerRef, OnInit } from '@angular/core';

@Component({
   selector: 'section',
   template: ''
})
export class SectionComponent {
    @Input() active: boolean;

    constructor(public viewContainerRef: ViewContainerRef) {}
}
