import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voxel-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.css']
})
export class DropdownListComponent implements OnInit {

  idSelectElement = Math.random().toString(36).substr(2, 9);

  disableDropdown: string = "all";

  @Input()
  placeholder: string = '';

  @Input()
  id: string;

  @Input()
  label: string;

  @Input()
  options: Array<any>;

  @Input()
  selected: string;

  @Input()
  selectedLabel: string;

  @Input()
  mutavel: boolean;

  @Input()
  search: boolean;

  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = false;

  @Output()
  optionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  inputBlur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  inputFocusOut: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
    // this.optionChange.emit(this);
  }

  ngAfterViewInit() {
    this.createSelect();
    this.optionChange.emit(this);

  }

  onBlur(e) {
    this.inputBlur.emit(e);
  }

  onFocusOut(e) {
    this.inputFocusOut.emit(e);
  }

  createSelect(newOptions?) {
    // console.log(this.options);

    if (this.id) {
      var self = this;
      const element: HTMLElement = document.getElementById(this.id).parentElement as HTMLElement;
      const emitter = this.optionChange;

      if (element) {
        let search;
        const selElmnt = element.querySelector('select');
        const selectItems = document.createElement('DIV');
        selectItems.setAttribute('class', 'select-items  select-hide');

        if (this.mutavel) {
          const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === 'attributes') {
                selectItems.innerHTML = '';

                for (let i = 0; i < selElmnt.length; i++) {
                  const option = document.createElement('OPTION') as HTMLOptionElement;
                  option.innerHTML = selElmnt.options[i].innerHTML;
                  option.value = i + '';
                  option.addEventListener('click', (e) => {

                    for (let j = 0; j < selectItems.children.length; j++) {
                      selectItems.children[j].classList.remove('selected');
                    }

                    option.classList.add('selected');
                    selElmnt.selectedIndex = Number(option.value);
                    self.selected = option.innerHTML;
                    this.selectedLabel = selElmnt.options[i].text;
                    this.selected = selElmnt.options[i].value;
                    emitter.emit(this);

                    const overlay = element.querySelector('.overlay') as HTMLElement;
                    overlay.click();
                  });

                  selectItems.appendChild(option);
                }
              }
            });

            if (this.search) {
              search = document.createElement('INPUT') as HTMLInputElement;
              search.placeholder = 'Pesquisar';
              search.classList.add('search');
              selectItems.prepend(search);

              search.addEventListener('click', (e) => {
                e.stopPropagation();
              });

              search.addEventListener('input', (e) => {
                const input = e.target as HTMLInputElement;
                selectItems.querySelectorAll('option').forEach(opt => {
                  if (!opt.text.toLocaleLowerCase().includes(input.value.toLocaleLowerCase())) {
                    opt.classList.add('select-hide');
                  } else {
                    opt.classList.remove('select-hide');
                  }
                });
              });
            }
          });

          observer.observe(selElmnt, { attributes: true });
        } else {
          for (let i = 0; i < selElmnt.length; i++) {
            const option = document.createElement('OPTION') as HTMLOptionElement;
            option.innerHTML = selElmnt.options[i].innerHTML;
            option.value = i + '';
            option.addEventListener('click', (e) => {

              for (let j = 0; j < selectItems.children.length; j++) {
                selectItems.children[j].classList.remove('selected');
              }

              option.classList.add('selected');
              selElmnt.selectedIndex = Number(option.value);
              self.selected = option.innerHTML;
              this.selectedLabel = selElmnt.options[i].text;
              this.selected = selElmnt.options[i].value;
              emitter.emit(this);

              const overlay = element.querySelector('.overlay') as HTMLElement;
              overlay.click();
            });

            /**
             * Filtra e seleciona opções de Cartões a emitir em Additional-info
             * ao alterar o Tipo de emissão
             */
            if (newOptions){
              if (newOptions === "OUTROS") {
                option.value === "2" ? selectItems.appendChild(option) : '';
                this.selected = "1";
                this.disabled = true;
                this.disableDropdown = "none";
                // this.onBlur(this);
              } else {
                this.disabled = false;
                this.disableDropdown = "all";
                selectItems.appendChild(option);
              }
            } else {
              selectItems.appendChild(option);
            }
          }

          if (this.search) {
            search = document.createElement('INPUT') as HTMLInputElement;
            search.placeholder = 'Pesquisar';
            search.classList.add('search');
            selectItems.prepend(search);

            search.addEventListener('click', (e) => {
              e.stopPropagation();
            });

            search.addEventListener('input', (e) => {
              const input = e.target as HTMLInputElement;
              selectItems.querySelectorAll('option').forEach(opt => {
                if (!opt.text.toLocaleLowerCase().includes(input.value.toLocaleLowerCase())) {
                  opt.classList.add('select-hide');
                } else {
                  opt.classList.remove('select-hide');
                }
              });
            });
          }
        }

        element.appendChild(selectItems);

        window.addEventListener('click', (e) => {
          selectItems.setAttribute('class', 'select-items  select-hide');
        });

        element.querySelector('.overlay').addEventListener('click', (e) => {
          e.stopPropagation();
          if (selectItems.getAttribute('class') === 'select-items  select-show') {
            const selects = Array.from(document.getElementsByTagName('select'));
            selects.forEach(el => {
              if (el.parentElement.querySelector('.select-items')) {
                el.parentElement.querySelector('.select-items').setAttribute('class', 'select-items  select-hide');
              }
            });
          } else {
            const selects = Array.from(document.getElementsByTagName('select'));
            selects.forEach(el => {
              if (el.parentElement.querySelector('.select-items')) {
                el.parentElement.querySelector('.select-items').setAttribute('class', 'select-items  select-hide');
              }
            });
            selectItems.setAttribute('class', 'select-items  select-show');
            if (this.search) {
              search.focus();
            }
          }
        });
      }
    }
  }

  /**
   *
   * @param valueInput
   */
  onChange(valueInput) {
    let valueSelect = valueInput !== null && valueInput !== undefined ? valueInput.toString() : null;
    for (let i = 0; i < this.options.length; i++) {
      this.options[0].value = null;
      let value = this.options[i].value !== null && this.options[i].value !== undefined ? this.options[i].value.toString() : '';
      if (value === valueSelect) {
        this.selected = this.options[i].value;
        this.optionChange.emit(this);
        break;
      }
    }
  }

  /**
   * Recarrega as opções de Cartões a emitir
   * baseado no Tipo de emissão selecionado
   *
   * @param newOptions
   */
  reloadOptions(newOptions) {
    const element: HTMLElement = document.getElementById(this.id).parentElement as HTMLElement;
    element.querySelector('.select-items').remove();
    this.createSelect(newOptions);
  }
}
