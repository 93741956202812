<div class="card-header">
  <voxel-container>
    <voxel-row>      
      <voxel-item tc="12" dc="12" mc="12">
     
      <img src="{{ path_assets + 'icon_fotodoc.svg' }}" class="icon">
        
      </voxel-item>
      
      <voxel-item tc="12" dc="12" mc="12">
        <p>{{ this.title }}</p>
      </voxel-item>  
      
      <voxel-item tc="12" dc="12" mc="12">
        <label>{{ this.subtitle }}</label>
      </voxel-item>
    </voxel-row>
  </voxel-container>  
</div>
