import { Injectable } from '@angular/core';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';


@Injectable({
  providedIn: 'root'
})
export class Ga4Service {

  constructor(
    private analyticsViewService: ItauDigitalAnalyticsView,
    private dnaService: DnaService
  ) {}

  public trackPageLoad(pageName: any, customerid?: string, customList:Record<string,unknown>[] = []): void {
    const coreInfo = this.getCoreInfo();

    const paramPageLoad = {
      analytics: {
        event_name: 'screen_view',
        rule: 'Interaction',
        parameters: {
          custom_path: ['OC/LG/NCC/Mova', pageName.section, pageName.page].filter(Boolean).join('/'),
          implementation_team: 'Canais_Exclusivos:GameOfMova',
          partner_name: coreInfo.id_parceiro,
          operator_id: coreInfo.id_operator,
          store_id: coreInfo.id_store_operator,
          store_name: coreInfo.store_name || 'no_storename',
          customer_id: customerid || coreInfo.id_customer,
          client_age_group: coreInfo.customer_age_range
        }
      }
    };

    customList.forEach(custom => {
      Object.assign(paramPageLoad.analytics.parameters, custom)
    })

    try {
      this.track(paramPageLoad);
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics ScreeView', pageName.page, error);
    }
  }

  public trackEvent(eventName: string, pageName: any, detail?: string, customList: Record<string, unknown>[] = []): void {

    const paramPageLoad = {
      analytics: {
        event_name: eventName,
        rule: 'Interaction',
        parameters: {
          custom_path: ['OC/LG/NCC/Mova', pageName.section, pageName.page].filter(Boolean).join('/'),
          implementation_team: 'Canais_Exclusivos:GameOfMova',
          detail
        }
      }
    };

    customList.forEach(custom=>{
      Object.assign(paramPageLoad.analytics.parameters, custom)
    })

    try {
      this.track(paramPageLoad);
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics Event', pageName.page, error);
    }
  }

  private sendLog(msg: string, page: string, error): void {
    const objectLog = {
      message: msg,
      page,
      error
    };
    this.dnaService.postCaptureLogs('errorGA4', false, false, objectLog);
  }

  public getCoreInfo(): any {
    if (!localStorage.getItem('core_info')) {
      return {
        id_operator: '',
        id_parceiro: '',
        id_store_operator: '',
        store_name: '',
        id_customer: '',
        customer_age_range: '',
      };
    }
    return JSON.parse(localStorage.getItem('core_info'));
  }


  private track(params:any){
    /**
     *
     * TODO: ignorado envio de evento referente ao GA4. Vamos testar se a divergência
     *  de dados no GA é referente a execução dupla (ga4&gau) usando a lib SDK Analytics.
     *  Tínhamos observado que a lib não é feita para executar disparo iguais seguidos.
     */
    // const eventName = (params.analytics||{}).event_name;
    // this.analyticsViewService.track(params);
    // console.log(`[analytics] fired GA4 ${eventName} event`, params);
  }

}
