<div class="form-address" *ngIf="this.fields">
  <voxel-container>
    <voxel-row>
      <voxel-item
        *ngFor="let field of this.fields; let i = index;"
        [id]="field.id + '-item'"
        [tc]="this.getColValue(field.id, 'tc')"
        [dc]="this.getColValue(field.id, 'dc')"
        [mc]="this.getColValue(field.id, 'mc')"
        [grid-col]="this.getGridPosition(field.id)">

        <ng-container *ngIf="readOnly(i); else elseTemplate">
          <custom-input
            [id]="field.id"
            [type]="field.type"
            [name]="field.type"
            [label]="field.label"
            [description]="field.description"
            [required]="field.required"
            [placeholder]="field.placeholder"
            [value]="field.value"
            [listname]="field.listname"
            [options]='field.dropdownValues'
            option-label="key"
            [flag-cep]="this.flagCep"
            (onBlur)="getInput($event, i)"
            (validate)="getInput($event, i)"
            (cepSearch)="searchCEP($event)"
            [search]="field?.search"
            [min]="field?.tamanho_minimo"
            [max]="field?.tamanho_maximo"
            [is-block]="field.isBlock"
            [validation]="field.validation_api"
            read-only          
          ></custom-input>
        </ng-container>
        <ng-template #elseTemplate>
          <custom-input
            [id]="field.id"
            [type]="field.type"
            [name]="field.type"
            [label]="field.label"
            [description]="field.description"
            [required]="field.required"
            [placeholder]="field.placeholder"
            [value]="field.value"
            [listname]="field.listname"
            [options]='field.dropdownValues'
            option-label="key"
            [flag-cep]="this.flagCep"
            (onBlur)="getInput($event, i)"
            (validate)="getInput($event, i)"
            (cepSearch)="searchCEP($event)"
            [search]="field?.search"
            [min]="field?.tamanho_minimo"
            [max]="field?.tamanho_maximo"
            [is-block]="field.isBlock"
            [validation]="field.validation_api">          
        </custom-input>
        </ng-template>
      </voxel-item>
    </voxel-row>
  </voxel-container>
</div>
