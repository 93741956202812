import { catchError } from 'rxjs/operators';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import { MonoTypeOperatorFunction, Observable } from 'rxjs';

/**
 * Operador RXJS que é emitido quando houver uma captura de erro.
 *  **Não manipula erros do observável**
 */
export const error =
  <T>(callback: (e: any) => void): MonoTypeOperatorFunction<T> =>
  (source: Observable<T>) =>
    source.pipe(
      catchError(e => {
        callback(e);
        throw e;
      })
    );
