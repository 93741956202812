import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent extends GlobalProperties {

  @Input("inputs") inputs: string;
  @Input("width") width: string;

  itens: any[];
  display: boolean;
  innerWidth: any;
  scrollY: any;

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.width = '0%';
  }

  ngOnInit() {    
  }

  ngAfterViewInit() {
    this.parseJSON();
  }

  parseJSON() {
    if (this.inputs) {
      this.itens = JSON.parse(this.inputs);
    }

    if (this.itens) {      
      this.itens.forEach(element => {       
        if(element.query_parameters)
          element.route = element.route + "?" + element.query_parameters;
      })
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  toggleMenu() {
    this.innerWidth = window.innerWidth;
    this.scrollY = window.scrollY;
    // console.log(this.innerWidth)
    if (this.innerWidth < 1024) {
      if (this.width === '0%') {
        document.documentElement.querySelector('body').classList.add('noscroll');
        this.width = '100%';
        setTimeout(() => {
          this.display = true;
        }, 150);
      } else {
        document.documentElement.querySelector('body').classList.remove('noscroll');
        this.display = false;
        setTimeout(() => {
          this.width = '0%';
        }, 150);
      }
    } else {
      if (this.width === '0%') {
        document.documentElement.querySelector('body').classList.add('noscroll');
        this.width = '40%';
        setTimeout(() => {
          this.display = true;
        }, 150);
      } else {
        this.display = false;
        document.documentElement.querySelector('body').classList.remove('noscroll');
        setTimeout(() => {
          this.width = '0%';
        }, 150);
      }
    }
  }

  close() {
    const sidebar = document.querySelector('.menu-container') as HTMLElement;
    sidebar.style.transition = 'none';
    this.display = false;
    this.width = '0%';
  }

}
