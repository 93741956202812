import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { GlobalProperties } from '../../shared/global-properties';
import { CustomHeaderDirective } from 'projects/commons-util/src/custom-header/svg-header.directive';

@Component({
  selector: 'custom-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [CustomHeaderDirective]
})
export class HeaderComponent extends GlobalProperties {

  @ViewChild(SidebarComponent)
  sidebarComponent: SidebarComponent;

  @ViewChild('content', { read: TemplateRef })
  actualContent: TemplateRef<any>;

  @ViewChild(CustomHeaderDirective)
  customHeader: CustomHeaderDirective;

  @Input('logo') logo: boolean;
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;
  @Input('percent') percentTrack: number;
  @Input('search') search: boolean;
  @Input('removeicon') removeIcon: boolean;
  @Input('arraysearch') arraysearch: any[];
  @Input('backbutton') backButton: boolean;
  @Input('sidebarmenu') sidebarmenu: string;

  leftIcon: any;
  rightIcon: any;
  logoIcon: any;
  innerWidth: any;
  parsedSidebarMenu: any;

  result: any[];

  icons = [
    { icon: 'icon-itaufonts_filtro', a11yLabel: ' ' }
  ];

  constructor(private el: ElementRef) {
    super();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.result = Object.assign([], this.arraysearch);
    this.adjustTitleSize(this.title);

    if (this.sidebarmenu) {
      this.parsedSidebarMenu = JSON.parse(this.sidebarmenu);
    };

    if (this.logo) {
      this.logoIcon = this.path_assets + this.all_properties['--partner-logo'];
    } else if (this.backButton) {
      this.leftIcon = 'icon-itaufonts_seta';
    } else {
      this.leftIcon = null;
    };

    if (this.search) {
      this.rightIcon = 'icon-itaufonts_busca_consulta';
    } else if(this.sidebarmenu && this.parsedSidebarMenu.length != 0){
      this.rightIcon = 'icon-itaufonts_menu_hamburguer';
    };

    if (this.removeIcon) {
      this.rightIcon = '';
    };
  }

  ngAfterViewInit() {
    this.customHeader.setElements();
  }

  onInputChange(input: any) {
    input = input.item.clearValue;
    this.result = this.arraysearch.filter(a => a.cpf.toLowerCase().replace(/[^A-Z0-9]+/ig, '').indexOf(input.toLowerCase().replace(/[^A-Z0-9]+/ig, '')) !== -1);
    if (input == null || input === '') {
      this.result = Object.assign([], this.arraysearch);
    }
  }

  /**
   * Diminui as divs de action e do botão do voltar do Voxel Header
   * para permitir títulos com tamanhos maiores
   *
   * @param title
   * @return string
   */
  adjustTitleSize(title: string) {

    if (title.length < 20) return;

    let divRight = document.getElementsByClassName('header__actions')[0];
    let divLeft = document.getElementsByClassName('header__back')[0];

    divRight.setAttribute('style', 'width: 3.5rem;');
    divLeft.setAttribute('style', 'width: 3.5rem;');
  }

}
