<form id="formCardCancellationData" method="post" asp-action="Index" asp-controller="CancellationRequestData" #form>
  <voxel-container>
    <voxel-row>
      <voxel-item dc="12" tc="12" mc="12">
        <div class="header-content">
          <span class="icon-itaufonts_full_cartao icon first-icon"></span>
          <h2>detalhe do cartão</h2>
        </div>
      </voxel-item>
    </voxel-row>

    <div class="card-review__status">
      <voxel-row class="spacing__margin-bottom-xs">

        <voxel-item dc="12" tc="12" mc="12">
          <div class="label">
            nome do cartão
          </div>

          <div class="value">
            {{ fields?.name_card }}
          </div>
        </voxel-item>

        <voxel-item dc="6" tc="12" mc="12">
          <div class="label">
            número do cartão
          </div>

          <div class="value">
            {{ fields?.number_card }}
          </div>
        </voxel-item>

        <voxel-item dc="6" tc="12" mc="12">
          <div class="label">
            bandeira
          </div>

          <div class="value">
            {{ fields?.flag_card }}
          </div>
        </voxel-item>

        <voxel-item dc="6" tc="12" mc="12">
          <div class="label">
            nome do titular
          </div>

          <div class="value">
            {{ fields?.name_carrier }}
          </div>
        </voxel-item>

        <voxel-item dc="6" tc="12" mc="12">
          <div class="label">
            CPF
          </div>

          <div class="value">
            {{ fields?.cpf | mask: '000.000.000-00' }}
          </div>
        </voxel-item>

      </voxel-row>
    </div>

    <div class="btn-content">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <voxel-button id="btnSubmit" label="Continuar" (click)="onClickContinuar()"></voxel-button>
        </voxel-item>

        <voxel-item dc="12" tc="12" mc="12">
          <a [link]="base_path + '/home'" loader-button>
            <voxel-button id="btnBack" label="Home"></voxel-button>
          </a>
        </voxel-item>
      </voxel-row>
    </div>
    <input id="inputSignature" type="text" name="inputs[0].value" hidden>
    <input type="text" name="inputs[0].id" value="assinatura" hidden>

  </voxel-container>
</form>

<voxel-dialog [(showDialog)]="showModal" [items]="items" description=" Erro ao abrir a assinatura digital."
  (dialogLinkClick)="closeModal()">
</voxel-dialog>

<modal-dialog title="Reforce ao cliente que" display="true" overlay="true" (event-btn)="modalBtnEvent($event)"
  input1="btnContinuarCancelamento" btn1="Continuar cancelamento" type1="close" class1="solid" input2="btnHome"
  btn2="Home" type2="submit" class2="outline" [link2]="base_path + '/home'">
  <div body class="modal">
    <div class='modal-elements'>
      <p>&bull; Quando o cartão titular é cancelado, os cartões adicionais também serão.</p>
    </div>
    <div class="modal-elements">
      <p>&bull; Caso haja algum serviço e/ou assinatura, com débito neste cartão, será necessário substituir a forma
        de
        pagamento.</p>
    </div>
    <div class="modal-elements">
      <p>&bull; Se houver saldo devedor, o cliente deverá continuar pagando as faturas dos meses seguintes, até que
        sejam
        quitadas todas as parcelas de compras ou financiamentos que ele tenha contratado ou demais valores devidos.
      </p>
    </div>
    <div class="modal-elements">
      <p>&bull; Os pontos dos eventuais programas de recompensas serão automaticamente cancelados, salvo se o
        titular
        possuir
        outro cartão ativo participante do Programa.</p>
    </div>
  </div>
</modal-dialog>