<div class="modal" [hidden]="!display" [style.top.px]="height">
  <div *ngIf="title" class="modal-title">
    {{ title }}
  </div>
  <div class="modal-subtitle">
    <ng-content select="[subt]"></ng-content>
  </div>
  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="modal-button">

    <ng-container *ngIf="link1; else elseTemplate1">
      <a [href]="link1">
        <voxel-button *ngIf="btn1 && type1=='submit'" (click)="onClick(true, input1)" type="submit" label="{{btn1}}" [ngClass]="class1" loader-button></voxel-button>
        <voxel-button *ngIf="btn1 && type1=='close'" (click)="onClick(false, input1)" label="{{btn1}}" [ngClass]="class1"></voxel-button>
        <voxel-button *ngIf="btn1 && type1=='buttonOnly'" (click)="callButton()" label="{{btn1}}" [ngClass]="class1"></voxel-button>
      </a>
    </ng-container>
    <ng-template #elseTemplate1>
      <voxel-button *ngIf="btn1 && type1=='submit'" (click)="onClick(true, input1)" type="submit" label="{{btn1}}" [ngClass]="class1"></voxel-button>
      <voxel-button *ngIf="btn1 && type1=='close'" (click)="onClick(false, input1)" label="{{btn1}}" [ngClass]="class1"></voxel-button>
      <voxel-button *ngIf="btn1 && type1=='buttonOnly'" (click)="callButton()" label="{{btn1}}" [ngClass]="class1"></voxel-button>
    </ng-template>

    <ng-container *ngIf="link2; else elseTemplate2">
      <a [href]="link2">
        <voxel-button *ngIf="btn2 && type2=='submit'" (click)="onClick(true, input2)" type="submit" label="{{btn2}}" [ngClass]="class2" loader-button></voxel-button>
        <voxel-button *ngIf="btn2 && type2=='close'" (click)="onClick(false, input2)" label="{{btn2}}" [ngClass]="class2"></voxel-button>
        <voxel-button *ngIf="btn2 && type2=='buttonOnly'" (click)="callButton()" label="{{btn2}}" [ngClass]="class2"></voxel-button>
      </a>
    </ng-container>
    <ng-template #elseTemplate2>
      <voxel-button *ngIf="btn2 && type2=='submit'" (click)="onClick(true, input2)" type="submit" label="{{btn2}}" [ngClass]="class2"></voxel-button>
      <voxel-button *ngIf="btn2 && type2=='close'" (click)="onClick(false, input2)" label="{{btn2}}" [ngClass]="class2"></voxel-button>
      <voxel-button *ngIf="btn2 && type2=='buttonOnly'" (click)="callButton()" label="{{btn2}}" [ngClass]="class2"></voxel-button>
    </ng-template>

    <ng-container *ngIf="link3; else elseTemplate3">
      <a [href]="link3">
        <voxel-button *ngIf="btn3 && type3=='submit'" (click)="onClick(true, input3)" type="submit" label="{{btn3}}" [ngClass]="class3" loader-button></voxel-button>
        <voxel-button *ngIf="btn3 && type3=='close'" (click)="onClick(false, input3)" label="{{btn3}}" [ngClass]="class3"></voxel-button>
        <voxel-button *ngIf="btn3 && type3=='buttonOnly'" (click)="callButton()" label="{{btn3}}" [ngClass]="class3"></voxel-button>
      </a>
    </ng-container>
    <ng-template #elseTemplate3>
      <voxel-button *ngIf="btn3 && type3=='submit'" (click)="onClick(true, input3)" type="submit" label="{{btn3}}" [ngClass]="class3"></voxel-button>
      <voxel-button *ngIf="btn3 && type3=='close'" (click)="onClick(false, input3)" label="{{btn3}}" [ngClass]="class3"></voxel-button>
      <voxel-button *ngIf="btn3 && type3=='buttonOnly'" (click)="callButton()" label="{{btn3}}" [ngClass]="class3"></voxel-button>
    </ng-template>

  </div>
  <input type="hidden" name="btn-modal-value" [value]="this.value">
</div>

<div *ngIf="overlay && display" class="overlay"></div>
