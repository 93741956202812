import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { ModalComponent } from '../../shared/modal/modal.component';
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';

@Component({
  selector: 'proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.css']
})
export class ProposalDetailComponent extends GlobalProperties {

  @Input("inputs") inputs: string;
  @ViewChild('form') form: HTMLFormElement;
  @ViewChild(ModalComponent) modalComponent: ModalComponent;

  proposal: any;
  statusClass: string;
  showModal = false;
  customer_id: string;
  idproposta: string;

  modalConfig = {
    title: '',
    content: '',
    primaryButtonLabel: '',
    secondaryButtonLabel: '',
    buttonAction: (e) => ({} as any),
  };

  readonly PAGE_NAME = {
    section: 'ConsultarProposta',
    page: 'DetalheDaProposta'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private dnaService: DnaService,
  ) {
    super();
    this.proposal = {};
    this.base_path = document.documentElement.style.getPropertyValue("--base-path");
  }

  ngOnInit() {
    this.cryptoPipe.transform(this.inputs, 'decrypt')
      .pipe(
        map(data => {
          console.log(data);
          this.proposal = data;
          this.customer_id = data.customer_id;
          this.idproposta = data.id_proposta;
          this.setGaParameters(data);

          this.setStatusClass(this.getStatusClass());
        }
        )).subscribe(() => this.callAnalytics());

    // this.proposal = JSON.parse(this.inputs);
    // this.customer_id = this.proposal.customer_id;
    // this.idproposta = this.proposal.id_proposta;
    // this.setGaParameters(this.proposal);
    // console.log('proposal', this.proposal);
    // this.callAnalytics();
    // this.setStatusClass(this.getStatusClass());

    document.querySelector('proposal-detail').setAttribute('inputs', '');
  }

  setGaParameters(bffParameters): void {
    this.analyticsService.setParameters(
      {
        id_operator: bffParameters.operator_id || '',
        id_parceiro: bffParameters.partner_name || '',
        id_store_operator: bffParameters.store_id || '',
        id_customer: bffParameters.customer_id || 'no_customerid',
        store_name: bffParameters.store_name || '',
        customer_age_range: null
      }
    );
    this.sendDataToAndroid(bffParameters);
  }

  sendDataToAndroid(bffParameters): void {
    const sdtoreData = {
      partner_name: bffParameters.partner_name,
      operator_id: bffParameters.operator_id,
      store_id: bffParameters.store_id,
      store_name: bffParameters.store_name,
      customer_id: this.customer_id
    };
    this.dnaService.post('', 'operatorStoreInfo', sdtoreData, false).subscribe();
  }

  callAnalytics(): void {
    this.analyticsService.trackPageLoadCustomParams(
      this.PAGE_NAME,
      [{ param: 'idproposta', value: this.idproposta }],
      this.customer_id
    );
    this.analyticsService.trackClick(this.PAGE_NAME,
      {
        category: 'Objeto Visto',
        action: 'StatusProposta',
        label: this.proposal.status_proposta
      }
    );
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackEvent('proposal', this.PAGE_NAME, null, [{ param: 'proposal_id', value: this.idproposta }]);
  }

  getStatusClass(): string {
    this.proposal.status_proposta = this.proposal.status_proposta.toLowerCase();
    if (this.proposal.status_proposta != null || this.proposal.status_proposta !== '') {
      if (this.isValidStatus(this.proposal.status_proposta)) { return this.proposal.status_proposta; }
    }

    return 'default';
  }

  /**
   * Retorna a classe CSS de acordo com o valor
   * do status da proposta
   *
   * @param value
   * @return Object
   */
  getClassByStatus(value: string): object {
    value = value ? value.toLocaleLowerCase() : '';

    let obj = {
      analisado: value === 'finalizado',
      recusado: value === 'recusado',
      'em-andamento': value === 'em análise',
    };

    return obj;
  }

  setStatusClass(statusClass): void {
    this.statusClass = 'value ' + statusClass;
  }

  isValidStatus(status): boolean {
    let validStatus = ['em andamento', 'analisado', 'recusado', 'finalizado'];
    return validStatus.includes(status);
  }

  checkIfTheOperatorInitiatedThisProposal() {
    const { user_atual_login, user_login, modal_exibe_botao_continua_proposta } = this.proposal;
    const isSameOperator = user_atual_login === user_login;

    if (isSameOperator) {
      this.ga4Service.trackEvent('click', this.PAGE_NAME, 'ContinuarProposta');
      this.formSubmit(false);
      return;
    }

    if (modal_exibe_botao_continua_proposta == 'true') {
      this.modalConfig = {
        title: 'Atenção!',
        content: `Essa proposta ficou inativa e ${user_login} dava andamento a ela. Tocando em abrir você pode acessar e seguir com a proposta.`,
        primaryButtonLabel: 'abrir a proposta',
        secondaryButtonLabel: 'voltar',
        buttonAction: (event) => this.modalDifferentOperatorAction(event),
      };
    } else {
      this.modalConfig = {
        title: 'Atenção!',
        content: `${user_login}, está com essa proposta em andamento e por isso ela não pode ser acessada agora.`,
        primaryButtonLabel: 'ok, entendi!',
        secondaryButtonLabel: null,
        buttonAction: (event) => this.modalComponent.display = false,
      };
    }

    this.modalComponent.display = true;
  }

  // TODO
  modalDifferentOperatorAction(event) {
    if (event === "primary") {
      this.analyticsService.trackClick(this.PAGE_NAME,
        {
          category: 'Clique',
          action: 'ContinuarPropostaBT',
          label: 'ContinuarProposta'
        }
      );
      this.ga4Service.trackEvent('click', this.PAGE_NAME, 'ContinuarProposta');
      this.formSubmit(false, true);
    }

    if (event === "secondary") {
      this.modalComponent.display = false;
    }
  }

  onClickCancelar(): void {
    this.modalConfig = {
      title: 'Deseja cancelar a proposta?',
      content: `<p>Ao clicar em "Cancelar" essa proposta será definitivamente cancelada e não poderá ser retomada.</p>
        <p>Importante: uma nova proposta poderá ser iniciada para esse CPF na rota "Novo Cartão".</p>`,
      primaryButtonLabel: 'Cancelar',
      secondaryButtonLabel: 'Voltar',
      buttonAction: (event) => this.modalCancelProposalAction(event),
    };

    this.modalComponent.display = true;
    window.scrollTo(0, 0);
  }

  modalCancelProposalAction(event) {
    if (event === "primary") {
      this.analyticsService.trackClick(this.PAGE_NAME,
        {
          category: 'Clique',
          action: 'CancelarPropostaBT',
          label: 'CancelarProposta'
        }
      );
      this.ga4Service.trackEvent('click', this.PAGE_NAME, 'CancelarProposta');
      this.formSubmit(true);
    }

    if (event === "secondary") {
      this.modalComponent.display = false;
      window.scrollTo(0, 0);
    }
  }

  onClickContinuar(): void {
    this.checkIfTheOperatorInitiatedThisProposal();
  }

  formSubmit(confirmCancelation: boolean, confirmModal = false): void {
    this.form.nativeElement.confirm_cancelation.value = confirmCancelation;
    this.form.nativeElement.confirm_modal.value = confirmModal;
    this.form.nativeElement.submit();
    initLoader(window.innerWidth);
  }
}
