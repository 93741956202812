import { Injectable } from '@angular/core';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { mergeObjects } from '../utils';

interface PageName {
  section?: string;
  page?: string;
}

interface EventInfo {
  category?: string;
  action?: string;
  label?: string;
}

interface CoreInfo {
  id_operator: number | string;
  id_parceiro: number | string;
  id_store_operator: string;
  id_customer: string;
  store_name: string;
  customer_age_range: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private analyticsViewService: ItauDigitalAnalyticsView,
    private dnaService: DnaService
  ) { }

  public trackPageLoad(pageName: PageName, customer_id?: string, errorPage?: string): void {
    const coreInfo = this.getCoreInfo();
    const page: any = {
      nome: ['OC:LG:NCC:Mova', pageName.section, pageName.page].filter(Boolean).join(':')
    }
    if (errorPage) {
      page.errorname = errorPage;
     }

    try {
      this.track({
        analytics: {
          rule: 'pageLoad',
          page,
          custom: {
            squadresponsaveltag: 'Canais Exclusivos:GameOfMova',
            parceiroloja: coreInfo.id_parceiro,
            clientagegroup: coreInfo.customer_age_range,
          },
          visitor: {
            companyoperator: coreInfo.id_operator,
            storeid: coreInfo.id_store_operator,
            customerid: customer_id || coreInfo.id_customer
          },
        }
      });
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics PageView', pageName.page, error);
    }
  }

  public trackClick(pageName: PageName, eventInfo: EventInfo): void {
    const coreInfo = this.getCoreInfo();
    try {
      this.track({
        analytics: {
          rule: 'customLink',
          page: {
            nome: ['OC:LG:NCC:Mova', pageName.section, pageName.page].filter(Boolean).join(':')
          },
          custom: {
            squadresponsaveltag: 'Canais Exclusivos:GameOfMova',
            parceiroloja: coreInfo.id_parceiro || ''
          },
          events: eventInfo
        }
      });
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics ClickEvent', pageName.page, error);
    }
  }

  public trackLoginPage(): void {
    try {
      this.track({
        analytics: {
          rule: 'pageLoad',
          page: {
            nome: 'OC:LG:NCC:Mova:Login'
          },
          custom: {
            squadresponsaveltag: 'Canais Exclusivos:GameOfMova',
          }
        }
      });
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics PageView', 'Login', error);
    }
  }

  private sendLog(msg: string, page: string, error): void {
    const objectLog = {
      message: msg,
      page,
      error
    };
    this.dnaService.postCaptureLogs('errorDA', false, false, objectLog);
  }

  public setParameters(data: Partial<CoreInfo>): void {
    const payload = mergeObjects(this.getCoreInfo(), data);

    localStorage.setItem('core_info', JSON.stringify(payload));
  }

  public getCoreInfo(): CoreInfo {
    if (!localStorage.getItem('core_info')) {
      return {
        id_operator: '',
        id_parceiro: '',
        id_store_operator: '',
        id_customer: '',
        store_name: '',
        customer_age_range: '',
      };
    }
    return JSON.parse(localStorage.getItem('core_info'));
  }

  public trackPreLoginPage(): void {
    const coreInfo = this.getCoreInfo();
    try {
      this.track({
        analytics: {
          rule: 'pageLoad',
          page: {
            nome: `OC:NL:NCC:Mova:PreLogin`
          },
          custom: {
            squadresponsaveltag: 'Canais Exclusivos:GameOfMova',
            parceiroloja: coreInfo.id_parceiro,
          },
          visitor: {
            storeid: coreInfo.id_store_operator,
            customerid: ''
          },
        }
      });
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics PageView', 'PreLogin', error);
    }
  }

  public trackPageLoadCustomParams(pageName: PageName, params?: Record<string,unknown>[], customer_id?: string): void {
    const coreInfo = this.getCoreInfo();
    const page: any = {
      nome: ['OC:LG:NCC:Mova', pageName.section, pageName.page].filter(Boolean).join(':')
    };
    const custom = {
      squadresponsaveltag: 'Canais Exclusivos:GameOfMova',
      parceiroloja: coreInfo.id_parceiro,
      clientagegroup: coreInfo.customer_age_range,
    };

    params.forEach(param => {
      Object.assign(custom, param)
    })

    try {
      this.track({
        analytics: {
          rule: 'pageLoad',
          page,
          custom,
          visitor: {
            companyoperator: coreInfo.id_operator,
            storeid: coreInfo.id_store_operator,
            customerid: customer_id
          },
        }
      });
    } catch (error) {
      this.sendLog('Erro Coleta Digital Analytics PageView', pageName.page, error);
    }
  }

  private track(params:any){
    const eventName = (params.analytics||{}).rule;

    this.analyticsViewService.track(params)
    console.log(`[analytics] fired GAU  ${eventName} event`, params)

  }

}
