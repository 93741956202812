import { Component, ElementRef, OnInit } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'denied-product',
  templateUrl: './denied-product.component.html',
  styleUrls: ['./denied-product.component.css']
})
export class DeniedProductComponent extends GlobalProperties {

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'ProdutoNegado'
  };

  constructor(
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    super();
  }

  ngOnInit() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

}
