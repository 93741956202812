<voxel-row class="card-padding">
  <voxel-item dc="12" tc="12" mc="12">
    <div class="card" (click)='sendObj()' [ngClass]="{'selected': selected===true}" >
      <div class="card-layout">
        <div [ngClass]="{'svg-align': svg}">
          <span *ngIf='!svg' class="icon-itaufonts_full_cartao"></span>
          <svg-icon *ngIf='svg' [applyCss]="true" class="iconImg"  src="{{this.path_assets + 'icone_loja.svg'}}"></svg-icon>
        </div>
        <div [ngClass]="{'title-padding': !subtitle, 'subtitle-padding': subtitle}">
          <div *ngIf='subtitle'><p class="subtitle">{{subtitle}}</p></div>
          <div><p *ngIf="title.length <= size" class="title" >{{title}}</p></div>
          <div><p *ngIf="title.length > size" class="title" >{{title | slice:0:size}}...</p></div>
        </div>
      </div>
    </div>
  </voxel-item>
</voxel-row>
