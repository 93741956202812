<div [ngClass]="cardClasses" (click)='clickCard($event)'>
    <voxel-row>
        <voxel-item tc="12" dc="12" mc="12">
          
          <div class="card-col-12">
            <span class="icon-itaufonts_full_cartao icon"></span>
            <p class="customer-name"> {{ customerName }} </p>
            <span [class]="additionalclass">adicional</span>
          </div>
                    
          <br style="clear:both">

          <div class="mobile-view">
            <div style="clear:both">
              <div class="card-col-6">
                <label>nome do cartão</label>
                <p class="title">{{ cardAlias }}</p>
              </div>
              
              <div class="card-col-6">
                  <label>número do cartão</label>
                  <p class="title">{{ cardNumber }}</p>
              </div>
            </div>
  
            <div style="clear:both">
              <div class="card-col-6">
                <label>CPF</label>
                <p class="title">{{ cpf }}</p>
              </div>
    
              <div class="card-col-6">
                <label>bandeira</label>
                <p class="title">{{ flag }}</p>
              </div>
            </div>
          </div>


          <div class="desktop-view">
            <div class="card-col-3">
              <label>CPF</label>
              <p class="title">{{ cpf }}</p>
            </div>

            <div class="card-col-3 cardname">
              <label>nome do cartão</label>
              <p class="title">{{ cardAlias }}</p>
            </div>

            <div class="card-col-3 cardnumber">
                <label>número do cartão</label>
                <p class="title">{{ cardNumber }}</p>
            </div>

            <div class="card-col-3 flag">
              <label >bandeira</label>
              <p class="title">{{ flag }}</p>
            </div>
          </div>
          
          <input type="hidden" [name]="id" [value]="selected" class="cards">

        </voxel-item>
    </voxel-row>  
</div>