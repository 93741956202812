<form id="formServices" asp-action="Index" asp-controller="SelectServicesInsurance" method="post" #form>
  <div class="main-container">
    <voxel-container>
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <h1 class="select-services-insurance-title">Selecione as opções desejadas.</h1>
        </voxel-item>

        <voxel-item dc="12" tc="12" mc="12" *ngIf="this.fields1.length > 0">
          <h2 class="select-services-insurance-subtitle">SERVIÇOS</h2>
        </voxel-item>
      </voxel-row>
    </voxel-container>

    <div *ngFor="let field of this.fields1; let i = index;">
      <generic-select-service-insurance
        [service-id]="field.id"
        [service-name]="field.label"
        [icon]="field.icon"
        accordion-description=" "
        [accordion-invoice]=""
        [accordion-advantages]="field.dropdownValues"
        [has-border-top]="verifyBorder(field.id)"
        [select-type]="field.description"
        [checked]="field.checked"
        (change-value)="setServiceChecked($event.checked, field.id, field.value, i)">
        <input [attr.value]="field?.id" type="text" [name]="field?.listname + '.id'" hidden>
        <input [attr.value]="field?.type" type="text" [name]="field?.listname + '.type'" hidden>
        <input [attr.value]="field?.value" [id]="field.id" type="text" [name]="field?.listname + '.value'" hidden>
        <input [attr.value]="field?.listname" type="text" [name]="field?.listname + '.listname'" hidden>
      </generic-select-service-insurance>
    </div>

    <voxel-container *ngIf="this.fields2.length > 0">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <h2 class="select-services-insurance-subtitle">SEGUROS</h2>
        </voxel-item>
      </voxel-row>
    </voxel-container>

    <div *ngFor="let field of this.fields2; let i = index;">
      <generic-select-service-insurance
        [service-id]="field.id"
        [service-name]="field.label"
        [icon]="field.icon"
        accordion-description=" "
        [accordion-invoice]="setResumo(field)"
        [accordion-advantages]="field.dropdownValues"
        [has-border-top]="verifyBorder(field.id)"
        [select-type]="field.description"
        [checked]="field.checked"
        (change-value)="setServiceChecked($event.checked, field.id, field.value, i)">
        <input [attr.value]="field?.id" type="text" [name]="field?.listname + '.id'" hidden>
        <input [attr.value]="field?.type" type="text" [name]="field?.listname + '.type'" hidden>
        <input [attr.value]="field?.value" [id]="field.id" type="text" [name]="field?.listname + '.value'" hidden>
        <input [attr.value]="field?.listname" type="text" [name]="field?.listname + '.listname'" hidden>
      </generic-select-service-insurance>
    </div>

    <!-- <div *ngFor="let item of this.hiddens">
      <input [attr.value]="item?.id" type="text" [name]="item?.listname + '.id'" hidden>
      <input [attr.value]="item?.type" type="text" [name]="item?.listname + '.type'" hidden>
      <input [id]="item.id" [attr.value]="item?.value" type="text" [name]="item?.listname + '.value'" hidden>
      <input [attr.value]="item?.listname" type="text" [name]="item?.listname + '.listname'" hidden>
    </div> -->

    <div class="btn-content">
      <input [value]="taskId" type="hidden" name="taskid">
      <input [value]="route" type="hidden" name="route">
      <voxel-button submit-button (click)="trackClick()" label="Continuar" [disabled]="validateForm()"></voxel-button>
    </div>


  </div>
</form>
