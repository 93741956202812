import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'voucher-error',
  templateUrl: './voucher-error.component.html',
  styleUrls: ['./voucher-error.component.css']
})
export class VoucherErrorComponent extends GlobalProperties {

  innerWidth: any;
  img: string;

  readonly PAGE_NAME = {section: 'Voucher', page: 'ErroVoucher'};

  constructor(
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
   }

  ngOnInit() {
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_sempermissao.svg';
    // } else {
    //   this.img = '03_dispositivo_nao_autorizado.svg';
    // }

    this.img = '03_dispositivo_nao_autorizado.svg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_sempermissao.svg';
    // } else {
    //   this.img = '03_dispositivo_nao_autorizado.svg';
    // }
  }

}
