import { AppDynamicsService } from './../../shared/services/app-dynamics.service';
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'form-waiting-release',
  templateUrl: './form-waiting-release.component.html',
  styleUrls: ['./form-waiting-release.component.css']
})
export class FormWaitingReleaseComponent extends GlobalProperties {

  readonly PAGE_NAME =       {
    section: 'NovoCartao',
    page: 'AguardandoLiberacaoCartao'
  };

  img: string;

  constructor(
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
    private elementRef: ElementRef,
    private appDynamicsService: AppDynamicsService,
  ) {
    super();
  }

  ngOnInit() {
    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_aguardando_liberacao.svg';
    // } else {
    //   this.img = 'icon_agu_lib.svg';
    // }

    this.img = 'icon_agu_lib.svg';
    this.analyticsService.trackPageLoad(this.PAGE_NAME);
    this.ga4Service.trackPageLoad(this.PAGE_NAME);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_aguardando_liberacao.svg';
    // } else {
    //   this.img = 'icon_agu_lib.svg';
    // }
  }

}
