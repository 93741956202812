<div class="main-header"  *ngIf='this.web === false'>
  <p>Autorizar dispositivo</p>
</div>

<div class="container-img">
  <!-- <img *ngIf="this.innerWidth < 768" src="{{ path_assets + 'ico_aguar_autor.svg' }}">
  <img *ngIf="this.innerWidth > 767" src="{{ path_assets + 'ilustra_aguardandoautorizacao.svg' }}"> -->
  <img src="{{ path_assets + img }}">
</div>

<div class="header">Aguardando autorização …</div>

<div class="body">A solicitação está em andamento, assim que estiver liberado, você poderá utilizar.</div>

<voxel-container>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <a [link]="base_path + '/home'" loader-button>
        <voxel-button class="home" id="button-principal" label="Sair"></voxel-button>
      </a>
    </voxel-item>
  </voxel-row>
</voxel-container>
