import { Component } from '@angular/core';
import { HeaderDemoComponent } from '../shared/header-demo/header-demo.component';
import { ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { GlobalProperties } from '../../shared/global-properties';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  selector: 'consult-proposal',
  templateUrl: './consult-proposal.component.html',
  styleUrls: ['./consult-proposal.component.css']
})
export class ConsultProposalComponent extends GlobalProperties {

  @ViewChild(HeaderDemoComponent) headerDemoComponent: HeaderDemoComponent;

  @Input('inputs') inputs: string;
  @Input('message') message: string;

  arraysearch: any[] = [];
  sidebarmenu: any;
  id_proposta: string;
  page: number;
  submited: boolean;
  title: string = ' ';
  subtitle: string = ' ';
  filter: any[] = [];
  side_menu: string;
  value: string;
  block: boolean
  post: boolean = true;
  scrollPosition: number;
  cancelationSuccess: boolean = false;
  items: Array<any>;
  dialogMessage: string = 'Proposta cancelada com sucesso!';
  readonly PAGE_PROPOSAL = {
    section: 'ConsultarProposta',
    page: 'ListaDePropostas'
  };
  readonly PAGE_VOUCHER = {
    section: 'Voucher',
    page: 'AcompanhamentoDeProposta'
  };

  constructor(
    private cryptoPipe: CryptoPipe,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service
  ) {
    super();
    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  ngOnInit() {
    const { version: appVersion } = require('package.json');
    console.log(appVersion);
  }

  ngAfterViewInit() {

    if (this.message !== undefined) {
      this.cancelationSuccess = true;
      this.dialogMessage = this.message;
    }

    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.saveAnalyticsDataId(data);
        this.setHeader(data);
        this.setProposals(data);
      }
      )).subscribe();

    // const parsed = JSON.parse(this.inputs);
    // this.saveAnalyticsDataId(parsed);
    // this.setHeader(parsed);
    // this.setProposals(parsed);

    document.querySelector('consult-proposal').setAttribute('inputs', '');

    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 500);
  }

  loadProposals() {
    if (this.post) {
      this.post = false;
      this.bindHidden('positionId', window.scrollY);
      initLoader(window.innerWidth);
      this.nextPage();
    }
  }

  /**
   * Requisita a próxima página da lista de proposta
   *
   * @return void
   */
  nextPage() {

    this.page++;

    this.bindHidden('proposalId', null);
    this.bindHidden('cpfSearchId', null);
    this.bindHidden('pageId', this.page);
    this.submit();
  }


  /**
   * Inicia as propriedades básicas da cabeçalho
   *
   * @param json
   */
  setHeader(obj: any) {
    const page = (parseInt(obj.page) || 1);
    this.page = page;
    this.value = obj.cpf_search;
    this.title = obj.menu ? obj.menu.title : '';
    this.subtitle = obj.menu ? obj.menu.subtitle : '';
    this.headerDemoComponent.filter = obj.status_proposal_filter ? obj.status_proposal_filter : '';
    this.headerDemoComponent.status_selected = obj.status_selected ? obj.status_selected : '';
    this.headerDemoComponent.sidebarmenu = obj.menu ? JSON.stringify(obj.menu.side_menu) : null;
    this.headerDemoComponent.onInit();
    this.trackPage(obj.tag);
  }

  saveAnalyticsDataId(data: any) {
    console.log('> backend response: ', data);
    this.analyticsService.setParameters({ id_customer: 'no_customerid',
    store_name: data.store_name || '', customer_age_range: null });
    if (data.partner_name) {
      this.analyticsService.setParameters({ id_parceiro: data.partner_name});
    }
    if (data.store_id) {
      this.analyticsService.setParameters({ id_store_operator: data.store_id});
    }
    if (data.operator_id) {
      this.analyticsService.setParameters({ id_operator: data.operator_id});
    }
  }

  trackPage(page: string): void {
    switch (page) {
      case 'consultProposal':
        this.analyticsService.trackPageLoad(this.PAGE_PROPOSAL, 'no_customerid');
        this.ga4Service.trackPageLoad(this.PAGE_PROPOSAL, 'no_customerid');
        break;
      case 'voucherConsultProposal':
        this.analyticsService.trackPageLoad(this.PAGE_VOUCHER, 'no_customerid');
        this.ga4Service.trackPageLoad(this.PAGE_VOUCHER, 'no_customerid');
        break;
    }
  }

  /**
   * Resgata as propostas para listagem
   *
   * @param json
   */
  setProposals(obj: any) {
    this.arraysearch = obj.proposes;
    // console.log(this.arraysearch);

    this.block = obj.block;
    this.scrollPosition = Number(obj.position);
  }

  /**
   * Insere o valor no input hidden
   *
   * @param id
   * @param value
   */
  bindHidden(id: string, value) {
    const obj = <HTMLElement>document.getElementById(id);
    if (value != null) {
      obj.setAttribute('value', value);
    }
  }

  /**
   * Envia as informações para o back-end para trazer
   * o resultado da pesquisa
   *
   * @param cpf
   * @param idProposal
   * @retun void
   */
  search(event) {
    initLoader(window.innerWidth);
    this.bindHidden('cpfSearchId', event.cpf)
    this.bindHidden('filterSearchId', event.filters)
    this.submit();
  }

  /**
   * Envia as informações para o back-end para
   * prosseguir com a proposta
   *
   * @param idProposal
   * @return void
   */
  goToProposal(proposal) {
    this.bindHidden("proposalId", proposal.id_proposta);
    this.submit();
  }

  /**
   * Submit no formulário para envio das informações
   *
   * @return void
   */
  submit() {
    const search = document.querySelector('.arraysearch') as HTMLElement;
    search.style.display = 'none';

    const form = <HTMLFormElement>document.getElementById('formProposal');
    form.submit();
  }

  closeModal() {
    this.cancelationSuccess = false;
  }

}
