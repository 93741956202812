import {  Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[auto-validate]',
})

export class AutoValidateFieldDirective {

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {

    /*
      Autovalidação é feita através do onBlur que verifica o input
      quando o campo é desfocado
      Diretiva criada apenas para fazer o autofocus e forçar a validação.
    */

    const input = this.el.nativeElement.firstChild.firstChild.querySelector('input');

    if (input != null) {
      /*setTimeout necessario para validar todos os campos ao mesmo tempo*/
      setTimeout(() => {
        input.focus();
    });
    }

  }
}
