import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[grid-col]'
})
export class GridColDirective implements OnInit {

  @Input("grid-col") gridCol:string;

  constructor(private el:ElementRef) { 
    
  }
  
  ngOnInit() {
    if (this.gridCol == 'left') {
      return this.left();
    }

    else if (this.gridCol == 'right') {
      return this.right()
    }

    else {
      return this.middle()
    }
  }

  left() {
    this.el.nativeElement.style.paddingRight = "0px";
    this.el.nativeElement.style.borderRight = "1px solid #DCDCDC";
  }

  right() {
    this.el.nativeElement.style.paddingLeft = "0px";

    let block = this.el.nativeElement;
    let nodes = block.querySelectorAll("select");
    let label = this.el.nativeElement.querySelector("label");

    if (label != null) {
      label.style.marginLeft = "15px";
    }

    let select = <HTMLElement> this.el.nativeElement.querySelector("select");

    if (select != null) {
      select.style.marginLeft = "15px";
    }
  }

  middle() {

  }
}
