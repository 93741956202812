<modal-dialog
    title="Atenção"
    display="true"
    overlay="true"
    btn1="Sim, o voucher foi utilizado corretamente"
    type1="buttonOnly"
    class1="freeline"
    btn2="Voltar para a tela anterior"
    type2="close"
    class2="freeline"
    (call-btn)="onSubmit(form, null)"
  >
    <div subt>
      Após <b style="color:red">clicar em Sim</b>, NÃO será possível visualizar <b style="color:red">e utilizar</b> o voucher. <b style="color:red">Tenha certeza que já utilizou antes de continuar.</b>
    </div>
  </modal-dialog>

<voxel-container class="voucher-print-confirmation">

  <div class="center-div">

    <voxel-row>
      <voxel-item class="header" tc="12" dc="12" mc="12">
        <div class="icon">
          <span class="icon-itaufonts_impressora"></span>
        </div>
        <div class="title">
          <p>CONFIRMAÇÃO DE VOUCHER</p>
        </div>
        <div class="subtitle">
          <p>
            Por favor, confirme se o voucher foi <b style="color:red">gerado e utilizado</b> corretamente.
          </p>
        </div>
      </voxel-item>
    </voxel-row>

    <div class="btn-content">
      <voxel-row>
        <voxel-item dc="12" tc="12" mc="12">
          <form
            id="formConfirmation"
            #form
            asp-action="Index"
            asp-controller="VoucherConfirmation"
            method="post"
          >
            <voxel-button
              id="btnSubmit"
              label="Voucher foi utilizado corretamente"
              (click)="onClickConfirm()"
              style="margin-right: 1rem"
            >
            </voxel-button>

            <voxel-button
              *ngIf="this.device === 'desktop'"
              id="btnPrint"
              label="Erro na utilização. Tentar novamente"
              (click)="onClickPrintTerm()"
            ></voxel-button>
            <voxel-button
              *ngIf="this.device === 'mobile'"
              id="btnPrint"
              label="Erro na exibição. Tentar novamente"
              (click)="onSubmit(form, ipt)"
            ></voxel-button>

            <input #ipt name="keyPdf" hidden />

            <modal-dialog
              #voucher
              overlay="true"
              title="Escolha a quantidade de vias para impressão"
            >
              <div body>
                <custom-input
                  *ngIf="this.dropdown"
                  id="qtdMax"
                  type="dropdown"
                  label="vias para impressão"
                  required="true"
                  listname="qtdMax"
                  [options]="this.dropdown"
                  option-label="key"
                  (change)="getInput($event, 0)"
                  (onBlur)="getInput($event, 0)"
                  (validate)="getInput($event, 0)"
                  style="display: block; margin-bottom: 2rem"
                >
                </custom-input>

                <button
                  (click)="onSubmit(form, ipt)"
                  [disabled]="this.hasFormError()"
                >
                  Continuar
                </button>
                <button
                  class="close"
                  type="submit"
                  (click)="this.voucherModal.display = false"
                >
                  Fechar
                </button>
              </div>
            </modal-dialog>
          </form>
        </voxel-item>
      </voxel-row>
    </div>
  </div>
</voxel-container>
