import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from 'src/app/BG4/shared/global-properties';

@Component({
  selector: 'group-menu',
  templateUrl: './group-menu.component.html',
  styleUrls: ['./group-menu.component.css']
})
export class GroupMenuComponent extends GlobalProperties {

  @Input("array") array: any[];
  @Input("name") name: string;
  @Input("type") type: string;

  widthCarousel: any;
  innerWidth: any;
  columnArray: any;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.type === "HORIZONTAL") {
      this.getWidthCarousel();
    }
    if (this.type === "DEFAULT") {
      this.onResize(event);
    }

    this.array.forEach(element => {
      if(element.query_parameters)
        element.route = element.route + "?" + element.query_parameters;
    })
  }

  getWidthCarousel() {
    let width = 0;
    
    this.array.forEach(element => {
      switch (element.type_item.weight) {
        case 1:
          width += 90;
          break;

        case 2:
          width += 100;
          break;

        case 3:
          width += 130;
          break;

        case 4:
          width += 160;
          break;

        case 5:
          width += 180;
          break;

        default:
          break;
      }
    });
    this.widthCarousel = width;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.type === "DEFAULT") {
      this.columnArray = [[]];
      this.innerWidth = window.innerWidth;

      if (this.innerWidth <= 768) {
        this.columnArray.push([]);
        this.columnArray.push([]);

        let control = 0;
        this.array.forEach(element => {
          if (control === 0) {
            this.columnArray[control].push(element);
            control = 1;
          } else if (control === 1) {
            this.columnArray[control].push(element);
            control = 0;
          }
        });
        this.columnArray.pop();

      } else if (this.innerWidth > 768) {
        this.columnArray.push([]);
        this.columnArray.push([]);
        this.columnArray.push([]);

        let control = 0;
        this.array.forEach(element => {
          if (control === 0) {
            this.columnArray[control].push(element);
            control = 1;
          } else if (control === 1) {
            this.columnArray[control].push(element);
            control = 2;
          } else if (control === 2) {
            this.columnArray[control].push(element);
            control = 0;
          }
        });
        this.columnArray.pop();
      }
    }
  }

}
