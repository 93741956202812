<voxel-container>
  <voxel-row>
    <voxel-item *ngFor="let item of items" tc='4' dc='4' mc='6' style="padding-top: 30px">
      <voxel-card [statusBorderColor]="item.ok ? 'success' : 'critical' ">
        <ng-template #contentPrimary>
          <div style="text-align: center;font-weight: bold">{{item.name}}</div><br><br>
          <div style="line-break: auto">
              Host: {{item.urlTest}}
          </div>
          <br *ngIf="!item.ok">
          <hr *ngIf="!item.ok">
          <div *ngIf="!item.ok"><br>{{item.error}}</div>
        </ng-template>
      </voxel-card>
    </voxel-item>
  </voxel-row>
</voxel-container>