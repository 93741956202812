<voxel-container>
<form>
  <voxel-row>
    <voxel-item dc="12" tc="12" mc="12">
      <img src="{{ path_assets + 'ilustra_analisandoproposta.svg' }}">
    </voxel-item>
  </voxel-row>

  <voxel-row>
    <p class="title-text">Analisando <br> proposta...</p>
  </voxel-row>

  <voxel-row>
    <p class="title-text-2">
      A proposta foi enviada para <br> análise. Em breve você poderá <br> consultar a mudança do status.
    </p>
  </voxel-row>

  <voxel-row>  
      <voxel-button submit-button id="button-consultar-proposta" class="btnConsulta" label="Ir para consultar proposta"></voxel-button>
   </voxel-row>

  <voxel-row> 
      <button submit-button id="button-Home" class="btnHome"> Home </button>
      <!-- <voxel-button submit-button id="button-Home" class="btnHome"><p>Home</voxel-button> -->
  </voxel-row>
</form>

</voxel-container>
