<voxel-row>
  <voxel-item tc="12" dc="12" mc="12" class="generic-select-service-insurance-header">
    <div
      [className]="hasBorderTop ? 'generic-select-service-insurance-header-first-child' : 'generic-select-service-insurance-header'" (click)=this.toogle()>
      <div class="service-insurance-align-icon">
        <span [class]=icon></span>
      </div>

      <div class="generic-select-service-insurance-service-name" [innerHTML]="serviceName">
      </div>


      <div class="service-insurance-align-checkbox">
        <voxel-checkbox *ngIf="selectType == 'SERVIÇOS' || selectType == 'SEGUROS' "
          [disabled]="disable"
          [name]=serviceId [id]=serviceId (checkboxChange)="checkChange($event)" [checked]="checked">
        </voxel-checkbox>
      </div>

      <div class="service-insurance-align-bow">
        <span [class]="show ? 'icon icon-itaufonts_seta_up' : 'icon icon-itaufonts_seta_down'">
        </span>
      </div>
    </div>
  </voxel-item>
</voxel-row>

<voxel-container [hidden]="!this.show">
  <voxel-row>
    <voxel-item tc="12" dc="12" mc="12">
      <div class="generic-select-service-insurance-component-div-container" *ngIf="accordionDescription.length > 0">


        <p class="generic-select-service-insurance-component-p-container" [innerHTML]="accordionDescription"></p>

        <ul class="generic-select-service-insurance-component-ul-container"
          *ngIf="this.accordionAdvantages && this.accordionAdvantages.length > 0">
          <li class="generic-select-service-insurance-component-li-container"
              *ngFor="let item of this.accordionAdvantages">
              <span *ngIf="item.key === 'texto_valor'">
                {{ item.value }}
              </span>
              <span *ngIf="item.key === 'valor'">
                {{ item.value }}
              </span>
              <small *ngIf="item.key === 'description'" [innerHTML]="item.value + '<br><br>'"></small>
              <div class="more" *ngIf="item.key === 'more'" (click)="modalComponent.display = true">
                <a href="#">ver mais</a>
              </div>
              <small *ngIf="item.key !== 'more' && item.key !== 'texto_valor' && item.key !== 'valor' && item.key !== 'description' && item.key !== 'fatura_block'" [innerHTML]="'&#10003;&nbsp;&nbsp;&nbsp;' + item.value"></small>
            </li>
        </ul>

        <ng-content></ng-content>

      </div>
    </voxel-item>
  </voxel-row>
</voxel-container>

<modal-dialog title="Seguro cartão protegido" display="true" overlay="true" (event-btn)="modalComponent.display = false"
  input1="btnClose" btn1="Fechar" type1="close" class1="solid">
  <div body class="modal">
    <div class='modal-elements'>
      <p [innerHTML]="modalContent"></p>
    </div>
  </div>
</modal-dialog>
