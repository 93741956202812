import br from '@angular/common/locales/br';
import { NgModule, Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { createCustomElement } from '@angular/elements';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { ItauSdkCoreAnalyticsModule } from '@app-analytics/analytics';

import { ModuleSettings } from './app.module.registry';
import { CustomDateAdapter } from './BG4/shared/custom-datepicker/custom.date.adapter';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(br, 'pt-BR');

@NgModule({
  declarations: ModuleSettings.declarations,
  imports: [
    ...ModuleSettings.modules,
    NgxMaskModule.forRoot(options),
    ItauSdkCoreAnalyticsModule.forRoot({dual_tagging:false})
  ],
  entryComponents: ModuleSettings.entryComponents,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})


export class AppModule {
  constructor(private injector: Injector) {
    (window as any)._containerGTM = 'GTM-K7756NP';
  }

  ngDoBootstrap() {
    var injectorReference = this.injector;
    for (var propertyName in ModuleSettings.export) {
      var camelCase = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
      customElements.define(camelCase.replace(/([A-Z])/g, "-$1").toLowerCase(),
        createCustomElement(ModuleSettings.export[propertyName], { injector: injectorReference }));
    }
  }
}
