import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { typeOf } from '../utils';

type WebViewWindow = Window & {
  handlerJs: Record<string, (...args: unknown[]) => unknown>;
};


export interface HandleFallbackProps<T> {
  /**
   * função de contingência (plano b) para ambiente NÃO webview. Caso definido, será usado
   *  no lugar do método javascript export do webview desejado
   */
  fallback?: () => T;
}

interface HandleProps<T> extends HandleFallbackProps<T> {
  /**
   * nome do método javascript exporto no webview
   */
  method: string;
  /**
   * argumentos que deve ser definido no método javascript exporto no webview
   */
  args?: unknown | unknown[];
}

/**
 * Serviço para obter os métodos que são exporto da [WebView](https://developer.android.com/reference/android/webkit/WebView)
 */
@Injectable({
  providedIn: 'root'
})
export class WebViewHandlerService {
  /**
   * o nome usado para expor o objeto em JavaScript
   *
   * @ref [`webView.addJavascriptInterface`](https://developer.android.com/reference/android/webkit/WebView#addJavascriptInterface(java.lang.Object,%20java.lang.String))
   */
  protected readonly propTarget = 'handlerJs';
  private readonly retryCount = 1;
  private readonly retryDelayTime = 1000;

  constructor() {}

  /**
   * retorna uma lista contendo todos os métodos disponíveis na webview
   */
  get methods() {
    return window[this.propTarget];
  }

  /**
   * retorna `true` se os métodos do webview estiverem disponíveis
   */
  get enabled(): boolean {
    return !!this.methods;
  }

  /**
   * retorna `true` se o método definido existir na lista
   *  de métodos expostos pelo webview
   */
  existsMethod(name: string): boolean {
    return this.enabled && !!this.methods[name];
  }

  /**
   * analisar e formatar os argumentos
   */
  private parseArguments(args: unknown | unknown[]): unknown[] {
    const argsParsed = Array.isArray(args) ? args : [args];

    return argsParsed
      .filter(arg => ![null, undefined].includes(arg))
      .map(arg => (typeOf(arg) === 'object' ? JSON.stringify(arg) : arg));
  }

  /**
   * retorna a resposta da função passada de contingência `fallback`.
   *
   * @throws {Error} caso `fallback` seja indefinido
   */
  private getFallbackResponse<T>({
    fallback,
  }: HandleFallbackProps<T>): T {
    if (!fallback) {
      throw new Error('Not is webview and fallback undefined');
    }

    return fallback();
  }

  /**
   * obter o retorno do método exporto da _webview_
   *
   * @throws {@link SyntaxError} caso haja algum exceção ao disparar o `method`
   * @throws {@link CustomError} caso o `method` não exista
   *
   * @return deve retornar o valor referente a `T` ou `undefined`.
   */
  private getResponse<T>({
    method,
    args,
  }: HandleProps<T>): T | undefined {
    try {
      const argsParsed = this.parseArguments(args);

      if (!this.existsMethod(method)) {
        throw new Error(`Not found method \`${method}\` in webview`);
      }

      const response = this.methods[method](...argsParsed) as
        | string
        | undefined;

      if (typeof response === 'undefined') return response;

      return JSON.parse(response) as T;
    } catch (error) {
      throw new SyntaxError(
        `Error when executing \`window.${this.propTarget}.${method}\` - ${
          (error as Error).message
        }`
      );
    }
  }

  /**
   * Retorna a resposta do método passado `method` exporto da webview. Caso não esteja em
   *  ambiente _webview_, retorna a resposta da função de contingência `fallback`
   *
   * @param method nome do método exporto do webview que deseja usar
   * @param args lista dos argumentos do método definido
   * @param fallback função de contingência (plano b) que será usado no lugar do método
   *  definido quando o ambiente atual não for um _webview_.
   *
   * @throws pode dispara exceções de {@link getResponse} ou {@link getFallbackResponse}
   */

  handle<T>({ method, args, fallback }: HandleProps<T>): T {
    let response: T;

    if (this.enabled) {
      response = this.getResponse({ method, args }) as T;
    } else {
      response = this.getFallbackResponse({ fallback });
    }

    return response;
  }

  /**
   * Constrói um observável do método definido `method` exporto da
   *  _webview_, quando inscrito, retorna sua resposta. Caso não esteja em
   *  ambiente _webview_, retorna a resposta da função de contingência `fallback`
   *
   * Quando inscrito o observável, é iniciado o monitoramento do processamento
   *  e enviado ao _Splunk_ quando esse observável é concluído.
   *
   *
   * @param method nome do método exporto do webview que deve ser usado
   * @param args lista dos argumentos do método definido
   * @param fallback função de contingência (plano b) que será usado no lugar do método
   *  definido quando o ambiente atual não for um _webview_.
   *
   * @return Um observável que emite a resposta do método definido e depois conclui.
   * @alias {@link handle}
   * @throws pode dispara exceções de {@link getResponse} ou {@link getFallbackResponse}
   */
  handleObservable<T>({
    method,
    args,
    fallback,
  }: HandleProps<Observable<T>>): Observable<T> {
    if (!this.enabled) {
      return this.getFallbackResponse<Observable<T>>({
        fallback,
      });
    }

    return new Observable<T>(subscriber => {
      subscriber.next(this.getResponse({ method, args }));
      subscriber.complete();
    });
  }
}
