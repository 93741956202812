import { Component, OnInit, ElementRef, Renderer2, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'dialog-continue-proposal',
  templateUrl: './dialog-continue-proposal.component.html',
  styleUrls: ['./dialog-continue-proposal.component.css']
})
export class DialogContinueProposalComponent implements OnInit {

  items:Array<any>;

  @ViewChild("itens") actualContent: ElementRef
  constructor(private el: ElementRef, private renderer:Renderer2) { 
    this.items = [
      {
        id: '1',
        content: 'Sim',
        a11yLabel: 'Sim'
      },
      {
        id: '2',
        content: 'Não, criar novo cartão',
        a11yLabel: 'Não, criar novo cartão'
      }
    ];
  }

  ngOnInit() {
    const div = this.renderer.createElement('div');
    div.setAttribute("class", "dialog__container voxel-ph-m voxel-pv-s")

    div.innerHTML = this.mountProposal()

    let dial = this.el.nativeElement.querySelector('voxel-dialog');
    let foot = dial.querySelector('footer');
    this.renderer.insertBefore(foot.parentNode, div, foot)

  }


  mountProposal() {
    return `
      <voxel-item tc="12" dc="12" mc="12">
       <span class="icon-itaufonts_cartao"></span>
      </voxel-item>
      <voxel-item tc="6" dc="6" mc="6">
       <label>Nome</lable>
       <p>Itaúcard 2.0</p>
      </voxel-item>
      `;
  }


  showModalOneLink()
  {

  }

  clickOneLink(){}
}
