<voxel-dialog [(showDialog)]="showModal" [items]="items"
  [description]="messageSuccess" (dialogLinkClick)="closeModal()">
</voxel-dialog>

<!-- MOBILE -->
<div class="container" *ngIf="this.device === 1" id='login-mobile'>
  <div class="background-img" [ngStyle]="{'background-image': 'url(' + this.folderImage + 'foto_login.png)' }"></div>
  <div class="box-login">
    <voxel-row>
      <voxel-item dc="7" tc="12" mc="12">
        <div class="logo-slogan" style='width: 100%; display: flex;'>
          <div>
            <img [src]="this.folderImage + 'Logo_Mova.svg'" height="107.9px" width="108.78px">
          </div>
          <div style="padding-left: 16px; padding-top: 29px;">
            <span class="textCanal">mobilidade <br> de vendas <br> e atendimento</span>
          </div>

        </div>
      </voxel-item>

      <voxel-item dc="5" tc="12" mc="12">
        <voxel-card class="login-form">
          <ng-template #contentPrimary>
            <div>
              <voxel-input id="{{this.name}}" cpfFuncional label="CPF ou Funcional"></voxel-input>
              <span id="{{errorCpf}}" style="font-size: 12px; color: #B71C1C"></span>
              <voxel-input #pwd id="{{this.password}}" type="password" label="Senha"></voxel-input>
              <span id="{{errorPassword}}" style="font-size: 12px; color: #B71C1C"></span>
              <button #btn submit-button noimage="true" [disabled]="pwd.value?.length > 0 ? false : true">Acessar</button>
            </div>
          </ng-template>
        </voxel-card>
      </voxel-item>


      <!-- <voxel-item dc="5" tc="12" mc="12">
        <a class="blankLink" href="{{forgotPassword}}">Esqueci minha senha</a>
      </voxel-item> -->
    </voxel-row>
  </div>
</div>

<!-- WEB -->
<div class="container-web" *ngIf='this.device === 2' id='login-web'>
  <div class="backgroundFilter">
    <img [src]="this.folderImage + '/background_login.png'" class="back-image">
  </div>
  <div class="login-form-background">
    <voxel-row>
      <voxel-item style='margin-left: 7vw; margin-top: 28vh;'>
        <voxel-item>
          <div class="movaLogo">
            <div>
              <img [src]="this.folderImage + 'Logo_Mova.svg'">
            </div>
            <div class="text">
              <span class="webTextCanal">mobilidade <br> de vendas <br> e atendimento</span>
            </div>
          </div>
        </voxel-item>
        <div class="form-div">
          <label class="form-label">Login</label>
          <voxel-input id="{{this.name}}" cpfFuncional label="CPF ou Funcional"></voxel-input>
          <span id="{{errorCpf}}" style="font-size: 12px; color: #B71C1C"></span>
          <voxel-input #pwd id="{{this.password}}" type="password" label="Senha"></voxel-input>
          <span id="{{errorPassword}}" style="font-size: 12px; color: #B71C1C"></span>
          <button #btn submit-button noimage="true" [disabled]="pwd.value?.length > 0 ? false : true">Acessar</button>
          <br>
          <!-- <a style='color: white' class="blankLink" href="{{forgotPassword}}">Esqueci minha senha</a> -->
        </div>
      </voxel-item>
    </voxel-row>
  </div>
  <div class="login-slogan">
    <p align='right' class="slogan-text">Seu novo canal de vendas de serviços.</p>
  </div>
</div>

<!-- TABLET -->
<div *ngIf="this.device === 3" id='login-tablet'>
  <div class="container-tablet" [ngStyle]="{'background-image': 'url(' + this.folderImage + 'background_login.png)' }">
  </div>
  <div class="box-login">
    <voxel-row class="row-position">
      <voxel-item dc="7" tc="12" mc="12" style='margin-bottom: 2.34vh;z-index: 1;'>
        <div style="display: flex; padding-bottom: 40px;">
          <div>
            <img [src]="this.folderImage + 'Logo_Mova.svg'" height="107.9px" width="108.78px">
          </div>
          <div style="padding-top: 35px; padding-left: 20px;">
            <span class="tabletTextCanal">mobilidade <br> de vendas <br> e atendimento</span>
          </div>
        </div>
      </voxel-item>

      <voxel-item dc="5" tc="12" mc="12">
        <voxel-card>
          <ng-template #contentPrimary>
            <div>
              <voxel-input id="{{this.name}}" cpfFuncional label="CPF ou Funcional"></voxel-input>
              <span id="{{errorCpf}}" style="font-size: 12px; color: #B71C1C"></span>
              <voxel-input #pwd id="{{this.password}}" type="password" label="Senha"></voxel-input>
              <span id="{{errorPassword}}" style="font-size: 12px; color: #B71C1C"></span>
              <button #btn submit-button noimage="true" [disabled]="pwd.value?.length > 0 ? false : true">Acessar</button>
            </div>
          </ng-template>
        </voxel-card>
      </voxel-item>

<!-- 
      <voxel-item dc="5" tc="12" mc="12" style='margin-top: 1.56vh; z-index: 100;' class="forgot-password">
        <a class="blankLink" style='color: white; ' href="{{forgotPassword}}">Esqueci minha senha</a>
      </voxel-item> -->
    </voxel-row>
  </div>
</div>
