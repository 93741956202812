import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, take, finalize, tap, map } from 'rxjs/operators';

// import { environment } from 'src/environments/environment.ts';

@Injectable({
    providedIn: 'root'
})
export class CacheRedisService {
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'device_id': 'fake_id' })
    };
    url_api: string
    redirectError: boolean
    urlRedirectError: string = "/warning-error/dna"

    constructor(
        private http: HttpClient
    ) {
        this.url_api = document.documentElement.style.getPropertyValue("--base-path")
    }

    setAws(id: string, redirectError: boolean = true): Observable<any> {
        this.urlRedirectError = "/warning-error/dna"
        this.redirectError = redirectError;
        let observable$ = null;

        let body = { id_device: id }

        observable$ = new Observable(obs => {
            this.http.post(`${this.url_api}/cache-values/SetAws`, JSON.stringify(body), this.httpOptions)
                .pipe(
                    catchError(err => this.handleError(err)),
                    map(data => {
                        this.postCaptureLogs({ bodyapi: body, response: data, type: "Cache", method: "/cache-values/SetAws" }, false)
                        obs.next(data)
                    }
                    )).subscribe();
        })

        return observable$.pipe(
            retry(1),
            catchError(err => this.handleError(err))
        )
    }

    parseUrlApiParameter(){
        if(location.href.includes("movahml.meuportaldenegocios/") || location.href.includes("localhost") || location.href.includes("-hom.cloud.itau.com.br")) {
            return "https://movaapiautenticacao-hom.cloud.itau.com.br/v1/urls"
        }
        else if(location.href.includes("movades.meuportaldenegocios/") || location.href.includes("-dev.cloud.itau.com.br")) {
            return "https://movaapiautenticacao-dev.cloud.itau.com.br/v1/urls"
        }else{
            return "https://movaapiautenticacao.cloud.itau.com.br/v1/urls"
        }
    }

    getBaseUrl(id: string, redirectError: boolean = true): Observable<any> {
        this.urlRedirectError = "/warning-error?error_message=N%C3%A3o%20foi%20poss%C3%ADvel%20carregar%20a%20URL%20do%20sistema.&link_button=%2Flogoff&text_button=Sair&cod_error=ERR.BG4.API.PARAM"
        this.redirectError = redirectError;
        let observable$ = null;

        observable$ = new Observable(obs => {
            this.http.get(`${this.parseUrlApiParameter()}?id_device=${id}`, this.httpOptions)
                .pipe(
                    catchError(err => this.handleError(err)),
                    map(data => {
                        this.postCaptureLogs({ response: data, type: "Toggle", method: "/v1/urls" }, false)
                        obs.next(data)
                    }
                    )).subscribe();
        })

        return observable$.pipe(
            retry(1),
            catchError(err => this.handleError(err))
        )
    }

    get(redirectError: boolean = true): Observable<any> {
        this.urlRedirectError = "/warning-error/dna"
        this.redirectError = redirectError;
        let observable$ = null;

        observable$ = new Observable(obs => {
            this.http.get(`${this.url_api}/cache-values/GetValues`)
                .pipe(
                    catchError(err => this.handleError(err)),
                    map(data => {
                        // console.log(data)
                        this.postCaptureLogs({ response: data, type: "Cache", route: "/cache-values/GetValues" }, false)
                        obs.next(data)
                    }
                    )).subscribe();
        })

        return observable$.pipe(
            retry(1),
            catchError(err => this.handleError(err))
        )
    }

    delete(keyDelete: String, redirectError: boolean = true): Observable<any> {
        this.urlRedirectError = "/warning-error/dna"
        this.redirectError = redirectError;
        let observable$ = null;
        let body = { deleteKey: keyDelete }

        observable$ = new Observable(obs => {
            this.http.post(`${this.url_api}/cache-values/DeleteKey`, JSON.stringify(body), this.httpOptions)
                .pipe(
                    catchError(err => this.handleError(err)),
                    map(data => {
                        this.postCaptureLogs({ response: data, type: "Cache", method: "/cache-values/DeleteKey" }, false)
                        obs.next(data)
                    }
                    )).subscribe();
        })

        return observable$.pipe(
            retry(1),
            catchError(err => this.handleError(err))
        )
    }

    post(body: any, redirectError: boolean = true): Observable<any> {
        this.urlRedirectError = "/warning-error/dna"
        this.redirectError = redirectError;
        let observable$ = null;
        // console.log("postobject", body)

        observable$ = new Observable(obs => {
            this.http.post(`${this.url_api}/cache-values/SetValues`, JSON.stringify(body), this.httpOptions)
                .pipe(
                    catchError(err => this.handleError(err)),
                    map(data => {
                        this.postCaptureLogs({ bodyapi: body, response: data, type: "Cache", method: "/cache-values/SetValues" }, false)
                        obs.next(data)
                    }
                    )).subscribe();
        })

        return observable$.pipe(
            retry(1),
            catchError(err => this.handleError(err))
        )
    }

    postCaptureLogs(errorMessage: any, redirect: boolean, error: boolean = false): void {
        this.urlRedirectError = "/warning-error/dna"
        const response = { result: error? 'error': 'success' }

        console.log("errorMessage", errorMessage)
        console.log("redirect", redirect)
        console.log("error", error)
        console.log("response", response)

        errorMessage["result"] = error ? "ERROR" : "SUCCESS"
        this.http.post('/capture-logs', JSON.stringify({ data: errorMessage }), this.httpOptions)
            .pipe(
                take(1),
                finalize(() => redirect && this.redirectError ? window.location.href = this.urlRedirectError : '')
            )
            .subscribe();
    }

    private handleError(error: any): Observable<never> {
        let errorMessage = '';
        if (error.error && error.error.message) {
            errorMessage = error.error.message; // Get client-side error
        } else if (error.status && error.message) {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`; // Get server-side error
        } else {
            errorMessage = error;
        }

        this.postCaptureLogs(errorMessage, true, true);

        return throwError(errorMessage);
    }
}
