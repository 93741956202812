import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import { Component, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { FormDynamicComponent } from '../../shared/form-dynamic/form-dynamic.component';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { CacheRedisService } from 'projects/commons-util/src/cache/cacheRedis.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';

@Component({
  selector: 'token-dna',
  templateUrl: './token-dna.component.html',
  styleUrls: ['./token-dna.component.css']
})
export class TokenDnaComponent extends FormDynamicComponent {

  @Input()
  input: string;
  field: any;
  mask: any;

  @ViewChild('form') form: HTMLFormElement;

  subscriprions: Subscription[] = [];
  innerWidth: any;
  web: boolean;
  inventory: string;
  failActivation: boolean = false;
  items: Array<any>;
  textInformation: string;
  readonly PAGE_NAME = { section: 'AtivarDispositivo' };

  constructor(
    private cdr: ChangeDetectorRef,
    private dnaService: DnaService,
    private cryptoPipe: CryptoPipe,
    private redisService: CacheRedisService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service,
  ) {
    super();

    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  closeModal() {
    this.failActivation = false;
  }

  getDeviceDataEncrypted() {
    this.subscriprions.push(
      this.dnaService.get('/Device/GetDeviceDataEncrypted', 'GetDeviceDataEncrypted')
        .pipe(
          map(dataEncrypt => {
            this.redisService.post({ id: dataEncrypt.id, message: dataEncrypt.message }).pipe().subscribe();
          })
        )
        .subscribe()
    );
  }

  ngOnInit() {
    this.getDeviceDataEncrypted();
    this.parseJson();
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }
    this.analyticsService.trackPageLoad(this.PAGE_NAME, 'no_customerid');
    this.ga4Service.trackPageLoad(this.PAGE_NAME, 'no_customerid');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 1000) {
      this.web = true;
    } else {
      this.web = false;
    }
  }

  parseJson() {
    if (this.input) {
      const parse = JSON.parse(this.input);
      this.saveAnalyticsDataId(parse);
      this.field = parse.fields[0];
      this.mask = parse.domains.masks.filter(m => m.key === 'token')[0];
      this.inventory = parse.domains.inventory_number;
      this.failActivation = parse.domains.activate_device_error;
      this.textInformation = parse.domains.textInformation;
    }
  }

  saveAnalyticsDataId(data): void {
    this.analyticsService.setParameters(
      {
        id_operator: data.domains.id_operator || '',
        id_parceiro: data.domains.name_parceiro || '',
        id_store_operator: data.domains.id_store_operator || '',
        id_customer: 'no_customerid',
        customer_age_range: null
      },
    );
  }

  onClickSubmit(reenviarEmail: boolean) {
    this.trackClick();
    this.form.nativeElement.SendDeviceActivationEmail.value = reenviarEmail;
    this.form.nativeElement.submit();
    initLoader(window.innerWidth);
  }

  trackClick(): void {
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Clique',
      action: 'AtivarDispBT',
      label: 'ReenviarCod'
    });
    this.ga4Service.trackEvent('click', this.PAGE_NAME, 'AutorizarDisptBT_ReenvCod');
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }

}
