import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelect2Module } from 'ng-select2';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SelectDropDownModule } from 'ngx-select-dropdown';

/* Voxel components */

import {
  VoxelGridModule
} from '@voxel/internet';

import {
  VoxelInputModule,
  VoxelButtonModule,
  VoxelCardModule,
  VoxelHeaderModule,
  VoxelIconModule,
  VoxelSliderModule,
  VoxelStepsModule,
  VoxelListModule,
  VoxelSelectModule,
  VoxelCheckboxModule,
  VoxelAccordionModule,
  VoxelLinkModule,
  VoxelRadioButtonModule,
  VoxelDialogModule

} from '@voxel/mobile';

/* Projects import */

/* Components */
import { FormNewCardComponent } from './BG4/new-card/form-new-card/form-new-card.component';
import { AuthorizeDeviceComponent } from './BG4/DNA_authorization/authorize-device/authorize-device.component';
import { TokenDnaComponent } from './BG4/DNA_authorization/token-dna/token-dna.component';
import { HeaderComponent } from './BG4/core/header/header.component';
import { HeaderDemoComponent } from './BG4/consult-proposal/shared/header-demo/header-demo.component';
import { BlankComponentComponent } from './BG4/shared/blank/blank.component';
import { FormChooseProductComponent } from './BG4/new-card/form-choose-product/form-choose-product.component';
import { FormAnalyzeProposalComponent } from './BG4/new-card/form-analyze-proposal/form-analyze-proposal.component';
import { FormWarningErrorComponent } from './BG4/core/form-warning-error/form-warning-error.component';
import { FormWaitingReleaseComponent } from './BG4/new-card/form-waiting-release/form-waiting-release.component';
import { PreviousAnalysisComponent } from './BG4/new-card/previous-analysis/previous-analysis.component';
import { CardAnalyzeComponent } from './BG4/new-card/shared/card-analyze/card-analyze.component';
import { CardHeaderPhotoDocumentComponent } from './BG4/new-card/shared/card-header-photo-document/card-header-photo-document.component';
import { CardConfirmationComponent } from './BG4/new-card/card-confirmation/card-confirmation.component';
import { DeniedProposalComponent } from './BG4/new-card/denied-proposal/denied-proposal.component';
import { TermSummaryComponent } from './BG4/new-card/term-summary/term-summary.component';
import { FormCaptureFacialBiometry } from './BG4/new-card/form-capture-facial-biometry/form-capture-facial-biometry.component';
import { CardTemplateComponent } from './BG4/new-card/shared/card-template/card-template.component';
import { SmallCardTemplateComponent } from './BG4/new-card/shared/small-card-template/small-card-template.component';
import { CustomCheckboxComponent } from './BG4/shared/custom-checkbox/custom-checkbox.component';
import { StylesControlComponent } from './BG4/shared/styles-control/styles-control.component';
import { ProposalDetailComponent } from './BG4/consult-proposal/proposal-detail/proposal-detail.component';
import { FormAddressComponent } from './BG4/new-card/shared/form-address/form-address.component'
import { DialogContinueProposalComponent } from './BG4/new-card/shared/dialog-continue-proposal/dialog-continue-proposal.component';
import { CustomInputComponent } from './BG4/shared/custom-input/custom-input.component';
import { ModalComponent } from './BG4/shared/modal/modal.component';
import { ConsultProposalComponent } from './BG4/consult-proposal/consult-proposal/consult-proposal.component';
import { FormCustomerComponent } from './BG4/new-card/form-customer/form-customer.component';
import { AdditionalInfoComponent } from './BG4/new-card/additional-info/additional-info.component';
import { CustomDatepickerComponent } from './BG4/shared/custom-datepicker/custom-datepicker.component';
import { AddressDataComponent } from './BG4/new-card/address-data/address-data.component';
import { ProfessionalDataComponent } from './BG4/new-card/professional-data/professional-data.component';
import { AdditionalCardComponent } from './BG4/new-card/additional-card/additional-card.component';
import { SectionComponent } from './BG4/new-card/additional-card/section.component';
import { FormAdditionalCardComponent } from './BG4/new-card/shared/form-additional-card/form-additional-card.component';
import { GetDataForCreditAnalysisComponent } from './BG4/new-card/get-data-for-credit-analysis/get-data-for-credit-analysis.component';
import { PhotoDocumentComponent } from './BG4/new-card/photo-document/photo-document.component';
import { ApprovedRequestComponent } from './BG4/new-card/approved-request/approved-request.component';
import { SelectableCardComponent } from './BG4/core/shared/selectable-card/selectable-card.component';
import { SelectServicesInsuranceComponent } from './BG4/new-card/select-services-insurance/select-services-insurance.component';
import { GenericSelectServiceInsuranceComponent } from './BG4/new-card/shared/generic-select-service-insurance/generic-select-service-insurance.component';
import { ClientIdComponent } from './BG4/cancel-card/client-id/client-id.component';
import { CardCancellationComponent } from './BG4/cancel-card/shared/card-cancellation/card-cancellation.component';
import { FormCardCancellationComponent } from './BG4/cancel-card/form-card-cancellation/form-card-cancellation.component';
import { CardCancellationDataBkpComponent } from './BG4/cancel-card/card-cancellation-data-bkp/card-cancellation-data-bkp.component';
import { CancellationConfirmedComponent } from './BG4/cancel-card/cancellation-confirmed/cancellation-confirmed.component';
import { SystemNotificationsComponent } from './BG4/notification/system-notifications/system-notifications.component';
import { AnalyzingProposalComponent } from './BG4/new-card/analyzing-proposal/analyzing-proposal.component';
import { AwaitingAuthorizationComponent } from './BG4/DNA_authorization/awaiting-authorization/awaiting-authorization.component';
import { UnauthorizedDeviceComponent } from './BG4/DNA_authorization/unauthorized-device/unauthorized-device.component';
import { DeniedProductComponent } from './BG4/new-card/denied-product/denied-product.component';
import { SidebarComponent } from './BG4/core/sidebar/sidebar.component';
import { FormWarningErrorDeviceComponent } from './BG4/core/form-warning-error-device/form-warning-error-device.component';
import { TermProposalDetailComponent } from './BG4/print/term-proposal-detail/term-proposal-detail.component';
import { TermPrintConfirmationComponent } from './BG4/print/term-print-confirmation/term-print-confirmation.component';
import { DeviceDetailComponent } from './BG4/core/device-detail/device-detail.component';
import { CardCancellationDataComponent } from './BG4/cancel-card/card-cancellation-data/card-cancellation-data.component';
import { PrintComprovantDetailComponent } from './BG4/print/print-comprovant-detail/print-comprovant-detail.component';
import { ForgotPasswordComponent } from './BG4/login/forgot-password/forgot-password.component'
import { FormChangePasswordComponent } from './BG4/login/form-change-password/form-change-password.component'
import { FormLoginComponent } from './BG4/login/form-login/form-login.component'
import { FormResetPasswordComponent } from './BG4/login/form-reset-password/form-reset-password.component'
import { InjectUserComponent } from './BG4/DNA_authorization/inject-user/inject-user.component';

/* Modules */

import { CommonsUtilModule } from 'projects/commons-util/src/projects';
import { PercentageBarModule } from 'projects/percentage-bar/src/projects';
import { DropdownListModule } from 'projects/dropdown-list/src/lib/dropdown-list.module';
import { CardHeaderModule } from 'projects/card-header/src/lib/card-header.module';
import { InputDynamicComponent } from './BG4/shared/input-dynamic/input-dynamic.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';
import { GridColDirective } from './BG4/shared/grid-col/grid-col.directive';
import { CaptureDeviceInfosComponent } from './BG4/DNA_authorization/capture-device-infos/capture-device-infos.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { HealthCheckComponent } from './BG4/core/health-check/health-check.component';
import { FormChooseStoreComponent } from './BG4/core/form-choose-store/form-choose-store.component';
import { FormWarningExitFlowComponent } from './BG4/core/form-warning-exit-flow/form-warning-exit-flow.component';
import { HomeComponent } from './BG4/home/home/home.component';
import { GroupMenuComponent } from './BG4/home/shared/group-menu/group-menu.component';
import { CardMenuComponent } from './BG4/home/shared/card-menu/card-menu.component';
import { VoucherDetailComponent } from './BG4/print/voucher-detail/voucher-detail.component';
import { VoucherErrorComponent } from './BG4/print/voucher-error/voucher-error.component';
import { VoucherConfirmationComponent } from './BG4/print/voucher-confirmation/voucher-confirmation.component';
import { SecurityDocumentComponent } from './BG4/DNA_authorization/security-document/security-document.component';
import { UserInformationComponent } from './BG4/login/user-information/user-information.component';
import { UserInformationGenericComponent } from './BG4/login/user-information-error/user-information-generic.component';
import { PreAnalysisComponent } from './BG4/new-card/pre-analysis/pre-analysis.component';
import { MasterPasswordResetComponent } from './BG4/master-password-reset/master-password-reset/master-password-reset.component';
import { TemporaryPasswordComponent } from './BG4/master-password-reset/temporary-password/temporary-password.component';
import { PasswordConfirmationComponent } from './BG4/master-password-reset/password-confirmation/password-confirmation.component';

/*

Registro de todos os elementos utilizados dentro e fora do angular
export: Componentes para serem exportados para utilizar fora do angular em camelCase
entryComponents: Registro de todos os Components usados na aplicaÃ§Ã£o (Criados ou importados)
declarations: Registro de todos os objetos criados nesta aplicaÃ§Ã£o Componentes, directives etc
modules: Modulos utilizados na aplicacao

*/

export const ModuleSettings = {

  export: {
    "FormNewCard": FormNewCardComponent,
    "CustomHeader": HeaderComponent,
    "InputDynamic": InputDynamicComponent,
    "FormChooseProduct": FormChooseProductComponent,
    "FormChooseStore": FormChooseStoreComponent,
    "formWaitingRelease": FormWaitingReleaseComponent,
    "authorizeDevice": AuthorizeDeviceComponent,
    "formWarningError": FormWarningErrorComponent,
    "formWarningExitFlow": FormWarningExitFlowComponent,
    "formAnalyzeProposal": FormAnalyzeProposalComponent,
    "previousAnalysis": PreviousAnalysisComponent,
    "FormWarningErrorDevice": FormWarningErrorDeviceComponent,
    "CardHeaderPhotoDocument": CardHeaderPhotoDocumentComponent,
    "CardConfirmation": CardConfirmationComponent,
    "DeniedProposal": DeniedProposalComponent,
    "TermSummary": TermSummaryComponent,
    "FormCaptureFacialBiometry": FormCaptureFacialBiometry,
    "StylesControl": StylesControlComponent,
    "ProposalDetail": ProposalDetailComponent,
    "DialogContinueProposal": DialogContinueProposalComponent,
    "FormCustomer": FormCustomerComponent,
    "ConsultProposal": ConsultProposalComponent,
    "AdditionalInfo": AdditionalInfoComponent,
    "AddressData": AddressDataComponent,
    "ProfessionalData": ProfessionalDataComponent,
    "AdditionalCard": AdditionalCardComponent,
    "GetDataForCreditAnalysis": GetDataForCreditAnalysisComponent,
    "PhotoDocument": PhotoDocumentComponent,
    "ApprovedRequest": ApprovedRequestComponent,
    "SelectServicesInsurance": SelectServicesInsuranceComponent,
    "ClientId": ClientIdComponent,
    "FormCardCancellation": FormCardCancellationComponent,
    "CardCancellationDataBkp": CardCancellationDataBkpComponent,
    "CancellationConfirmed": CancellationConfirmedComponent,
    "SystemNotifications": SystemNotificationsComponent,
    "AnalyzingProposal": AnalyzingProposalComponent,
    "AwaitingAuthorization": AwaitingAuthorizationComponent,
    "UnauthorizedDevice": UnauthorizedDeviceComponent,
    "DeniedProduct": DeniedProductComponent,
    "CaptureDeviceInfos": CaptureDeviceInfosComponent,
    "HomePage": HomeComponent,
    "HealthCheck": HealthCheckComponent,
    "TokenDna":TokenDnaComponent,
    "TermProposalDetail": TermProposalDetailComponent,
    "TermPrintConfirmation": TermPrintConfirmationComponent,
    "DeviceDetail": DeviceDetailComponent,
    "CardCancellationData": CardCancellationDataComponent,
    "VoucherDetail": VoucherDetailComponent,
    "VoucherError": VoucherErrorComponent,
    "VoucherConfirmation": VoucherConfirmationComponent,
    "PrintComprovantDetail": PrintComprovantDetailComponent,
    "SecurityDocument": SecurityDocumentComponent,
    "UserInformation" : UserInformationComponent,
    "UserInformationGeneric": UserInformationGenericComponent,
    "FormPreAnalysis": PreAnalysisComponent,
    "MasterPasswordReset": MasterPasswordResetComponent,
    "TemporaryPassword": TemporaryPasswordComponent,
    "PasswordConfirmation": PasswordConfirmationComponent,
    "FormLogin": FormLoginComponent,
    "InjectUser": InjectUserComponent
  },

  entryComponents: [
    HomeComponent,
    GroupMenuComponent,
    CardMenuComponent,
    SidebarComponent,
    AwaitingAuthorizationComponent,
    FormNewCardComponent,
    HeaderComponent,
    HeaderDemoComponent,
    BlankComponentComponent,
    InputDynamicComponent,
    FormChooseProductComponent,
    FormChooseStoreComponent,
    FormWaitingReleaseComponent,
    AuthorizeDeviceComponent,
    FormWarningErrorComponent,
    FormWarningExitFlowComponent,
    FormAnalyzeProposalComponent,
    PreviousAnalysisComponent,
    CardAnalyzeComponent,
    CardHeaderPhotoDocumentComponent,
    CardConfirmationComponent,
    DeniedProposalComponent,
    TermSummaryComponent,
    FormCaptureFacialBiometry,
    CardTemplateComponent,
    SmallCardTemplateComponent,
    CustomCheckboxComponent,
    StylesControlComponent,
    ProposalDetailComponent,
    FormAddressComponent,
    DialogContinueProposalComponent,
    CustomInputComponent,
    FormCustomerComponent,
    ProposalDetailComponent,
    ModalComponent,
    ConsultProposalComponent,
    AdditionalInfoComponent,
    CustomDatepickerComponent,
    AddressDataComponent,
    ProfessionalDataComponent,
    FormAdditionalCardComponent,
    AdditionalCardComponent,
    SectionComponent,
    GetDataForCreditAnalysisComponent,
    PhotoDocumentComponent,
    ApprovedRequestComponent,
    SelectableCardComponent,
    SelectServicesInsuranceComponent,
    GenericSelectServiceInsuranceComponent,
    ClientIdComponent,
    FormCardCancellationComponent,
    CardCancellationComponent,
    CardCancellationDataBkpComponent,
    CancellationConfirmedComponent,
    SystemNotificationsComponent,
    AnalyzingProposalComponent,
    UnauthorizedDeviceComponent,
    DeniedProductComponent,
    CaptureDeviceInfosComponent,
    HealthCheckComponent,
    FormWarningErrorDeviceComponent,
    TokenDnaComponent,
    TermProposalDetailComponent,
    TermPrintConfirmationComponent,
    DeviceDetailComponent,
    CardCancellationDataComponent,
    VoucherDetailComponent,
    VoucherErrorComponent,
    VoucherConfirmationComponent,
    PrintComprovantDetailComponent,
    SecurityDocumentComponent,
    UserInformationComponent,
    UserInformationGenericComponent,
    PreAnalysisComponent,
    MasterPasswordResetComponent,
    TemporaryPasswordComponent,
    PasswordConfirmationComponent,
    FormLoginComponent,
    InjectUserComponent
  ],

  declarations: [
    HomeComponent,
    GroupMenuComponent,
    CardMenuComponent,
    SidebarComponent,
    AwaitingAuthorizationComponent,
    FormNewCardComponent,
    HeaderComponent,
    HeaderDemoComponent,
    BlankComponentComponent,
    InputDynamicComponent,
    FormChooseProductComponent,
    FormChooseStoreComponent,
    FormWaitingReleaseComponent,
    AuthorizeDeviceComponent,
    FormWarningErrorComponent,
    FormWarningExitFlowComponent,
    FormAnalyzeProposalComponent,
    PreviousAnalysisComponent,
    CardAnalyzeComponent,
    CardHeaderPhotoDocumentComponent,
    CardConfirmationComponent,
    DeniedProposalComponent,
    TermSummaryComponent,
    FormCaptureFacialBiometry,
    CardTemplateComponent,
    SmallCardTemplateComponent,
    CustomCheckboxComponent,
    StylesControlComponent,
    ProposalDetailComponent,
    FormAddressComponent,
    GridColDirective,
    DialogContinueProposalComponent,
    CustomInputComponent,
    ModalComponent,
    FormCustomerComponent,
    ProposalDetailComponent,
    ConsultProposalComponent,
    AdditionalInfoComponent,
    CustomDatepickerComponent,
    AddressDataComponent,
    ProfessionalDataComponent,
    FormAdditionalCardComponent,
    AdditionalCardComponent,
    SectionComponent,
    GetDataForCreditAnalysisComponent,
    PhotoDocumentComponent,
    ApprovedRequestComponent,
    SelectableCardComponent,
    SelectServicesInsuranceComponent,
    GenericSelectServiceInsuranceComponent,
    ClientIdComponent,
    FormCardCancellationComponent,
    CardCancellationComponent,
    CardCancellationDataBkpComponent,
    CancellationConfirmedComponent,
    SystemNotificationsComponent,
    AnalyzingProposalComponent,
    UnauthorizedDeviceComponent,
    DeniedProductComponent,
    CaptureDeviceInfosComponent,
    HealthCheckComponent,
    FormWarningErrorDeviceComponent,
    TokenDnaComponent,
    TermProposalDetailComponent,
    TermPrintConfirmationComponent,
    DeviceDetailComponent,
    CardCancellationDataComponent,
    VoucherDetailComponent,
    VoucherErrorComponent,
    VoucherConfirmationComponent,
    PrintComprovantDetailComponent,
    SecurityDocumentComponent,
    UserInformationComponent,
    UserInformationGenericComponent,
    PreAnalysisComponent,
    ForgotPasswordComponent,
    FormChangePasswordComponent,
    FormLoginComponent,
    FormResetPasswordComponent,
    MasterPasswordResetComponent,
    TemporaryPasswordComponent,
    PasswordConfirmationComponent,
    InjectUserComponent
  ],

  modules: [
    RouterModule,
    RouterModule.forRoot(

      [
        { path: '', component: BlankComponentComponent }
      ]

    ),
    AngularSvgIconModule,
    NgSelect2Module,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    VoxelInputModule,
    VoxelButtonModule,
    VoxelCardModule,
    VoxelHeaderModule,
    CommonsUtilModule,
    VoxelIconModule,
    VoxelSliderModule,
    VoxelStepsModule,
    PercentageBarModule,
    VoxelGridModule,
    VoxelListModule,
    InlineSVGModule,
    HttpClientModule,
    VoxelSelectModule,
    VoxelCheckboxModule,
    VoxelAccordionModule,
    VoxelLinkModule,
    VoxelGridModule,
    VoxelRadioButtonModule,
    DropdownListModule,
    CardHeaderModule,
    VoxelDialogModule,
    NgxPaginationModule,
    DeviceDetectorModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    CalendarModule,
    InputMaskModule,
    AngularMyDatePickerModule,
    SelectDropDownModule
  ]
}

