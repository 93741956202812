import { Component, OnInit, Input, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { removeLoader } from 'projects/commons-util/src/globalFunctions/loader';

@Component({
  selector: 'security-document',
  templateUrl: './security-document.component.html',
  styleUrls: ['./security-document.component.css']
})

@Injectable()
export class SecurityDocumentComponent implements OnInit {

  API_URL = environment.bff;
  route_pdf = "/modulo-desktop/documentacaoDna"
  route_instalador = "/modulo-desktop/instaladorDna"

  @Input("folder-image") folderImage: string;


  device: any;

  constructor(
    private dnaService: DnaService
  ) {
  }

  ngOnInit() {
  }

  getInstalador() {
    this.dnaService.download(this.route_instalador).subscribe(
      (data: Blob) => {
        var file = new Blob([data], { type: 'application/zip' })
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'instaladorDna.zip';
        document.body.appendChild(a);
        a.click();
        removeLoader()
      });
  }

  getDocumento() {
    this.dnaService.download(this.route_pdf).subscribe(
      (data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'ManualModuloSeguranca.pdf';
        document.body.appendChild(a);
        a.click();
        removeLoader()
      });
  }
  
  baixarInstalador(): void {
    this.getInstalador();
  }

  baixarDocumento(): void {
    this.getDocumento();
  }

}
