import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Input } from '@angular/core';
import { FormAddressComponent } from '../shared/form-address/form-address.component';
import { ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { SubmitButtonDirective } from 'projects/commons-util/src/submit-button/submit-button.directive';
import { initLoader } from 'projects/commons-util/src/globalFunctions/loader';
import { CustomInputComponent } from '../../shared/custom-input/custom-input.component';
import { CryptoPipe } from 'projects/commons-util/src/crypto/crypto.pipe';
import { map } from 'rxjs/operators';
import { CacheRedisService } from 'projects/commons-util/src/cache/cacheRedis.service';
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AppDynamicsService } from '../../shared/services/app-dynamics.service';
import { Ga4Service } from '../../shared/services/ga4.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.css']
})
export class AddressDataComponent implements OnInit {

  @Input("inputs") inputs: string;

  @ViewChild('res') formResidential: FormAddressComponent;
  @ViewChild('com') formCommercial: FormAddressComponent;
  // @ViewChild('oth') formOther: FormAddressComponent;
  @ViewChild('slt') select: CustomInputComponent;

  @ViewChild(SubmitButtonDirective) submitButtonDirective: SubmitButtonDirective;

  readonly PAGE_NAME = {
    section: 'NovoCartao',
    page: 'DadosDeEndereco'
  };

  taskId: string;
  route: string;

  checked: boolean = false;
  options: any[];
  optionsComercial: any[];
  optionsOther: any[];
  selected: string;
  mutavel: boolean = true;
  innerWidth: any;
  submitCep: boolean = true;
  flag: boolean;
  showModal: boolean = false;
  items: Array<any>;

  addConfirm1 = {
    id: '',
    name: '',
    label: '',
    required: '',
    description: '',
    listname: '',
    type: '',
    placeholder: '',
    value: false,
    validation_api: ''
  };

  addConfirm2 = {
    id: '',
    name: '',
    label: '',
    required: '',
    description: '',
    listname: '',
    type: '',
    placeholder: '',
    value: '',
    validation_api: ''
  };

  residencial: any;
  comercial: any;
  other: any;

  error_cep: string;
  flagCep: string;
  customer_id: string;
  // block_endereco_residencial: string;
  // block_endereco_comercial: string;
  // block_endereco_correspondencia: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private redisService: CacheRedisService,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private appDynamicsService: AppDynamicsService,
    private elementRef: ElementRef,
    private ga4Service: Ga4Service
  ) {
    this.optionsOther = [
      { label: 'Residencial', value: 'residencial' }
      // { label: 'Outro', value: 'outro' }
    ];

    this.optionsComercial = [
      { label: 'Residencial', value: 'residencial' },
      { label: 'Comercial', value: 'comercial' }
      // { label: 'Outro', value: 'outro' }
    ];

    this.options = this.optionsOther;
    this.flag = true;
  }

  ngOnInit() {
    this.items = [{ id: '1', content: 'Ok', a11yLabel: 'Ok' }];
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
    if (this.flag && this.formResidential && this.formCommercial) {  // && this.formOther
      this.flag = false;
      this.parseJSON(this.inputs);
    }
    const elementForm: HTMLFormElement = document.querySelector('address-data') as HTMLFormElement;
    if (elementForm) elementForm.setAttribute('inputs', '');
  }

  parseJSON(string: string) {
    this.cryptoPipe.transform(this.inputs, 'decrypt').pipe(
      map(data => {
        this.setFields(data);
        this.customer_id = data.customer_id;
      }
      )).subscribe(() => { this.setMetrics(); });

    // this.setFields(JSON.parse(string));
  }

  setMetrics(): void {
    this.analyticsService.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.ga4Service.trackPageLoad(this.PAGE_NAME, this.customer_id);
    this.appDynamicsService.callAppAdynamics(this.elementRef);
  }


  closeModal() {
    this.showModal = false;
  }

  setFields(obj: any) {
    this.taskId = obj.taskId;
    this.route = obj.route;

    this.flagCep = obj.domains.flagCep;
    this.error_cep = obj.domains.error_cep;

    if (this.error_cep)
      this.showModal = true

    // this.block_endereco_residencial = obj.domains.block_endereco_residencial;
    // this.block_endereco_comercial = obj.domains.block_endereco_comercial;
    // this.block_endereco_correspondencia = obj.domains.block_endereco_correspondencia;

    for (let i = 0; i < obj.fields.length; i++) {
      const field = obj.fields[i];
      if (field.id === 'endereco_residencial') {
        this.residencial = field;
        field.properties.forEach(prop => {
          prop.listname = field.listname + '.' + prop.listname;
          this.formResidential.validateFields.push(!prop.required);
        });
        this.formResidential.fields = field.properties;
      }

      if (field.id === 'endereco_comercial') {
        this.comercial = field;
        this.comercial.listname = field.listname;
        field.properties.forEach(prop => {
          prop.listname = field.listname + '.' + prop.listname;
          this.formCommercial.validateFields.push(!prop.required);
        });

        this.formCommercial.fields = field.properties;
      }

      // if (field.id === 'endereco_correspondencia') {
      //   this.other = field;
      //   this.other.listname = field.listname;
      //   field.properties.forEach(prop => {
      //     prop.listname = field.listname + '.' + prop.listname;
      //     this.formOther.validateFields.push(!prop.required);
      //   });

      //   this.formOther.fields = field.properties;
      // }

      if (field.id === 'endereco_adicional_confirmacao' ||
        field.id === 'endereco_comercial_confirmacao') {

        const aux = field;
        this.addConfirm1 = field;
        if (aux.value) {

          if (typeof aux.value === 'string') {

            this.addConfirm1.value = (aux.value.toLowerCase() === 'true' ? true : false);
          } else {
            this.addConfirm1.value = true;
          }

        } else {
          this.addConfirm1.value = false;
        }

        obj.fields.splice(obj.fields.indexOf(field), 1);
        i--;
      }


      // if (field.id === 'endereco_correspondencia_confirmacao') {
      //   console.log(this.options);
      //   if (field.value == null){
      //     field.value = this.options[0].value;
      //   }

      //   this.addConfirm2 = field;
      //   obj.fields.splice(obj.fields.indexOf(field), 1);
      //   i--;
      // }

      if (this.addConfirm1.value) {
        this.options = this.optionsComercial;
      }

      // this.chekboxChange();
      //this.clearBlock();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  searchCEP(cep, inputCep, type, inputType) {
    let validInputs = [];
    validInputs.push({ key: cep, value: cep });
    if (cep && this.submitCep) {
      document.activeElement instanceof HTMLElement ? document.activeElement.blur() : '';
      this.submitCep = false;
      const obj1 = <HTMLElement>inputType;
      const obj2 = <HTMLElement>inputCep;
      obj1.setAttribute('value', type);
      obj2.setAttribute('value', cep);

      const elementForm: HTMLFormElement = document.getElementById('formAddress') as HTMLFormElement;


      this.redisService.get()
        .pipe(
          map(data => {
            let token: any = data.tokenencrypt;
            let body = {};
            if (validInputs.length > 2) {
              let index1, index2;
              while (index1 === index2) {
                index1 = Math.floor(Math.random() * validInputs.length);
                index2 = Math.floor(Math.random() * validInputs.length);
              }
              body = { message: token, inputs: validInputs.filter((input, i) => i === index1 || i === index2) };
            } else {
              body = { message: token, inputs: validInputs };
            }

            if (token) {
              this.dnaService.post('/Cryptography/TokenEncrypt', 'TokenEncrypt', body)
                .pipe(
                  map(dataEncrypt => {
                    this.redisService.post({ tokenencrypt: dataEncrypt.message }).pipe(
                      map(data => {
                        initLoader(this.innerWidth);
                        elementForm.submit();
                      }
                      )).subscribe();

                  })
                ).subscribe();
            } else {
              this.dnaService.postCaptureLogs('token-id is missing', true, true, 'token-id is missing');
            };

          }
          )).subscribe();
    }
  }

  // clearBlock() {
  //   if (this.block_endereco_residencial == 'false') {
  //     this.formResidential.blockList1 = [];
  //   }
  //   if (this.block_endereco_comercial == 'false') {
  //     this.formCommercial.blockList2 = [];
  //   }
  //   if (this.block_endereco_correspondencia == 'false') {
  //     this.formOther.blockList3 = [];
  //   }
  // }

  /**
   * Verifica se hÃ¡ erros no formulÃ¡rio de dados de endereÃ§o,
   * dependendo da seleÃ§Ã£o de preferÃªncia de correpondÃªncia
   *
   * @return boolean
   */
  hasFormError(): boolean {

    if (this.formResidential.hasFormError()) {
      return true;
    }

    if (this.formCommercial.hasFormError() && this.addConfirm1.value) {
      return true;
    }

    // if (this.formOther.hasFormError() && this.selected === 'outro') {
    //   return true;
    // }

    return false;
  }

  /**
   * Recebe a preferÃªncia do usuÃ¡rio
   *
   * @param $e
   * @rerturn void
   */
  getAddressPref($e) {
    if ($e == undefined) return false;
    this.selected = $e.value;
  }

  chekboxChange() {
    const selectDiv: HTMLElement = document.getElementById('endereco_correspondencia_confirmacao');
    this.addConfirm1.value = !this.addConfirm1.value;
    if (this.addConfirm1.value) {
      this.options = this.optionsComercial;
      selectDiv.setAttribute('data-change', '0');
    } else {
      if (this.select.value !== 'outro') {
        this.select.value = 'residencial';
        this.addConfirm2.value = 'residencial';
      } else {
        this.select.value = 'outro';
        this.addConfirm2.value = 'outro';
      }
      this.options = this.optionsOther;
      selectDiv.setAttribute('data-change', '1');
    }
  }

  public onSubmit(): void {
    const endComercial = this.addConfirm1.value ? 'sim' : 'não';
    this.analyticsService.trackClick(this.PAGE_NAME, {
      category: 'Clique',
      action: 'EnderComercial',
      label: endComercial
    });
    this.ga4Service.trackEvent('click', this.PAGE_NAME, `EnderecoComercial: ${endComercial}`);
  }
}
