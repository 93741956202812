import { Component, OnInit, Input } from '@angular/core';
import { HostListener } from '@angular/core';
import { GlobalProperties } from '../../shared/global-properties';

@Component({
  selector: 'unauthorized-device',
  templateUrl: './unauthorized-device.component.html',
  styleUrls: ['./unauthorized-device.component.css']
})
export class UnauthorizedDeviceComponent extends GlobalProperties {

  @Input("unauthorized-type") unauthorizedType : any;
  innerWidth: any;
  img: string;

  constructor() {
    super();
   }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
      
    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_sempermissao.svg';
    // } else {
    //   this.img = '03_dispositivo_nao_autorizado.svg';
    // }

    this.img = '03_dispositivo_nao_autorizado.svg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    // 29/06/2020 - Thomas - Conforme Andrea (Itau) a ilustração dos bonequinhos
    // são antigas, devendo todas ficar com o modelo novo
    // if (window.innerWidth > 640) {
    //   this.img = 'ilustra_sempermissao.svg';
    // } else {
    //   this.img = '03_dispositivo_nao_autorizado.svg';
    // }
  }

}
