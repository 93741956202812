<form method="post" #form>
  <input type="text" name="domains" [value]="this.biometriaModel" hidden>

  <div class="warning-error">
    <div class="main-header" *ngIf='this.web === false'>
      <p>Validação Biometria</p>
    </div>

    <div *ngIf="this.text != 'error'" class="body">
      <card-header title="BIOMETRIA FACIAL"
        subtitle="Utilizaremos a biometria facial coletada, para processos de identificação e/ ou autenticação nos sistemas eletrônicos do conglomerado Itaú, para fins de segurança e prevenção a fraudes."
        [svg]="this.path_assets + 'icone_biometria.svg'"></card-header>
    </div>

    <div class="btnGroup">
      <voxel-button id="button-next" label="Capturar e validar biometria" loader-button (click)="captureBiometria()">
      </voxel-button>
      <a [link]="base_path + '/logoff'" loader-button>
        <voxel-button id="button-exit" label="Sair"></voxel-button>
      </a>
    </div>

    <voxel-dialog [(showDialog)]="showModal" [items]="items"
      [description]="this.modalDescription" (dialogLinkClick)="closeModal()">
    </voxel-dialog>

  </div>
</form>