import { typeOf } from '../type-of';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Obj = Record<string, any>;

const parse = (target: unknown) => {
  if (typeOf(target) === 'object') return deepCloneFromObject(target as Obj);
  return target;
};

/**
 * Clone profundo de objeto
 * @param target  Objeto alvo que deseja fazer o clone profundo
 * @returns Retorna clone do objeto
 */
export const deepCloneFromObject = (target: Obj) => {
  const output: Obj = {};

  Object.keys(target).forEach(key => {
    const value =
      target[key] instanceof Object ? parse(target[key]) : target[key];

    output[key] = Array.isArray(value) ? value.map(parse) : value;
  });

  return output;
};
