<voxel-container class="confirmed-request">
  <voxel-row>
    <voxel-item tc="12" dc="12" mc="12">
      <div class="img-div">
          <img src="{{ path_assets + img }}">
      </div>
    </voxel-item>
  </voxel-row>

  <div class="content-row">
    <voxel-row>
      <voxel-item tc="12" dc="12" mc="12">
        <div class="title-div">
          <p>Cancelamento confirmado</p>
        </div>
        <div class="subtitle-div">
          <p>O cancelamento foi efetuado com sucesso e seu comprovante será enviado para o e-mail cadastrado.</p>
        </div>
        <div class="request-data-div">
          <p class="title">número do cartão</p>
          <p class="data">{{this.obj?.solicitation.number_protocol}}</p>
        </div>
        <div class="request-data-div">
          <p class="title">data</p>
          <p class="data">{{this.fullDate}}</p>
        </div>
      </voxel-item>
    </voxel-row>
  </div>

  <voxel-row>
    <voxel-item tc="12" dc="12" mc="12">
      <div class="btnGroup">
        <a href="/list-active-card" *ngIf="this.backToCardList == true">
          <voxel-button label='Voltar para lista de cartões' class="primary"></voxel-button>
        </a>
        <a href="/home">
          <voxel-button label='Home' class="secondary"></voxel-button>
        </a>
      </div>
    </voxel-item>
  </voxel-row>
</voxel-container>
