<form id="formAddress" asp-action="Index" asp-controller="CaptureInformationAddress" method="post">
  <voxel-container>
    <voxel-row>
      <voxel-item tc="12" mc="12" dc="12">
        <div class="title">Complete os dados de endereço</div>
        <br>
      </voxel-item>
    </voxel-row>
    <voxel-row>
      <voxel-item tc="12" mc="12" dc="12">
        <div class="subtitle">
          endereço residencial
        </div>
      </voxel-item>
    </voxel-row>
  </voxel-container>

  <input #inputType type="text" name="domains.inputType" hidden>
  <input #inputCep type="text" name="domains.inputCep" hidden>

  <input [attr.value]="this.residencial?.id" type="text" [name]="this.residencial?.listname + '.id'" hidden>
  <input [attr.value]="this.residencial?.type" type="text" [name]="this.residencial?.listname + '.type'" hidden>
  <input [attr.value]="this.residencial?.listname" type="text" [name]="this.residencial?.listname + '.listname'" hidden>
  <form-address #res [flag-cep]="this.flagCep"
    (cepSearch)="searchCEP($event, inputCep, 'endereco_residencial', inputType)">
  </form-address>

  <voxel-container>
    <voxel-row>
      <voxel-item tc="12" mc="12" dc="12">
        <div class="subtitle">
          {{this.addConfirm1.label}}
        </div>
      </voxel-item>
      <voxel-item tc="12" mc="12" dc="12">
        <voxel-checkbox #box1 label="Sim" id="checkbox1" value="true" name="checkbox1"
          [checked]="this.addConfirm1.value" (checkboxChange)="chekboxChange()">
        </voxel-checkbox>
        <voxel-checkbox #box2 label="Não" id="checkbox2" value="false" name="checkbox2"
          [checked]="!this.addConfirm1.value" (checkboxChange)="chekboxChange()">
        </voxel-checkbox>
        <input hidden
          [attr.id]="this.addConfirm1.id"
          [attr.type]="this.addConfirm1.type"
          [attr.label]="this.addConfirm1.label"
          [attr.required]="this.addConfirm1.required"
          [attr.description]="this.addConfirm1.description"
          [attr.listname]="this.addConfirm1.listname"
          [attr.name]="this.addConfirm1.listname + '.value'"
          [attr.value]="this.addConfirm1.value">
        <input hidden [attr.name]="this.addConfirm1.listname + '.id'" [attr.value]="this.addConfirm1.id">
        <input hidden [attr.name]="this.addConfirm1.listname + '.type'" [attr.value]="this.addConfirm1.type">
        <input hidden [attr.name]="this.addConfirm1.listname + '.label'" [attr.value]="this.addConfirm1.label">
        <input hidden [attr.name]="this.addConfirm1.listname + '.required'" [attr.value]="this.addConfirm1.required">
        <input hidden [attr.name]="this.addConfirm1.listname + '.description'" [attr.value]="this.addConfirm1.description">
        <input hidden [attr.name]="this.addConfirm1.listname + '.listname'" [attr.value]="this.addConfirm1.listname">
      </voxel-item>
    </voxel-row>
  </voxel-container>

  <input [attr.value]="this.comercial?.id" type="text" [name]="this.comercial?.listname + '.id'" hidden>
  <input [attr.value]="this.comercial?.type" type="text" [name]="this.comercial?.listname + '.type'" hidden>
  <input [attr.value]="this.comercial?.listname" type="text" [name]="this.comercial?.listname + '.listname'" hidden>
  <form-address #com [hidden]="!this.addConfirm1.value" [flag-cep]="this.flagCep" (cepSearch)="searchCEP($event, inputCep, 'endereco_comercial', inputType)">
  </form-address>

  <!-- <voxel-container>
    <voxel-row>
      <voxel-item tc="12" mc="12" dc="12">
        <custom-input #slt
          [id]="'endereco_correspondencia_confirmacao'"
          type="dropdown"
          [name]="this.addConfirm2.type"
          [label]="this.addConfirm2.label"
          [description]="this.addConfirm2.description"
          [required]="this.addConfirm2.required"
          [placeholder]="this.addConfirm2.placeholder"
          [value]="this.addConfirm2.value"
          [listname]="this.addConfirm2.listname"
          [options]='this.options'
          (validate)="getAddressPref($event)"
          [mutavel]="this.mutavel"
          [validation]="this.addConfirm2.validation_api">
        </custom-input>
      </voxel-item>
    </voxel-row>
  </voxel-container>

  <input [attr.value]="this.other?.id" type="text" [name]="this.other?.listname + '.id'" hidden>
  <input [attr.value]="this.other?.type" type="text" [name]="this.other?.listname + '.type'" hidden>
  <input [attr.value]="this.other?.listname" type="text" [name]="this.other?.listname + '.listname'" hidden>
  <form-address #oth [hidden]="slt.value != 'outro'" [flag-cep]="this.flagCep" >
  </form-address> -->

  <voxel-container>
    <voxel-row>
      <voxel-item tc="12" mc="12" dc="12" data-class="btn-content">
        <input [value]="this.taskId" type="text" name="taskId" hidden>
        <input [value]="this.route" type="text" name="route" hidden>
        <voxel-button #btnSubmit id="btnSubmit" label="Continuar" [disabled]="this.hasFormError()" submit-button (click)="onSubmit()">
        </voxel-button>
      </voxel-item>
    </voxel-row>
  </voxel-container>

  <voxel-dialog [(showDialog)]="showModal" [items]="items"
    description="Ops, parece que o CEP que você digitou não é válido. Verifique no site dos correios o CEP correspondente ao logradouro do cliente e tente novamente."
    (dialogLinkClick)="closeModal()">
  </voxel-dialog>

</form>
<br>