import { Ga4Service } from 'src/app/BG4/shared/services/ga4.service';
import {
  Component,
  Input,
  ChangeDetectorRef
} from "@angular/core";
import { FormDynamicComponent } from "../../shared/form-dynamic/form-dynamic.component";
import { CryptoPipe } from "projects/commons-util/src/crypto/crypto.pipe";
import { map } from "rxjs/operators";
import { DnaService } from 'projects/commons-util/src/crypto/dna.service';
import { AnalyticsService } from "../../shared/services/analytics.service";

@Component({
  selector: 'password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.css']
})
export class PasswordConfirmationComponent extends FormDynamicComponent {
  @Input("inputs") inputs: string;

  domains: any[];
  items: Array<any>;
  cpf: string;
  name: string;
  hasPermission: boolean;
  readonly PAGE_NAME_APROV = {
    section: 'RedefSenhaPopUpAprov'
  };
  readonly PAGE_NAME_INV = {
    section: 'RedefSenhaPopUpInv'
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private cryptoPipe: CryptoPipe,
    private dnaService: DnaService,
    private analyticsService: AnalyticsService,
    private ga4Service: Ga4Service,
  ) {
    super();
    this.items = [
      {
        id: "1",
        content: "Ok",
        a11yLabel: "Ok"
      }
    ];
  }

  ngOnInit() {
    this.cryptoPipe
      .transform(this.inputs, "decrypt")
      .pipe(
        map(data => {
          this.domains = data.domains;
          this.checkDomains(this.domains);
        })
      )
      .subscribe();

    // const data = JSON.parse(this.inputs);
    // console.log(data);
    // this.checkDomains(data.domains);

    document
      .querySelector("password-confirmation")
      .setAttribute("input", "");
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  checkDomains(domains): void {
    this.hasPermission = domains.hasPermission;
    if (this.hasPermission) {
      this.cpf = domains.cpf;
      this.name = domains.name;
      this.analyticsService.trackPageLoad(this.PAGE_NAME_APROV, 'no_customerid');
      this.ga4Service.trackPageLoad(this.PAGE_NAME_APROV, 'no_customerid');
    } else {
      this.analyticsService.trackPageLoad(this.PAGE_NAME_INV, 'no_customerid');
      this.ga4Service.trackPageLoad(this.PAGE_NAME_INV, 'no_customerid');
    }
  }

  trackClick(): void {
    this.analyticsService.trackClick(this.PAGE_NAME_APROV,
      {
        category: 'Clique',
        action: 'BTSenha',
        label: 'GerarNova'
      }
    );
    this.ga4Service.trackEvent('click', this.PAGE_NAME_APROV, 'BTSenha_GerarNovaSenha');
  }

}

