<div class="menu-container" [style.width]="width" [style.top.px]="scrollY">
  <div class="menu-header">
    <span class="title">menu</span>
    <span class="close icon-itaufonts_fechar" (click)="toggleMenu()"></span>
  </div>
  <a *ngFor="let item of itens" (click)="close()" [link]="this.base_path + item.route" loader-button class="ignore-css">
    <div class="menu-item">
      <span *ngIf="display">{{ item.name }} </span>
      <svg-icon [applyCss]="true" class="icon-menu" src="{{this.path_assets + item.icon}}"></svg-icon>
    </div>
  </a>
</div>
<div [ngClass]="{overlay: width != '0%' ? true : false}"></div>
