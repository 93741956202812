<form method="post" #form>

    <input #taskid type="text" name="taskid" [value]="this.objs?.taskId" hidden>
    <input #route  type="text" name="route"  [value]="this.objs?.route"  hidden>
    <input #route  type="text" name="fields[0].id"  [attr.value]="this.fields?.id"  hidden>
    <input #route  type="text" name="fields[0].value"  [attr.value]="this.fields?.value"  hidden>

    <div class="additional-card">

        <card-header [title]="this.fields?.label" [subtitle]="this.fields?.description">
        </card-header>

        <div class="message">
            <voxel-container>
                <voxel-row>
                    <voxel-item tc="12" dc="12" mc="12">
                        <div class="message-text">
                            <p [innerHTML]="this.objs?.domains?.descricao + '&nbsp;' + this.objs?.domains?.descricaoValor"></p>
                        </div>
                    </voxel-item>
                </voxel-row>
            </voxel-container>
        </div>

        <section id="additional-card-form" active="true" class='cards'></section>

        <div class="position-buttons">
            <div class="spacing__margin-top-xs">
                <voxel-container>
                    <voxel-row>
                        <voxel-item tc="12" mc="12" dc="12">
                            <voxel-button [disabled]="!this.enabledAdd" label="Adicionar cartão" class="secondary" (click)="addCard()">
                            </voxel-button>
                        </voxel-item>
                    </voxel-row>
                </voxel-container>
            </div>

            <div class="spacing__margin-top-xs spacing__margin-bottom-xs">
                <voxel-container>
                    <voxel-row>
                        <voxel-item tc="12" mc="12" dc="12">
                            <voxel-button class="primary" submit-button [disabled]="!this.enabledSubmit" label="Continuar" (click)="callAnalytics()">
                            </voxel-button>
                        </voxel-item>
                    </voxel-row>
                </voxel-container>
            </div>
        </div>
    </div>
</form>
