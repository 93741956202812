import { Component, OnInit, ElementRef, Renderer2, ViewChild, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.css']
})
export class FormLoginComponent implements OnInit {

  @Input("name") name: string;
  @Input("password") password: string;
  @Input("id-error-cpf") errorCpf: string;
  @Input("id-error-password") errorPassword: string;
  @Input("folder-image") folderImage: string;
  @Input("link-forgot-password") forgotPassword: string;

  form: HTMLFormElement;
  innerWidth: any;

  showModal: boolean = false;
  items: Array<any>;
  messageSuccess: string;

  device: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDevice();
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private el: ElementRef,
    private analyticsService: AnalyticsService
  ) {
    this.innerWidth = window.innerWidth;
    this.items = [
      {
        id: '1',
        content: 'Ok',
        a11yLabel: 'Ok'
      }
    ];
  }

  ngOnInit() {
    this.analyticsService.trackLoginPage();
    this.setDevice();
    this.messageSuccess = "-1";

    var urlParams = new URLSearchParams(document.location.search);
    if (urlParams.has('changesuccess') && urlParams.get("changesuccess") === 'true'){
      this.showModal = true;

      if(this.messageSuccess == "-1")
        this.messageSuccess = "Troca de senha efetuada com sucesso."
    }
  }

  closeModal(){ this.showModal = false }

  setDevice() {
    if (window.innerWidth < 640) {
      this.device = 1;
    } else if (window.innerWidth < 1024) {
      this.device = 3;
    } else {
      this.device = 2;
    }
  }

}
