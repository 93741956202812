<!-- <voxel-container>
    <div class="card">
      <table >
        <tr>
            <td class="table-alignment">
              <i class='{{this.icon}}' ></i>
            </td>
            <td class="value-name" >{{this.name}}</td>
            <td style='padding: 10px;'>
              <label class="value-label">Valor mensal</label><br>
              <p class="value">{{this.value}}</p>
            </td>
          </tr>
        </table>
    </div>
  </voxel-container> -->

<voxel-container>
  <div class="card">
    <div class="icon">
        <i class='{{this.icon}}' ></i>
    </div>
    <div class="name">
      <p [innerHTML]="this.name"></p>
    </div>
    <div class="month-value">
      <p class="value-label">{{this.label}}</p>
      <span [innerHTML]="this.value"></span>
    </div>
  </div>
</voxel-container>
